import { useState, useEffect } from "react";
import cn from "classnames";
import {
  // Notifications,
  // AddAccount,          //for future use
  // RemoveAccount,       //for future use
  DisputesWidget,
  RecentOrderHeader,
  RecentDisputes,
  Loading,
  SingleAccountBreadcrumb,
} from "components";
import { IconRender, _helper } from "elements";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import {
  postCreateUserRequest,
  getOpenDisputesRequest,
  getOpenDisputesByAccountRequest,
  getRecoveredDisputesRequest,
  getRecoveredDisputesByAccountRequest,
} from "redux/actions";
import { setItem, getItem, removeItem } from "utils/localStorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./style.module.scss";

export default function Home() {
  const dispatch = useDispatch();
  const [isTokenFetch, setIsTokenFetch] = useState(false);

  const navigate = useNavigate();
  const AddedAccount = getItem("AddAccount");
  const SingleAccountCheck = getItem("singleAccountCheck");
  const { accountId } = useParams();

  const { user = {}, getAccessTokenSilently } = useAuth0();

  const userData = useSelector((state: any) => state.authStore);

  const { isLoading, isError, isSuccess } = userData.userDetails;
  const onboardBoolean = localStorage.getItem("isOnboard");

  useEffect(() => {
    if (
      user &&
      !user["https://trackipal.com/onboardstatus"] &&
      isSuccess &&
      !onboardBoolean
    ) {
      toast.success("User account successfully created", {
        theme: "colored",
      });
      navigate("/paypal");
    } else if (
      user &&
      !user["https://trackipal.com/onboardstatus"] &&
      isError
    ) {
      toast.error("Oops! Create user account fail", {
        theme: "colored",
      });
    }
  }, [userData]);

  const tokenHandler = async () => {
    try {
      const token = await getAccessTokenSilently();
      // const token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlF0enp6N2JrenV4bTZvYk1mb3paaiJ9.eyJpc3MiOiJodHRwczovL2NoYXJnZXBheS51cy5hdXRoMC5jb20vIiwic3ViIjoiYXV0aDB8NjZhYjBmMjJmMzBiYWE3MTIxZjYzMmYzIiwiYXVkIjpbImh0dHBzOi8vY2hhcmdlcGF5LnVzLmF1dGgwLmNvbS9hcGkvdjIvIiwiaHR0cHM6Ly9jaGFyZ2VwYXkudXMuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTcyNDEyNTUyMiwiZXhwIjoxNzI0MjExOTIyLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXpwIjoic05mdTNxUW1SZFlySmlrb2pCWlFWbnVnV2hRSVRnNG8ifQ.ntObyn9DpcU77E_DicH8PrbZNxEgRcz38CMGXfBHvozYToAxgDW1NfJIJ4Pzk71LU20MkSJ6-1IhqVCgabZcDfJolt_szugu6cFkBo3cv_v4WWyM2uwQ8rXkhPS_tjgELz7zZA573y7VHBRjDmd7D5u6uPmgQlfYlO2CS9ATeo52-P6_vWXfVLGs6UGbgrW8WH7jZVqOlEB7o8qZrq3GIpaNF0cw3c2hxeY0ULuc4ybyjL1nRNgautBD3oqvpe3GcIhO41Thf0d5URbynRGGl7zdFYycmbCgN1E0YtG8gKi14oRMglS7af1k_x_cZazcGvv2z1cR0s9W0k3YS47aJQ";

      setItem("accessToken", token);
      setIsTokenFetch(true);
      const onboardstatus = user["https://trackipal.com/onboardstatus"];

      if (user && onboardstatus === false && !onboardBoolean) {
        const payload = {
          userId: user.sub || null,
          email: user.email || null,
          username: user.name || null,
          picture: user.picture || null,
          name: user.name || null,
          nickname: user.nickname || null,
          familyName: user.familyName || null,
          birthDate: user.birthDate || null,
          telephone: user.telephone || null,
          planId: 1,
        };
        dispatch(postCreateUserRequest(payload));
      }
    } catch (error: any) {
      toast.error("Oops! Something went wrong", {
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    tokenHandler();
  }, [user?.email]);

  const { openDisputesData, recoveredDisputesData, recentDisputesData } =
    useSelector((state: any) => state.dashboard);
  const openDisputesObj = {
    title: "Open Disputes",
    blueEye: true,
    icon: <IconRender iconName="layersThree" />,
    totalDisputes: openDisputesData?.data?.totalDisputes || 0,
    loading: openDisputesData.isLoading,
  };
  const activeDisputesObj = {
    title: "Total Amount Disputed(Open)",
    blueEye: true,
    icon: <IconRender iconName="activity" />,
    disputedAmount: openDisputesData?.data?.totalChallengedAmount || 0,
    loading: openDisputesData.isLoading,
  };

  const recoveredDisputesObj = {
    title: "Recovered Disputes",
    blueEye: true,
    icon: <IconRender iconName="currencyDollar" />,
    totalDisputes: recoveredDisputesData?.data?.totalDisputes || 0,
    loading: recoveredDisputesData.isLoading,
  };

  const totalDisputesWonObj = {
    title: "Total Amount Recovered(Won)",
    blueEye: true,
    icon: <IconRender iconName="trophy" />,
    disputedAmount: recoveredDisputesData?.data?.totalChallengedAmount || 0,
    loading: recoveredDisputesData.isLoading,
  };

  const recentDisputesHeading = {
    title: "Recent Disputes",
    description:
      !recentDisputesData?.isLoading &&
      recentDisputesData?.isSuccess &&
      recentDisputesData?.data &&
      recentDisputesData?.data?.length > 0 &&
      `You have ${
        recentDisputesData?.data?.length > 10
          ? 10
          : recentDisputesData?.data?.length
      } active disputes that needs your attention`,
    viewAll:
      !recentDisputesData?.isLoading &&
      recentDisputesData?.isSuccess &&
      recentDisputesData?.data &&
      recentDisputesData?.data?.length > 0 &&
      true,
    link: SingleAccountCheck ? `disputes/${accountId}` : "disputes",
  };

  useEffect(() => {
    if (isTokenFetch) {
      if (SingleAccountCheck) {
        dispatch(getOpenDisputesByAccountRequest(accountId));
        dispatch(getRecoveredDisputesByAccountRequest(accountId));
      } else {
        dispatch(getOpenDisputesRequest());
        dispatch(getRecoveredDisputesRequest());
      }
    }
  }, [isTokenFetch, SingleAccountCheck]);

  useEffect(() => {
    if (AddedAccount === "true") {
      setTimeout(() => {
        toast.success("Account have been added successfully", {
          theme: "colored",
        });
        removeItem("AddAccount");
      }, 5000);
    } else if (AddedAccount === "false") {
      setTimeout(() => {
        toast.error("Oops! Something went wrong. Please try again.", {
          theme: "colored",
        });
        removeItem("AddAccount");
      }, 5000);
    }
  }, []);

  return (
    <div className={styles.HomeWrapper}>
      <ToastContainer />
      {isLoading || !isTokenFetch ? (
        <Loading />
      ) : (
        <div className={styles.HomeBody}>
          {/* <Notifications.Upgrade /> */}

          {SingleAccountCheck && <SingleAccountBreadcrumb />}

          <div>
            <h2>
              {_helper.greeting()}, {user?.nickname}
            </h2>
          </div>
          <div className={styles.cardsWrapper}>
            <div className={styles.main}>
              <div className={styles.disputesWrapper}>
                {/* <div className={styles.card}>
                  <div className="group" style={{ display: "flex" }}>
                    <DisputesWidget
                      mockComponent={true}
                      disputeWidgetData={openDisputesObj}
                    />
                    <DisputesWidget
                      mockComponent={true}
                      disputeWidgetData={recoveredDisputesObj}
                    />
                  </div>
                  <div className="group" style={{ display: "flex" }}>
                    <DisputesWidget
                      mockComponent={true}
                      disputeWidgetData={activeDisputesObj}
                    />

                    <DisputesWidget
                      mockComponent={true}
                      disputeWidgetData={totalDisputesWonObj}
                    />
                  </div>
                </div> */}

                <div className={styles.card}>
                  <DisputesWidget disputeWidgetData={openDisputesObj} />
                </div>

                <div className={styles.card}>
                  <DisputesWidget disputeWidgetData={activeDisputesObj} />
                </div>

                <div className={styles.card}>
                  <DisputesWidget disputeWidgetData={recoveredDisputesObj} />
                </div>

                <div className={styles.card}>
                  <DisputesWidget disputeWidgetData={totalDisputesWonObj} />
                </div>
              </div>

              <div
                className={styles.recentDisputes}
                data-testid="recentDisputes"
              >
                <RecentOrderHeader headingData={recentDisputesHeading} />
                <div className={cn(styles.card, styles.recentDisputesTable)}>
                  <RecentDisputes />
                </div>
              </div>
            </div>
          </div>

          {/* <AddAccount
              isOpen={isAddAccountOpen}
              onClose={onAddAccountClose}
              name="Add account"
            /> */}
          {/* <RemoveAccount
              isOpen={isRemoveAccountOpen}
              onClose={onRemoveAccountClose}
              account={account}
              onRemove={onRemoveAccountClose}
            /> */}
        </div>
      )}
    </div>
  );
}
