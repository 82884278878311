import { TextField, Button, Box } from "@mui/material";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { postShopSaveCompanyPoliciesAddonRequest } from "redux/actions";
import styles from "./style.module.scss";

interface CompanyBackgroundProp {
  setShopAddonCheck: React.Dispatch<React.SetStateAction<boolean>>;
}

type CompanyBackgroundFormProps = {
  companyName: string;
  background: string;
  line1: string;
  line2: string;
  state: string;
  zip: string;
  countryCode: string;
  country: string;
};

const CompanyBackground = ({ setShopAddonCheck }: CompanyBackgroundProp) => {
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const { shopFindShopAddon } = useSelector((state: any) => state?.storesShop);

  // Initializing react-hook-form with default values
  const { control, handleSubmit, reset } = useForm<CompanyBackgroundFormProps>({
    defaultValues: {
      companyName: "",
      background: "",
      line1: "",
      line2: "",
      state: "",
      zip: "",
      countryCode: "",
      country: "",
    },
  });

  // Fetching initial data and setting form values
  useEffect(() => {
    if (shopFindShopAddon?.isSuccess && shopFindShopAddon?.data) {
      const { companyName, background, address } = shopFindShopAddon.data;
      reset({
        companyName: companyName || "",
        background: background || "",
        line1: address?.line1 || "",
        line2: address?.line2 || "",
        state: address?.state || "",
        zip: address?.zip || "",
        countryCode: address?.countryCode || "",
        country: address?.country || "",
      });
    }
  }, [shopFindShopAddon, reset]);

  // Handling form submission
  const onSubmit = (data: CompanyBackgroundFormProps) => {
    const payload = {
      id: null,
      companyName: data.companyName,
      background: data.background,
      refundPolicy: shopFindShopAddon?.data?.refundPolicy || null,
      termsAndConditions: shopFindShopAddon?.data?.termsAndConditions || null,
      deliveryPolicy: shopFindShopAddon?.data?.deliveryPolicy || null,
      refundPolicyUrl: shopFindShopAddon?.data?.refundPolicyUrl || null,
      termsAndConditionsUrl: shopFindShopAddon?.data?.termsAndConditionsUrl || null,
      deliveryPolicyUrl: shopFindShopAddon?.data?.deliveryPolicyUrl || null,
      address: {
        id: null,
        line1: data.line1,
        line2: data.line2,
        state: data.state,
        zip: data.zip,
        countryCode: data.countryCode,
        country: data.country,
      },
      returnShippingAddress: {
        id: null,
        line1: shopFindShopAddon?.data?.returnShippingAddressLine1 || null,
        line2: shopFindShopAddon?.data?.returnShippingAddressLine2 || null,
        state: shopFindShopAddon?.data?.returnShippingAddressState || null,
        zip: shopFindShopAddon?.data?.returnShippingAddressZip || null,
        countryCode: shopFindShopAddon?.data?.returnShippingAddressCountryCode || null,
        country: shopFindShopAddon?.data?.returnShippingAddressCountry || null,
      },
      shopId: Number(storeId),
    };
    setShopAddonCheck(true);
    dispatch(postShopSaveCompanyPoliciesAddonRequest(payload));
  };

  return (
    <div className={styles.formWrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formBuild}>
          <Box
            display="flex"
            justifyContent="space-between"
            gap={1}
            flexWrap="wrap"
            maxWidth="87%"
          >
            <div className={styles.inputbox}>
              <label htmlFor="companyName">Company Name</label>
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    placeholder="Company name"
                  />
                )}
              />
            </div>

            <div className={styles.textFields}>
              <div className={styles.message}>
                <label>Background</label>
                <Controller
                  name="background"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Type background here..."
                      multiline
                      rows={4}
                      className={styles.textField}
                    />
                  )}
                />
              </div>
            </div>
          </Box>

          {/* Address Section */}
          <div className={styles.addressWrapper}>
            <label htmlFor="address" className={styles.addressHead}>
              Address:
            </label>

            <div className={styles.linesAddressWrapper}>
              <div className={styles.linesAddress}>
                <label>Line1</label>
                <Controller
                  name="line1"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      placeholder="Enter Value Line 1"
                    />
                  )}
                />
              </div>
              <div className={styles.linesAddress}>
                <label>Line2</label>
                <Controller
                  name="line2"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      placeholder="Enter Value Line 2"
                    />
                  )}
                />
              </div>
            </div>

            <div className={styles.addressWithCodes}>
              <div className={styles.addressState}>
                <label>State</label>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      placeholder="Enter State Value"
                    />
                  )}
                />
              </div>
              <div className={styles.addressCode}>
                <label>ZIP</label>
                <Controller
                  name="zip"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      placeholder="XXXX"
                    />
                  )}
                />
              </div>
              <div className={styles.addressCode}>
                <label>Country Code</label>
                <Controller
                  name="countryCode"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      placeholder="XXXX"
                    />
                  )}
                />
              </div>
              <div className={styles.addressState}>
                <label>Country</label>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      placeholder="Enter Country Value"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <Button variant="contained" type="submit">
          Save
        </Button>
      </form>
    </div>
  );
};

export default CompanyBackground;

