import { useEffect, useState } from "react";
import { Loading, Pagination } from "components";
import styles from "./style.module.scss";
import { BreadcumbItem } from "components/ui";
import { useBreadCumbs } from "hooks";
import MacrosTable from "components/macros";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getMissingMacrosRequest } from "redux/actions";

const Macros = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const pageLength = 15;
  const {
    data: missingMacrosData,
    isSuccess,
    isLoading,
    isError,
  } = useSelector((state: any) => state?.disputeResponse?.getMacros);
  const { breadCumbs } = useBreadCumbs({ pageName: "Macros" });
  useEffect(() => {
    const payload = {
      page,
      pageLength,
      disputeResponseInfo: "MISSING_INFO",
      responseSection: "HTML",
    };
    dispatch(getMissingMacrosRequest(payload));
  }, [page]);

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };
  return (
    <div className={styles.disputesWrapper}>
      <div className={styles.nav}>
        <h3>Macros</h3>
        <div className={styles.navPath}>
          {breadCumbs.map((item, key) => (
            <BreadcumbItem {...item} {...{ key }} />
          ))}
        </div>
      </div>
      <div>
        {isLoading ? (
          <div style={{ background: "#fff" }}>
            <Loading />
          </div>
        ) : (
          <MacrosTable
            {...{
              data: missingMacrosData?.disputeResponseMacrosMenus,
              isLoading,
              isSuccess,
              isError,
            }}
          />
        )}
      </div>
      {Math.ceil(missingMacrosData?.count / pageLength) > 1 && (
        <div>
          <Pagination
            onPageChange={(pageNum) => onPageChange(pageNum)}
            page={page}
            totalPages={Math.ceil(missingMacrosData?.count / pageLength)}
          />
        </div>
      )}
    </div>
  );
};

export default Macros;
