import { useState } from "react";
import { ProgressLine, Option, Trial } from "components";

import styles from "./style.module.scss";

const PaddleBuyPage = () => {
  const isConnected = true;
  const [isTrial, setIsTrial] = useState<boolean>(false);

  const handleTrial = () => {
    setIsTrial(true);
  };

  return (
    <div className={styles.paddleWrapper}>
      <ProgressLine isConnected={isConnected} isTrial={isTrial} />
      <div className={styles.bottomPaddle}>
        {!isTrial ? <Trial handleTrial={handleTrial} /> : <Option />}
      </div>
    </div>
  );
};

export default PaddleBuyPage;
