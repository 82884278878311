import { useNavigate, useParams, useLocation } from "react-router-dom";
import { accountBreadcrumb } from "utils/accountsUtils";
import styles from "./style.module.scss";

const SingleAccountBreadcrumb = () => {
  const navigate = useNavigate();
  const { accountId } = useParams();

  const location = useLocation();

  const handleNavigate = (link: string) => {
    navigate(`${link}/${accountId}`);
  };

  return (
    <>
      <div className={styles.nav}>
        <h3>{accountId}</h3>
        <div className={styles.navPath}>
          {accountBreadcrumb.map((page, index) => {
            return (
              <p
                data-testid={`breadcrumb_${page.title}`}
                key={page.title}
                onClick={() => handleNavigate(page.link)}
                className={
                  location.pathname.includes(`${page.link}`)
                    ? styles.active
                    : undefined
                }
              >
                {page.title}
              </p>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SingleAccountBreadcrumb;
