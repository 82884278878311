import {
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "white",
    maxWidth: 320,
    minWidth: 200,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #225CCB",
  },
}));

export default HtmlTooltip;
