import { Button, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { svgIcons } from "elements";
// import { getDisputeResponsePdfRequest } from "redux/actions";
import styles from "./style.module.scss";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import TiptapEditor from "components/disputeTemplate/TiptapEditor";
import { useResponseSaveContent } from "hooks";
import { isEmpty } from "lodash";
import Loading from "components/loading/loading";
import { useDispatch } from "react-redux";
import { FullscreenExit, Fullscreen } from "@mui/icons-material";
import {
  getDisputeResponseFetchContentRequest,
  getDisputeResponsePdfByIdRequest,
  getDisputeUpdateStatusSubmittedRequest,
} from "redux/actions";
// import { Viewer } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "./sweetAlert.scss";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface disputeResponsePreviewProp {
  disputeResponsePreview: {
    id: number;
    disputeId: string;
    disputeTemplateId: number;
    fields: any;
    missingFields: string | any;
    products: any;
    submittedAt: string;
    status: string;
  };
  isSubmitted: boolean;
}

const DisputeResponsePreview = ({
  disputeResponsePreview,
  isSubmitted,
}: disputeResponsePreviewProp) => {
  const [pdfSwitch, setPdfSwitch] = useState(false);
  const dispatch = useDispatch();
  const [pdfDownload, setPdfDownload] = useState(false);
  const [pdfData, setPdfData] = useState<any>();
  const { data, isSuccess, isLoading } = useSelector(
    (state: any) => state?.disputeResponse?.fetchedContent
  );
  const disputeResponseFindByDisputeId = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponseFindByDisputeId
  );
  const { disputeDetails } = useSelector(
    (state: any) => state?.disputeDetailsStore
  );
  const paymentProcessor = disputeDetails?.data?.paymentProcessor;
  const {
    handleUpdateSaveContent,
    data: responseData,
    isSavedContentSuccess,
    isSavedContentLoading,
  } = useResponseSaveContent();
  const [previewEditorContent, setPreviewEditorContent] = useState("");

  // adding the closing tag of col and img and br
  const htmlContent = previewEditorContent?.replace(/<\/img>/g, "");
  const modifiedHtmlContent = htmlContent
    .replace(/<img(\s[^>]*?)?>/g, "<img$1/>")
    .replace(/<col(\s[^>]*?)?>/g, "<col$1/>")
    .replace(/<br(?!\/)>/g, "<br/>");

  const handleValidate = () => {
    const payload = {
      disputeResponseId: disputeResponsePreview?.id,
      htmlContent: modifiedHtmlContent ?? data?.htmlContent,
      summaryText: data?.summaryContent,
    };
    handleUpdateSaveContent(payload);
  };

  const handleApprove = () => {
    const payload = disputeResponsePreview?.id;
    Swal.fire({
      title: `Are you aware of this button which will publish the response to ${paymentProcessor}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!",
      customClass: {
        title: "customPopupTitle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Approve!",
          text: "Dispute status has been approved!",
          icon: "success",
        });
        dispatch(getDisputeUpdateStatusSubmittedRequest(payload));
      }
    });
  };
  const { isLoading: isPdfResponseLoading } = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponsePdfByResponseId
  );

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isEmpty(data?.htmlContent) && isSuccess) {
      setPreviewEditorContent(data?.htmlContent);
    }
  }, [data?.htmlContent]);

  useEffect(() => {
    if (isSavedContentSuccess) {
      dispatch(getDisputeResponseFetchContentRequest(data?.disputeResponseId));
      // here isSavaedContentSuccess should be false after fetching data
    }
  }, [isSavedContentSuccess]);

  const disputeResponsePdf = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponsePdf
  );

  const disputeResponsePdfById = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponsePdfByResponseId
  );
  // const handleReview = () => {
  //   const payload = {
  //     templateId: disputeResponsePreview?.disputeTemplateId,
  //     disputeId: disputeResponsePreview?.disputeId,
  //   };
  //   setPdfSwitch(true);
  //   dispatch(getDisputeResponsePdfRequest(payload));
  // };
  useEffect(() => {
    if (
      pdfSwitch &&
      disputeResponsePdf?.isSuccess &&
      disputeResponsePdf?.data
    ) {
      const pdfUrl = window.URL.createObjectURL(
        new Blob([disputeResponsePdf?.data], { type: "application/pdf" })
      );
      setPdfData(pdfUrl);
    }
  }, [disputeResponsePdf, pdfSwitch]);
  function downloadPdfFromBlob(blobResponse: any, filename = "download.pdf") {
    // Create a blob from the
    const blob = new Blob([blobResponse], { type: "application/pdf" });

    // Create a URL for the blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");

    // Set link attributes
    link.href = blobUrl;
    link.download = filename;

    // Append link to the body
    document.body.appendChild(link);

    // Trigger download
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  }
  //need to work on the fixation of multiple downloads
  useEffect(() => {
    if (
      disputeResponsePdfById?.isSuccess &&
      disputeResponsePdfById?.data &&
      pdfDownload
    ) {
      downloadPdfFromBlob(
        disputeResponsePdfById?.data,
        `${disputeResponseFindByDisputeId?.data[0]?.disputeId}_${disputeResponseFindByDisputeId?.data[0]?.disputeTemplateId}.pdf`
      );
      setPdfDownload(false);
    }
  }, [disputeResponsePdfById, pdfSwitch, pdfDownload]);
  const handlePdfResponse = () => {
    setPdfDownload(true);
    const payload = disputeResponsePreview?.id;
    dispatch(getDisputeResponsePdfByIdRequest(payload));
  };

  return (
    <div
      className={styles.disputeWrapper}
      style={{
        position: isOpen ? "fixed" : "initial",
        top: isOpen ? "-19px" : "",
        left: isOpen ? "0" : "",
        background: isOpen ? "#fff" : "",
        width: isOpen ? "100%" : "",
        height: isOpen ? "100vh" : "",
        zIndex: isOpen ? "10000" : "",
        padding: isOpen ? "20px 35px" : "",
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={styles.manageHead}>
            <h5>Dispute response preview</h5>
            {!isSubmitted && (
              <div className={styles.headButtons}>
                {/*
                <div className={styles.actionButtons}>
                <Button
                  variant="outlined"
                  onClick={handleValidate}
                  className={styles.queue}
                >
                  Validate
                </Button>
                <Button
                  variant="outlined"
                  className={styles.review}
                  onClick={handleReview}
                >
                  <img src={svgIcons.Eye_2} alt="review" />
                  <p>Review</p>
                </Button>
              </div>
               */}
                <div className={styles.actionButtons}>
                  <Tooltip
                    title={isOpen ? "click to collapse" : "click to expand"}
                    placement={isOpen ? "bottom" : "top"}
                    arrow
                    style={{ zIndex: "1000" }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() =>
                        isOpen ? setIsOpen(false) : setIsOpen(true)
                      }
                    >
                      {isOpen ? <FullscreenExit /> : <Fullscreen />}
                    </Button>
                  </Tooltip>

                  <Button
                    variant="contained"
                    className={styles.send}
                    onClick={handlePdfResponse}
                    disabled={isPdfResponseLoading ? true : false}
                    style={{
                      background: isPdfResponseLoading
                        ? "rgba(0, 0, 0, 0.12)"
                        : "",
                    }}
                  >
                    {isPdfResponseLoading ? "loading..." : "PDF"}
                  </Button>
                  {disputeResponsePreview?.status === "QUEUED" && (
                    <Button
                      onClick={handleApprove}
                      variant="contained"
                      className={styles.send}
                    >
                      Approve
                    </Button>
                  )}
                  <Button
                    onClick={handleValidate}
                    variant="contained"
                    className={styles.send}
                    style={{
                      background: isSavedContentLoading
                        ? "rgba(0, 0, 0, 0.12)"
                        : "",
                    }}
                  >
                    {isSavedContentLoading ? "Loading..." : "Save Changes"}
                  </Button>
                  {disputeResponsePreview.status === "READY_FOR_SUBMISSION" && (
                    <Button
                      onClick={handleValidate}
                      variant="contained"
                      className={styles.send}
                    >
                      Send
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          <div
            // className={styles.disputePreview}
            className={
              isOpen ? styles.disputePreviewFullScreen : styles.disputePreview
            }
            style={{ height: isOpen ? "100vh" : "" }}
          >
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <TiptapEditor
                  noToolBox
                  setEditorContent={setPreviewEditorContent}
                  editorData={data?.htmlContent}
                  fullWidth={isOpen ? true : false}
                />
                {/* <Viewer
              fileUrl="/assets/pdf-open-parameters.pdf"
              plugins={[defaultLayoutPluginInstance]}
            /> */}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DisputeResponsePreview;
