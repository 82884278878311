import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface disputeTemplateRes {
  data: {
    id: number;
    value: string;
    klarnaRequestId: string;
  }[];
  status: number;
}

export function* getKlarnaByDisputeRequest(action: any) {
  try {
    const res: disputeTemplateRes = yield Api.getKlarnaRequestedByDisputeId(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getKlarnaByDisputeError());
    }else{
      yield put(actions.getKlarnaByDisputeSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getKlarnaByDisputeError());
  }
}
