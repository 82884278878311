import DisputeFlow from "components/disputeFlow";
import styles from "./style.module.scss";
const DisputeFlows = ({ hideHeader }: { hideHeader?: boolean }) => {
  return (
    <div
      className={styles.disputesWrapper}
      style={
        hideHeader ? { paddingLeft: 0, paddingRight: 0, minHeight: 0 } : {}
      }
    >
      <DisputeFlow {...{ hideHeader }} />
    </div>
  );
};

export default DisputeFlows;
