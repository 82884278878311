import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

interface Props {
  children?: React.ReactNode
}

const Auth0ProviderWithHistory = ({ children }: Props) => {
  const domain= process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId= process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri= process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URI;
  const audience= process.env.REACT_APP_AUTH0_AUDIENCE;
  
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      // @ts-ignore
      domain={domain}
      // @ts-ignore
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;