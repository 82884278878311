import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
import { configs } from '../../../configs/index';
export class PostAccountList {
    /**
     * Function to invoke to get account list
     */
    async invoke(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_ACCOUNT_LIST, payload, { baseURL: configs.BASE_URL as string });

        return res;
    }
}