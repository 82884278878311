import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface DisputeObj {
    "id": string,
    "createTime": string,
    "updateTime": string,
    "status": string,
    "reason": string,
    "disputeState": string,
    "disputeLifeCycleStage": string,
    "order": string,
    "storeOrderId": string,
    "shopName": string,
    "shopType": string,
    "sellerMerchantId": string,
    "disputeAmountValue": number,
    "disputeAmountCurrencyCode": string,
    "sellerResponseDueDate": string,
    "buyerResponseDueDate": string,
    "sellerTransactionId": string,
    "paypalId": string
}

interface ResProp {
    data: {
        count: number,
        disputeResponseList: DisputeObj[],
    },
    status: number
}

export function* getReportingListRequest(action: any) {
    try {
        const res: ResProp = yield Api.getReportingList(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getReportingListError());
        } else {
            yield put(actions.getReportingListSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getReportingListError());
    }
}
export function* getByStoresReportingListRequest(action: any) {
    try {
        const res: ResProp = yield Api.getByStoresReportingList(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getByStoresReportingListError());
        } else {
            yield put(actions.getByStoresReportingListSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getByStoresReportingListError());
    }
}
export function* getByPspReportingListRequest(action: any) {
    try {
        const res: ResProp = yield Api.getByPspReportingList(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getByPspReportingListError());
        } else {
            yield put(actions.getByPspReportingListSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getByPspReportingListError());
    }
}
export function* getByReasonsReportingListRequest(action: any) {
    try {
        const res: ResProp = yield Api.getByReasonsReportingList(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getByReasonsReportingListError());
        } else {
            yield put(actions.getByReasonsReportingListSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getByReasonsReportingListError());
    }
}
export function* getByMonthlyReportingListRequest(action: any) {
    try {
        const res: ResProp = yield Api.getByMonthlyReportingList(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getByMonthlyReportingListError());
        } else {
            yield put(actions.getByMonthlyReportingListSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getByMonthlyReportingListError());
    }
}