import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    disputeTemplate: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeTemplateList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    disputeTemplateFullList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    disputeTemplateReasonList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeTemplateActionList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeTemplateStageList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeTemplateFindByType: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    disputeTemplateFindByStore: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeTemplateFilterTemplates: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeTemplateView: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    disputeTemplatepreviewPdf: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    disputeTemplateFetchContent: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
}

//dispute-template-creator
const postDisputeTemplateRequest = (state: any, action: any) => {
    return update(state, {
        disputeTemplate: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postDisputeTemplateSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTemplate: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postDisputeTemplateError = (state: any, action: any) => {
    return update(state, {
        disputeTemplate: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-template-list
const getDisputeTemplateListRequest = (state: any, action: any) => {
    return update(state, {
        disputeTemplateList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeTemplateListSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTemplateList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getDisputeTemplateListError = (state: any, action: any) => {
    return update(state, {
        disputeTemplateList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// dispute-template-full-list
const getDisputeTemplateFullListRequest = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFullList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeTemplateFullListSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFullList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getDisputeTemplateFullListError = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFullList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-template-reason-list
const getDisputeTemplateReasonListRequest = (state: any, action: any) => {
    return update(state, {
        disputeTemplateReasonList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeTemplateReasonListSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTemplateReasonList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeTemplateReasonListError = (state: any, action: any) => {
    return update(state, {
        disputeTemplateReasonList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-template-action-list
const getDisputeTemplateActionListRequest = (state: any, action: any) => {
    return update(state, {
        disputeTemplateActionList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeTemplateActionListSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTemplateActionList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeTemplateActionListError = (state: any, action: any) => {
    return update(state, {
        disputeTemplateActionList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-template-stage-list
const getDisputeTemplateStageListRequest = (state: any, action: any) => {
    return update(state, {
        disputeTemplateStageList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeTemplateStageListSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTemplateStageList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeTemplateStageListError = (state: any, action: any) => {
    return update(state, {
        disputeTemplateStageList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-template-find-by-type
const getDisputeTemplateFindByTypeRequest = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFindByType: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeTemplateFindByTypeSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFindByType: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getDisputeTemplateFindByTypeError = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFindByType: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//dispute-template-find-by-store
const getDisputeTemplateFindByStoreRequest = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFindByStore: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeTemplateFindByStoreSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFindByStore: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getDisputeTemplateFindByStoreError = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFindByStore: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-template-filter-templates
const postDisputeTemplateFilterTemplatesRequest = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFilterTemplates: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postDisputeTemplateFilterTemplatesSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFilterTemplates: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const postDisputeTemplateFilterTemplatesError = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFilterTemplates: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-template-view
const getDisputeTemplateViewRequest = (state: any, action: any) => {
    return update(state, {
        disputeTemplateView: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeTemplateViewSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTemplateView: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getDisputeTemplateViewError = (state: any, action: any) => {
    return update(state, {
        disputeTemplateView: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//dispute-template-preview-pdf
const getDisputeTemplatePreviewPdfRequest = (state: any, action: any) => {
    return update(state, {
        disputeTemplatepreviewPdf: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeTemplatePreviewPdfSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTemplatepreviewPdf: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeTemplatePreviewPdfError = (state: any, action: any) => {
    return update(state, {
        disputeTemplatepreviewPdf: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//dispute-template-fetch-content
const getDisputeTemplateFetchContentRequest = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFetchContent: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeTemplateFetchContentSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFetchContent: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeTemplateFetchContentError = (state: any, action: any) => {
    return update(state, {
        disputeTemplateFetchContent: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


export default handleActions(
    {
        [constants.POST_DISPUTE_TEMPLATE_REQUEST]: postDisputeTemplateRequest,
        [constants.POST_DISPUTE_TEMPLATE_SUCCESS]: postDisputeTemplateSuccess,
        [constants.POST_DISPUTE_TEMPLATE_ERROR]: postDisputeTemplateError,

        [constants.GET_DISPUTE_TEMPLATE_LIST_REQUEST]: getDisputeTemplateListRequest,
        [constants.GET_DISPUTE_TEMPLATE_LIST_SUCCESS]: getDisputeTemplateListSuccess,
        [constants.GET_DISPUTE_TEMPLATE_LIST_ERROR]: getDisputeTemplateListError,

        [constants.GET_DISPUTE_TEMPLATE_FULL_LIST_REQUEST]: getDisputeTemplateFullListRequest,
        [constants.GET_DISPUTE_TEMPLATE_FULL_LIST_SUCCESS]: getDisputeTemplateFullListSuccess,
        [constants.GET_DISPUTE_TEMPLATE_FULL_LIST_ERROR]: getDisputeTemplateFullListError,

        [constants.GET_DISPUTE_TEMPLATE_REASON_LIST_REQUEST]: getDisputeTemplateReasonListRequest,
        [constants.GET_DISPUTE_TEMPLATE_REASON_LIST_SUCCESS]: getDisputeTemplateReasonListSuccess,
        [constants.GET_DISPUTE_TEMPLATE_REASON_LIST_ERROR]: getDisputeTemplateReasonListError,

        [constants.GET_DISPUTE_TEMPLATE_ACTION_LIST_REQUEST]: getDisputeTemplateActionListRequest,
        [constants.GET_DISPUTE_TEMPLATE_ACTION_LIST_SUCCESS]: getDisputeTemplateActionListSuccess,
        [constants.GET_DISPUTE_TEMPLATE_ACTION_LIST_ERROR]: getDisputeTemplateActionListError,

        [constants.GET_DISPUTE_TEMPLATE_STAGE_LIST_REQUEST]: getDisputeTemplateStageListRequest,
        [constants.GET_DISPUTE_TEMPLATE_STAGE_LIST_SUCCESS]: getDisputeTemplateStageListSuccess,
        [constants.GET_DISPUTE_TEMPLATE_STAGE_LIST_ERROR]: getDisputeTemplateStageListError,

        [constants.GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_REQUEST]: getDisputeTemplateFindByTypeRequest,
        [constants.GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_SUCCESS]: getDisputeTemplateFindByTypeSuccess,
        [constants.GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_ERROR]: getDisputeTemplateFindByTypeError,

        [constants.GET_DISPUTE_TEMPLATE_FIND_BY_STORE_REQUEST]: getDisputeTemplateFindByStoreRequest,
        [constants.GET_DISPUTE_TEMPLATE_FIND_BY_STORE_SUCCESS]: getDisputeTemplateFindByStoreSuccess,
        [constants.GET_DISPUTE_TEMPLATE_FIND_BY_STORE_ERROR]: getDisputeTemplateFindByStoreError,

        [constants.POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_REQUEST]: postDisputeTemplateFilterTemplatesRequest,
        [constants.POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_SUCCESS]: postDisputeTemplateFilterTemplatesSuccess,
        [constants.POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_ERROR]: postDisputeTemplateFilterTemplatesError,

        [constants.GET_DISPUTE_TEMPLATE_VIEW_REQUEST]: getDisputeTemplateViewRequest,
        [constants.GET_DISPUTE_TEMPLATE_VIEW_SUCCESS]: getDisputeTemplateViewSuccess,
        [constants.GET_DISPUTE_TEMPLATE_VIEW_ERROR]: getDisputeTemplateViewError,

        [constants.GET_DISPUTE_TEMPLATE_PREVIEW_PDF_REQUEST]: getDisputeTemplatePreviewPdfRequest,
        [constants.GET_DISPUTE_TEMPLATE_PREVIEW_PDF_SUCCESS]: getDisputeTemplatePreviewPdfSuccess,
        [constants.GET_DISPUTE_TEMPLATE_PREVIEW_PDF_ERROR]: getDisputeTemplatePreviewPdfError,

        [constants.GET_DISPUTE_TEMPLATE_FETCH_CONTENT_REQUEST]: getDisputeTemplateFetchContentRequest,
        [constants.GET_DISPUTE_TEMPLATE_FETCH_CONTENT_SUCCESS]: getDisputeTemplateFetchContentSuccess,
        [constants.GET_DISPUTE_TEMPLATE_FETCH_CONTENT_ERROR]: getDisputeTemplateFetchContentError,
    },
    initialState
);