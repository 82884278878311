/**
 * Custom hook that fetches and manages state for viewing a store.
 *
 * Fetches store details, dispute templates, and dispute flows for the given store ID.
 * Manages state like the store details array, Shopify view badge, and saving shop add-on.
 * Handles events like tab changes.
 */
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getShopFindShopAddonRequest,
  getShopFindShopRequest,
  getDisputeTemplateFindByStoreRequest,
  getDisputeFlowFindByStoreRequest,
} from "redux/actions";

interface store {
  title: string;
  data: string | number;
}
const useStoreView = () => {
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const [value, setValue] = useState(0);
  const [storeDetails, setStoreDetails] = useState<store[]>([]);
  const [shopAddonCheck, setShopAddonCheck] = useState(false);
  const [viewInShopify, setViewInShopify] = useState<boolean>(false);

  const { shopsSaveAddon, shopFindShop, shopFindShopAddon } = useSelector(
    (state: any) => state?.storesShop
  );
  const { disputeTemplateFindByStore } = useSelector(
    (state: any) => state.disputeTemplate
  );
  const { disputeFlowFindByStore } = useSelector(
    (state: any) => state.disputeFlow
  );
  const handleStoreData = () => {
    const storeData = [
      {
        title: "Name:",
        data: shopFindShop?.data?.name,
      },
      {
        title: "Owner:",
        data: shopFindShop?.data?.shopOwner,
      },
      {
        title: "Country:",
        data: shopFindShop?.data?.country,
      },
      {
        title: "Domain:",
        data: shopFindShop?.data?.myShopifyDomain,
      },
      {
        title: "Email:",
        data: shopFindShop?.data?.email,
      },
      {
        title: "Created:",
        data:
          shopFindShop?.data?.createdAt &&
          moment(shopFindShop?.data?.createdAt).format("MMM DD, YYYY, hh:mm A"),
      },
      {
        title: "Subscribed:",
        data: shopFindShop?.data?.subscriptionDate,
      },
      {
        title: "Collaborator Access:",
        data: shopFindShop?.data?.collaboratorAccess,
      },
    ];

    setStoreDetails(storeData);
  };

  const handleBadges = () => {
    if (
      shopFindShop?.data?.shopType?.toLowerCase() === "shopify" &&
      shopFindShop?.data?.externalId
    ) {
      setViewInShopify(true);
    }
  };

  useEffect(() => {
    handleBadges();
    handleStoreData();
  }, [shopFindShop]);

  useEffect(() => {
    if (shopAddonCheck && shopsSaveAddon?.isSuccess) {
      toast.success("Shop save add on successful", {
        theme: "colored",
      });
      storeId && dispatch(getShopFindShopAddonRequest(storeId));
      setShopAddonCheck(false);
    } else if (shopAddonCheck && shopsSaveAddon?.isError) {
      toast.error("Oops! something went wrong", {
        theme: "colored",
      });
      setShopAddonCheck(false);
    }
  }, [shopsSaveAddon]);

  useEffect(() => {
    if (storeId) {
      dispatch(getShopFindShopRequest(storeId));
      dispatch(getShopFindShopAddonRequest(storeId));
    }
  }, [storeId]);
  useEffect(() => {
    if (storeId) {
      dispatch(getDisputeTemplateFindByStoreRequest(storeId));
      dispatch(getDisputeFlowFindByStoreRequest(storeId));
    }
  }, [storeId]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return {
    viewInShopify,
    storeDetails,
    value,
    shopFindShop,
    shopFindShopAddon,
    shopsSaveAddon,
    disputeTemplateFindByStore,
    disputeFlowFindByStore,
    handleChange,
    setShopAddonCheck,
  };
};

export default useStoreView;
