import { svgIcons } from "elements";

export const disputeAiResolver = [
    {
        disputeState: "Item not received",
        aiDisputeResolverOptions: [
            {
                optionTitle: "Order Delivered",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The order was placed in our shopify store by the customer and we shipped it on time according to our policies. The ordered items were also delivered to the customer on 22th June 2022 to the given shipping address. Attached delivery proof show the details.",
                            para2: "Below is the Tracking Link that shows the products are delievered."
                        },
                        viewTrackingInformation: true,
                        chooseDate: true,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Customer Messages*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: true
                        },
                        {
                            documentTitle: "Shipping Lable (If avaiable)",
                            icon: svgIcons.Alert_Circle_Black,
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "SHIPPING_LABEL",
                            important: false
                        },
                    ]
                }
            },
            {
                optionTitle: "Order In Transit",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The order was placed in our shopify store by the customer and we shipped it on time according to our policies. The ordered items were shipped to the customer on 22th June 2022 to the given shipping address. Attached delivery proof show the details.",
                            para2: "Below is the Tracking Link that shows the products are in-transit."
                        },
                        viewTrackingInformation: true,
                        chooseDate: false,
                        pasteTrackingLink: false
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "File automatically attached by trackipal",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Shipping Lable (If avaiable)",
                            icon: svgIcons.Alert_Circle_Black,
                            documentPlaceholder: "File automatically attached by trackipal",
                            file: undefined,
                            uploadFileType: "SHIPPING_LABEL",
                            important: false
                        }
                    ]
                }
            }
        ]
    },
    {
        disputeState: "Item not as described",
        aiDisputeResolverOptions: [
            {
                optionTitle: "Didn’t Communicate With Merchant",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The customer Ordered this Product and we Shipped it on time According to our Policies. The Product Was Completely New and Damage Free. Although somehow if the Product is not as Per Expectation Then The Customer needs to Communicate That to Us.",
                            para2: "We are Happy to Resolve any issue if the Customer Contacts Us. However, the Customer Has Not Communicated With us at all Regarding This Order. Attached is the Customer Communication Details"
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: false
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "COMMUNICATION_PROOF",
                            important: false
                        },
                        {
                            documentTitle: "Policy Document or screenshot",
                            icon: svgIcons.Alert_Circle_Black,
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "POLICY_DOCUMENT",
                            important: false
                        },
                    ]
                }
            },
            {
                optionTitle: "Communicated With Merchant",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The Customer Contacted us regarding this order and we tried to resolve it with them. However, we didn’t get the required information from the customer.",
                            para2: "We’re happy to help the customer to resolve the issue if we’re provided with required information. Attached is the helpdesk Communication details"
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: false
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "COMMUNICATION_PROOF",
                            important: false
                        },
                        {
                            documentTitle: "Policy Document or screenshot",
                            icon: svgIcons.Alert_Circle_Black,
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "POLICY_DOCUMENT",
                            important: false
                        },
                    ]
                }
            },
            {
                optionTitle: "Timeline Expired for Return/Replace",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The order was placed in our shopify store by the customer and we shipped it on time according to our policies. But as per our policy the customer is required to contact us for any issue related to the product within <24> days after the product is delivered. After that we will no longer be able to accept the return or replacemenet of the product.",
                            para2: "Below is our policy link:"
                        },
                        viewTrackingInformation: false,
                        viewPolicies: true,
                        chooseDate: false,
                        pasteTrackingLink: false
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "COMMUNICATION_PROOF",
                            important: false
                        },
                        {
                            documentTitle: "Policy Document or screenshot",
                            icon: svgIcons.Alert_Circle_Black,
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "POLICY_DOCUMENT",
                            important: false
                        },
                    ]
                }
            },
            {
                optionTitle: "Product Not Yet Delivered",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The order was placed in our shopify store by the customer and we shipped it on time according to our policies. The ordered items are currently in transit and expected to get delivered in <5 Days>.",
                            para2: "As the order is yet to be delivered thus, there is no chance of the product being damaged or defective, which means this chargeback is invalid.",
                            para3: "Below is our Tracking link:"
                        },
                        viewTrackingInformation: false,
                        viewPolicies: true,
                        chooseDate: false,
                        pasteTrackingLink: false
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "COMMUNICATION_PROOF",
                            important: false
                        },
                        {
                            documentTitle: "Policy Document or screenshot",
                            icon: svgIcons.Alert_Circle_Black,
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "POLICY_DOCUMENT",
                            important: false
                        },
                    ]
                }
            }
        ],
    },
    {
        disputeState: "Unauthorized transaction",
        aiDisputeResolverOptions: [
            {
                optionTitle: "Order Already Delivered",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The order was placed in our shopify store by the customer and we shipped it on time according to our policies. The ordered items were also delivered to the customer on 22th June 2022 to the given shipping address. Attached delivery proof show the details.",
                            para2: "Below is the Tracking Link that shows the products are delievered."
                        },
                        viewTrackingInformation: true,
                        chooseDate: true,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Customer Messages*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: true
                        }
                    ]
                }
            },
            {
                optionTitle: "Order Still in transit",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The order was placed in our shopify store by the customer and we shipped it on time according to our policies. The ordered items are currently in transit and are expected to get delivered in <24 Days>. As the product has been already shipped thus, nothing can’t be done from ourside.",
                            para2: "Below is the Tracking Link that order is in transit."
                        },
                        viewTrackingInformation: true,
                        chooseDate: true,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Customer Messages*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: true
                        }
                    ]
                }
            }
        ]
    },
    {
        disputeState: "Refund not received",
        aiDisputeResolverOptions: [
            {
                optionTitle: "Customer Communicated with the Merchant",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplateBtn: [
                            {
                                title: "Talked Specifically",
                                isClicked: false
                            },
                            {
                                title: "Didn’t Ask Anything",
                                isClicked: false
                            }
                        ],
                        responseTemplate: {
                            para1: "This Should change based on the Communication between the merchant and the customer. The refund policy might be required to be uploaded in case there is specific reason due to which the merchant refused to refund the customer."
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: false
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        },
                        {
                            documentTitle: "Screenshot of refund policy",
                            icon: svgIcons.Alert_Circle_Black,
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "REFUND_POLICY",
                            important: false
                        }
                    ]
                }
            },
            {
                optionTitle: "Customer Didn’t Communicated with Merchant",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "This is not valid chargeback.The customer ordered this product and we shipped it on time according to our policies clearly expressed on the website, as well as the specific product page, We are happy to resolve any issues and issue refund if the customer contact us. However the customer has not communicated with us at all regarding this order. See screenshots attached.",
                            para2: "Below is the Tracking Link that shows the ordered products have been shipped/delivered:"
                        },
                        viewTrackingInformation: true,
                        chooseDate: false,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            }
        ]
    },
    {
        disputeState: "Duplicate Payment",
        aiDisputeResolverOptions: [
            {
                optionTitle: "If There Is Really Some Problem",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "This Should change based on the actual issue."
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            },
            {
                optionTitle: "If There are Multiple Order",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The Customer ordered multiple Products from our Website. We Processed them with multiple payment transactions. Each Payment matches to the customer’s ordering. There has been no duplicate for the same product. Attached Shopify orde information shows other products for which payments were processed."
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        },
                        {
                            documentTitle: "Screenshot Of All Orders From Customer",
                            icon: svgIcons.Alert_Circle_Black,
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "ALL_CUSTOMER_ORDERS",
                            important: false
                        }
                    ]
                }
            },
            {
                optionTitle: "If There is One Order and Total Payment Matches The Order Amount",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The Customer ordered multiple Products from our Website. We Processed them with multiple payment transactions. Each Payment matches to the customer’s ordering. There has been no duplicate for the same product. Attached is a screenshot of all the orders placed by the customer for which the payments match."
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            }
        ]
    },
    {
        disputeState: "Incorrect Transaction Amount",
        aiDisputeResolverOptions: [
            {
                optionTitle: "Total Transaction Amount does not Matched the Total Amount",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "This Should change based on the actual issue."
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            },
            {
                optionTitle: "Total Transaction Amount Matched the Total Amount",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The attached shopify order detail shows the products the customer ordered and the payment deducted was based on the products ordered. The order amount can be seen in the shopify order and this is the same amount being deducted. in other words, the payment matches the order amount."
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            }
        ]
    },
    {
        disputeState: "Paid With Other Method",
        aiDisputeResolverOptions: [
            {
                optionTitle: "Paid Via Credit Card and the Merchant already Clarified the Customer",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "This is not a valid chargeback. As you can see from the shopify order details, the customer paid via Credit Card. We also clarified this to the customer."
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: false
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            },
            {
                optionTitle: "Paid Via Paypal and the Merchant already Clarified the Customer",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "This is not a valid chargeback. As you can see from the shopify order details, the customer paid via Credit Card. We also clarified this to the customer."
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: false
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            },
            {
                optionTitle: "Paid Via Credit Card and the Customer didn’t Contact us",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "This is not a valid chargeback. As you can see from the shopify order details, the customer paid via Credit Card. We also clarified this to the customer."
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: false
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            },
            {
                optionTitle: "Paid Via Paypal and the Customer didn’t Contact us",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "This is not a valid chargeback. As you can see from the shopify order details, the customer paid via Credit Card. We also clarified this to the customer."
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: false
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            }
        ]
    },
    {
        disputeState: "Wrong amount or date",
        aiDisputeResolverOptions: [
            {
                optionTitle: "Customer Communicated With the Merchant",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The response would change based on actual customer communication"
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            },
            {
                optionTitle: "Customer Didn’t Communicated With the Merchant",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "The Customer placed the order and the payment matches the order amount. We’ve shipped the products as per our policies clearly expressed on the website, as well as the specific product page. Also the customer didn’t contact us regarding this order. Thus, this is not a valid chargeback."
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            }
        ]
    },
    {
        disputeState: "Cancel Pre Approved Payment",
        aiDisputeResolverOptions: [
            {
                optionTitle: "Customer Communicated With the Merchant",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "We do not offer any subscriptions on our website. The Customer purchased the following productsfrom us (see attached shopify order details). This is not a valid dispute. The Customer ordered these items  and we shipped them on time according to our policies clearly expressed on the website, as Well as the specific product page. We already clarified the same to the customer."
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            },
            {
                optionTitle: "Customer Didn’t Communicated With the Merchant",
                clicked: false,
                resolverFields: {
                    customerResponse: {
                        responseTemplate: {
                            para1: "We do not offer any subscriptions on our website. The Customer purchased the following productsfrom us (see attached shopify order details). This is not a valid dispute. The Customer ordered these items  and we shipped them on time according to our policies clearly expressed on the website, as Well as the specific product page. In addition the customer had not communicated with us at all regarding this order"
                        },
                        viewTrackingInformation: false,
                        chooseDate: false,
                        pasteTrackingLink: true
                    },
                    disputeDocument: [
                        {
                            documentTitle: "Screenshots of Shopify order or invoice*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "INVOICE",
                            important: true
                        },
                        {
                            documentTitle: "Screenshots of Tracking Page*",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "TRACKING_PAGE",
                            important: true
                        },
                        {
                            documentTitle: "Customer Care Communication Proof",
                            documentPlaceholder: "Browse File or Drag & Drop",
                            file: undefined,
                            uploadFileType: "CUSTOMER_MESSAGES",
                            important: false
                        }
                    ]
                }
            }
        ]
    }
]

//for the AI Dispute Resolver option button
export const getAiDisputeResolverOptions = (disputeState: string) => {
    let disputeResolveOptions = disputeAiResolver.find((option, index) => {
        return option?.disputeState?.toLowerCase() === disputeState?.toLowerCase();
    })
    return disputeResolveOptions
}
export const  capitalizeDisputeDate = (expectedDate:string)=>  {
    let words = expectedDate.split("_");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
    return words.join(" ");
  }
//for the dynamic UI values of the AI dispute resolve choosen options
export const getDisputeResolveFields = (disputeState: string) => {
    let disputeResolveOptions = disputeAiResolver.find((option, index) => {
        return option?.disputeState?.toLowerCase() === disputeState?.toLowerCase();
    })
    return disputeResolveOptions
}