import { Box } from '@mui/material';
import SelectUI from 'components/disputeFlow/RelatedCom/Select';
import { useBillingReports, useOpenDatePicker } from 'hooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './style.module.scss';
import { CalendarIcon } from 'components/disputeFlow/NodeHelpers';
import DatePicker from 'react-datepicker';
import { Button } from 'components/ui';
import {
  postDisputeBillingRequest,
  getTotalBilledAmountRequest,
  getTotalAmountRequest,
} from 'redux/actions';
import { DisputesWidget, Loading, Pagination } from 'components';
import ReusableTable from 'components/table';
import { IconRender } from 'elements';
import { useSelector } from 'react-redux';

type BillingHistoryType = {
  selectedDisputes: any;
  handleCheckboxChange: any;
  onPageChange: any;
};

const billingHistoryTableHeading = [
  'Dispute ID',
  'Store',
  'Order number',
  'Amount',
  'Billable',
  // 'Status',
  'Dispute Reason',
  'Date Opened',
  'Decision Date',
  'Response SubmittedAt',
  'Action',
];

const BillingHistory = ({
  selectedDisputes,
  handleCheckboxChange,
  onPageChange,
}: BillingHistoryType) => {
  const { openDatePicker, datePickerRef } = useOpenDatePicker();
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState([null, null]);
  const {
    mapedForm,
    formFunction,
    billingReport,
    isReportLoading,
    page,
    pageLength,
  } = useBillingReports();
  const [startDate, endDate] = dateRange;
  const [dateFilterCheck, setDateFilterCheck] = useState(false);
  const { clearErrors, setValue, errors, control, handleSubmit, reset } =
    formFunction;
  const checkBoxVisible = false;
  const onSubmit = (data: any) => {
    Object.assign(data, {
      decisionDate: dateRange,
    });
    // this is the object to submit the filter data
    const submitForm = {
      disputeId: null,
      merchantName: null,
      disputedAmount: 0,
      shopId: data?.store?.value ?? null,
      status: data?.status?.value ?? null,
      disputeReason: data?.disputeReason?.value ?? null,
      billable: 0,
      dateOpenedFrom: dateRange[0],
      dateOpenedTo: dateRange[1],
      decisionDateFrom: null,
      decisionDateTo: null,
    };
    const payload = {
      pagination: {
        page: 1,
        pageLength: 8,
      },
      submitForm: submitForm,
    };
    dispatch(postDisputeBillingRequest(payload));
    // setSubmitted(submittedData);
    // reset();
  };

  const {
    data: totalBilledAmountList,
    isLoading: isTotalBilledLoading,
    isError: isTotalBilledError,
    isSuccess: isTotalBilledSuccess,
  } = useSelector((state: any) => state?.billing?.totalBilledAmountList);

  const {
    data: totalAmountList,
    isLoading: isTotalAmountLoading,
    isError: isTotalAmountError,
    isSuccess: isTotalAmountSuccess,
  } = useSelector((state: any) => state?.billing?.totalAmountList);

  useEffect(() => {
    dispatch(getTotalBilledAmountRequest());
    dispatch(getTotalAmountRequest());
  }, []);

  // cards data
  const totalCountCard = {
    title: 'Total Count     ',
    blueEye: true,
    icon: <IconRender iconName='layersThree' />,
    totalDisputes: billingReport?.count || 0,
    loading: isTotalBilledLoading,
  };
  const totalAmountCard = {
    title: 'Total Amount',
    blueEye: true,
    icon: <IconRender iconName='activity' />,
    disputedAmount: totalAmountList || 0,
    loading: isTotalAmountLoading,
  };
  const totalBillableAmountCard = {
    title: 'Total Billiable Amount',
    blueEye: true,
    icon: <IconRender iconName='activity' />,
    disputedAmount: totalBilledAmountList || 0,
    loading: isTotalBilledLoading,
  };

  return (
    <div className={styles.disputesWrapper}>
      <div className={styles.cardsWrapper}>
        <div className={styles.card}>
          <DisputesWidget disputeWidgetData={totalCountCard} />
        </div>

        <div className={styles.card}>
          <DisputesWidget disputeWidgetData={totalAmountCard} />
        </div>

        <div className={styles.card}>
          <DisputesWidget disputeWidgetData={totalBillableAmountCard} />
        </div>
      </div>
      <Box
        component={`form`}
        onSubmit={handleSubmit(onSubmit)}
        className={styles.usersSearch}
      >
        <Box
          display={`flex`}
          justifyContent={`space-between`}
          alignItems={`center`}
          width={`100%`}
          gap='8px'
          className={styles.inputSearchContainer}
        >
          {mapedForm.map(
            (
              { name, label, options, rules, placeholder, type }: any,
              key: number
            ) =>
              type !== 'date' ? (
                <SelectUI
                  key={label}
                  {...{
                    styles,
                    control,
                    name,
                    rules,
                    options,
                    errors,
                    clearErrors,
                    setValue,
                    placeholder,
                  }}
                />
              ) : (
                <div onClick={openDatePicker} className={styles.date}>
                  <CalendarIcon className={styles.icon} />
                  <DatePicker
                    dateFormat='dd/MM/yyyy'
                    selectsRange={true}
                    placeholderText='Select date range...'
                    ref={datePickerRef}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update: any) => {
                      setDateRange(update);
                      setDateFilterCheck(true); //to control the API call
                    }}
                    className={styles.datePicker}
                    monthsShown={2}
                    isClearable
                  />
                </div>
              )
          )}
        </Box>
        <Box
          display={`flex`}
          justifyContent={`start`}
          alignItems={`center`}
          gap={`4px`}
          marginBottom={`20px`}
        >
          <Button
            {...{
              btnLabel: 'Reset',
              btnTitle: 'Click to reset',
              type: 'button',
              placement: 'left',
              onClick: () => reset(),
            }}
          />
          <Button
            {...{
              btnLabel: 'Submit',
              btnTitle: 'Click to filter',
              type: 'submit',
              placement: 'right',
            }}
          />
        </Box>
      </Box>
      <div>
        {isReportLoading ? (
          <div style={{ background: '#fff' }}>
            <Loading />
          </div>
        ) : (
          <ReusableTable
            {...{
              usersColumns: billingHistoryTableHeading,
              data: billingReport,
              isLoading: false,
              isSuccess: true,
              isError: false,
              selectedDisputes,
              handleCheckboxChange,
              checkBoxVisible,
              isPdfButton: true,
            }}
          />
        )}
      </div>
      {Math.ceil(billingReport?.count / pageLength) > 1 && (
        <div>
          <Pagination
            onPageChange={pageNum => onPageChange(pageNum)}
            page={page}
            totalPages={Math.ceil(billingReport?.count / pageLength)}
          />
        </div>
      )}
    </div>
  );
};

export default BillingHistory;
