import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Grid } from "@mui/material";
import { getDisputeTemplateListRequest } from "redux/actions";
import { svgIcons } from "elements";
import styles from "./style.module.scss";
import { cloneDeep } from "lodash";
import { useDisputeReasonList } from "hooks";

interface filterTypeOptionsProps {
  value: string;
  label: string;
  status: boolean;
}

interface applyFilterProps {
  setListPayload: any;
}

const ApplyFilter = ({ setListPayload }: applyFilterProps) => {
  const dispatch = useDispatch();
  const [filterCheck, setFilterCheck] = useState(true); //to check any filter checkbox checked or not


  const { data } = useDisputeReasonList();
  const [reasonOptions, setReasonOptions] = useState(data);

  useEffect(() => {
    try {
      if (data && data.length > 1) {
        const newReasonsArray =
          data.length > 1 &&
          data.map((reason: any) => {
            return {
              value: reason.disputeReasonValue,
              label: reason.disputeReasonValue,
              status: false,
            };
          });
        setReasonOptions(newReasonsArray);
      }
    } catch (err) {
      console.log(err);
    }
  }, [data]);
  

  const handleType = (data: any, index: any) => {
    setFilterCheck(false);
    const dataList = cloneDeep(reasonOptions);
    data.status = !data.status;
    dataList[index] = data;
    setReasonOptions(dataList);
  };

  const handleFiltering = () => {
    //FOR-TYPE
    const typeArr =
      reasonOptions &&
      reasonOptions.length > 0 &&
      reasonOptions.filter(
        (typeData: filterTypeOptionsProps) => typeData.status
      );
    const typeFilterArr = typeArr.map((type: filterTypeOptionsProps) => {
      return type?.value;
    });

    const payload = {
      type: typeFilterArr.length > 0 ? typeFilterArr : null,
      enabled: true,
      page: 1,
      pageLength: 15,
    };
    setListPayload(payload);
    dispatch(getDisputeTemplateListRequest(payload));
  };

  const handleFilterReset = () => {
    setFilterCheck(true);
    setReasonOptions(reasonOptions);
    const payload = {
      enabled: true,
      page: 1,
      pageLength: 15,
    };
    setListPayload(null);
    dispatch(getDisputeTemplateListRequest(payload));
  };

  useEffect(() => {
    let checkFilter =
      reasonOptions &&
      reasonOptions.length > 0 &&
      reasonOptions.filter((type: filterTypeOptionsProps) => type.status);
    if (checkFilter && checkFilter.length > 0) {
      setFilterCheck(false);
    } else {
      setFilterCheck(true);
    }
  }, [reasonOptions]);

  return (
    <>
      <div className={styles.filtersDrop}>
        <div className={styles.filtersDropOptions}>
          <div className={styles.check}>
            <div className={styles.filtersDropTitle}>Reason</div>
            <div className={styles.filterOptions}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 1, md: 1 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {reasonOptions &&
                    reasonOptions.length > 1 &&
                    reasonOptions.map(
                      (reason: filterTypeOptionsProps, index: number) => {
                        return (
                          <Grid item xs={2} sm={4} md={3} key={index}>
                            <div className={styles.checkbox} key={index}>
                              <input
                                id={reason.label}
                                type="checkbox"
                                checked={reason.status}
                                name={reason.label}
                                onChange={() => handleType(reason, index)}
                              />
                              <label htmlFor={reason.label}>
                                {reason?.label}
                              </label>
                            </div>
                          </Grid>
                        );
                      }
                    )}
                </Grid>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.searchButtons}>
        <Button
          variant="contained"
          className={styles.buttonApply}
          onClick={() => handleFiltering()}
          disabled={filterCheck}
          data-testid="apply-filter"
        >
          <img src={svgIcons.CheckTwo} alt="check" /> Apply Filters
        </Button>
        <button
          className={styles.buttonReset}
          onClick={handleFilterReset}
          data-testid="reset-filter"
        >
          Reset
        </button>
      </div>
    </>
  );
};

export default ApplyFilter;
