import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import ErrorMessage from "./ErrorMessage";

const ValueInput = ({ styles, register, name, errors }: any) => {
  return (
    <FormControl className={styles.formControl}>
      <OutlinedInput
        className={styles.outLineInput}
        placeholder="Value"
        id="outlined-adornment-amount"
        type="number"
        {...register(name, {
          required: {
            value: true,
            message: "This is required",
          },
        })}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
      />
      <ErrorMessage name={name} errors={errors} />
    </FormControl>
  );
};

export default ValueInput;
