import Flows from "./flows";
const DisputeFlow = ({ hideHeader }: { hideHeader?: boolean }) => {
  return (
    <>
      <Flows {...{ hideHeader }} />
    </>
  );
};

export default DisputeFlow;
