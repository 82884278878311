import { FC } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import { Eye } from "elements/svgViewer";
import { Tooltip } from "@mui/material";

interface Props {
  headingData: {
    title: string;
    description?: string;
    viewAll?: boolean;
    link?: any;
  };
}
const RecentOrderHeader: FC<Props> = ({ headingData }) => {
  const navigate = useNavigate();

  const handleViewAll = () => {
    navigate(`/${headingData.link}`);
  };
  return (
    <div className={styles.recentOrderHeader}>
      <div className={styles.recentOrderHeader_sub}>
        <p className={styles.recentOrderHeading}>{headingData.title}</p>
        {headingData.viewAll && (
          <Tooltip arrow placement="top" title={"Click to view all"}>
            <div className={styles.recentOrderHeader_Btn}>
              <button onClick={handleViewAll}>
                <Eye />
                <p>View All</p>
              </button>
            </div>
          </Tooltip>
        )}
      </div>

      <div className={styles.recentOrderHeader_description}>
        <p>{headingData.description}</p>
      </div>
    </div>
  );
};
export default RecentOrderHeader;
