import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
  manageManager: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
  },
  
  manageUsers: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
  },

  updateDispute: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
  },
  
  updateShop: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
  },

  findShop: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
  },

  findDispute: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
  },

  createUser: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
  },
};

//get-managerlist-by-disputeId
const getManagerListRequest = (state: any, action: any) => {
  return update(state, {
    manageManager: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      data: { $set: [] },
    },
  });
};

const getManageUserSuccess = (state: any, action: any) => {
  return update(state, {
    manageManager: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      data: { $set: [ ...action.payload ] },
    },
  });
};

const getManageUserError = (state: any, action: any) => {
  return update(state, {
    manageManager: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      data: { $set: action.payload },
    },
  });
};

//Post-update-manager-by-shop-id
const postUpdateManagerByShopIdRequest = (state: any, action: any) => {
  return update(state, {
    updateShop: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      data: { $set: [] },
    },
  });
};

const postUpdateManagerByShopIdSuccess = (state: any, action: any) => {
  return update(state, {
    updateShop: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      data: { $set: { ...action.payload } },
    },
  });
};

const postUpdateManagerByShopIdError = (state: any, action: any) => {
  return update(state, {
    updateShop: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      data: { $set: action.payload },
    },
  });
};

//Post-update-manager-by-dispute-id
const postUpdateManagerByDisputeIdRequest = (state: any, action: any) => {
  return update(state, {
    updateDispute: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      data: { $set: [] },
    },
  });
};

const postUpdateManagerByDisputeIdSuccess = (state: any, action: any) => {
  return update(state, {
    updateDispute: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      data: { $set: { ...action.payload } },
    },
  });
};

const postUpdateManagerByDisputeIdError = (state: any, action: any) => {
  return update(state, {
    updateDispute: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      data: { $set: action.payload },
    },
  });
};

// get shop find by manager
const getShopFindByManagerRequest = (state: any, action: any) => {
  return update(state, {
    findShop: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      data: { $set: [] },
    },
  });
};

const getShopFindByManagerSuccess = (state: any, action: any) => {
  return update(state, {
    findShop: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      data: { $set: [ ...action.payload ] },
    },
  });
};

const getShopFindByManagerError = (state: any, action: any) => {
  return update(state, {
    findShop: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      data: { $set: action.payload },
    },
  });
};

// get dispute find by manager
const getDisputeFindByManagerRequest = (state: any, action: any) => {
  return update(state, {
    findDispute: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      data: { $set: [] },
    },
  });
};

const getDisputeFindByManagerSuccess = (state: any, action: any) => {
  return update(state, {
    findDispute: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      data: { $set: [ ...action.payload ] },
    },
  });
};

const getDisputeFindByManagerError = (state: any, action: any) => {
  return update(state, {
    findDispute: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      data: { $set: action.payload },
    },
  });
};

//get manage user list
const getManageUserListRequest = (state: any, action: any) => {
  return update(state, {
    manageUsers: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      data: { $set: [] },
    },
  });
};

const getManageUserListSuccess = (state: any, action: any) => {
  return update(state, {
    manageUsers: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      data: { $set: [...action.payload]  },
    },
  });
};

const getManageUserListError = (state: any, action: any) => {
  return update(state, {
    manageUsers: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      data: { $set: action.payload },
    },
  });
};

//post manage create User
const postManageCreateUserRequest = (state: any, action: any) => {
  return update(state, {
    createUser: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      data: { $set: [] },
    },
  });
};

const postManageCreateUserSuccess = (state: any, action: any) => {
  return update(state, {
    createUser: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      data: { $set: [ ...action.payload ] },
    },
  });
};

const postManageCreateUserError = (state: any, action: any) => {
  return update(state, {
    createUser: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      data: { $set: action.payload },
    },
  });
};


export default handleActions(
  {
    [constants.GET_LIST_MANAGER_REQUEST]: getManagerListRequest,
    [constants.GET_LIST_MANAGER_SUCCESS]: getManageUserSuccess,
    [constants.GET_LIST_MANAGER_ERROR]: getManageUserError,

    [constants.POST_UPDATE_MANAGER_BY_SHOPID_REQUEST]: postUpdateManagerByShopIdRequest,
    [constants.POST_UPDATE_MANAGER_BY_SHOPID_SUCCESS]: postUpdateManagerByShopIdSuccess,
    [constants.POST_UPDATE_MANAGER_BY_SHOPID_ERROR]: postUpdateManagerByShopIdError,
   
    [constants.POST_UPDATE_MANAGER_BY_DISPUTEID_REQUEST]: postUpdateManagerByDisputeIdRequest,
    [constants.POST_UPDATE_MANAGER_BY_DISPUTEID_SUCCESS]: postUpdateManagerByDisputeIdSuccess,
    [constants.POST_UPDATE_MANAGER_BY_DISPUTEID_ERROR]: postUpdateManagerByDisputeIdError,

    
    [constants.GET_SHOP_FIND_BY_MANAGER_REQUEST]: getShopFindByManagerRequest,
    [constants.GET_SHOP_FIND_BY_MANAGER_SUCCESS]: getShopFindByManagerSuccess,
    [constants.GET_SHOP_FIND_BY_MANAGER_ERROR]: getShopFindByManagerError,

    
    [constants.GET_DISPUTE_FIND_BY_MANAGER_REQUEST]: getDisputeFindByManagerRequest,
    [constants.GET_DISPUTE_FIND_BY_MANAGER_SUCCESS]: getDisputeFindByManagerSuccess,
    [constants.GET_DISPUTE_FIND_BY_MANAGER_ERROR]: getDisputeFindByManagerError,

    
    [constants.GET_USER_LIST_REQUEST]: getManageUserListRequest,
    [constants.GET_USER_LIST_SUCCESS]: getManageUserListSuccess,
    [constants.GET_USER_LIST_ERROR]: getManageUserListError,


    [constants.POST_MANAGE_CREATE_USER_REQUEST]: postManageCreateUserRequest,
    [constants.POST_MANAGE_CREATE_USER_SUCCESS]: postManageCreateUserSuccess,
    [constants.POST_MANAGE_CREATE_USER_ERROR]: postManageCreateUserError,

 
  },
  initialState
);
