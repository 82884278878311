import { Node, mergeAttributes } from "@tiptap/core";

const CustomSection = Node.create({
  name: "section",

  group: "block",

  content: "block*",

  parseHTML() {
    return [
      {
        tag: "section",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["section", mergeAttributes(HTMLAttributes), 0];
  },

  //   addAttributes() {
  //     return {
  //       level: {
  //         default: 1,
  //         parseHTML: (element) => {
  //           const level = element.getAttribute("data-level");
  //           return level ? parseInt(level, 10) : 1;
  //         },
  //         renderHTML: (attributes) => {
  //           return {
  //             "data-level": attributes.level,
  //           };
  //         },
  //       },
  //     };
  //   },
});

export default CustomSection;
