import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    disputeFlow: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeFlowList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeFlowReasonList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeFlowActionList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeFlowStageList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeFlowFindByType: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeFlowFindByStore: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeFlowFilterFlows: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeFlowView: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    globalFlowState: {
        data: {},
        isExpand: false,
    },
}

//dispute-flow-creator
const postDisputeFlowRequest = (state: any, action: any) => {
    return update(state, {
        disputeFlow: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postDisputeFlowSuccess = (state: any, action: any) => {
    return update(state, {
        disputeFlow: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set:action.payload }
        }
    });
};

const postDisputeFlowError = (state: any, action: any) => {
    return update(state, {
        disputeFlow: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-Flow-list
const getDisputeFlowListRequest = (state: any, action: any) => {
    return update(state, {
        disputeFlowList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeFlowListSuccess = (state: any, action: any) => {
    return update(state, {
        disputeFlowList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getDisputeFlowListError = (state: any, action: any) => {
    return update(state, {
        disputeFlowList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-Flow-reason-list
const getDisputeFlowReasonListRequest = (state: any, action: any) => {
    return update(state, {
        disputeFlowReasonList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeFlowReasonListSuccess = (state: any, action: any) => {
    return update(state, {
        disputeFlowReasonList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeFlowReasonListError = (state: any, action: any) => {
    return update(state, {
        disputeFlowReasonList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-Flow-action-list
const getDisputeFlowActionListRequest = (state: any, action: any) => {
    return update(state, {
        disputeFlowActionList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeFlowActionListSuccess = (state: any, action: any) => {
    return update(state, {
        disputeFlowActionList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeFlowActionListError = (state: any, action: any) => {
    return update(state, {
        disputeFlowActionList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-Flow-stage-list
const getDisputeFlowStageListRequest = (state: any, action: any) => {
    return update(state, {
        disputeFlowStageList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeFlowStageListSuccess = (state: any, action: any) => {
    return update(state, {
        disputeFlowStageList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeFlowStageListError = (state: any, action: any) => {
    return update(state, {
        disputeFlowStageList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-Flow-find-by-type
const getDisputeFlowFindByTypeRequest = (state: any, action: any) => {
    return update(state, {
        disputeFlowFindByType: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeFlowFindByTypeSuccess = (state: any, action: any) => {
    return update(state, {
        disputeFlowFindByType: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getDisputeFlowFindByTypeError = (state: any, action: any) => {
    return update(state, {
        disputeFlowFindByType: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-Flow-find-by-store
const getDisputeFlowFindByStoreRequest = (state: any, action: any) => {
    return update(state, {
        disputeFlowFindByStore: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeFlowFindByStoreSuccess = (state: any, action: any) => {
    return update(state, {
        disputeFlowFindByStore: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getDisputeFlowFindByStoreError = (state: any, action: any) => {
    return update(state, {
        disputeFlowFindByStore: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-Flow-filter-Flows
const postDisputeFlowFilterFlowsRequest = (state: any, action: any) => {
    return update(state, {
        disputeFlowFilterFlows: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postDisputeFlowFilterFlowsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeFlowFilterFlows: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const postDisputeFlowFilterFlowsError = (state: any, action: any) => {
    return update(state, {
        disputeFlowFilterFlows: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-Flow-view
const getDisputeFlowViewRequest = (state: any, action: any) => {
    return update(state, {
        disputeFlowView: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeFlowViewSuccess = (state: any, action: any) => {
    return update(state, {
        disputeFlowView: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getDisputeFlowViewError = (state: any, action: any) => {
    return update(state, {
        disputeFlowView: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
const expandTheFlowEditor = (state: any, action: any) => {
    return update(state, {
        globalFlowState: {
            isExpand: { $set: action.payload.isExpand },
            data: { $set: action.payload.data },
        }
    });
};


export default handleActions(
    {
        [constants.POST_DISPUTE_FLOW_REQUEST]: postDisputeFlowRequest,
        [constants.POST_DISPUTE_FLOW_SUCCESS]: postDisputeFlowSuccess,
        [constants.POST_DISPUTE_FLOW_ERROR]: postDisputeFlowError,

        [constants.GET_DISPUTE_FLOW_LIST_REQUEST]: getDisputeFlowListRequest,
        [constants.GET_DISPUTE_FLOW_LIST_SUCCESS]: getDisputeFlowListSuccess,
        [constants.GET_DISPUTE_FLOW_LIST_ERROR]: getDisputeFlowListError,

        [constants.GET_DISPUTE_FLOW_REASON_LIST_REQUEST]: getDisputeFlowReasonListRequest,
        [constants.GET_DISPUTE_FLOW_REASON_LIST_SUCCESS]: getDisputeFlowReasonListSuccess,
        [constants.GET_DISPUTE_FLOW_REASON_LIST_ERROR]: getDisputeFlowReasonListError,

        [constants.GET_DISPUTE_FLOW_ACTION_LIST_REQUEST]: getDisputeFlowActionListRequest,
        [constants.GET_DISPUTE_FLOW_ACTION_LIST_SUCCESS]: getDisputeFlowActionListSuccess,
        [constants.GET_DISPUTE_FLOW_ACTION_LIST_ERROR]: getDisputeFlowActionListError,

        [constants.GET_DISPUTE_FLOW_STAGE_LIST_REQUEST]: getDisputeFlowStageListRequest,
        [constants.GET_DISPUTE_FLOW_STAGE_LIST_SUCCESS]: getDisputeFlowStageListSuccess,
        [constants.GET_DISPUTE_FLOW_STAGE_LIST_ERROR]: getDisputeFlowStageListError,

        [constants.GET_DISPUTE_FLOW_FIND_BY_TYPE_REQUEST]: getDisputeFlowFindByTypeRequest,
        [constants.GET_DISPUTE_FLOW_FIND_BY_TYPE_SUCCESS]: getDisputeFlowFindByTypeSuccess,
        [constants.GET_DISPUTE_FLOW_FIND_BY_TYPE_ERROR]: getDisputeFlowFindByTypeError,

        [constants.GET_DISPUTE_FLOW_FIND_BY_STORE_REQUEST]: getDisputeFlowFindByStoreRequest,
        [constants.GET_DISPUTE_FLOW_FIND_BY_STORE_SUCCESS]: getDisputeFlowFindByStoreSuccess,
        [constants.GET_DISPUTE_FLOW_FIND_BY_STORE_ERROR]: getDisputeFlowFindByStoreError,

        [constants.POST_DISPUTE_FLOW_FILTER_FLOWS_REQUEST]: postDisputeFlowFilterFlowsRequest,
        [constants.POST_DISPUTE_FLOW_FILTER_FLOWS_SUCCESS]: postDisputeFlowFilterFlowsSuccess,
        [constants.POST_DISPUTE_FLOW_FILTER_FLOWS_ERROR]: postDisputeFlowFilterFlowsError,

        [constants.GET_DISPUTE_FLOW_VIEW_REQUEST]: getDisputeFlowViewRequest,
        [constants.GET_DISPUTE_FLOW_VIEW_SUCCESS]: getDisputeFlowViewSuccess,
        [constants.GET_DISPUTE_FLOW_VIEW_ERROR]: getDisputeFlowViewError,

        [constants.EXPAND_THE_FLOW_EDITOR]: expandTheFlowEditor,
    },
    initialState
);