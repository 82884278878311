import { Box, Button } from "@mui/material";
import { sidebarFormForCondition } from "./helper.sidebar";
import ConditionFormItem from "./ConditionFormItem";
import React from "react";
import { ConditionFormProps } from "../types";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";

const ConditionForm: React.FC<ConditionFormProps> = ({
  handleSubmit,
  onSubmit,
  control,
  errors,
  resultArray,
  openSideBar,
  register,
}) => {
  const { globalFlowState } = useSelector((state: any) => state?.disputeFlow);
  return (
    <Box
      display={`flex`}
      justifyContent={`space-between`}
      border={`none`}
      flexDirection={`column`}
      component={"form"}
      // minHeight={`calc(100vh - 575px)`}
      sx={{
        minHeight: globalFlowState?.isExpand
          ? "calc(100vh - 328px)"
          : "calc(100vh - 395px)",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ConditionFormItem
        sidebarFormForCondition={sidebarFormForCondition}
        register={register}
        openSideBar={openSideBar}
        errors={errors}
        control={control}
        resultArray={resultArray}
      />

      <Button type="submit" className={styles.buttonStyle} fullWidth>
        Apply
      </Button>
    </Box>
  );
};

export default ConditionForm;
