import {handleActions} from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState ={
    filteredAwaitingDisputes: {
        data: {},
        isLoading: false,
        isError: false,
        isSuccess: false,
    }
}

// filtered-awaiting-seller-response
const getFilteredWaitingSellerRequest = (state: any, action: any) => {
    return update(state, {
        filteredAwaitingDisputes: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getFilteredWaitingSellerSuccess = (state: any, action: any) => {
    return update(state, {
        filteredAwaitingDisputes: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getFilteredWaitingSellerError = (state: any, action: any) => {
    return update(state, {
        filteredAwaitingDisputes: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
}
    export default handleActions(
        {
            [constants.GET_FILTERED_WAITING_SELLER_REQUEST]: getFilteredWaitingSellerRequest,
            [constants.GET_FILTERED_WAITING_SELLER_SUCCESS]: getFilteredWaitingSellerSuccess,
            [constants.GET_FILTERED_WAITING_SELLER_ERROR]: getFilteredWaitingSellerError,
      
        },
        initialState
    )