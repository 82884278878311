import { Button, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './style.module.scss';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import TiptapEditor from 'components/disputeTemplate/TiptapEditor';
import { useDisputeTemplatePreview } from 'hooks';
import Loading from 'components/loading/loading';
import { useDispatch } from 'react-redux';
import { FullscreenExit, Fullscreen } from '@mui/icons-material';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const DisputeTemplatePreview = () => {
  const dispatch = useDispatch();
  const [previewEditorContent, setPreviewEditorContent] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  // this is for template view editor htmlContent
  const { data, isLoading } = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplateView
  );
  // this is for find our templateId
  const disputeResponseFindByDisputeId = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponseFindByDisputeId
  );
  const templateId = disputeResponseFindByDisputeId?.data[0]?.disputeTemplateId;
  // this is for pdf download url link
  const {
    data: previewPdf,
    isSuccess: isPdfSuccess,
    isError: isPdfError,
  } = useDisputeTemplatePreview(templateId as string);

  // useEffect(() => {
  //   if (templateId) {
  //     dispatch(getDisputeTemplateViewRequest(templateId));
  //   }
  // }, [dispatch, templateId]);
  return (
    <div
      className={styles.disputeWrapper}
      style={{
        position: isOpen ? 'fixed' : 'initial',
        top: isOpen ? '-19px' : '',
        left: isOpen ? '0' : '',
        background: isOpen ? '#fff' : '',
        width: isOpen ? '100%' : '',
        height: isOpen ? '100vh' : '',
        zIndex: isOpen ? '10000' : '',
        padding: isOpen ? '20px 35px' : '',
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={styles.manageHead}>
            <h5>Dispute Response</h5>
            <div className={styles.headButtons}>
              <div className={styles.actionButtons}>
                <Tooltip
                  title={isOpen ? 'click to collapse' : 'click to expand'}
                  placement={isOpen ? 'bottom' : 'top'}
                  arrow
                  style={{ zIndex: '1000' }}
                >
                  <Button
                    variant='contained'
                    size='small'
                    onClick={() =>
                      isOpen ? setIsOpen(false) : setIsOpen(true)
                    }
                  >
                    {isOpen ? <FullscreenExit /> : <Fullscreen />}
                  </Button>
                </Tooltip>

                <Button
                  variant='contained'
                  className={styles.send}
                  // onClick={handlePdfResponse}
                >
                  PDF
                </Button>
                <Button
                  // onClick={handleValidate}
                  variant='contained'
                  className={styles.send}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
          <div
            className={
              isOpen ? styles.disputePreviewFullScreen : styles.disputePreview
            }
            style={{ height: isOpen ? '100vh' : '' }}
          >
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <TiptapEditor
                  noToolBox
                  setEditorContent={setPreviewEditorContent}
                  editorData={data?.htmlContent}
                  fullWidth={isOpen ? true : false}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DisputeTemplatePreview;
