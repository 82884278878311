import { SvgIconProps } from 'components/disputeFlow/types';
import * as React from 'react';
const ArrowRight: React.FC<SvgIconProps> = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      d='M6 11.9941L10 7.99414L6 3.99414'
      stroke='#8391A2'
      strokeWidth={1.33333}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Eye: React.FC<SvgIconProps> = props => (
  <svg
    width={12}
    height={12}
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_33617_28938)'>
      <path
        d='M0.5 6C0.5 6 2.5 2 6 2C9.5 2 11.5 6 11.5 6C11.5 6 9.5 10 6 10C2.5 10 0.5 6 0.5 6Z'
        stroke='#A7BEEA'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 7.5C6.82843 7.5 7.5 6.82843 7.5 6C7.5 5.17157 6.82843 4.5 6 4.5C5.17157 4.5 4.5 5.17157 4.5 6C4.5 6.82843 5.17157 7.5 6 7.5Z'
        stroke='#A7BEEA'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_33617_28938'>
        <rect width={12} height={12} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const BlueEye: React.FC<SvgIconProps> = props => (
  <svg
    width={19}
    height={13}
    viewBox='0 0 19 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width={18.6875} height={13} rx={6.5} fill='#225CCB' />
    <g clipPath='url(#clip0_6216_235708)'>
      <path
        d='M4.875 6.5C4.875 6.5 6.5 3.25 9.34375 3.25C12.1875 3.25 13.8125 6.5 13.8125 6.5C13.8125 6.5 12.1875 9.75 9.34375 9.75C6.5 9.75 4.875 6.5 4.875 6.5Z'
        stroke='#A7BEEA'
        strokeWidth={1.21875}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.34375 7.71875C10.0168 7.71875 10.5625 7.1731 10.5625 6.5C10.5625 5.8269 10.0168 5.28125 9.34375 5.28125C8.67065 5.28125 8.125 5.8269 8.125 6.5C8.125 7.1731 8.67065 7.71875 9.34375 7.71875Z'
        stroke='#A7BEEA'
        strokeWidth={1.21875}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_6216_235708'>
        <rect
          width={9.75}
          height={9.75}
          fill='white'
          transform='translate(4.46875 1.625)'
        />
      </clipPath>
    </defs>
  </svg>
);

const LayersThree: React.FC<SvgIconProps> = props => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2 12.0001L11.6422 16.8212C11.7734 16.8868 11.839 16.9196 11.9078 16.9325C11.9687 16.9439 12.0313 16.9439 12.0922 16.9325C12.161 16.9196 12.2266 16.8868 12.3578 16.8212L22 12.0001M2 17.0001L11.6422 21.8212C11.7734 21.8868 11.839 21.9196 11.9078 21.9325C11.9687 21.9439 12.0313 21.9439 12.0922 21.9325C12.161 21.9196 12.2266 21.8868 12.3578 21.8212L22 17.0001M2 7.00006L11.6422 2.17895C11.7734 2.11336 11.839 2.08056 11.9078 2.06766C11.9687 2.05622 12.0313 2.05622 12.0922 2.06766C12.161 2.08056 12.2266 2.11336 12.3578 2.17895L22 7.00006L12.3578 11.8212C12.2266 11.8868 12.161 11.9196 12.0922 11.9325C12.0313 11.9439 11.9687 11.9439 11.9078 11.9325C11.839 11.9196 11.7734 11.8868 11.6422 11.8212L2 7.00006Z'
      stroke='#2057C5'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const Activity: React.FC<SvgIconProps> = props => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M22 12H18L15 21L9 3L6 12H2'
      stroke='#2057C5'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const CurrencyDollar: React.FC<SvgIconProps> = props => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6 16C6 18.2091 7.79086 20 10 20H14C16.2091 20 18 18.2091 18 16C18 13.7909 16.2091 12 14 12H10C7.79086 12 6 10.2091 6 8C6 5.79086 7.79086 4 10 4H14C16.2091 4 18 5.79086 18 8M12 2V22'
      stroke='#225CCB'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const Trophy: React.FC<SvgIconProps> = props => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12 15C8.68629 15 6 12.3137 6 9V3.44444C6 3.0306 6 2.82367 6.06031 2.65798C6.16141 2.38021 6.38021 2.16141 6.65798 2.06031C6.82367 2 7.0306 2 7.44444 2H16.5556C16.9694 2 17.1763 2 17.342 2.06031C17.6198 2.16141 17.8386 2.38021 17.9397 2.65798C18 2.82367 18 3.0306 18 3.44444V9C18 12.3137 15.3137 15 12 15ZM12 15V18M18 4H20.5C20.9659 4 21.1989 4 21.3827 4.07612C21.6277 4.17761 21.8224 4.37229 21.9239 4.61732C22 4.80109 22 5.03406 22 5.5V6C22 6.92997 22 7.39496 21.8978 7.77646C21.6204 8.81173 20.8117 9.62038 19.7765 9.89778C19.395 10 18.93 10 18 10M6 4H3.5C3.03406 4 2.80109 4 2.61732 4.07612C2.37229 4.17761 2.17761 4.37229 2.07612 4.61732C2 4.80109 2 5.03406 2 5.5V6C2 6.92997 2 7.39496 2.10222 7.77646C2.37962 8.81173 3.18827 9.62038 4.22354 9.89778C4.60504 10 5.07003 10 6 10M7.44444 22H16.5556C16.801 22 17 21.801 17 21.5556C17 19.5919 15.4081 18 13.4444 18H10.5556C8.59188 18 7 19.5919 7 21.5556C7 21.801 7.19898 22 7.44444 22Z'
      stroke='#225CCB'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const BlueTick: React.FC<SvgIconProps> = props => (
  <svg
    width={22}
    height={22}
    viewBox='0 0 29 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width={29} height={29} rx={14.5} fill='#D1FADF' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.6582 8.9296L12.0065 17.2792L9.71067 14.8263C9.28775 14.4275 8.62317 14.4034 8.13983 14.7417C7.66858 15.0921 7.53567 15.7084 7.82567 16.2038L10.5444 20.6263C10.8103 21.0371 11.2694 21.2909 11.789 21.2909C12.2844 21.2909 12.7557 21.0371 13.0215 20.6263C13.4565 20.0584 21.7578 10.1621 21.7578 10.1621C22.8453 9.05044 21.5282 8.07169 20.6582 8.91752V8.9296Z'
      fill='#12B76A'
    />
  </svg>
);
const OrderFullfillment: React.FC<SvgIconProps> = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={17}
    height={17}
    viewBox='0 0 17 17'
    fill='none'
    {...props}
  >
    <path
      d='M9.34587 2.16992V4.92362C9.34587 5.29698 9.34587 5.48367 9.41853 5.62628C9.48244 5.75172 9.58443 5.8537 9.70987 5.91762C9.85248 5.99028 10.0392 5.99028 10.4125 5.99028H13.1662M6.01253 11.3236L7.34587 12.6569L10.3459 9.6569M9.34587 1.99023H5.8792C4.75909 1.99023 4.19904 1.99023 3.77122 2.20822C3.39489 2.39997 3.08893 2.70593 2.89719 3.08225C2.6792 3.51008 2.6792 4.07013 2.6792 5.19023V12.1236C2.6792 13.2437 2.6792 13.8037 2.89719 14.2315C3.08893 14.6079 3.39489 14.9138 3.77122 15.1056C4.19904 15.3236 4.75909 15.3236 5.8792 15.3236H10.1459C11.266 15.3236 11.826 15.3236 12.2538 15.1056C12.6302 14.9138 12.9361 14.6079 13.1279 14.2315C13.3459 13.8037 13.3459 13.2437 13.3459 12.1236V5.99023L9.34587 1.99023Z'
      stroke='#225CCB'
      strokeWidth={1.3}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const CustomerInformationIcon: React.FC<SvgIconProps> = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={17}
    height={17}
    viewBox='0 0 17 17'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_2753_12507)'>
      <path
        d='M2.6793 15.2448C3.08101 15.3665 3.62361 15.3665 4.54595 15.3665H11.4793C12.4016 15.3665 12.9442 15.3665 13.3459 15.2448M2.6793 15.2448C2.59316 15.2187 2.5135 15.187 2.43797 15.1485C2.06164 14.9568 1.75568 14.6508 1.56393 14.2745C1.34595 13.8467 1.34595 13.2866 1.34595 12.1665V5.2332C1.34595 4.1131 1.34595 3.55305 1.56393 3.12522C1.75568 2.7489 2.06164 2.44294 2.43797 2.25119C2.86579 2.0332 3.42584 2.0332 4.54595 2.0332H11.4793C12.5994 2.0332 13.1594 2.0332 13.5873 2.25119C13.9636 2.44294 14.2695 2.7489 14.4613 3.12522C14.6793 3.55305 14.6793 4.1131 14.6793 5.2332V12.1665C14.6793 13.2866 14.6793 13.8467 14.4613 14.2745C14.2695 14.6508 13.9636 14.9568 13.5873 15.1485C13.5117 15.187 13.4321 15.2187 13.3459 15.2448M2.6793 15.2448C2.67952 14.7053 2.68275 14.4198 2.73052 14.1796C2.94094 13.1218 3.76786 12.2949 4.82571 12.0844C5.0833 12.0332 5.39307 12.0332 6.01261 12.0332H10.0126C10.6322 12.0332 10.9419 12.0332 11.1995 12.0844C12.2574 12.2949 13.0843 13.1218 13.2947 14.1796C13.3425 14.4198 13.3457 14.7053 13.3459 15.2448M10.6793 7.0332C10.6793 8.50596 9.48537 9.69987 8.01261 9.69987C6.53985 9.69987 5.34595 8.50596 5.34595 7.0332C5.34595 5.56044 6.53985 4.36654 8.01261 4.36654C9.48537 4.36654 10.6793 5.56044 10.6793 7.0332Z'
        stroke='#225CCB'
        strokeWidth={1.2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2753_12507'>
        <rect
          width={16}
          height={16}
          fill='white'
          transform='translate(0.0125732 0.699219)'
        />
      </clipPath>
    </defs>
  </svg>
);

const Klarna: React.FC<SvgIconProps> = props => (
  <svg
    width='28px'
    height='28px'
    style={{
      background: '#F4B6C7',
      padding: 5,
      borderRadius: 10,
    }}
    viewBox='0 0 24 24'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M4.592 2v20H0V2h4.592zm11.46 0c0 4.194-1.583 8.105-4.415 11.068l-.278.283L17.702 22h-5.668l-6.893-9.4 1.779-1.332c2.858-2.14 4.535-5.378 4.637-8.924L11.562 2h4.49zM21.5 17a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5z' />
  </svg>
);
const Calendar: React.FC<SvgIconProps> = props => (
  <svg
    width={16}
    height={17}
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_2841_9479)'>
      <path
        d='M14 6.95052H2M10.6667 1.61719V4.28385M5.33333 1.61719V4.28385M5.2 14.9505H10.8C11.9201 14.9505 12.4802 14.9505 12.908 14.7325C13.2843 14.5408 13.5903 14.2348 13.782 13.8585C14 13.4307 14 12.8706 14 11.7505V6.15052C14 5.03042 14 4.47036 13.782 4.04254C13.5903 3.66622 13.2843 3.36025 12.908 3.16851C12.4802 2.95052 11.9201 2.95052 10.8 2.95052H5.2C4.0799 2.95052 3.51984 2.95052 3.09202 3.16851C2.71569 3.36025 2.40973 3.66622 2.21799 4.04254C2 4.47036 2 5.03042 2 6.15052V11.7505C2 12.8706 2 13.4307 2.21799 13.8585C2.40973 14.2348 2.71569 14.5408 3.09202 14.7325C3.51984 14.9505 4.0799 14.9505 5.2 14.9505Z'
        stroke='#225CCB'
        strokeWidth={1.6}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2841_9479'>
        <rect
          width={16}
          height={16}
          fill='white'
          transform='translate(0 0.283203)'
        />
      </clipPath>
    </defs>
  </svg>
);
const Billing: React.FC<SvgIconProps> = props => (
  <svg
    width={16}
    height={13}
    viewBox='0 0 16 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M3.99998 6.10612V8.77278M12 4.77279V7.43945M11.3333 1.43945C12.9658 1.43945 13.8487 1.68929 14.2881 1.88308C14.3466 1.90889 14.3758 1.92179 14.4602 2.00237C14.5109 2.05067 14.6032 2.19238 14.627 2.25818C14.6666 2.36795 14.6666 2.42795 14.6666 2.54795V9.71355C14.6666 10.3194 14.6666 10.6223 14.5758 10.778C14.4834 10.9364 14.3943 11.01 14.2213 11.0709C14.0512 11.1308 13.708 11.0648 13.0214 10.9329C12.5409 10.8406 11.971 10.7728 11.3333 10.7728C9.33331 10.7728 7.33331 12.1061 4.66665 12.1061C3.03417 12.1061 2.15122 11.8563 1.7119 11.6625C1.6534 11.6367 1.62414 11.6238 1.53971 11.5432C1.4891 11.4949 1.39672 11.3532 1.37296 11.2874C1.33331 11.1776 1.33331 11.1176 1.33331 10.9976L1.33331 3.83202C1.33331 3.22618 1.33331 2.92325 1.42416 2.76755C1.51658 2.60916 1.60571 2.53553 1.77869 2.47466C1.94874 2.41482 2.292 2.48078 2.97853 2.61268C3.45906 2.70501 4.02897 2.77279 4.66665 2.77279C6.66665 2.77279 8.66665 1.43945 11.3333 1.43945ZM9.66665 6.77279C9.66665 7.69326 8.92045 8.43945 7.99998 8.43945C7.07951 8.43945 6.33331 7.69326 6.33331 6.77279C6.33331 5.85231 7.07951 5.10612 7.99998 5.10612C8.92045 5.10612 9.66665 5.85231 9.66665 6.77279Z'
      stroke='#225CCB'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const IconRender = ({ iconName }: any) => {
  switch (iconName) {
    case 'arrow':
      return <ArrowRight />;
    case 'eye':
      return <Eye />;
    case 'blueEye':
      return <BlueEye />;
    case 'layersThree':
      return <LayersThree />;
    case 'activity':
      return <Activity />;
    case 'currencyDollar':
      return <CurrencyDollar />;
    case 'trophy':
      return <Trophy />;
    case 'tick':
      return <BlueTick />;
    case 'klarna':
      return <Klarna />;
    case 'orderfullfillment':
      return <OrderFullfillment />;
    case 'customerinformation':
      return <CustomerInformationIcon />;
    default:
      return <CurrencyDollar />;
  }
};

const PaginationLeftArrow: React.FC<SvgIconProps> = props => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.8333 10.0003H4.16663M4.16663 10.0003L9.99996 15.8337M4.16663 10.0003L9.99996 4.16699'
      stroke='#8391A2'
      strokeWidth={1.67}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const PaginationRightArrow: React.FC<SvgIconProps> = props => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.16663 10.0003H15.8333M15.8333 10.0003L10.0001 4.16699M15.8333 10.0003L10.0001 15.8337'
      stroke='#8391A2'
      strokeWidth={1.67}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export {
  ArrowRight,
  Eye,
  BlueEye,
  LayersThree,
  Activity,
  CurrencyDollar,
  Trophy,
  Klarna,
  BlueTick,
  OrderFullfillment,
  CustomerInformationIcon,
  Calendar,
  Billing,
  IconRender,
  PaginationLeftArrow,
  PaginationRightArrow,
};
