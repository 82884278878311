import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import { Box, Button, TextField, Tooltip } from "@mui/material";
import { ToastContainer } from "react-toastify";
import styles from "./style.module.scss";
import { useKlarnaByDispute, useManageManually } from "hooks";
import { svgIcons } from "elements";
import { Calendar, ErrorMessage } from "components/disputeFlow/NodeHelpers";
import { isEmpty } from "lodash";
import { Textarea } from "components/ui";
import { capitalizeDisputeDate } from "utils/disputeUtils/disputeDetails";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { getDisputeResponseFindByDisputeIdRequest } from "redux/actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "components/loading/loading";
import SelectUI from "components/disputeFlow/RelatedCom/Select";
const fileTypes = ["PNG", "JPG", "JPEG", "PDF"];

const ManageManually = ({ disputePaymentGateway }: any) => {
  const [uploadFile, setUploadFile] = useState<any>([]);

  const { onFormSubmit } = useManageManually();
  const { data } = useKlarnaByDispute(
    disputePaymentGateway?.toLowerCase() === "klarna"
  );
  const { disputeId } = useParams();
  const dispatch = useDispatch();
  const disputeResponseFindByDisputeId = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponseFindByDisputeId
  );
  const { isLoading } = disputeResponseFindByDisputeId;
  useEffect(() => {
    if (disputeId) {
      dispatch(getDisputeResponseFindByDisputeIdRequest(disputeId));
    }
  }, [dispatch]);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm();
  const onSubmit = async (data: any) => {
    Object.assign(data, {
      shipping_date: dayjs(data.shipping_date).format("YYYY-MM-DD"),
    });
    onFormSubmit(data, uploadFile);
  };

  const handleFileUpload = (doc: any) => {
    setUploadFile(doc[0]);
  };
  useEffect(() => {
    if (uploadFile?.name) {
      clearErrors("uncategorizedFile");
    }
  }, [uploadFile?.name]);

  const reformForManageManually =
    data &&
    data.map((item: any) => {
      const newItem: any = {};
      newItem.id = item.id;
      newItem.name = item.value;
      newItem.label = capitalizeDisputeDate(item?.value);
      newItem.type = item?.value.includes("shipping_date") ? "date" : "text";
      return newItem;
    });
  const options = [
    {
      label: "PROOF_OF_FULFILLMENT",
      value: "PROOF_OF_FULFILLMENT",
    },
    {
      label: "PROOF_OF_REFUND",
      value: "PROOF_OF_REFUND",
    },
    {
      label: "PROOF_OF_RETURN",
      value: "PROOF_OF_RETURN",
    },
    {
      label: "OTHER",
      value: "OTHER",
    },

  ]

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.formWrapper}>
          <ToastContainer />
          <form onSubmit={handleSubmit(onSubmit)}>
            {disputePaymentGateway?.toLowerCase() === "klarna" && (
              <Box display={`flex`} mt="1rem" gap={"1rem"}>
                {reformForManageManually &&
                  reformForManageManually.map((item: any, index: number) =>
                    item.type === "text" ? (
                      <Box width="100%">
                        <p style={{ marginBottom: "8px" }}>{item.label}</p>
                        <Controller
                          control={control}
                          name={item.name}
                          rules={{
                            required: {
                              value: true,
                              message: "This is required",
                            },
                          }}
                          render={({ field }) => {
                            return (
                              <TextField
                                fullWidth
                                label={item?.label}
                                {...field}
                              />
                            );
                          }}
                        />
                      </Box>
                    ) : (
                      <Box width="100%">
                        <p style={{ marginBottom: "8px" }}>{item.label}</p>
                        <Calendar
                          className={styles.calendarStyle}
                          control={control}
                          name={item?.name}
                          errors={errors}
                        />
                      </Box>
                    )
                  )}
              </Box>
            )}

            <Tooltip
              arrow
              placement="top"
              title="Upload or drag and drop the file"
            >
              <div className={styles.uploadFile}>
                <label>Uncategorized File</label>
                <div className={""}>
                  <FileUploader
                    multiple={true}
                    handleChange={(doc: any) => handleFileUpload(doc)}
                    name="file"
                    types={fileTypes}
                    children={
                      <div className={styles.documentField}>
                        <img
                          style={{ display: uploadFile.name ? "none" : "" }}
                          src={svgIcons.Upload_In_Blue}
                          alt="upload"
                        />
                        <p style={{ display: uploadFile.name ? "none" : "" }}>
                          Uncategorized File
                        </p>
                        <input
                          data-testid="dispute-document"
                          hidden={uploadFile && uploadFile?.name ? false : true}
                          readOnly
                          value={uploadFile?.name}
                        />
                      </div>
                    }
                  />
                </div>
                <ErrorMessage name={"uncategorizedFile"} errors={errors} />
              </div>
            </Tooltip>
            <div className={styles.dropDownWrapper}>
              <div className={styles.initialSelects}>
                <div className={styles.initialSelectWrapper}>
                  <label>Uncategorized text</label>
                  <Controller
                    name="uncategorizedText"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <Textarea
                          {...field}
                          aria-label="minimum height"
                          minRows={9}
                          placeholder="write uncategorized text..."
                        />
                      );
                    }}
                  />
                  <ErrorMessage name={"uncategorizedText"} errors={errors} />
                </div>
              </div>
            </div>

            {
              disputePaymentGateway === "PayPal" &&
              <>
              <div className={styles.dropDownWrapper}>
                <div className={styles.initialSelects}>
                  <div className={styles.initialSelectWrapper}>
                    <SelectUI
                      {...{
                        styles,
                        control,
                        name: "evidenceType",
                        options,
                        label: "Select Evidence Type",
                        errors,
                        clearErrors,
                        placeholder: "Select evidence type",
                      }}
                    />
                    <ErrorMessage name={"evidenceType"} errors={errors} />
                  </div>
                </div>
              </div>
              <div className={styles.dropDownWrapper}>
              <div className={styles.initialSelects}>
                <div className={styles.initialSelectWrapper}>
                  <label>Carrier</label>
                  <Controller
                    name="carrier"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          aria-label="minimum height"
                          placeholder="Write carrier name"
                          size='small'
                        />
                      );
                    }}
                  />
                  <ErrorMessage name={"carrier"} errors={errors} />
                </div>
              </div>
            </div>
            <div className={styles.dropDownWrapper}>
              <div className={styles.initialSelects}>
                <div className={styles.initialSelectWrapper}>
                  <label>Tracking number</label>
                  <Controller
                    name="tracking_id"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          aria-label="minimum height"
                          placeholder="write tracking number"
                          size='small'
                        />
                      );
                    }}
                  />
                  <ErrorMessage name={"tracking_id"} errors={errors} />
                </div>
              </div>
            </div>
              </>
            }
            <Box mt="16px">
              <Button
                type="submit"
                onClick={() => {
                  if (isEmpty(uploadFile?.name)) {
                    setError("uncategorizedFile", {
                      type: "required",
                      message: "Please add a file",
                    });
                  } else {
                    clearErrors("uncategorizedFile");
                  }
                }}
                disabled={
                  disputeResponseFindByDisputeId?.data?.length > 0
                    ? true
                    : false
                }
                variant="contained"
              >
                Manage
              </Button>
              {disputeResponseFindByDisputeId?.data?.length > 0 && (
                <p style={{ marginTop: "10px", color: '#98A2B3' }}>
                  Please delete the existing response to manage manually
                </p>
              )}
            </Box>
          </form>
        </div>
      )}
    </>
  );
};

export default ManageManually;
