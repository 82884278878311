import { Tooltip } from '@mui/material';
import styles from './styles.module.scss';
const SingleBox = ({ name, onClick, label }: any) => {
  return (
    <div className={styles.institution} data-testid={name} onClick={onClick}>
      <div className={styles.lineGroup}>
        <Tooltip arrow placement='top' title={label}>
          <p className={styles.institutionName}>{label}</p>
        </Tooltip>
      </div>
    </div>
  );
};

export default SingleBox;
