import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    klarnaRequestedField: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
}

// get klarna requested fields
const getKlarnaByDisputeRequest = (state:any,action:any) => {
    return update(state, {
        klarnaRequestedField: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
}
const getKlarnaByDisputeSuccess = (state:any,action:any) => {
    return update(state, {
        klarnaRequestedField: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: action.payload  }
        }
    });
}
const getKlarnaByDisputeError = (state:any,action:any) => {
    return update(state, {
        klarnaRequestedField: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: action.payload }
        }
    });
}

export default handleActions(
    {
        [constants.GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_REQUEST]: getKlarnaByDisputeRequest,
        [constants.GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_SUCCESS]: getKlarnaByDisputeSuccess,
        [constants.GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_ERROR]: getKlarnaByDisputeError,
    },
    initialState
);