import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    reportList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    byStoreReportList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    byPspReportList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    byReasonsReportList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    byMonthlyReportList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
}

//processing-dispute
const getReportingListRequest = (state: any, action: any) => {
    return update(state, {
        reportList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getReportingListSuccess = (state: any, action: any) => {
    return update(state, {
        reportList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getReportingListError = (state: any, action: any) => {
    return update(state, {
        reportList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//by-stores
const getByStoresReportingListRequest = (state: any, action: any) => {
    return update(state, {
        byStoreReportList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getByStoresReportingListSuccess = (state: any, action: any) => {
    return update(state, {
        byStoreReportList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getByStoresReportingListError = (state: any, action: any) => {
    return update(state, {
        byStoreReportList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//by-psp
const getByPspReportingListRequest = (state: any, action: any) => {
    return update(state, {
        byPspReportList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getByPspReportingListSuccess = (state: any, action: any) => {
    return update(state, {
        byPspReportList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getByPspReportingListError = (state: any, action: any) => {
    return update(state, {
        byPspReportList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//by-Reasons
const getByReasonReportingListRequest = (state: any, action: any) => {
    return update(state, {
        byReasonsReportList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getByReasonReportingListSuccess = (state: any, action: any) => {
    return update(state, {
        byReasonsReportList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getByReasonReportingListError = (state: any, action: any) => {
    return update(state, {
        byReasonsReportList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//by-Monthly
const getByMonthlyReportingListRequest = (state: any, action: any) => {
    return update(state, {
        byMonthlyReportList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getByMonthlyReportingListSuccess = (state: any, action: any) => {
    return update(state, {
        byMonthlyReportList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getByMonthlyReportingListError = (state: any, action: any) => {
    return update(state, {
        byMonthlyReportList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


export default handleActions(
    {
        [constants.GET_REPORTING_REQUEST]: getReportingListRequest,
        [constants.GET_REPORTING_SUCCESS]: getReportingListSuccess,
        [constants.GET_REPORTING_ERROR]: getReportingListError,

        [constants.GET_BY_STORES_REPORTING_REQUEST]: getByStoresReportingListRequest,
        [constants.GET_BY_STORES_REPORTING_SUCCESS]: getByStoresReportingListSuccess,
        [constants.GET_BY_STORES_REPORTING_ERROR]: getByStoresReportingListError,

        [constants.GET_BY_PSP_REPORTING_REQUEST]: getByPspReportingListRequest,
        [constants.GET_BY_PSP_REPORTING_SUCCESS]: getByPspReportingListSuccess,
        [constants.GET_BY_PSP_REPORTING_ERROR]: getByPspReportingListError,

        [constants.GET_BY_REASONS_REPORTING_REQUEST]: getByReasonReportingListRequest,
        [constants.GET_BY_REASONS_REPORTING_SUCCESS]: getByReasonReportingListSuccess,
        [constants.GET_BY_REASONS_REPORTING_ERROR]: getByReasonReportingListError,

        [constants.GET_BY_MONTHLY_REPORTING_REQUEST]: getByMonthlyReportingListRequest,
        [constants.GET_BY_MONTHLY_REPORTING_SUCCESS]: getByMonthlyReportingListSuccess,
        [constants.GET_BY_MONTHLY_REPORTING_ERROR]: getByMonthlyReportingListError,
    },
    initialState
);