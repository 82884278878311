import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getDisputeCustomerDetailsRequest } from "redux/actions";

interface RootState {
  disputeDetailsStore: {
    manageDisputeCustomerDetails: {
      isSuccess: boolean;
      data: any;
      isLoading: boolean;
    };
  };
}

const useCustomerInformation = () => {
  const dispatch = useDispatch();
  const { disputeId } = useParams();

  const { isSuccess, data, isLoading } = useSelector(
    (state: RootState) =>
      state?.disputeDetailsStore?.manageDisputeCustomerDetails
  );
  useEffect(() => {
    if (disputeId) {
      const payload = disputeId;
      dispatch(getDisputeCustomerDetailsRequest(payload));
    }
  }, [disputeId]);
  return { data, isLoading, isSuccess };
};
export default useCustomerInformation;
