import { useEffect } from 'react';
import classNames from 'classnames';
// => Tiptap packages
import { EditorContent, BubbleMenu } from '@tiptap/react';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import './style.scss';
import styles from './styles.module.scss';
import { isEmpty } from 'lodash';
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  InsertLink,
  RotateRight,
  StrikethroughS,
} from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import Toolbox from '../editor/editorToolbox/toolbox';
import { templateComponent } from 'utils/disputeTemplates';
import { svgIcons } from 'elements';
import { useNavigate } from 'react-router-dom';
import { useTiptap } from 'hooks';
import LinkModal from 'components/modal/LinkModal';
//Make this into className #TODO
// sorted

const TiptapEditor = ({
  setEditorContent,
  editorData,
  noToolBox,
  setValue,
  preview,
  fullWidth,
}: any) => {
  const {
    editor,
    modalIsOpen,
    isExpanded,
    url,
    closeModal,
    onDrop,
    onDrag,
    toggleHeading,
    toggleHeading2,
    toggleHeading3,
    toggleStrike,
    toggleItalic,
    toggleUnderline,
    toggleBold,
    removeLink,
    saveLink,
    openModal,
    setIsModalOpen,
    setUrl,
    removeRow,
    addRow,
    setIsExpanded,
  } = useTiptap(setEditorContent, setValue);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isEmpty(editorData)) {
      editor?.commands?.setContent(editorData, false, {
        preserveWhitespace: 'full',
      });
    }
  }, [editorData, editor]);

  // TODO move to the another file
  // moved to another component
  return (
    <Box className='editor'>
      <div
        className={styles.buttons}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: !noToolBox ? '12px 5px' : '',
        }}
      >
        {!noToolBox && (
          <>
            <div>
              <Button
                onClick={() => navigate('/templates')}
                variant='outlined'
                className={styles.exit}
              >
                <p>Exit</p>
                <img src={svgIcons.Logout} alt='exit' />
              </Button>
            </div>
            <div className={styles.editorButtons}>
              <Button
                style={{ cursor: 'not-allowed', marginRight: '20px' }}
                variant='outlined'
                className={styles.preview}
              >
                <p>Preview</p>
                <img src={svgIcons.File_Attachment} alt='file-attach' />
              </Button>
            </div>
          </>
        )}
      </div>
      {/* Reusable component #TODO */}

      <BubbleMenu
        className='bubble-menu-light'
        tippyOptions={{ duration: 500 }}
        editor={editor}
        shouldShow={({ editor, view, state, oldState, from, to }) => {
          // only show the bubble menu for links.
          return from === to && editor.isActive('link');
        }}
      >
        <button className='button' onClick={openModal}>
          Edit
        </button>
        <button className='button-remove' onClick={removeLink}>
          Remove
        </button>
      </BubbleMenu>
      {editor && (
        <BubbleMenu editor={editor} tippyOptions={{ duration: 500 }}>
          <Box
            display={`flex`}
            padding={1}
            bgcolor={`white`}
            border={`1px solid #ccc`}
            className='flex p-1 bg-white rounded border'
            sx={{
              width: '500px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              borderRadius: '12px',
            }}
          >
            <button
              type='button'
              className='menu-button'
              onClick={() => editor.chain().focus().undo().run()}
              disabled={!editor.can().undo()}
            >
              <RotateLeftIcon />
            </button>
            <button
              type='button'
              className='menu-button'
              onClick={() => editor.chain().focus().redo().run()}
              disabled={!editor.can().redo()}
            >
              <RotateRight />
            </button>

            <button
              type='button'
              className={classNames('menu-button', {
                'is-active': editor.isActive('link'),
              })}
              onClick={() => openModal()}
            >
              <InsertLink />
            </button>

            <button
              type='button'
              className={classNames('menu-button', {
                'is-active': editor.isActive('bold'),
              })}
              onClick={toggleBold}
            >
              <FormatBold />
            </button>
            <button
              type='button'
              className={classNames('menu-button', {
                'is-active': editor.isActive('underline'),
              })}
              onClick={toggleUnderline}
            >
              <FormatUnderlined />
            </button>

            <button
              type='button'
              className={classNames('menu-button', {
                'is-active': editor.isActive('italic'),
              })}
              onClick={toggleItalic}
            >
              <FormatItalic />
            </button>

            <button
              type='button'
              className={classNames('menu-button', {
                'is-active': editor.isActive('strike'),
              })}
              onClick={toggleStrike}
            >
              <StrikethroughS />
            </button>
            <button
              type='button'
              className={classNames('menu-button', {
                'is-active': editor.isActive('heading', { level: 1 })
                  ? 'is-active'
                  : '',
              })}
              onClick={toggleHeading}
            >
              <img src={svgIcons.HeadingOne} alt='heading-one' />
            </button>
            <button
              type='button'
              className={classNames('menu-button', {
                'is-active': editor.isActive('heading', { level: 2 })
                  ? 'is-active'
                  : '',
              })}
              onClick={toggleHeading2}
            >
              <img src={svgIcons.HeadingTwo} alt='heading-two' />
            </button>
            <button
              type='button'
              className={classNames('menu-button', {
                'is-active': editor.isActive('heading', { level: 3 })
                  ? 'is-active'
                  : '',
              })}
              onClick={toggleHeading3}
            >
              <img src={svgIcons.HeadingThree} alt='heading-three' />
            </button>

            <button
              className={classNames('menu-button')}
              onClick={() => setIsExpanded(!isExpanded)}
              style={{
                position: 'relative',
                fontWeight: '600',
                fontSize: '16px',
              }}
            >
              <img src={svgIcons.Table} alt='table' />
            </button>
            <ul
              style={{ left: '300px' }}
              className={`${
                isExpanded
                  ? styles.isExpandStyle
                  : !isExpanded
                  ? styles.nonExpandStyle
                  : styles.dropDownMenu
              }`}
            >
              <li style={{ width: '100%' }}>
                <Tooltip arrow placement='top' title='remove row'>
                  <button
                    type='button'
                    onClick={removeRow}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '30px',
                      width: '100%',
                    }}
                  >
                    <img src={svgIcons.RemoveTableRow} alt='remove-row' />
                    <span>Remove Row</span>
                  </button>
                </Tooltip>
              </li>
              <li style={{ width: '100%' }}>
                <Tooltip arrow placement='top' title='add row'>
                  <button
                    type='button'
                    onClick={addRow}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '30px',
                      width: '100%',
                    }}
                  >
                    <img
                      style={{ marginLeft: '4px' }}
                      src={svgIcons.AddTableRow}
                      alt='add-row'
                    />
                    <span>Add Row</span>
                  </button>
                </Tooltip>
              </li>
            </ul>
          </Box>
        </BubbleMenu>
      )}
      <Box
        display={`flex`}
        justifyContent={`space-between`}
        className={
          noToolBox && fullWidth
            ? 'contentDisputeWrapper fullWidthDivWrapper'
            : noToolBox && preview
            ? 'fullContent'
            : noToolBox
            ? 'contentDisputeWrapper'
            : fullWidth
            ? 'fullWidthDivWrapper'
            : 'contentWrapper'
        }
        gap={`1rem`}
      >
        <EditorContent
          placeholder="Let's create dispute template"
          editor={editor}
          height={'100vh'}
          onDrop={event => onDrop(event)}
          style={{ width: '99%' }}
        />
        {!noToolBox && (
          <Box className={styles.toolsboxSection}>
            <Box className={styles.toolsbox}>
              {templateComponent.map((tool, index) => (
                <Toolbox key={tool.id} data={tool} {...{ index, onDrag }} />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <LinkModal
        url={url}
        isOpen={modalIsOpen}
        title='Edit Link'
        onRequestClose={closeModal}
        contentLabel='Edit Link Modal'
        closeModal={closeModal}
        setIsModalOpen={setIsModalOpen}
        onChangeUrl={(e: any) => setUrl(e.target.value)}
        onSaveLink={saveLink}
        onRemoveLink={removeLink}
      />
    </Box>
  );
};
export default TiptapEditor;
