import styles from "./style.module.scss";

const DisputeAwaitingStage = ({ stageIcon, stage, stageName }: any) => {
  return (
    <div className={styles.underReviewWrapper}>
      <div>
        <img src={stageIcon} alt="stage" />
        <div className={styles.postReviewInfo}>
          <h6>{stage}</h6>
          <p>{stageName}</p>
        </div>
      </div>
    </div>
  );
};

export default DisputeAwaitingStage;
