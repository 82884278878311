import { useBreadCumbs, useStoreView } from "hooks";
import { StoresView } from "components";
import styles from "./style.module.scss";
import { BreadcumbItem } from "components/ui";

const StoreView = () => {
  const {
    handleChange,
    setShopAddonCheck,
    viewInShopify,
    storeDetails,
    value,
    shopFindShop,
    shopFindShopAddon,
    shopsSaveAddon,
    disputeTemplateFindByStore,
    disputeFlowFindByStore,
  } = useStoreView();
  const { allBreadCumbs: breadCumbs, currentSelected } = useBreadCumbs({
    styles,
  });

  return (
    <div className={styles.disputesWrapper}>
      <div className={styles.nav}>
        <h3>{shopFindShop?.data?.name || "Store View"}</h3>
        <div className={styles.navPath}>
          {breadCumbs.map((item, key) => (
            <BreadcumbItem {...item} {...{ key }} />
          ))}
        </div>
      </div>
      <div>
        <StoresView
          {...{
            handleChange,
            viewInShopify,
            storeDetails,
            value,
            shopFindShop,
            setShopAddonCheck,
            shopsSaveAddon,
            currentSelected,
            disputeTemplateFindByStore,
            disputeFlowFindByStore,
            shopFindShopAddon,
          }}
        />
      </div>
    </div>
  );
};

export default StoreView;
