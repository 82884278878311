import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Loading from '../../../loading/loading';
import { Button } from 'components/ui';
import styles from './style.module.scss';
import { Textarea } from 'components/ui';
import BasicSelect from 'components/ui/Select/Index';
import {
  useResizeObserver,
  useFetchDisputeflow,
  useCreateResponse,
} from 'hooks';
import { ErrorMessage } from 'components/disputeFlow/NodeHelpers';
import { Box, Tooltip } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { svgIcons } from 'elements';
import _, { isEmpty } from 'lodash';
import {
  getDisputeResponseFindByDisputeIdRequest,
  getDisputeTemplateViewRequest,
  getFetchDisputeTemplateRequest,
  getFetchTemplateSummaryRequest,
} from 'redux/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import DisputeTemplatePreview from 'components/disputeDetails/disputeTemplatePreview';

type FlowIdDataTypes = { id: number; name: string }[];
type TemplateIdDataTypes = { id: number; name: string }[];

const fileTypes = ['PNG', 'JPG', 'JPEG', 'PDF'];
const CreateResponse = ({ paymentProcessor }: any) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();
  const disputeDetails = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );
  const { handleUpdateCreateResponse } = useCreateResponse();
  useResizeObserver();
  const dispatch = useDispatch();
  const [newFlowIdData, setNewFlowIdData] = useState([] as FlowIdDataTypes);
  const [newTemplateData, setNewTemplateData] = useState(
    [] as TemplateIdDataTypes
  );
  const [uploadFile, setUploadFile] = useState<any>([]);
  const { disputeId } = useParams();
  const { data: templateIds, isSuccess } = useSelector(
    (state: any) => state?.disputeDetailsStore?.manageDisputeFetchTemplate
  );
  const { data: basedOnTemplateSummary } = useSelector(
    (state: any) => state?.disputeDetailsStore?.manageDisputeFetchSummary
  );
  const disputeResponseFindByDisputeId = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponseFindByDisputeId
  );
  // useEffect(() => {
  //   if (disputeId) {
  //     dispatch(getDisputeResponseFindByDisputeIdRequest(disputeId));
  //   }
  // }, [dispatch]);
  const handleFileUpload = (doc: any) => {
    setUploadFile(doc[0]);
  };
  useEffect(() => {
    if (uploadFile?.name) {
      clearErrors('file');
    }
  }, [uploadFile?.name]);
  const { fetchedFlow, isFlowLoading, isFlowSuccess } = useFetchDisputeflow({
    disputeId,
    paymentProcessor,
  });
  const onSubmit = (_data: any) => {
    const payload = {
      disputeId,
      flowId: _data.flowId,
      templateId: _data.templateId,
      disputeSummary: _data?.summary,
    };
    handleUpdateCreateResponse(payload, uploadFile);
    reset();
  };
  const { isSuccess: isHandleCreateResponseSuccess } = useSelector(
    (state: any) => state?.disputeResponse?.createDisputeResponse
  );
  useEffect(() => {
    if (isHandleCreateResponseSuccess) {
      dispatch(getDisputeResponseFindByDisputeIdRequest(disputeId));
    }
  }, [dispatch]);
  useEffect(() => {
    reset({ flowId: '', templateId: '' });
  }, []);
  useEffect(() => {
    // for flow id
    if (isFlowSuccess && fetchedFlow) {
      let flowIdOptions: { id: number; name: string }[] = fetchedFlow?.map(
        (flow: any) => {
          let stageObj = {
            id: flow?.id,
            name: flow?.name,
          };
          return stageObj;
        }
      );

      // Check if {flowId: 0, name:"PLACEHOLDER"} is present in flowIdOptions
      const placeholderExists =
        flowIdOptions &&
        flowIdOptions.some(
          option => option.id === 0 && option.name === 'PLACEHOLDER'
        );

      // If not present, append the placeholder object to the array
      if (!placeholderExists) {
        flowIdOptions.push({ id: 0, name: 'PLACEHOLDER' });
      }

      setNewFlowIdData(flowIdOptions);
    }
  }, [fetchedFlow, isFlowSuccess]);

  const template = watch('templateId');
  const flowId = watch('flowId');
  const templateId = disputeResponseFindByDisputeId?.data[0]?.disputeTemplateId;

  //changing the flow Id
  useEffect(() => {
    if (flowId === 0 || flowId) {
      dispatch(getFetchDisputeTemplateRequest({ flowId, disputeId }));
    }
  }, [flowId]);
  useEffect(() => {
    if (template === 0 || template) {
      dispatch(
        getFetchTemplateSummaryRequest({ templateId: template, disputeId })
      );
      dispatch(getDisputeTemplateViewRequest(templateId));
    }
  }, [template]);
  useEffect(() => {
    if (isSuccess && templateIds) {
      ///should be template
      let clonedTemplateId: { id: number; name: string }[] =
        _.cloneDeep(templateIds);
      const placeholderExists =
        clonedTemplateId &&
        clonedTemplateId.some(
          (option: any) => option.id === 0 && option.name === 'PLACEHOLDER'
        );
      // If not present, append the placeholder object to the array
      if (!placeholderExists) {
        clonedTemplateId.push({ id: 0, name: 'PLACEHOLDER' });
      }
      setNewTemplateData(clonedTemplateId);
    }
  }, [isSuccess, templateIds]);

  useEffect(() => {
    if (template && !isEmpty(basedOnTemplateSummary)) {
      setValue('summary', basedOnTemplateSummary ?? '');
    }
  }, [template, basedOnTemplateSummary]);

  // useEffect(() => {
  //   if (!isEmpty(newFlowIdData)) {
  //     const findFlow = newFlowIdData.find((item) => Number(item.id) === 0);
  //     const findTemplate = newTemplateData.find(
  //       (item) => Number(item.id) === 0
  //     );
  //     if (findFlow) {
  //       setValue("flowId", findFlow.id); // Set the initial value of flowId
  //     }
  //     if (findTemplate) {
  //       setValue("templateId", findTemplate.id); // Set the initial value of templateId
  //     }
  //   }
  // }, [newFlowIdData, newTemplateData,setValue,watch]);

  useEffect(() => {
    if (!isEmpty(newFlowIdData) && !isEmpty(newTemplateData)) {
      const currentFlowValue = watch('flowId');
      const currentTemplateValue = watch('templateId');

      if (!currentFlowValue && !currentTemplateValue) {
        const findFlow = newFlowIdData.find(item => Number(item.id) === 0);
        const findTemplate = newTemplateData.find(
          item => Number(item.id) === 0
        );
        if (findFlow) {
          setValue('flowId', findFlow.id);
        }
        if (findTemplate) {
          setValue('templateId', findTemplate.id);
        }
      }
    }
  }, [newFlowIdData, newTemplateData, flowId, template]);

  // useEffect(() => {}, []);

  return (
    <div className={styles.formWrapper}>
      {isFlowLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.dropDownWrapper}>
            <div className={styles.initialSelects}>
              <Box gap={2} display={`flex`} justifyContent={`space-between`}>
                <Box flex={`1`} className={styles.initialSelectWrapper}>
                  <Controller
                    name='flowId'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Flow is required',
                      },
                    }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <BasicSelect
                            label={'Flow Id'}
                            options={newFlowIdData ?? []}
                            field={field}
                            placeholder={'Select Flow...'}
                          />
                          {fieldState.error && (
                            <p className={styles.errorStyle}>
                              {fieldState.error.message}
                            </p>
                          )}
                        </>
                      );
                    }}
                  />
                </Box>

                <Box flex={`1`} className={styles.initialSelectWrapper}>
                  <Controller
                    name='templateId'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Template is required',
                      },
                    }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <BasicSelect
                            label={'Template'}
                            options={newTemplateData ?? []}
                            field={field}
                            error={!!fieldState.error}
                            placeholder={'Select Template...'}
                          />
                          {fieldState.error && (
                            <p className={styles.errorStyle}>
                              {fieldState.error.message}
                            </p>
                          )}
                        </>
                      );
                    }}
                  />
                </Box>
              </Box>
              <div>
                <DisputeTemplatePreview />
              </div>
              <div className={styles.textAreaWrapper}>
                <label>Summary</label>
                <Textarea
                  minRows={10}
                  aria-label='maximum height'
                  placeholder='Type here...'
                  {...register('summary', {
                    required: {
                      value: true,
                      message: 'summary is required',
                    },
                  })}
                />
                {errors && <ErrorMessage name='summary' errors={errors} />}
              </div>
              {template === 0 && flowId === 0 && (
                <Tooltip
                  arrow
                  placement='top'
                  title='Upload or drag and drop the file'
                >
                  <div className={styles.uploadFile}>
                    <label>Upload File</label>
                    <div className={''}>
                      <FileUploader
                        multiple={true}
                        handleChange={(doc: any) => handleFileUpload(doc)}
                        name='file'
                        types={fileTypes}
                        children={
                          <div className={styles.documentField}>
                            <img
                              style={{ display: uploadFile.name ? 'none' : '' }}
                              src={svgIcons.Upload_In_Blue}
                              alt='upload'
                            />
                            <p
                              style={{ display: uploadFile.name ? 'none' : '' }}
                            >
                              Upload File
                            </p>
                            <input
                              data-testid='dispute-document'
                              hidden={
                                uploadFile && uploadFile?.name ? false : true
                              }
                              readOnly
                              value={uploadFile?.name}
                            />
                          </div>
                        }
                      />
                    </div>
                    <ErrorMessage name={'file'} errors={errors} />
                  </div>
                </Tooltip>
              )}
              {!disputeDetails?.data?.disputeResponseSubmitted && (
                <div>
                  <Button
                    {...{
                      btnTitle: 'Create',
                      btnLabel: 'Create',
                      className: styles.selectButtonStyle,
                      isDisable:
                        disputeResponseFindByDisputeId?.data.length > 0
                          ? true
                          : false,
                      type: 'submit',
                      onClick: () => {
                        if (
                          isEmpty(uploadFile?.name) &&
                          flowId === 0 &&
                          template === 0
                        ) {
                          setError('file', {
                            type: 'required',
                            message: 'Please add a file',
                          });
                        } else {
                          clearErrors('uncategorizedFile');
                        }
                      },
                    }}
                  />
                  {disputeResponseFindByDisputeId?.data.length > 0 && (
                    <p style={{ marginTop: '10px', color: '#98A2B3' }}>
                      Please delete the existing response to create a new
                      response.
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default CreateResponse;
