import axios from "axios";
import { configs } from "configs";
import Swal from "sweetalert2";

const useSendMessage = () => {

  const onFormSubmit = async (data: any, uploadFile: any,disputeId:any) => {
    const formData: any = new FormData();
    formData.append("file", uploadFile);
    formData.append("message", data);
    formData.append("disputeId",disputeId);

    console.log(data,'this is the formdata from the useSendMessage form hook')

    const token = localStorage.getItem("accessToken");
    const API_UPLOAD_CONFIG = {
      timeout: 10000,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await axios.post(
        `${configs.BASE_URL}/dispute/${disputeId}/send-message`,
        formData,
        API_UPLOAD_CONFIG
      );
      if (response.data) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your document has been send successfully",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Something is wrong with submission file",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };
  return { onFormSubmit };
};
export default useSendMessage;
