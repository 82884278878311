import axios from "axios";
import { configs } from "configs";
import { isEmpty } from "lodash";
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deleteDisputeResponseClear, deleteDisputeResponseRequest } from "redux/actions";
import Swal from "sweetalert2";

const useCreateResponse = () => {
  const dispatch = useDispatch();
  const { data, isSuccess, isLoading, isError } = useSelector(
    (state: any) => state?.disputeResponse?.deleteSingleDisputeResponse
  );
  const[currentId, setCurrentId]= useState(0);
  const handleUpdateCreateResponse = async (data: any, uploadFile: any) => {
    const formData: any = new FormData();
    if (!isEmpty(uploadFile)) {
      formData.append("file", uploadFile);
    }
    formData.append("disputeId", data?.disputeId);
    formData.append("flowId", data.flowId);
    formData.append("templateId", data.templateId);
    formData.append("disputeSummary", data?.disputeSummary);
    const token = localStorage.getItem("accessToken");
    const API_UPLOAD_CONFIG = {
      timeout: 10000,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await axios.post(
        `${configs.BASE_URL}/dispute-response/create-response`,
        formData,
        API_UPLOAD_CONFIG
      );
      if (response.data) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `"Your data has been saved successfully"`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Something is wrong with submission form",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  useEffect(() => {
    if(isSuccess &&  data === 'SUCCESS' && currentId){
      dispatch(deleteDisputeResponseClear(currentId))
    }
  }, [isSuccess, data])
  const handleDeleteResponse = (id: number) => {
    dispatch(deleteDisputeResponseRequest(id));
    setCurrentId(id)
  };
  return { handleUpdateCreateResponse, handleDeleteResponse };
};
export default useCreateResponse;
