import { useState } from "react";
import { Button } from "@mui/material";
import { Search } from "../../..";
import ApplyFilter from "./applyFilter";

import styles from "./style.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BranchIcon, FilterIcon } from "components/disputeFlow/NodeHelpers";

interface templateFilterProps {
  // setRedirect: React.Dispatch<React.SetStateAction<string>>,
  setListPayload: React.Dispatch<React.SetStateAction<undefined>> | any;
  handleSearch: (query:string)=> void
}

const TemplateFilter = ({ setListPayload,handleSearch }: templateFilterProps) => {
  const navigate = useNavigate();
  const [filterOpen, setFilterOpen] = useState<boolean>(false);

  const { storeId } = useParams();
  let location = useLocation();
  const toBeSelected = location?.pathname.includes("store-details");
  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  function handleRedirect() {
    if (toBeSelected && storeId) {
      navigate(`/templates/create-new/${storeId}`);
    } else {
      navigate("/templates/create-new");
    }
  }

  return (
    <div className={styles.search}>
      <div className={styles.searchTop}>
        <button
          data-testid="dispute-list-filter"
          className={styles.filterButton}
          onClick={() => handleFilter()}
        >
          <FilterIcon className={styles.icon} />
          Filter
        </button>
        <div className={styles.searchDiv}>
          <Search
            onSearch={(query)=> handleSearch(query) }
            onType={(query)=> handleSearch(query)}
            type={""}
            placeholder={"Search here ..."}
            className={styles.searchInput}
          />
        </div>
        <div className={styles.createNew}>
          <Button
            variant="contained"
            onClick={handleRedirect}
            className={styles.git}
            data-testid="my-templates-create-new"
          >
            <BranchIcon className={styles.icon} />
            <p>Create New</p>
          </Button>
        </div>
      </div>
      <div
        className={styles.filters}
        style={{ display: !filterOpen ? "none" : "" }}
      >
        <ApplyFilter setListPayload={setListPayload} />
      </div>
    </div>
  );
};

export default TemplateFilter;
