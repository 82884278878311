import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import styles from "./style.module.scss";

export default function ChangeInfoManager({ options, setOptions }: any) {
  const [access, setAccess] = React.useState("Requested");
  const handleChange = (event: SelectChangeEvent) => {
    setAccess(event.target.value as string);
  };

  const toUsers = [
    {
      id: 1,
      value: "Request",
    },

    {
      id: 2,
      value: "Pending",
    },
    {
      id: 3,
      value: "Success",
    },
  ];

  return (
    <Box>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value="1"
          IconComponent={KeyboardArrowDownIcon}
          onChange={handleChange}
          style={{
            width: "260px",
            borderRadius: "8px",
            border: `1px solid #D0D5DD`,
            height: "40px",
          }}
        >
          {toUsers.map((item, index) => (
            <MenuItem value={item?.id} key={index}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
