import React from "react";
import { Box } from "@mui/material";
import { ErrorMessage } from "../NodeHelpers";
import { renderInputTypes } from "../types";

const RenderInput: React.FC<renderInputTypes> = ({
  register,
  name,
  styles,
  errors,
  placeholder,
}) => {
  return (
    <Box width={"31.3%"}>
      <p style={{fontSize:"14px"}}>Name</p>
      <div className={styles.input}>
        <input
          placeholder={placeholder ?? "New Dispute Flow "}
          {...register(name, {
            required: {
              value: true,
              message: "This is required",
            },
          })}
        />
      </div>
      <ErrorMessage name={name} errors={errors} />
    </Box>
  );
};
export default RenderInput;
