import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
import { configs } from '../../../configs/index';
export class PostDisputeDetailsSendMessage {
    /**
     * Function to invoke to post dispute-flow upload file
     */
    async invoke(payload: any) {

        const fileUpload = true;
        const res = await ApiService.getInstance(fileUpload).post(apiConstants.POST_SHOP_SEND_MESSAGE(payload), payload?.file, { baseURL: configs.BASE_URL as string });

        return res;
    }
}