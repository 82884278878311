//for tiptap Editor data
const ShippingTerms = () => {
  const renderedValue = `<h2>Shipping Terms</h2><section><p>The shipping policy snapshot is provided below and is available in full at our website.</p>
  <b>{(Company_ShippingPolicyUrl)}</b>
  <p><b>{(img_private_Company_ShippingPolicy_Screenshot)}<b></p></section>
  `;
  return renderedValue;
};

export default ShippingTerms;
