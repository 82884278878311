import Modal from "components/ui/Modal";
type LinkModalType = {
  url: string;
  isOpen: any;
  title: string;
  onRequestClose: any;
  contentLabel: string;
  closeModal: any;
  setIsModalOpen: any;
  onChangeUrl: any;
  onSaveLink: any;
  onRemoveLink: any;
};

const LinkModal = ({
  isOpen,
  setIsModalOpen,
  closeModal,
  url,
  onChangeUrl,
  onRemoveLink,
  onSaveLink,
  title,
  contentLabel,
  onRequestClose,
}: LinkModalType) => {
  return (
    <>
      <Modal open={isOpen} setOpen={setIsModalOpen}>
        <div style={{
          margin: "40px 0px",
        }}>
          <p style={{ margin: "5px 0px" }}>Edit Link</p>
          <button
            style={{
              marginRight: "20px",
              marginTop: "20px",
            }}
            className="modal-close"
            type="button"
            onClick={closeModal}
          >
            X
          </button>
          <input
            className="modal-input"
            autoFocus
            value={url}
            onChange={onChangeUrl}
          />
          <div className="modal-buttons">
            <button
              className="button-remove"
              type="button"
              onClick={onRemoveLink}
              style={{
                minWidth: "50px",
              }}
            >
              x
            </button>
            <button
              style={{
                minWidth: "70px",
              }}
              className="button-save"
              type="button"
              onClick={onSaveLink}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LinkModal;
