import { Box, Tooltip } from "@mui/material";
import { FC, useEffect, useState } from "react";
import styles from "./style.module.scss";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { Calendar } from "elements";
import advanceFormat from "dayjs/plugin/advancedFormat";
import { Button } from "components/ui";
import Loading from "components/loading/loading";
import { useGetTracking, useShippingDetails } from "hooks";
import { useSelector } from "react-redux";
dayjs.extend(advanceFormat);

type CurrentShippingType = {
  estimatedDeliveryDates: string[];
  shippingStateDTOList: [];
  trackingState: string;
  trackingStateLastUpdatedAt: string;
};

const ShippingJourney: FC<any> = () => {
  const { data, isSuccess, isLoading } = useShippingDetails();
  const { data: trackingDetailsData } = useSelector(
    (state: any) => state?.disputeDetailsStore?.manageDisputeFulfillmentDetails
  );
  const { handleGetTracking } = useGetTracking();
  const [currentShipping, setCurrentShipping] = useState<CurrentShippingType>({
    estimatedDeliveryDates: [],
    shippingStateDTOList: [],
    trackingState: "",
    trackingStateLastUpdatedAt: "",
  });
  useEffect(() => {
    if (!isEmpty(data) && isSuccess) {
      setCurrentShipping({
        estimatedDeliveryDates: data?.estimatedDeliveryDates,
        shippingStateDTOList: data?.shippingStateDTOList,
        trackingState: data?.trackingState,
        trackingStateLastUpdatedAt: data?.trackingStateLastUpdatedAt,
      });
    }
  }, [data, isSuccess]);
  return (
    <div className={styles.shippingWrapper}>
      <div className={styles.manageHead}>
        <h5>Shipping Journey</h5>
        <div style={{ display: "flex", alignItems: "center" }}>
          {currentShipping?.trackingState && (
            <Tooltip title="Tracking State" arrow placement="top">
              <span className={styles.showingTheTrackingState}>
                {currentShipping?.trackingState}
              </span>
            </Tooltip>
          )}
          {currentShipping?.trackingStateLastUpdatedAt && (
            <Tooltip
              title="Tracking State Last Updated At"
              arrow
              placement="top"
            >
              <span className={styles.showingTheTrackingState}>
                {dayjs(currentShipping.trackingStateLastUpdatedAt).format(
                  "MMMM D, YYYY"
                )}
              </span>
            </Tooltip>
          )}
          <Button
            {...{
              size: "medium",
              btnLabel: "Get Tracking",
              btnTitle: "Get Tracking",
              // className: styles.buttonStyle,
              onClick: () => handleGetTracking(),
              isDisable: (trackingDetailsData && trackingDetailsData[0]?.trackingNumber) ? false : true,
            }}
          />
        </div>
      </div>
      <div className={styles.shippingPreview}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {currentShipping.shippingStateDTOList.length > 0 ? (
              <>
                {currentShipping.estimatedDeliveryDates.length > 0 && (
                  <Box
                    display={`flex`}
                    justifyContent={`flex-start`}
                    alignItems={`center`}
                    gap={3}
                    bgcolor={"#f7faff"}
                    padding={2}
                  >
                    <Calendar />
                    <Box>
                      <p className={styles.estimateDate}>
                        Estimated date of arrival
                      </p>
                      <p className={styles.estimateDate}>
                        {` ${
                          currentShipping?.estimatedDeliveryDates.length &&
                          dayjs(
                            currentShipping.estimatedDeliveryDates[0]
                          ).format("Do MMMM YYYY")
                        } `}
                        -
                        {` ${
                          currentShipping?.estimatedDeliveryDates.length &&
                          dayjs(
                            currentShipping.estimatedDeliveryDates[1]
                          ).format("Do MMMM YYYY")
                        }`}
                      </p>
                    </Box>
                  </Box>
                )}

                <Box className={styles.responseTable}>
                  <table>
                    <tbody>
                      {currentShipping.shippingStateDTOList.length > 0
                        ? currentShipping.shippingStateDTOList.map(
                            (dispute: any) => (
                              <tr key={dispute.id}>
                                <td>
                                  <p
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      margin: 0,
                                      textAlign: "right",
                                    }}
                                  >{`${
                                    dispute?.date
                                      ? dayjs(
                                          dispute?.date.split("T")[0]
                                        ).format("DD MMMM YY")
                                      : ""
                                  }`}</p>
                                  <p
                                    style={{
                                      fontWeight: 300,
                                      fontSize: "14px",
                                      margin: 0,
                                      textAlign: "right",
                                      color: "#8391A2",
                                    }}
                                  >{`${
                                    dispute?.date
                                      ? dayjs(dispute?.date).format("HH:mm")
                                      : ""
                                  }`}</p>
                                </td>
                                <td
                                  className={styles.dotStyle}
                                  style={{ maxWidth: "20" }}
                                >
                                  <p
                                    style={{
                                      height: 11,
                                      borderRadius: "50%",
                                      width: 11,
                                      background: "#D5E4FF",
                                    }}
                                  ></p>
                                </td>
                                <td
                                  style={{
                                    maxWidth: "100%",
                                    textAlign: "left",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      margin: 0,
                                    }}
                                  >{`${dispute.status}`}</p>
                                  <p
                                    style={{
                                      fontWeight: 300,
                                      fontSize: "14px",
                                      margin: 0,
                                      color: "#8391A2",
                                    }}
                                  >
                                    {dispute.location}
                                  </p>
                                </td>
                                {dispute?.carrier && (
                                  <td>
                                    <Tooltip
                                      title="Carrier"
                                      arrow
                                      placement="top"
                                    >
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          background: "#efefef",
                                          padding: 5,
                                          borderRadius: 4,
                                        }}
                                      >
                                        {dispute?.carrier}
                                      </span>
                                    </Tooltip>
                                  </td>
                                )}
                              </tr>
                            )
                          )
                        : null}
                    </tbody>
                  </table>
                </Box>
              </>
            ) : (
              <Box
                display="flex"
                justifyContent={`center`}
                alignItems={`center`}
                padding={`40px 0px`}
                color={`#98A2B3`}
              >
                <p>No shipping Journey</p>
              </Box>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ShippingJourney;
