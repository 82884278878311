import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";


export class GetTotalRevenueProtected {
    /**
     * Function to invoke to get total revenue protected
     */
    async invoke() {

        const res = await ApiService.getInstance().get(apiConstants.DASHBOARD_TOTAL_REVENUE_PROTECTED)

        return res;
    }

    async invokeByAccount(payload: string) {

        const res = await ApiService.getInstance().get(apiConstants.DASHBOARD_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT(payload))

        return res;
    }

}