import { useEffect } from "react";
import { getManageDisputeFlowRequest } from "redux/actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const useFetchDisputeFlow = (_data: any) => {
  const dispatch = useDispatch();
  const { isLoading, isError, isSuccess, data } = useSelector(
    (state: any) => state?.disputeDetailsStore?.manageDisputeFetchFlow
  );

  useEffect(() => {
    if (_data?.paymentProcessor) {
      dispatch(getManageDisputeFlowRequest(_data));
    }
  }, []);

  return {
    isFlowLoading: isLoading,
    isFlowError: isError,
    isFlowSuccess: isSuccess,
    fetchedFlow: data,
  };
};
export default useFetchDisputeFlow;
