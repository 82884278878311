import { FC } from "react";
import styles from "./style.module.scss";
import FormatCurrency from "components/formatCurrency";
import { BlueEye } from "elements";
import Loading from "components/loading/loading";

interface Props {
  disputeWidgetData: {
    title: string;
    blueEye?: boolean;
    totalDisputes?: number;
    icon?: any;
    disputedAmount?: number;
    loading?: boolean;
  };
  mockComponent?: boolean;
}

const DisputesWidget: FC<Props> = ({ disputeWidgetData, mockComponent }) => {
  return (
    <div
      className={styles.Disputes}
      style={{ padding: mockComponent ? "15px 18px" : "" }}
    >
      <div className={styles.DisputesSub}>
        <div className={styles.Disputesheader}>
          <p className={styles.Disputesheading}>{disputeWidgetData?.title}</p>
          {disputeWidgetData?.blueEye && <BlueEye />}
        </div>

        <div
          className={styles.DisputesFooter}
          style={{
            margin: mockComponent ? "5px" : "",
            alignItems: mockComponent ? "center" : "",
          }}
        >
          <div className={styles.DisputesLeft}>
            {disputeWidgetData.loading ? (
              <Loading />
            ) : (
              <p style={{ fontSize: mockComponent ? "" : "" }}>
                {disputeWidgetData?.totalDisputes ? (
                  Math.trunc(disputeWidgetData?.totalDisputes)
                ) : disputeWidgetData?.disputedAmount ? (
                  <FormatCurrency
                    value={Number(disputeWidgetData?.disputedAmount)?.toFixed(
                      2
                    )}
                  />
                ) : (
                  "0"
                )}
              </p>
            )}
          </div>
          {disputeWidgetData?.icon && (
            <div
              className={styles.graph}
              style={{ marginLeft: mockComponent ? "5px" : "" }}
            >
              {disputeWidgetData.icon}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default DisputesWidget;
