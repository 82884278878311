import React, { ComponentType, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { isEmpty } from 'lodash';

interface Props {
  component?: ComponentType;
}

const ProtectedRoute = ({ component, ...args }: Props) => {
  // @ts-ignore
  // const { loginWithRedirect, isAuthenticated } = useAuth0();
  // const { user = {}, logout } = useAuth0();

  // const handleLogout = () => {
  //   logout({
  //     returnTo: window.location.origin,
  //   });
  // };
  // const token = localStorage.getItem("accessToken");
  // console.log({isAuthenticated , token})
  // useEffect(() => {
  //   setTimeout(() => {
  //   if (isEmpty(token) && !isAuthenticated) {
  //         logout({
  //           returnTo: window.location.origin,
  //         });
  //       }
  //     }, 3000)
  // }, [token, isAuthenticated]);
  const Component = withAuthenticationRequired(component as any, args);
  return <Component />;
};

export default ProtectedRoute;
