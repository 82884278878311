import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
export class GetDisputeCustomerDetails {
    /**
     * Function to invoke to get dispute transaction details
     */
    async invoke(payload: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_CUSTOMER(payload));
        return res;
    }
}