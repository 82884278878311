import ActionOrCondition from "./ActionOrCondition";
import FirstSideLayer from "./FirstSideLayer";
import { useFlowSidebar } from "hooks";
import { sideBarData } from "./helper.sidebar";
import { FlowSideBarProps } from "../types";

const FlowSideBar: React.FC<FlowSideBarProps> = ({
  currentNode,
  openSideBar,
  setCurrentNode,
  setOpenSideBar,
}) => {
  const { currentSidebarData, handleSidebarClick } = useFlowSidebar(sideBarData);
  return (
    <>
      {currentNode.type && openSideBar?.name ? (
        <ActionOrCondition
          currentNode={currentNode}
          setCurrentNode={setCurrentNode}
          openSideBar={openSideBar}
          setOpenSideBar={setOpenSideBar}
        />
      ) : (
        <FirstSideLayer
          currentNode={currentNode}
          setOpenSideBar={setOpenSideBar}
          currentSidebarData={currentSidebarData}
          handleSidebarClick={handleSidebarClick}
        />
      )}
    </>
  );
};
export default FlowSideBar;
