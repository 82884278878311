import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  postDisputeTemplateRequest,
  getDisputeTemplateReasonListRequest,
  getDisputeTemplateViewRequest,
  postShopFilterShopsRequest,
} from "redux/actions";
import {
  handleTemplateEditData,
  handleTemplateEditStore,
} from "utils/disputeTemplates";

const useDisputeTemplate = () => {
  interface selectDropDownOption {
    value: string | any;
    label: string | any;
  }
  const paymentGatewayOptions = [
    { value: "PayPal", label: "PayPal" },
    { value: "BrainTree", label: "BrainTree" },
    { value: "Stripe", label: "Stripe" },
    { value: "ShopifyPayments", label: "Shopify Payments" },
    { value: "Klarna", label: "Klarna" },
  ];
  const navigate = useNavigate();
  const { templateId } = useParams(); //for editing template
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const [editorData, setEditorData] = useState<any>({});
  const [submitted, setSubmitted] = useState();
  const [submitCheck, setSubmitCheck] = useState<boolean>(false); //to check form validation
  const [storesOptions, setStoresOptions] = useState<selectDropDownOption[]>();
  const [shopAllShopsCall, setShopAllShopsCall] = useState<boolean>(false); //to check stores option set after API call

  const [paymentGatewaySelected, setPaymentGatewaySelected] =
    useState<selectDropDownOption>();

  const [reasons, setReasons] = useState<selectDropDownOption[]>();
  const [editorContent, setEditorContent] = useState("");
  const [editorHtmlContent, setEditorHtmlContent] = useState();
  const [templateEditCheck, setTemplateEditCheck] = useState<boolean>(false); //to check editor open to edit the DB template
  const initialDefault: any = {
    id: "",
    name: "",
    store: "",
    psp: "",
    reason: "",
    summaryText: "",
  };
  const { isLoading, isSuccess, isError, data } = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplate
  );
  const disputeTemplateReasonList = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplateReasonList
  );
  const shopAllShops = useSelector(
    (state: any) => state.storesShop.shopAllShops
  );

  //to-get-template-data
  const disputeTemplateView = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplateView
  );
  const [defaultValues, setdefaultValues] = useState(initialDefault);
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);
  useEffect(() => {
    if (templateId && disputeTemplateView?.isSuccess) {
      //psp
      const pspTemplate = handleTemplateEditData(
        disputeTemplateView?.data?.paymentGateway,
        paymentGatewayOptions
      );
      //stores
      const storeTemplate = handleTemplateEditStore(
        disputeTemplateView?.data?.shop?.id,
        storesOptions
      );
      //reason
      const reasonTemplate = handleTemplateEditData(
        disputeTemplateView?.data?.reason,
        reasons
      );
      setEditorData(disputeTemplateView?.data?.htmlContent);
      setdefaultValues((prev: any) => ({
        ...prev,
        id: templateId,
        name: disputeTemplateView.data.name,
        psp: pspTemplate,
        store: storeTemplate,
        reason: reasonTemplate,
        summaryText: disputeTemplateView.data.summaryText,
      }));
    }
    if (templateId && disputeTemplateView?.isError) {
      Swal.fire({
        icon: "warning",
        title: "Something went wrong",
        text: "Please try again!",
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/templates");
        }
      });
    }
  }, [
    templateId,
    disputeTemplateView.data,
    shopAllShops?.data?.shopResponseList,
    disputeTemplateView?.isSuccess,
    storesOptions,
    reasons,
    disputeTemplateReasonList.data,
  ]);
  //send the template data which need to be edited
  useEffect(() => {
    if (
      disputeTemplateReasonList?.isSuccess &&
      disputeTemplateReasonList?.data?.length > 0
    ) {
      let reasonOption: any = [];
      disputeTemplateReasonList?.data?.forEach((reason: any) => {
        let reasonObj = {
          value: reason?.disputeReasonValue,
          label: reason?.description,
        };
        reasonOption.push(reasonObj);
      });
      setReasons(reasonOption);
    }

    //to populate the stores options
    if (
      shopAllShopsCall &&
      shopAllShops?.isSuccess &&
      shopAllShops?.data?.count > 0
    ) {
      let storeOption: any = [];
      shopAllShops?.data?.shopResponseList?.forEach((store: any) => {
        let storeObj = {
          value: store?.id,
          label: store?.name,
        };
        storeOption.push(storeObj);
      });
      setStoresOptions(storeOption);
    }
  }, [disputeTemplateReasonList, shopAllShops]);

  const updatedEditorContent = editorContent
    .replace(/<br>/g, "<br/>")
    .replace(/<col>/g, "<col/>")
    .replace(/<strong>/g, "<b>")
    .replace(/<\/strong>/g, "</b>");
  //   const updateNewEditorContent = updatedEditorContent.replace(
  //     /(<customhorizontal)(.*?)(>)/g,
  //     '$1 style="page-break-before:always;"$2$3'
  // );

  const onSubmit = (data: any, e: any) => {
    setSubmitted(data);
    setSubmitCheck(true);
    const payload = {
      id: templateId ?? null,
      name: data.name ?? null,
      content: null,
      description: null,
      enabled: null,
      category: null,
      paymentGateway: data.psp?.value ?? null,
      reason: data.reason?.value ? data.reason?.value : null,
      htmlContent: updatedEditorContent ? updatedEditorContent : editorHtmlContent ,

      shop: data.store?.value === "all" ? null : { id: data.store?.value },
      summaryText: data.summaryText ?? null,
    };
    setSubmitCheck(false);
    dispatch(postDisputeTemplateRequest(payload));
  };
  useEffect(() => {
    if (submitted && isSuccess && data?.id) {
      toast.success(
        `Template ${templateId ? "updated" : "created"} successfully`,
        {
          theme: "colored",
        }
      );
      setTimeout(() => {
        navigate("/templates");
      }, 3000);
    } else if (submitted && (isError || (isSuccess && !data?.id))) {
      toast.error("Oops! something went wrong", {
        theme: "colored",
      });
    }
  }, [submitted, isSuccess, isError, data]);

  useEffect(() => {
    dispatch(getDisputeTemplateReasonListRequest());
    const shopPayload = {
      chargeActive: true,
      searchString: null,
    };
    dispatch(postShopFilterShopsRequest(shopPayload));
    setShopAllShopsCall(true);

    if (templateId) {
      dispatch(getDisputeTemplateViewRequest(templateId));
    }
  }, [dispatch, templateId]);

  const getStoreSelected = () => {
    const getSelected = storesOptions?.find((item) => item.value == storeId);
    return getSelected;
  };
  const mapedForm = [
    {
      label: "Name",
      name: "name",
      rules: {
        required: {
          value: true,
          message: "This is required",
        },
      },
      type: "text",
    },
    {
      label: "Store",
      name: "store",
      rules: {
        required: {
          value: true,
          message: "This is required",
        },
      },
      options: storesOptions,
    },
    {
      label: "Psp",
      name: "psp",
      rules: {
        required: {
          value: true,
          message: "This is required",
        },
      },
      options: paymentGatewayOptions,
    },

    {
      label: "Reason",
      name: "reason",
      rules: {
        required: {
          value: true,
          message: "This is required",
        },
      },
      options: reasons,
    },
    {
      label: "Summary",
      name: "summaryText",
      type: "textArea",
    },
  ];

  return {
    paymentGatewayOptions,
    editorData,
    templateId,
    storeId,
    submitCheck,
    control,
    errors,
    storesOptions,
    shopAllShopsCall,
    editorContent,
    reasons,
    templateEditCheck,
    paymentGatewaySelected,
    editorHtmlContent,
    isLoading,
    disputeTemplateView,
    mapedForm,
    clearErrors,
    setValue,
    handleSubmit,
    getStoreSelected,
    register,
    setPaymentGatewaySelected,
    setReasons,
    setEditorContent,
    setEditorHtmlContent,
    setTemplateEditCheck,
    onSubmit,
  };
};
export default useDisputeTemplate;
