import styles from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import { gettingSummaryAndSupply } from "utils/disputeTemplates";
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";

const Toolbox = ({ data, onDrag, index }: any) => {
  const [open, setOpen] = useState(false);
  const anchorRef: any = useRef<HTMLButtonElement>(null);
  const handlePopOver = (e: any, index: any) => {
    e.stopPropagation();
    if (index === 5 || index === 4) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);
  return (
    <div
      ref={anchorRef}
      draggable={[0, 1, 2, 4, 5].includes(index) ? false : true}
      onDragStart={(event) =>
        [0, 1, 2, 4, 5].includes(index) ? null : onDrag(event, data.id, index)
      }
      onClick={(e) => handlePopOver(e as any, index)}
    >
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="top"
        transition
        style={{ zIndex: 100 }}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <Box
                    padding={"10px"}
                    maxHeight={200}
                    overflow={"hidden auto"}
                    maxWidth={"240px"}
                  >
                    <p
                      style={{ fontWeight: 600 }}
                      className={styles.titleStyle}
                      draggable="false"
                    >
                      {index === 4
                        ? "Dispute Summary"
                        : "Supplementary Evidence"}
                    </p>
                    {gettingSummaryAndSupply.map((item) => (
                      <Tooltip
                        title="drag to left canvas"
                        arrow
                        placement="left"
                      >
                        <p
                          className={styles.menuTitleStyle}
                          draggable="true"
                          onDragStart={(event) => onDrag(event, item.id, index)}
                        >
                          {item.title}
                        </p>
                      </Tooltip>
                    ))}
                  </Box>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Tooltip
        title={[0, 1, 2].includes(index) ? "Not draggable" : [4, 5].includes(index) ? "Click to open" : "drag to left canvas"}
        placement={[4, 5].includes(index) ? "top" : "left" }
        arrow
      >
        <div
          className={styles.toolbox}
          style={{
            userSelect: "none",
            cursor: [0, 1, 2].includes(index)
              ? "not-allowed"
              : [4, 5].includes(index)
              ? "pointer"
              : "grab",
          }}
        >
          <img draggable={false} className={styles.toolIcon} src={data.icon} alt={data.title} />
          <p className={styles.toolTitle}>{data.title}</p>
        </div>
      </Tooltip>
    </div>
  );
};
export default Toolbox;
