import { sideBarInterface } from "../types";
import {
  DisputeReasonSvg,
  DisputeChannelSvg,
  DisputeAmountSvg,
  DisputeLifeCircleStageSvg,
  ShippingStatusSvg,
  DisputeStatusSvg,
  SellerResponseDueDateSvg,
  UserWorkflowSvg,
  DisputeResponseSvg,
  SendEmailSvg,
  DisputeResponseStatSvg,
  MerchantFollowUpSVG,
  PaypalFlow,
  ParcelAppSVG,
} from "./flowIcons";

export const onConditionIssues = {
  reason: {
    MERCHANDISE_OR_SERVICE_NOT_RECEIVED:
      " The customer did not receive the merchandise or service.",
    MERCHANDISE_OR_SERVICE_NOT_AS_DESCRIBED:
      " The customer reports that the merchandise or service is not as described.",
    UNAUTHORISED:
      "   The customer did not authorize purchase of the merchandise or service.",
    CREDIT_NOT_PROCESSED:
      "   The refund or credit was not processed for the customer.",
    DUPLICATE_TRANSACTION: "   The transaction was a duplicate.",
    INCORRECT_AMOUNT: "    The customer was charged an incorrect amount.",
    PAYMENT_BY_OTHER_MEANS:
      "The customer paid for the transaction through other means.",
    CANCELED_RECURRING_BILLING:
      "The customer was being charged for a subscription or a recurring transaction that was canceled.",
    PROBLEM_WITH_REMITTANCE: " A problem occurred with the remittance.",
    OTHER: "Other",
  },
  status: {
    OPEN: "The dispute is open.",
    WAITING_FOR_BUYER_RESPONSE:
      "The dispute is waiting for a response from the customer.",
    WAITING_FOR_SELLER_RESPONSE:
      "The dispute is waiting for a response from the merchant.",
    UNDER_REVIEW: " The dispute is under review with PayPal.",
    RESOLVED: "The dispute is resolved.",
    OTHER:
      "The default status if the dispute does not have one of the other statuses.",
  },
  dispute_life_cycle_stage: {
    INQUIRY:
      "A customer and merchant interact in an attempt to resolve a dispute without escalation to PayPal. Occurs when the customer:  Has not received goods or a service.Reports that the received goods or service are not as described.Needs more details, such as a copy of the transaction or a receipt.",
    CHARGEBACK:
      "  A customer or merchant escalates an inquiry to a claim, which authorizes PayPal to investigate the case and make a determination. Occurs only when the dispute channel is INTERNAL. This stage is a PayPal dispute lifecycle stage and not a credit card or debit card chargeback. All notes that the customer sends in this stage are visible to PayPal agents only. The customer must wait for PayPal's response before the customer can take further action. In this stage, PayPal shares dispute details with the merchant, who can complete one of these actions:Accept the claim.Submit evidence to challenge the claim.Make an offer to the customer to resolve the claim.",
    PRE_ARBITRATION:
      "The first appeal stage for merchants. A merchant can appeal a chargeback if PayPal's decision is not in the merchant's favor. If the merchant does not appeal within the appeal period, PayPal considers the case resolved.",
    ARBITRATION:
      "The second appeal stage for merchants. A merchant can appeal a dispute for a second time if the first appeal was denied. If the merchant does not appeal within the appeal period, the case returns to a resolved status in pre-arbitration stage.",
  },
  dispute_channel: {
    INTERNAL:
      "The customer contacts PayPal to file a dispute with the merchant.",
    EXTERNAL:
      "The customer contacts their card issuer or bank to request a refund.",
    ALERT:
      "Pre-chargeback alert when customer contacts their card issuer to request a refund.",
  },
  Shipping_status: {
    ARCHIVE: "ARCHIVE",
    DELIVERED: "DELIVERED",
    TRANSIT: "TRANSIT",
    ARRIVED: "ARRIVED",
    PICKUP: "PICKUP",
  },
  DisputeResponse: {
    CREATED: "created",
    REQUIRED_REVIEW: "require review",
    IN_QUEUE: "in queue",
  },
  seller_response_due_date: {
    LESS: "< (Less than)",
    GREATER_THAN: "> (Greater than)",
    EQUALS_TO: "= (Equals to)",
  },
  Disputed_Amount: {
    LESS: "< (Less than)",
    GREATER_THAN: "> (Greater than)",
    EQUALS_TO: "= Equals to with currency selection",
  },
  merchant_follow_up: {
    NOT_REQUIRED: "Not Required",
    AWAITING_RESPONSE: "Awaiting Response",
    ALL_DATA_PROVIDED: "All Data Provided",
    DID_NOT_REPLY: "Did Not Reply",
    COULD_NOT_NOT_PROVIDE_DATA: "Could Not Provide Data",
    MISSING_SOME_DATA: "Missing Some Data",
  },
  dispute_status: {
    MISSING_INFO: "MISSING_INFO",
    CREATED: "CREATED",
    WAITING_REVIEW: "WAITING_REVIEW",
    IN_REVIEW: "IN_REVIEW",
    APPROVED: "APPROVED",
    QUEUED: "QUEUED",
    SUBMITTED: "SUBMITTED",
    REJECTED: "REJECTED",
  },
  paypalActions:{
    "appeal":"appeal",
    "provide-supporting-info":"provide-supporting-info",
    "provide-evidence":"provide-evidence",
    "escalate":"escalate",
    "send-message":"send-message",
    "acknowledge-return-item":"acknowledge-return-item",
    "make-offer":"make-offer",
    "accept-claim":"accept-claim"
  },
  parcelsAppStatus:{
    "archive":"archive",
    "delivered":"delivered",
    "transit":"transit",
    "arrived":"arrived",
    "pickup":"pickup",
  }
};

export const selectType = {
  PLAINSELECT: "plainSelect",
  DROPSELECT: "dropSelect",
};

export const sideBarData: sideBarInterface[] = [
  {
    name: "Dispute Reason",
    icon: <DisputeReasonSvg />,
    id: 1,
    options: {},
    type: selectType.PLAINSELECT,
    date: false,
  },
  {
    name: "Dispute Response",
    icon: <DisputeResponseSvg />,
    options: {},
    date: false,
    type: selectType.PLAINSELECT,
    id: 9,
  },
  {
    name: "Dispute Channel",
    icon: <DisputeChannelSvg />,
    options: onConditionIssues.dispute_channel,
    type: selectType.PLAINSELECT,
    date: false,
    id: 2,
  },
  {
    name: "Dispute Amount",
    icon: <DisputeAmountSvg />,
    type: selectType.DROPSELECT,
    options: onConditionIssues.Disputed_Amount,
    date: false,
    id: 3,
  },
  {
    name: "Dispute Life Cycle Stage",
    icon: <DisputeLifeCircleStageSvg />,
    options: {},
    type: selectType.PLAINSELECT,
    date: false,
    id: 4,
  },
  {
    name: "Shipping Status",
    icon: <ShippingStatusSvg />,
    type: selectType.PLAINSELECT,
    options: onConditionIssues.Shipping_status,
    date: false,
    id: 5,
  },
  {
    name: "Dispute Status",
    icon: <DisputeStatusSvg />,
    type: selectType.PLAINSELECT,
    options: onConditionIssues.status,
    date: false,
    id: 6,
  },
  {
    name: "Seller Response Due Date",
    icon: <SellerResponseDueDateSvg />,
    options: onConditionIssues.seller_response_due_date,
    type: selectType.DROPSELECT,
    date: true,
    id: 7,
  },
  {
    name: "Paypal Actions",
    icon: <PaypalFlow />,
    options: onConditionIssues.paypalActions, 
    type: selectType.PLAINSELECT,
    date: false,
    id: 8,
  },
  {
    name: "ParcelsApp Status",
    icon: <ParcelAppSVG />,
    options: onConditionIssues.parcelsAppStatus, 
    type: selectType.PLAINSELECT,
    date: false,
    id: 8,
  },

  {
    name: "Dispute Response Status",
    date: false,
    icon: <DisputeResponseStatSvg />,
    type: selectType.PLAINSELECT,
    options: onConditionIssues.dispute_status,
    id: 10,
  },
  {
    name: "Merchant Follow Up Outcome",
    icon: <MerchantFollowUpSVG />,
    type: selectType.PLAINSELECT,
    date: false,
    options: onConditionIssues.merchant_follow_up,
    id: 12,
  },
  {
    name: "User Workflow",
    icon: <UserWorkflowSvg />,
    options: {},
    type: selectType.PLAINSELECT,
    date: false,
    id: 8,
  },
  {
    name: "Send Email",
    date: false,
    type: selectType.PLAINSELECT,
    icon: <SendEmailSvg />,
    options: {},
    id: 11,
  },
];

export const sidebarFormForCondition = [
  {
    label: "Dispute amount",
    name: "dispute_amount",
    rules: {
      required: {
        value: true,
        message: "This is required",
      },
    },
    placeholder: "select",
    type: "select",
  },
  {
    label: "Name",
    name: "name",
    rules: {
      required: {
        value: true,
        message: "This is required",
      },
    },
    type: "text",
  },
];
