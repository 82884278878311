import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getDisputeTransactionDetailsRequest } from "redux/actions";

const useTransactionDetails = () => {
  const dispatch = useDispatch();
  const { disputeId } = useParams();

  const { isSuccess, data, isLoading } = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeTransactionDetails
  );
  useEffect(() => {
    if (disputeId) {
      const payload = disputeId;
      dispatch(getDisputeTransactionDetailsRequest(payload));
    }
  }, [disputeId]);
  return { isSuccess, data, isLoading };
};
export default useTransactionDetails;


