import { useState } from "react";
import { Button } from "@mui/material";
import { Loading, Pagination, Search, UsersTable } from "components";
import styles from "./style.module.scss";
import { mockUsersData } from "./mockUsersData";
import { BreadcumbItem } from "components/ui";
import { AddUserIcon } from "components/disputeFlow/NodeHelpers";
import { useBreadCumbs, useGetUser } from "hooks";

const data: any = mockUsersData;

const Users = () => {
  const [page, setPage] = useState(1);
  const tableList = 7;
  const { manageUsers } = useGetUser();

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };
  const { breadCumbs } = useBreadCumbs({ pageName: "Users" });
  return (
    <div className={styles.disputesWrapper}>
      <div className={styles.nav}>
        <h3>Users</h3>
        <div className={styles.navPath}>
          {breadCumbs.map((item, key) => (
            <BreadcumbItem {...item} {...{ key }} />
          ))}
        </div>
      </div>
      <div className={styles.note}>
        <p>Users that can manage the disputes.</p>
      </div>

      <div className={styles.usersSearch}>
        <div>
          <Search
            onSearch={function (query: string): void {
            }}
            onType={function (query: string): void {
            }}
            type={""}
            placeholder={"Search by name..."}
            className={styles.searchInput}
          />
        </div>

        <Button variant="outlined">
          <AddUserIcon />
          <p>Add User</p>
        </Button>
      </div>

      <div>
        {manageUsers.isLoading ? (
          <div style={{ background: "#fff" }}>
            <Loading />
          </div>
        ) : (
          <UsersTable
            {...{
              data: manageUsers?.data,
              isLoading: manageUsers.isLoading,
              isSuccess: manageUsers.isSuccess,
              isError: manageUsers.isError,
            }}
          />
        )}
      </div>
      {Math.ceil(data?.count / tableList) > 1 && (
        <div>
          <Pagination
            onPageChange={(pageNum) => onPageChange(pageNum)}
            page={page}
            totalPages={Math.ceil(data?.count / tableList)}
          />
        </div>
      )}
    </div>
  );
};

export default Users;
