import Filters from "./svg/filters.svg";
import Calender from "./svg/calender.svg";
import Right from "./svg/right.svg";
import Refresh from "./svg/refresh.svg";
import Search from "./svg/search.svg";
import Arrows from "./svg/arrows.svg";
import Check from "./svg/check.svg";
import Err from "./svg/error.svg";
import Prev from "./svg/prev.svg";
import Next from "./svg/next.svg";
import FilterDown from "./svg/filterDropdown.svg";
import Delete from "./svg/trash.svg";
import Completed from "./svg/completed.svg";
import Progress from "./svg/progress.svg";
import Failed from "./svg/failed.svg";
import Download from "./svg/download.svg";
import NoDispute from "./svg/noDispute.svg";
import Info from "./svg/info.svg";
import Lachiepor from "./svg/lachiepor.svg";
import LachieporTwo from "./svg/lachieporTwo.svg";
import Majanogout from "./svg/majanogout.svg";
import SelfService from "./svg/selfService.svg";
import StoreOne from "./svg/unltdStoresOne.svg";
import Wallet from "./svg/ultdAccOne.svg";
import SelfPlatform from "./svg/selfPlatform.svg";
import Priority from "./svg/priorityOne.svg";
import Digital from "./svg/digitalOne.svg";
import Courier from "./svg/courier.svg";
import Subscription from "./svg/subscription.svg";
import OfferOne from "./svg/offerOne.svg";
import OfferTwo from "./svg/offerTwo.svg";
import StoreTwo from "./svg/unltdStoresTwo.svg";
import Dedicated from "./svg/dedicated.svg";
import DigitalTwo from "./svg/digitalTwo.svg";
import SubscriptionTwo from "./svg/subscriptionTwo.svg";
import FullyManaged from "./svg/fullyManaged.svg";
import Costco from "./svg/costco.svg";
import Ecommerce from "./svg/ecommerce.svg";
import View from "./svg/view.svg";
import Edit from "./svg/edit.svg";
import Add from "./svg/add.svg";
import CircleCheck from "./svg/circle.svg";
import NotificationActive from "./svg/notificationActive.svg";
import NotificationInactive from "./svg/notificationInactive.svg";
import BackInTime from "./svg/backInTime.svg";
import Card from "./svg/card.svg";
import DashBoard from "./svg/dashboard.svg";
import Disputes from "./svg/disputes.svg";
import Message from "./svg/message-circle.svg";
import Messages from "./svg/messages.svg";
import Down from "./svg/down.svg";
import TPConnect from "./svg/tpConnect.svg";
import Upload from "./svg/upload.svg";
import Upload_In_Blue from "./svg/upload-in-blue.svg";
import Document from "./svg/document.svg";
import Plus from "./svg/plus.svg";
import ArrowLeft from "./svg/arrowLeft.svg";
import ArrowRight from "./svg/arrow-right.svg";
import Amazon from "./svg/amazon.svg";
import Google from "./svg/google.svg";
import Netflix from "./svg/netflix.svg";
import Skype from "./svg/skype.svg";
import Update from "./svg/update.svg";
import PaypalDiv from "./svg/paypalDiv.svg";
import Paypal from "./svg/paypal.svg";
import PaypalBig from "./svg/paypalBig.svg";
import PayPalFill from "./svg/paypalfill.svg";
import PayPal_Icon_Text from "./svg/paypal_Icon_Text.svg";
import PayPal_Linked_Account from "./svg/payPalLinkedAccount.svg";
import Stripe from "./svg/stripe.svg";
import Stripe_Icon_Text from "./svg/Stripe_Icon_Text.svg";
import Close from "./svg/close.svg";
import CheckTwo from "./svg/checkTwo.svg";
import ProgressChart from "./svg/progressChart.svg";
import ArrowUp from "./svg/arrowUp.svg";
import ArrowDown from "./svg/arrowDown.svg";
import Regress from "./svg/regressChart.svg";
import Cart from "./svg/cart.svg";
import Cart_Alt from "./svg/bx_cart-alt.svg";
import SettingsTwo from "./svg/settingsTwo.svg";
import CheckThree from "./svg/checkThree.svg";
import CircleTwo from "./svg/circleTwo.svg";
import Alert_Circle from "./svg/alert-circle.svg";
import Alert_Circle_Black from "./svg/alert_circle_black.svg";
import Alert_Circle_Blue from "./svg/alert-circle-blue.svg";
import Bar_Chart from "./svg/bar_chart.svg";
import YellowFlag from "./svg/yellowFlag.svg";
import Money from "./svg/Money.svg";
import BlueEyeBadge from "./svg/blueEyeBadge.svg";
import Eye from "./svg/eye.svg";
import Eye_Black from "./svg/eye-black.svg";
import Leather from "./svg/leather.svg";
import ArrowUpDown from "./svg/arrowUpDown.svg";
import GreenTick from "./svg/greenTick.svg";
import RedCross from "./svg/redCross.svg";
import RedOverdue from "./svg/redOverdue.svg";
import Star from "./svg/Star.svg";
import Star_Blue from "./svg/starBlue.svg";
import Trash_1 from "./svg/trash-1.svg";
import Trash_2 from "./svg/trash-2.svg";
import CheckFour from "./svg/checkFour.svg";
import CheckFive from "./svg/checkFive.svg";
import CheckSix from "./svg/CheckSix.svg";
import Arrow_Up from "./svg/arrow-up.svg";
import InProgress from "./svg/inProgress.svg";
import Edit_White from "./svg/edit_white.svg";
import Majanogout_Gray from "./svg/majanogout_gray.svg";
import Costco_Gray from "./svg/costco_gray.svg";
import CheckIcon from "./svg/check-icon.svg";
import Bank from "./svg/Bank.svg";
import ChartLineUp from "./svg/ChartLineUp.svg";
import Chart from "./svg/chart.svg";
import Coins from "./svg/Coins.svg";
import MasterCard from "./svg/masterCard.svg";
import UnderReview from "./svg/underReview.svg";
import Result from "./svg/result.svg";
import Link from "./svg/link.svg";
import GettingCoffee from "./svg/GettingCoffee-rafiki.svg";
import Undraw_Winner from "./svg/undraw_winners.svg";
import Undraw_Lost from "./svg/undraw_lost.svg";
import Animation_640_1 from "./svg/animation_640_1.svg";
import Animation_640_3 from "./svg/animation_640_3.svg";
import Animation_640_4 from "./svg/animation_640_4.svg";
import Half_Slive_Polo_Tshirt from "./svg/half-slive-polo-tshirt.svg";
import chevronRight from "./svg/chevronRight.svg";
import Squarespace from "./svg/squarespace.svg";
import Wix from "./svg/wix.svg";
import Ecwid from "./svg/ecwid.svg";
import Prestashop from "./svg/prestashop.svg";
import Package from "./svg/package.svg";
import Scissors from "./svg/scissors.svg";
import Shield_Off from "./svg/shield-off.svg";
import Shuffle from "./svg/shuffle.svg";
import Sidebar from "./svg/sidebar.svg";
import Slash from "./svg/slash.svg";
import Trello from "./svg/trello.svg";
import Voicemail from "./svg/voicemail.svg";
import X_Circle from "./svg/x-circle.svg";
import Git_Branch from "./svg/git-branch.svg";
import Other_Integration_Gear from "./svg/otherIntegrationGear.svg";
import Backward_Step from "./svg/backward-step-solid.svg";
import Bars_Staggered_Solid from "./svg/bars-staggered-solid.svg";
import Cart_Shopping_Solid from "./svg/cart-shopping-solid.svg";
import Comments_Regular from "./svg/comments-regular.svg";
import Credit_Card from "./svg/credit-card-regular.svg";
import Desktop_Solid from "./svg/desktop-solid.svg";
import Envelope from "./svg/envelope-regular.svg";
import File_Lines from "./svg/file-lines-regular.svg";
import Header from "./svg/heading-solid.svg";
import Image from "./svg/image-regular.svg";
import Paragraph from "./svg/paragraph-solid.svg";
import Truck from "./svg/truck-solid.svg";
import Blaze from "./svg/blaze.svg";
import Activity from "./svg/activity.svg";
import Currency_Dollar from "./svg/currency-dollar.svg";
import Layers_three from "./svg/layers-three-01.svg";
import Trophy from "./svg/trophy-01.svg";
import Coffee_Break from "./svg/Coffee-break-rafiki-1.svg";
import ChargePayLogo from "./svg/ChargePayLogo.svg";
import ChargePayName from "./svg/ChargePayName.svg";
import Accounts from "./svg/Accounts.svg";
import Flows from "./svg/Flows.svg";
import Logout from "./svg/log-out-01.svg";
import Settings from "./svg/Settings.svg";
import Stores from "./svg/Stores.svg";
import Users from "./svg/Users.svg";
import Edit_Page from "./svg/edit-page.svg";
import Plus_Circle from "./svg/plus-circle.svg";
import Text from "./svg/type-01.svg";
import Heading from "./svg/heading-01.svg";
import List from "./svg/list.svg";
import Background from "./svg/background.svg";
import Summary from "./svg/file-06.svg";
import Lightning from "./svg/lightning-01.svg";
import File_Search from "./svg/file-search-02.svg";
import Card_Card_Check from "./svg/credit-card-check.svg";
import Users_2 from "./svg/users-02.svg";
import Coins_Hand from "./svg/coins-hand.svg";
import Truck_1 from "./svg/truck-01.svg";
import Coins_Swap from "./svg/coins-swap-01.svg";
import Message_Chat_Circle from "./svg/message-chat-circle.svg";
import Clipboard_Check from "./svg/clipboard-check.svg";
import Mail from "./svg/mail-02.svg";
import Credit_Card_Plus from "./svg/credit-card-plus.svg";
import Monitor from "./svg/monitor-01.svg";
import User_Circle from "./svg/user-circle.svg";
import Notification_Text from "./svg/notification-text.svg";
import Marker_Pin_04 from "./svg/marker-pin-04.svg";
import Marker_Pin from "./svg/marker-pin-01.svg";
import Archive from "./svg/archive.svg";
import File from "./svg/file-02.svg";
import Ticket from "./svg/ticket-01.svg";
import SquareCheck from "./svg/check-square-broken.svg";

import File_Attachment from "./svg/file-attachment-05.svg";
import squareLink from "./svg/squareLink.svg";
import Eye_2 from "./svg/eye-3.svg";
import Play from "./svg/play.svg";
import Trash_3 from "./svg/trash-3.svg";
import BrainTree from "./svg/brainTree.svg";
import Klarna from "./svg/klarna.svg";
import product from "./svg/product.svg";
import Refund from "./svg/refundIcon.svg";
import Return from "./svg/return.svg";
import HeadingOne from "./svg/heading1.svg";
import HeadingTwo from "./svg/Heading2.svg";
import HeadingThree from "./svg/Heading3.svg";
import TermsCondition from "./svg/terms_Conditions.svg";
import RemoveTableRow from "./svg/removeRow.svg";
import AddTableRow from "./svg/addRow.svg";
import pageBreak from "./svg/pageBreak.svg";
import Table from "./svg/table.svg";
import DownArrow from "./svg/downArrow.svg";
export const svgIcons = {
  Ecommerce,
  Blaze,
  Paypal,
  View,
  Edit,
  Edit_White,
  pageBreak,
  Add,
  OfferTwo,
  StoreTwo,
  Dedicated,
  DigitalTwo,
  SubscriptionTwo,
  FullyManaged,
  SelfService,
  StoreOne,
  Wallet,
  SelfPlatform,
  Priority,
  Digital,
  Courier,
  Subscription,
  OfferOne,
  CircleCheck,
  NotificationActive,
  NotificationInactive,
  BackInTime,
  Card,
  DashBoard,
  Disputes,
  Message,
  Messages,
  Down,
  TPConnect,
  Upload,
  Upload_In_Blue,
  Document,
  Plus,
  Filters,
  Calender,
  Right,
  Refresh,
  Search,
  Arrows,
  Check,
  Err,
  Prev,
  Next,
  FilterDown,
  Delete,
  Completed,
  Progress,
  Failed,
  Download,
  NoDispute,
  Info,
  Lachiepor,
  Majanogout,
  Majanogout_Gray,
  Costco,
  Costco_Gray,
  LachieporTwo,
  ArrowLeft,
  ArrowRight,
  Amazon,
  Google,
  Skype,
  Netflix,
  Update,
  PaypalDiv,
  Close,
  CheckTwo,
  ProgressChart,
  ArrowUp,
  Arrow_Up,
  ArrowDown,
  Regress,
  Cart,
  Cart_Alt,
  SettingsTwo,
  CheckThree,
  CheckFour,
  CheckFive,
  CheckSix,
  CheckIcon,
  CircleTwo,
  PaypalBig,
  Alert_Circle,
  Alert_Circle_Black,
  Alert_Circle_Blue,
  Bar_Chart,
  YellowFlag,
  Money,
  BlueEyeBadge,
  Eye,
  Eye_Black,
  Leather,
  ArrowUpDown,
  GreenTick,
  RedCross,
  RedOverdue,
  Star,
  Star_Blue,
  PayPalFill,
  PayPal_Linked_Account,
  Trash_1,
  Trash_2,
  InProgress,
  chevronRight,
  PayPal_Icon_Text,
  Stripe,
  Stripe_Icon_Text,
  Bank,
  ChartLineUp,
  Chart,
  Coins,
  MasterCard,
  UnderReview,
  Result,
  Link,
  GettingCoffee,
  Undraw_Winner,
  Undraw_Lost,
  Animation_640_1,
  Animation_640_3,
  Animation_640_4,
  Half_Slive_Polo_Tshirt,
  Squarespace,
  Wix,
  Ecwid,
  Prestashop,
  Package,
  Scissors,
  Shield_Off,
  Shuffle,
  Sidebar,
  Slash,
  Trello,
  Voicemail,
  X_Circle,
  Git_Branch,
  Other_Integration_Gear,
  Backward_Step,
  Bars_Staggered_Solid,
  Cart_Shopping_Solid,
  Comments_Regular,
  Credit_Card,
  Desktop_Solid,
  Envelope,
  File_Lines,
  Header,
  Image,
  Paragraph,
  Truck,
  SquareCheck,
  Activity,
  Currency_Dollar,
  Layers_three,
  Trophy,
  Coffee_Break,
  ChargePayLogo,
  ChargePayName,
  Accounts,
  Flows,
  Settings,
  Logout,
  Stores,
  Users,
  Edit_Page,
  Plus_Circle,
  squareLink,
  Text,
  Heading,
  List,
  Background,
  Summary,
  Lightning,
  File_Search,
  Card_Card_Check,
  Users_2,
  Coins_Hand,
  Truck_1,
  Coins_Swap,
  Message_Chat_Circle,
  Clipboard_Check,
  Mail,
  Credit_Card_Plus,
  Monitor,
  User_Circle,
  Notification_Text,
  Marker_Pin_04,
  Marker_Pin,
  Archive,
  File,
  Ticket,
  Play,
  File_Attachment,
  Eye_2,
  Trash_3,
  BrainTree,
  Klarna,
  product,
  Refund,
  Return,
  TermsCondition,
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  RemoveTableRow,
  AddTableRow,
  Table,
  DownArrow,
};
