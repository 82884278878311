import { Box } from "@mui/material";
import styles from "./style.module.scss";
import SelectUI from "../RelatedCom/Select";
import FlowBuilder from "../flowBuilder";
import { useDispatch } from "react-redux";
import RenderInput from "../RelatedCom/RenderInput";
import {
  expandTheFlowEditor,
  postDisputeFlowRequest,
} from "redux/actions";
import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loading from "components/loading/loading";
import { initialNewNodes } from "../NodeHelpers/Utils";
import { useLocation, useParams } from "react-router-dom";
import { DisputeFormData, RouteParams } from "../types";
import { useDisputeFlowViewData, useDisputeflow, useFlow } from "hooks";
import { uniqueId } from "lodash";
import { Button } from "components/ui";

const CreateDisputeFlow: React.FC = () => {
  const { flowId } = useParams<RouteParams>();
  const {
    mapedForm,
    isError,
    isSuccess,
    isLoading,
    submitted,
    formFunction,
    disputeFlowView,
    globalFlowState,
    setSubmitted,
  } = useDisputeflow();
  const { errors, control, register, handleSubmit, clearErrors, setValue } =
    formFunction;
  const { isExpand } = globalFlowState;
  const {
    edges,
    nodes,
    nodeTypes,
    proOptions,
    openSideBar,
    currentNode,
    setNodes,
    setEdges,
    handleNodeClick,
    handleEdgeClick,
    handleDownloadJSON,
    onNodesChange,
    onEdgesChange,
    setCurrentNode,
    onPaneClick,
    setOpenSideBar,
  } = useFlow();
  const dispatch = useDispatch();
  const location = useLocation();
  const isCreateNew = location.pathname.includes("create-new");
  const onSubmit = (data: any) => {
    const canvasObject = {
      nodes,
      edges,
    };
    Object.assign(data, {
      flow: canvasObject,
    });
    const submittedData: DisputeFormData = {
      id: isCreateNew ? "" : disputeFlowView?.data?.id,
      name: data.name,
      flowType: "SYSTEM",
      paymentGateway: data.psp.value,
      shop: {
        id: data.store.value,
      },
      flow: JSON.stringify(data.flow),
    };
    setSubmitted(submittedData);
    dispatch(postDisputeFlowRequest(submittedData));
  };
  const { isLoading: isGetLoading } = useDisputeFlowViewData(flowId);
  useEffect(() => {
    if (submitted && isSuccess) {
      toast.success(
        `Flow ${isCreateNew ? "created" : "updated"} successfully`,
        {
          theme: "colored",
        }
      );
    } else if (submitted && isError) {
      toast.error("Oops! something went wrong", {
        theme: "colored",
      });
    }
  }, [submitted, isSuccess, isError]);

  useEffect(() => {
    if (disputeFlowView?.data?.id && flowId) {
      const flow =
        disputeFlowView.data.flow !== "Flow Test" &&
        JSON.parse(disputeFlowView?.data?.flow);
      setNodes(flow.nodes);
      setEdges(flow.edges);
    } else {
      const maped = initialNewNodes.map((item) => {
        const id = uniqueId();
        item.id = `start_${id}`;
        item.data.conditions = [
          {
            label: "Select a reason",
            id: "condition1",
            source: "",
            additionalArguments: null,
          },
        ];
        return item;
      });
      setNodes(() => [...maped]);
      setEdges([]);
    }
  }, [disputeFlowView, disputeFlowView?.data?.id, setNodes, setEdges, flowId]);

  const handleFullScreen = () => {
    dispatch(expandTheFlowEditor({ isExpand: !isExpand }));
  };
  const fullScreenDivStyle = {
    height: isExpand ? "calc(100vh - 160px)" : "calc(100vh - 220px)",
    top: isExpand ? "50%" : "0",
    left: isExpand ? "50%" : "0",
  };

  return (
    <div className={styles.disputesWrapper}>
      <ToastContainer />
      <div className={styles.name}>
        {isGetLoading ? (
          <Loading />
        ) : (
          <>
            <Box
              display={`flex`}
              justifyContent={`space-between`}
              alignItems={`center`}
              onSubmit={handleSubmit(onSubmit)}
              component={"form"}
            >
              <p className={styles.topNav}>
                {flowId ? "Update the" : "Create new"} flow
              </p>
              <Button
                {...{
                  size: "small",
                  btnLabel: "Save",
                  btnTitle: "Click to save",
                  type: "submit",
                }}
              />
            </Box>
            <Box
              display={`flex`}
              justifyContent={`space-between`}
              alignItems={`flex-start`}
              flexWrap={"wrap"}
              gap={2}
              style={{
                display: isExpand ? "none" : "",
                transition: isExpand ? "display 0.5s ease" : "",
              }}
            >
              {mapedForm.map(
                ({ name, label, options, rules, type, placeholder }, key) =>
                  type === "text" ? (
                    <RenderInput
                      key={label}
                      {...{
                        rules,
                        errors,
                        name,
                        styles,
                        register,
                      }}
                    />
                  ) : (
                    <SelectUI
                      key={label}
                      {...{
                        styles,
                        control,
                        name,
                        rules,
                        options,
                        label,
                        errors,
                        clearErrors,
                        placeholder,
                        setValue,
                      }}
                    />
                  )
              )}
            </Box>
          </>
        )}
      </div>
      <Box className={styles.flowchart} style={fullScreenDivStyle}>
        {isLoading ? (
          <Loading />
        ) : (
          <FlowBuilder
            {...{
              edges,
              nodes,
              setNodes,
              nodeTypes,
              proOptions,
              openSideBar,
              currentNode,
              handleNodeClick,
              handleEdgeClick,
              handleDownloadJSON,
              onNodesChange,
              onEdgesChange,
              setCurrentNode,
              onPaneClick,
              setOpenSideBar,
              handleFullScreen,
              isExpand,
            }}
          />
        )}
      </Box>
    </div>
  );
};

export default CreateDisputeFlow;
