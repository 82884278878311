import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    accountList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    }
}


//account-list and filtered-account-list
const postAccountListRequest = (state: any, action: any) => {
    return update(state, {
        accountList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postAccountListSuccess = (state: any, action: any) => {
    return update(state, {
        accountList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postAccountListError = (state: any, action: any) => {
    return update(state, {
        accountList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.POST_ACCOUNT_LIST_REQUEST]: postAccountListRequest,
        [constants.POST_ACCOUNT_LIST_SUCCESS]: postAccountListSuccess,
        [constants.POST_ACCOUNT_LIST_ERROR]: postAccountListError,
    },
    initialState
);