import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
export class GetDisputeResponse {
    /**
     * Function to invoke dispute response
     */
    async invoke() {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_RESPONSE_FIND_ALL);

        return res;
    }

    async invokeFindByDisputeId(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID(payload));

        if (res.status === 200) {
            return res;
        } else {
            let newError = new Error()
            newError = { ...newError }
            throw newError
        }
    }

    async invokeCreate(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_DISPUTE_RESPONSE_CREATE, payload);

        return res;
    }

    async invokePdf(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_RESPONSE_PDF(payload));

        return res;
    }
    async invokePdfByResponseId(payload: any) {

        const res = await ApiService.getInstance().getPdf(apiConstants.GET_DISPUTE_RESPONSE_PDF_BY_ID(payload));

        return res;
    }
    async invokeStatusSubmitted(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.UPDATE_STATUS_SUBMITTED(payload));

        return res;
    }

    async invokeResponseStatusSubmitted(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.UPDATE_RESPONSE_STATUS_SUBMITED(payload));

        return res;
    }

    async invokeStatusQueued(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.UPDATE_STATUS_QUEUED(payload));

        return res;
    }
    async invokeStatusApproved(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.UPDATE_STATUS_APPROVED(payload));

        return res;
    }
    async invokeDisputeFetchContent(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_RESPONSE_FETCH_CONTENT(payload));

        return res;
    }
    async invokeDisputeSaveContent(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_DISPUTE_RESPONSE_SAVE_CONTENT, payload);

        return res;
    }
    async invokeSavingMissingMacros(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_SAVING_MISSING_MACROS, payload);

        return res;
    }
    async invokeGettingMacros(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_RESPONSE_MACROS(payload));

        return res;
    }

    async invokeGetMissingMacros(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_MISSING_MACROS(payload));

        return res;
    }

    async invokeDisputeUpdateStatusApproved(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_DISPUTE_RESPONSE_UPDATE_STATUS_APPROVED, payload);
        return res;
    }
    async invokeDisputeCreateResponse(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_DISPUTE_RESPONSE_CREATE_RESPONSE, payload);
        return res;
    }
    async invokeDeleteDisputeCreateResponse(payload: any) {

        const res = await ApiService.getInstance().delete(apiConstants.DELETE_SINGLE_DISPUTE_RESPONSE(payload));
        return res;
    }
    async invokeManageDisputeFlow(payload: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_FLOW(payload));
        return res;
    }
    async invokeFetchDisputeTemplate(payload: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_TEMPLATE(payload));
        return res;
    }
    async invokeFetchTemplateSummary(payload: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_SUMMARY_TEMPLATE(payload));
        return res;
    }

}