import axios from "axios";
import { configs } from "configs";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";

const useDisputeEvidence = () => {
  const handleCreateDisputeResponse = async (data: any, uploadFile: any) => {
    const formData: any = new FormData();
    if (!isEmpty(uploadFile.name)) {
      formData.append("file", uploadFile);
    }
    formData.append("disputeId", data?.disputeId);
    formData.append("type", data.type);
    formData.append("description", data?.description);
    const token = localStorage.getItem("accessToken");
    const API_UPLOAD_CONFIG = {
      timeout: 10000,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await axios.post(
        `${configs.BASE_URL}/response-evidence`,
        formData,
        API_UPLOAD_CONFIG
      );
      if (response.data) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `"Your data has been saved successfully"`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Something is wrong with submission form",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return { handleCreateDisputeResponse };
};
export default useDisputeEvidence;
