import moment from "moment";
import styles from "./style.module.scss";
import { useTransactionDetails } from "hooks";
import { Box, Paper, Tooltip } from "@mui/material";
import Loading from "components/loading/loading";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

const TransactionDetails = () => {
  const { isSuccess, data, isLoading } = useTransactionDetails();
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isSuccess && data?.length > 0 ? (
            <Box display={`flex`} flexDirection={`column`} gap={1}>
              {data.map((item: any, index: number) => (
                <Paper key={index} style={{ padding: "15px" }}>
                  <div className={styles.transactionInfo}>
                    <div className={styles.transactionData}>
                      <p>Authorization Key</p>
                      <div className={styles.transactionValue}>
                        <Tooltip
                          arrow
                          placement="top"
                          title={item?.authorizationKey}
                        >
                          {item?.authorizationKey}
                        </Tooltip>
                      </div>
                    </div>
                    <div className={styles.transactionData}>
                      <p>Transaction Date</p>
                      <div className={styles.transactionValue}>
                        <Tooltip
                          arrow
                          placement="top"
                          title={`${
                            item?.createTime &&
                            dayjs(item?.createTime).format("Do MMMM YYYY")
                          }`}
                        >
                          {item?.createTime &&
                            dayjs(item?.createTime).format("Do MMMM YYYY")}
                        </Tooltip>
                      </div>
                    </div>
                  </div>

                  <div className={styles.transactionInfo}>
                    <div className={styles.transactionData}>
                      <p>Brand</p>
                      <div className={styles.transactionValue}>
                        {item?.brand}
                      </div>
                    </div>
                    <div className={styles.transactionData}>
                      <p>BIN & Last 4</p>
                      <div className={styles.transactionValue}>
                        {item?.last4}
                      </div>
                    </div>
                  </div>

                  <div className={styles.transactionInfo}>
                    <div className={styles.transactionData}>
                      <p>Card Category</p>
                      <div className={styles.transactionValue}>
                        {item?.CardCategory}
                      </div>
                    </div>
                    <div className={styles.transactionData}>
                      <p>Gateway</p>
                      <div className={styles.transactionValue}>
                        {/* <img src={data.Gateway} alt='gateWay' /> */}
                        {item?.gateway}
                      </div>
                    </div>
                  </div>

                  <div className={styles.transactionInfo}>
                    <div className={styles.transactionData}>
                      <p>Charge Descriptor</p>
                      <div className={styles.transactionValue}>
                        {item?.ChargeDescriptor}
                      </div>
                    </div>
                    <div className={styles.transactionData}>
                      <p>Exp Date</p>
                      <div className={styles.transactionValue}>
                        {item?.creditCardExpMonth} / {item?.creditCardExpYear}
                      </div>
                    </div>
                  </div>

                  <div className={styles.transactionInfo}>
                    <div className={styles.transactionData}>
                      <p>Type of Card</p>
                      <div className={styles.transactionValue}>
                        {/* <img src={data.TypeofCardIcon} alt='cardIcon' /> */}
                        {item?.creditCardCompany}
                      </div>
                    </div>
                    <div className={styles.transactionData}>
                      <p>Country</p>
                      <div className={styles.transactionValue}>
                        {item?.country}
                      </div>
                    </div>
                  </div>

                  <div className={styles.transactionInfo}>
                    <div className={styles.transactionData}>
                      <p>Reason Code</p>
                      <div className={styles.transactionValue}>
                        {item?.ReasonCode}
                      </div>
                    </div>
                  </div>
                </Paper>
              ))}
            </Box>
          ) : (
            <div className={styles.evidenceWrapper}>
              <p>No Transaction Details avaiable</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TransactionDetails;
