import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "@mui/material";
import cn from "classnames";
import { StoresList, Search, Pagination } from "components";
import { StroesFilterSection } from "utils/accountsUtils";
import styles from "./style.module.scss";
import { cloneDeep } from "lodash";
import {
  getShopAllShopsRequest,
  postShopFilterShopsRequest,
} from "redux/actions";
import { BreadcumbItem } from "components/ui";
import { useBreadCumbs } from "hooks";

const Stores = () => {
  const dispatch = useDispatch();
  const { breadCumbs } = useBreadCumbs({ pageName: "Stores" });
  const [storesStatusBtnCheck, setStoresStatusBtnCheck] = useState(false);
  const [storesClick, setstoresClick] = useState(false);
  const [storesStatusButton, setStoresStatusButton] = useState([
    ...StroesFilterSection,
  ]);
  const [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = useState(1);
  const tableList = 15;

  const { data } = useSelector((state: any) => state.storesShop.shopAllShops);

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handleStoresStatus = (accountStatus: any, index: number) => {
    const storeBtn = cloneDeep(storesStatusButton);
    storeBtn.map((store) => {
      store.status = false;
    });
    accountStatus.status = !accountStatus.status;
    setstoresClick(true); //to check that status filter button clicked
    storeBtn[index] = accountStatus;
    setStoresStatusButton([...storeBtn]);
    setStoresStatusBtnCheck(!storesStatusBtnCheck);
    setPage(1);
  };

  useEffect(() => {
    if (storesClick || page > 0) {
      let chargeActiveFilter;
      const statusArr = storesStatusButton.filter(
        (statusData) => statusData.status
      );
      statusArr.map((item) => {
        if (item?.title?.toLowerCase() === "active") {
          chargeActiveFilter = true;
        } else if (item?.title?.toLowerCase() === "inactive") {
          chargeActiveFilter = false;
        }
      });

      if (chargeActiveFilter === undefined && !searchFilter) {
        const payload = {
          page,
          pageLength: tableList,
        };
        dispatch(getShopAllShopsRequest(payload));

        setstoresClick(false);
        setStoresStatusBtnCheck(false);
      } else {
        const payload = {
          chargeActive:
            chargeActiveFilter === true || chargeActiveFilter === false
              ? chargeActiveFilter
              : null,
          searchString: searchFilter ? searchFilter : null,
          page: Number(page),
          pageLength: Number(tableList),
        };
        dispatch(postShopFilterShopsRequest(payload));
      }
    }
  }, [storesStatusBtnCheck, page, searchFilter]);

  return (
    <div className={styles.disputesWrapper}>
      <div className={styles.nav}>
        <h3>Stores</h3>
        <div className={styles.navPath}>
          {breadCumbs.map((item, key) => (
            <BreadcumbItem {...item} {...{ key }} />
          ))}
        </div>
      </div>

      <div>
        <div className={styles.status}>
          {storesStatusButton?.map((statusBtn, index) => {
            return (
              <Button
                key={statusBtn.title}
                data-testid={`${statusBtn?.title}-status-button`}
                className={cn(
                  statusBtn.status && styles.allDisputes,
                  styles.statusDiv
                )}
                onClick={() => handleStoresStatus({ ...statusBtn }, index)}
              >
                <div
                  className={cn(styles.open)}
                  style={{
                    background: statusBtn.status ? "#ffffff" : statusBtn.color,
                  }}
                ></div>
                {statusBtn?.title?.split("_").join(" ")}
              </Button>
            );
          })}
          <div className={styles.searchDiv}>
            <Search
              onSearch={function (query: string): void {
                setSearchFilter(query);
                setPage(1);
              }}
              onType={function (query: string): void {
                // throw new Error("Function not implemented.");
                setSearchFilter(query);
                setPage(1);
              }}
              type={""}
              placeholder={"Search by store Id..."}
              className={styles.searchInput}
            />
          </div>
        </div>
        <div>
          <StoresList />

          {Math.ceil(data?.count / tableList) > 1 && (
            <Pagination
              onPageChange={(pageNum) => onPageChange(pageNum)}
              page={page}
              totalPages={Math.ceil(data?.count / tableList)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Stores;
