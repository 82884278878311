import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";
import { constant, isError } from "lodash";

const initialState = {
    disputeBilling: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    disputeApprove: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    disputeReadyStatus: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    newBillingList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    newBillingReadyDisputes: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    totalBilledAmountList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    totalAmountList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    newBillingTotalBilledAmount: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    newBillingTotalAmount: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    readyDisputeTotalBilledAmount: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    readyDisputeTotalAmount: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
}

//dispute-billing-reports
const postDisputeBillingRequest = (state: any, action: any) => {
    return update(state, {
        disputeBilling: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postDisputeBillingSuccess = (state: any, action: any) => {
    return update(state, {
        disputeBilling: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postDisputeBillingError = (state: any, action: any) => {
    return update(state, {
        disputeBilling: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute approve
const postDisputeApproveRequest = (state: any, action: any) => {
    return update(state, {
        disputeApprove: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postDisputeApproveSuccess = (state: any, action: any) => {
    return update(state, {
        disputeApprove: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postDisputeApproveError = (state: any, action: any) => {
    return update(state, {
        disputeApprove: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
const removeDisputeApproveRequest = (state: any, action: any) => {
    return update(state, {
        disputeApprove: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: {} },
        }
    });
};

// manage dispute ready status
const postDisputeReadyStatusRequest = (state: any, action: any) => {
    return update(state, {
        disputeReadyStatus: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postDisputeReadyStatusSuccess = (state: any, action: any) => {
    return update(state, {
        disputeReadyStatus: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postDisputeReadyStatusError = (state: any, action: any) => {
    return update(state, {
        disputeReadyStatus: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// new billing list
const postNewBillingListRequest = (state: any, action: any) => {
    return update(state, {
        newBillingList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postNewBillingListSuccess = (state: any, action: any) => {
    return update(state, {
        newBillingList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postNewBillingListError = (state: any, action: any) => {
    return update(state, {
        newBillingList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// new billing list
const getNewBillingReadyDisputeListRequest = (state: any, action: any) => {
    return update(state, {
        newBillingReadyDisputes: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getNewBillingReadyDisputeListSuccess = (state: any, action: any) => {
    return update(state, {
        newBillingReadyDisputes: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};
const getNewBillingReadyDisputeListError = (state: any, action: any) => {
    return update(state, {
        newBillingReadyDisputes: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


// total-billed-ammount
const getTotalBilledAmountRequest = (state: any, action: any) => {
    return update(state, {
        totalBilledAmountList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getTotalBilledAmountSuccess = (state: any, action: any) => {
    return update(state, {
        totalBilledAmountList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getTotalBilledAmountError = (state: any, action: any) => {
    return update(state, {
        totalBilledAmountList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// total-ammount
const getTotalAmountRequest = (state: any, action: any) => {
    return update(state, {
        totalAmountList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getTotalAmountSuccess = (state: any, action: any) => {
    return update(state, {
        totalAmountList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getTotalAmountError = (state: any, action: any) => {
    return update(state, {
        totalAmountList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// new billing total billed amount
const getNewBillingTotalBilledAmountRequest = (state: any, action: any) => {
    return update(state, {
        newBillingTotalBilledAmount: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getNewBillingTotalBilledAmountSuccess = (state: any, action: any) => {
    return update(state, {
        newBillingTotalBilledAmount: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getNewBillingTotalBilledAmountError = (state: any, action: any) => {
    return update(state, {
        newBillingTotalBilledAmount: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// new billing total amount
const getNewBillingTotalAmountRequest = (state: any, action: any) => {
    return update(state, {
        newBillingTotalAmount: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getNewBillingTotalAmountSuccess = (state: any, action: any) => {
    return update(state, {
        newBillingTotalAmount: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getNewBillingTotalAmountError = (state: any, action: any) => {
    return update(state, {
        newBillingTotalAmount: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// ready dispute total billed amount
const getReadyDisputeTotalBilledAmountRequest = (state: any, action: any) => {
    return update(state, {
        readyDisputeTotalBilledAmount: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getReadyDisputeTotalBilledAmountSuccess = (state: any, action: any) => {
    return update(state, {
        readyDisputeTotalBilledAmount: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getReadyDisputeTotalBilledAmountError = (state: any, action: any) => {
    return update(state, {
        readyDisputeTotalBilledAmount: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// ready dispute total amount
const getReadyDisputeTotalAmountRequest = (state: any, action: any) => {
    return update(state, {
        readyDisputeTotalAmount: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getReadyDisputeTotalAmountSuccess = (state: any, action: any) => {
    return update(state, {
        readyDisputeTotalAmount: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getReadyDisputeTotalAmountError = (state: any, action: any) => {
    return update(state, {
        readyDisputeTotalAmount: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.POST_DISPUTE_BILLING_REQUEST]: postDisputeBillingRequest,
        [constants.POST_DISPUTE_BILLING_SUCCESS]: postDisputeBillingSuccess,
        [constants.POST_DISPUTE_BILLING_ERROR]: postDisputeBillingError,

        [constants.POST_DISPUTE_APPROVE_REQUEST]: postDisputeApproveRequest,
        [constants.POST_DISPUTE_APPROVE_SUCCESS]: postDisputeApproveSuccess,
        [constants.POST_DISPUTE_APPROVE_ERROR]: postDisputeApproveError,
        [constants.REMOVE_DISPUTE_APPROVE_ERROR]: removeDisputeApproveRequest,


        [constants.POST_DISPUTE_READY_STATUS_REQUEST]: postDisputeReadyStatusRequest,
        [constants.POST_DISPUTE_READY_STATUS_SUCCESS]: postDisputeReadyStatusSuccess,
        [constants.POST_DISPUTE_READY_STATUS_ERROR]: postDisputeReadyStatusError,

        [constants.POST_NEW_BILLING_LIST_REQUEST]: postNewBillingListRequest,
        [constants.POST_NEW_BILLING_LIST_SUCCESS]: postNewBillingListSuccess,
        [constants.POST_NEW_BILLING_LIST_ERROR]: postNewBillingListError,

        [constants.GET_NEW_BILLING_READY_DISPUT_REQUEST]: getNewBillingReadyDisputeListRequest,
        [constants.GET_NEW_BILLING_READY_DISPUT_SUCCESS]: getNewBillingReadyDisputeListSuccess,
        [constants.GET_NEW_BILLING_READY_DISPUT_ERROR]: getNewBillingReadyDisputeListError,

        [constants.GET_TOTAL_BILLED_AMOUNT_REQUEST]: getTotalBilledAmountRequest,
        [constants.GET_TOTAL_BILLED_AMOUNT_SUCCESS]: getTotalBilledAmountSuccess,
        [constants.GET_TOTAL_BILLED_AMOUNT_ERROR]: getTotalBilledAmountError,

        [constants.GET_TOTAL_AMOUNT_REQUEST]: getTotalAmountRequest,
        [constants.GET_TOTAL_AMOUNT_SUCCESS]: getTotalAmountSuccess,
        [constants.GET_TOTAL_AMOUNT_ERROR]: getTotalAmountError,

        [constants.GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_REQUEST]: getNewBillingTotalBilledAmountRequest,
        [constants.GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_SUCCESS]: getNewBillingTotalBilledAmountSuccess,
        [constants.GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_ERROR]: getNewBillingTotalBilledAmountError,

        [constants.GET_NEW_BILLING_TOTAL_AMOUNT_REQUEST]: getNewBillingTotalAmountRequest,
        [constants.GET_NEW_BILLING_TOTAL_AMOUNT_SUCCESS]: getNewBillingTotalAmountSuccess,
        [constants.GET_NEW_BILLING_TOTAL_AMOUNT_ERROR]: getNewBillingTotalAmountError,

        [constants.GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_REQUEST]: getReadyDisputeTotalBilledAmountRequest,
        [constants.GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_SUCCESS]: getReadyDisputeTotalBilledAmountSuccess,
        [constants.GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_ERROR]: getReadyDisputeTotalBilledAmountError,

        [constants.GET_READY_DISPUTE_TOTAL_AMOUNT_REQUEST]: getReadyDisputeTotalAmountRequest,
        [constants.GET_READY_DISPUTE_TOTAL_AMOUNT_SUCCESS]: getReadyDisputeTotalAmountSuccess,
        [constants.GET_READY_DISPUTE_TOTAL_AMOUNT_ERROR]: getReadyDisputeTotalAmountError,
    },
    initialState
);