import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getDisputeFlowListRequest } from "redux/actions";

const useSystemFlow = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isError, data } = useSelector(
    (state: any) => state?.disputeFlow?.disputeFlowList
  );
  useEffect(() => {
    dispatch(getDisputeFlowListRequest());
  }, [dispatch]);

  return {
    isLoading,
    isSuccess,
    isError,
    data,
  };
};

export default useSystemFlow;
