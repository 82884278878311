import { CreateDisputeTemplate } from "components";
import styles from "./style.module.scss";

const CreateOrEditTemplate = () => {
  return (
    <div className={styles.disputesWrapper}>
      <CreateDisputeTemplate />
    </div>
  );
};

export default CreateOrEditTemplate;
