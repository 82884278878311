import ApiService from '../../apiService';
import { apiConstants } from '../network/api-constants';

export class GetUnderReviewDisputes {
  async invoke(paylaod: any) {
    const res = await ApiService.getInstance().get(apiConstants.GET_FETCH_UNDER_REVIEW_DISPUTES(paylaod));
    return res;
  }
  
  async invokeByTotalBilledAmount(paylaod: any) {
    const res = await ApiService.getInstance().get(apiConstants.GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT(paylaod));
    return res;
  }

  
  async invokeByTotalAmount(paylaod: any) {
    const res = await ApiService.getInstance().get(apiConstants.GET_UNDER_REVIEW_TOTAL_AMOUNT(paylaod));
    return res;
  }

}
