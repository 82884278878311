import { Box, Tooltip } from "@mui/material";
import Loading from "components/loading/loading";
import { svgIcons } from "elements";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["PNG", "JPG", "JPEG"];

const FileUpload = ({
  styles,
  handleFileUpload,
  checkFile,
  type,
  label,
  imageUrl,
  handlePreview,
}: any) => {
  return (
    <div className={styles.message}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <label>{label}</label>
        {imageUrl && (
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handlePreview(imageUrl);
            }}
          >
            <Tooltip arrow placement="top" title="Click to preview">
              <img src={svgIcons.Eye_2} alt="eye" />
            </Tooltip>
          </Box>
        )}
      </Box>
      <Tooltip arrow placement="top" title="Upload or drag and drop the file">
        <div className={styles.uploadFile}>
          <div className={""}>
            <FileUploader
              multiple={true}
              handleChange={(doc: any) => handleFileUpload(doc, type)}
              name="file"
              types={fileTypes}
              children={
                <div className={styles.documentField}>
                  <img
                    style={{
                      display: checkFile.name ? "none" : "",
                    }}
                    src={svgIcons.Upload_In_Blue}
                    alt="upload"
                  />
                  <p
                    style={{
                      display: checkFile.name ? "none" : "",
                    }}
                  >
                    {label}
                  </p>
                  <div></div>
                  <Box
                    display="flex"
                    justifyContent={`center`}
                    alignItems={`center`}
                  >
                    {checkFile.name && <Loading />}
                    <input
                      style={{ marginLeft: "-35px" }}
                      data-testid="dispute-document"
                      hidden={checkFile && checkFile.name ? false : true}
                      readOnly
                      value={checkFile.name}
                    />
                  </Box>
                </div>
              }
            />
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default FileUpload;
