export const colorSelectArray: { [key: string]: string }[] = [
    {
        status: "Inactive",
        backgroundColor: '#E0E0E0',
        textColor: '#787F8E'
    },
    {
        status: "In_trial",
        backgroundColor: '#FFFAF0',
        textColor: '#FEC768'
    },
    {
        status: "Active",
        backgroundColor: '#E2F9EC',
        textColor: '#21D191'
    },

    //email-verified color combination used in Accounts and Stores
    {
        emailVerified: "True",
        backgroundColor: '#D7F3E4',
        textColor: '#12B76A'                    //--Success--500 
    },
    {
        emailVerified: "Not Verified",
        backgroundColor: '#EFF0F7',
        textColor: '#1D2939'
    },
]

//used in Accounts table and Stores table
export const getColorByStatus = (status: string) => {
    let colorStatus = colorSelectArray.find((item, index) => {
        return item?.status?.toLowerCase() === status?.toLowerCase();
    })
    return colorStatus
}

//used in Accounts table and Stores table
export const getColorByEmailVerified = (emailVerified: string) => {
    let colorStatus = colorSelectArray.find((item, index) => {
        return item?.emailVerified?.toLowerCase() === emailVerified?.toLowerCase();
    })
    return colorStatus
}

//accounts filters
export const FilterSection = [
    {
        title: 'Active',
        color: '#fec768',
        status: false,
    },
    {
        title: 'In_trial',
        color: '#65CFFD',
        status: false,
    },
    {
        title: 'Inactive',
        color: '#3751FB',
        status: false,
    }
];

// stores filter
export const StroesFilterSection = [
    {
        title: 'Active',
        color: '#21D191',
        status: false,
    },
    // {
    //     title: 'In_trial',
    //     color: '#FEC768',
    //     status: false,
    // },
    {
        title: 'Inactive',
        color: '#787F8E',
        status: false,
    }
];

//single-account-breadcrumb
export const accountBreadcrumb = [
    {
        title: "Dashboard",
        link: "/home"
    },
    {
        title: "Disputes",
        link: "/disputes"
    },
    {
        title: "Flows",
        link: "/flows"
    },
    {
        title: "Templates",
        link: "/templates"
    },
    {
        title: "Linked Accounts",
        link: "#####"
    },
    {
        title: "Settings",
        link: "#####"
    },
]