import React from "react";
import { Button, Popper, PopperPlacementType, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
const PopperItem = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => !prev);
    };

  return (
    <Popper open={open} anchorEl={anchorEl} placement={"right-end"} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <Typography sx={{ p: 2 }}>
              Are you sure you want to delete this?
            </Typography>
            <Button onClick={handleClick("right-end")}>Yes</Button>
            <Button onClick={handleClick("right-end")}>No</Button>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default PopperItem;
