import { useState } from "react";
import { useNavigate } from "react-router-dom";

type BreadCumbItem = {
  pageName?: string;
  styles?: any;
};

const useBreadCumbs = ({ pageName, styles }: BreadCumbItem) => {
  const navigate = useNavigate();
  const [currentSelected, setCurrentSelected] = useState("");
  function handleBreadcrumb(page: string) {
    navigate(page);
  }
  function handleStoreRelatedData(page: string) {
    setCurrentSelected(page);
  }
  const breadCumbs = [
    {
      title: "Home",
      className: "",
      off: false,
      onClick: () => handleBreadcrumb("/home"),
    },
    {
      title: pageName,
      className: "",
      off: true,
      onClick: () => { },
    },
  ];
  const allBreadCumbs = [
    {
      title: "Home",
      className: "",
      onClick: () => handleBreadcrumb("/home"),
    },
    {
      title: "Stores",
      className: "",
      onClick: () => handleBreadcrumb("/stores"),
    },
    {
      title: "Store details",
      className: currentSelected === "" ? styles?.activeCheck : "",
      onClick: () => handleStoreRelatedData(""),
    },
    {
      title: "Disputes",
      className: currentSelected === "disputes" ? styles?.activeCheck : "",
      onClick: () => handleStoreRelatedData("disputes"),
    },
    {
      title: "Templates",
      className: currentSelected === "templates" ? styles?.activeCheck : "",
      onClick: () => handleStoreRelatedData("templates"),
    },
    {
      title: "Flows",
      className: currentSelected === "flows" ? styles?.activeCheck : "",
      off: true,
      onClick: () => handleStoreRelatedData("flows"),
    },
  ];

  const billingBreadCumbs = [
    {
      title: "Home",
      className: "",
      onClick: () => handleBreadcrumb("/home"),
    },
    {
      title: "Billing",
      className: currentSelected === "billings" ? styles?.activeCheck : "",
      off: true,
      onClick: () => handleStoreRelatedData(""),
    },
  ];

  const templateViewBreadCumb = [
    {
      title: "Home",
      className: "",
      off: false,
      onClick: () => handleBreadcrumb("/home"),
    },
    {
      title: "Templates",
      className: "",
      off: false,
      onClick: () => handleBreadcrumb("/templates"),
    },
    {
      title: pageName,
      className: "",
      off: true,
      onClick: () => { },
    },
  ];
  return {
    breadCumbs,
    allBreadCumbs,
    billingBreadCumbs,
    currentSelected,
    templateViewBreadCumb
  };
};

export default useBreadCumbs;
