import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import cn from "classnames";
import styles from "./style.module.scss";
import { svgIcons } from "../../elements";
import { itemNotReceivedStripeData } from "../../utils/disputeflowUtils";

const stripeToggle = [
    "Visa",
    "Mastercard",
    "American Express",
    "Discover",
]

const ItemsNotReceived = () => {
    const navigate = useNavigate();
    const [toggle, setToggle] = useState<string>("Visa")
    const [stripeData, setStripeData] = useState<any>(itemNotReceivedStripeData[0]);

    const handletoggletab = (toggleName: string, index: number) => {
        setToggle(toggleName);
        setStripeData(itemNotReceivedStripeData[index])
    }

    function handleBreadcrumb(page: string) {
        navigate(page);
    }

    return (
        <div className={styles.disputesWrapper}>
            <div className={styles.nav}>
                <h3>System Flows</h3>
                <div className={styles.navPath}>
                    <p onClick={() => handleBreadcrumb('/home')}>Home</p>
                    <p onClick={() => handleBreadcrumb('/flows')}>Flows</p>
                    <p>System Flows</p>
                </div>
            </div>
            <div className={styles.box}>
                <div className={styles.heading}>
                    <img src={svgIcons.Package} />
                    <h4 data-testid="Items-Not-Received-heading">Item Not Received</h4>
                </div>
                <div className={styles.flowchart}>
                </div>
            </div>

            <div className={styles.cards}>
                <div className={styles.cardsname}>
                    {stripeToggle?.map((toggleHead, index) => (
                        <p key={toggleHead}
                            onClick={() => handletoggletab(toggleHead, index)}
                            className={toggle === toggleHead
                                ? cn(styles.underline, styles.toggleHead)
                                : styles.toggleHead}
                        >
                            {toggleHead}
                        </p>
                    ))}
                </div>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th className={styles.column1}>
                                    STRIPE CATEGORY
                                </th>
                                <th>
                                    {stripeData.codes}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {stripeData?.stripeCategory?.map((stripe: any) => (
                                <tr key={stripe.stripeCategory}>
                                    <td className={styles.column1}>
                                        {stripe.stripeCategory}
                                    </td>
                                    <td>
                                        <ul>
                                            {stripe.stripeData?.map((data: any) => (
                                                <li key={data.id}>
                                                    {data.data}
                                                </li>
                                            ))}
                                        </ul>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ItemsNotReceived;