import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getDisputeOrderFulfillmentDetailsRequest } from "redux/actions";

const useOrderFulfillmentDetails = () => {
  const dispatch = useDispatch();
  const { disputeId } = useParams();

  const { isSuccess, data, isLoading } = useSelector(
    (state: any) => state?.disputeDetailsStore?.manageDisputeFulfillmentDetails
  );
  useEffect(() => {
    if (disputeId) {
      const payload = disputeId;
      dispatch(getDisputeOrderFulfillmentDetailsRequest(payload));
    }
  }, [disputeId]);
  return { isSuccess, data, isLoading };
};
export default useOrderFulfillmentDetails;
