import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";
import { Button } from "components/ui";
import ChangeInfoManager from "../changeInfoManager";
const AssignedManager = () => {
  return (
    <div style={{ background: "#fff", padding: "40px", borderRadius: "12px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "30px 0",
        }}
      >
        <p>stores</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginRight: "20px" }}>
            <p style={{ marginBottom: "10px" }}>Available Stores</p>
            <ChangeInfoManager />
          </div>
          <div>
            <p style={{ marginBottom: "10px" }}>Assigned Stores</p>
            <ChangeInfoManager />
          </div>
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "30px 0",
        }}
      >
        <p>Disputes</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginRight: "20px" }}>
            <p style={{ marginBottom: "10px" }}>Available Disputes</p>
            <ChangeInfoManager />
          </div>
          <div>
            <p style={{ marginBottom: "10px" }}>Assigned Disputes</p>
            <ChangeInfoManager />
          </div>
        </div>
      </div>
      <Divider />
      <Box marginTop={`10px`} justifyContent={`flex-end`} display={`flex`}>
        <Button
          {...{
            size: "medium",
            btnLabel: "Save",
            btnTitle: "Save",
            type: "submit",
          }}
        />
      </Box>
    </div>
  );
};

export default AssignedManager;
