import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UseFormProps, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  getDisputeTemplateReasonListRequest,
  getDisputeTemplateStageListRequest,
  getShopAllShopsRequest,
  getDisputeFlowViewRequest,
  getDisputeTemplateActionListRequest,
} from "redux/actions";
import {
  DisputeFormData,
  selectDropDownOption,
} from "components/disputeFlow/types";
import {
  handlePaymentGateWay,
  handleTemplateAction,
  handleTemplateStore,
  paymentGatewayOptions,
} from "components/disputeFlow/NodeHelpers/Utils";

/**
 * useDisputeflow custom hook
 *
 * Manages state and side effects for dispute flow form.
 * - Fetches data from Redux store
 * - Dispatches async requests
 * - Handles mapping API responses to form values
 * - Resets form on defaultValues change
 *
 * @returns Object with fields needed for dispute flow form
 */
const useDisputeflow = () => {
  const { flowId } = useParams();
  const { disputeFlowView, globalFlowState }: any = useSelector(
    (state: any) => state?.disputeFlow
  );
  const { storeId } = useParams();
  const dispatch = useDispatch();
  const initialDefault: any = {
    id: "",
    name: "",
    psp: "",
    store: "",
  };
  const [defaultValues, setdefaultValues] = useState(initialDefault);
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  }: any = useForm<UseFormProps>({
    defaultValues,
  });
  const [storesOptions, setStoresOptions] = useState<selectDropDownOption[]>(
    []
  );
  const [reasons, setReasons] = useState<selectDropDownOption[]>();
  const [stages, setStages] = useState<selectDropDownOption[]>();
  const [submitted, setSubmitted] = useState<DisputeFormData | null>();
  const { isLoading, isSuccess, isError } = useSelector(
    (state: any) => state?.disputeFlow?.disputeFlow
  );

  const disputeTemplateReasonList = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplateReasonList
  );
  const disputeTemplateActionList = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplateActionList
  );
  const disputeTemplateStageList = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplateStageList
  );
  const shopAllShops = useSelector(
    (state: any) => state.storesShop.shopAllShops
  );
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  /**
   * Updates defaultValues state when flowId changes using values from
   * disputeFlowView, paymentGatewayOptions, and shopAllShops.
   * Handles mapping disputeFlowView data to dropdown options.
   * Ensures form resets when defaultValues change.
   */

  useEffect(() => {
    if (flowId && disputeFlowView.data) {
      const pspTemplate = handlePaymentGateWay(
        disputeFlowView.data.paymentGateway,
        paymentGatewayOptions
      );
      const storeTemplate = handleTemplateStore(
        disputeFlowView?.data?.shop?.id,
        shopAllShops?.data?.shopResponseList
      );
      setdefaultValues((prev: any) => ({
        ...prev,
        id: flowId,
        name: disputeFlowView.data.name,
        psp: pspTemplate,
        store: storeTemplate,
      }));
    }
  }, [
    flowId,
    disputeFlowView.data,
    shopAllShops?.data?.shopResponseList,
    disputeTemplateStageList.data,
    disputeTemplateReasonList.data,
  ]);
  useEffect(() => {
    if (storeId) {
      let storesOptionsed = shopAllShops?.data?.shopResponseList?.map(
        (store: any) => {
          let storeObj = {
            value: store?.id,
            label: store?.name,
          };
          return storeObj;
        }
      );
      const getSelected = storesOptionsed?.find(
        (item: any) => item.value == storeId
      );
      setdefaultValues((prev: any) => ({
        ...prev,
        store: getSelected,
      }));
    }
  }, [shopAllShops?.data?.shopResponseList, storeId]);
  //TODO ` Need to check why I am using this
  useEffect(() => {
    if (flowId && disputeFlowView.data?.type) {
      dispatch(
        getDisputeTemplateActionListRequest(disputeFlowView?.data?.type)
      );
    }
  }, [disputeFlowView?.data?.type, flowId, dispatch]);

  useEffect(() => {
    if (flowId && disputeFlowView?.data?.action) {
      const actionTemplate =
        disputeTemplateActionList.data.length &&
        handleTemplateAction(
          disputeFlowView?.data?.action,
          disputeTemplateActionList?.data
        );
      setValue("action", actionTemplate);
    }
  }, [
    disputeFlowView?.data?.action,
    disputeTemplateActionList.data,
    flowId,
    setValue,
  ]);

  useEffect(() => {
    const payload = {
      enabled: true,
    };

    dispatch(getDisputeTemplateReasonListRequest(payload));
    dispatch(getDisputeTemplateStageListRequest(payload));
    dispatch(getShopAllShopsRequest(payload));
    if (flowId) {
      dispatch(getDisputeFlowViewRequest(flowId));
    }
  }, [dispatch, flowId]);

  useEffect(() => {
    if (
      disputeTemplateReasonList?.isSuccess &&
      disputeTemplateReasonList?.data?.length > 0
    ) {
      const response = disputeTemplateReasonList?.data?.map((reason: any) => {
        let reasonObj = {
          value: reason?.disputeReasonValue,
          label: reason?.description,
        };
        return reasonObj;
      });
      setReasons(response);
    }
    if (
      disputeTemplateStageList?.isSuccess &&
      disputeTemplateStageList?.data?.length > 0
    ) {
      let stageOption = disputeTemplateStageList?.data?.map((stage: any) => {
        let stageObj = {
          value: stage?.disputeLifeCycleValues,
          label: stage?.disputeLifeCycleValues?.split("_")?.join(" "),
        };
        return stageObj;
      });
      setStages(stageOption);
    }
    if (shopAllShops.isSuccess && shopAllShops?.data?.count > 0) {
      let storeOption: any = [];

      shopAllShops?.data?.shopResponseList?.forEach((store: any) => {
        let storeObj = {
          value: store?.id,
          label: store?.name,
        };
        storeOption.push(storeObj);
      });
      setStoresOptions(storeOption);
    }
  }, [disputeTemplateReasonList, disputeTemplateStageList, shopAllShops]);

  //this array denotes to showing the form for the creating the fields
  const mapedForm = [
    {
      label: "Name",
      name: "name",
      rules: {
        required: {
          value: true,
          message: "This is required",
        },
      },
      type: "text",
    },
    {
      label: "Store",
      name: "store",
      rules: {
        required: {
          value: true,
          message: "This is required",
        },
      },
      options: storesOptions,
      placeholder: "Select store",
    },
    {
      label: "Psp",
      name: "psp",
      rules: {
        required: {
          value: true,
          message: "This is required",
        },
      },
      placeholder: "Select psp",
      options: paymentGatewayOptions,
    },
  ];
  const formFunction = {
    control,
    errors,
    register,
    handleSubmit,
    clearErrors,
    setValue,
  };
  return {
    storesOptions,
    isLoading,
    isSuccess,
    isError,
    submitted,
    mapedForm,
    formFunction,
    disputeFlowView,
    reasons,
    stages,
    globalFlowState,
    setSubmitted,
  };
};
export default useDisputeflow;
