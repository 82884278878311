import { configs } from "configs";
import ApiService from "services/apiService";
import { apiConstants } from "../network";

export class PostFilteredDisputes {
    /**
     * Function to invoke to get filtered disputes
     */
    async invoke(payload: any) {
        const res = await ApiService.getInstance().get(apiConstants.FIND_FILTERED_DISPUTES(payload), { baseURL: configs.BASE_URL as string });

        if (res.data.count || res.data.count === 0) {
            return res;
        } else {
            let newError = new Error()
            newError = { ...newError }
            throw newError
        }
    }
}