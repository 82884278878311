import { Box, Tooltip } from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.scss";
import { selectType } from "./helper.sidebar";
import Search from "components/search/Search";
import { useForm } from "react-hook-form";
import { useUpdateNode } from "hooks";
import SingleBox from "./SingleBox";
import ConditionForm from "./ConditionForm";
import { ActionOrConditionProps } from "../types";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { BackIcon } from "./flowIcons";

// Defining a functional component with props of type ActionOrConditionProps
const ActionOrCondition: React.FC<ActionOrConditionProps> = ({
  currentNode,
  setCurrentNode,
  openSideBar,
  setOpenSideBar,
}) => {
  const { options } = openSideBar; // Extracting options from openSideBar prop
  const { globalFlowState } = useSelector((state: any) => state?.disputeFlow); // Getting globalFlowState from the redux store
  const { handleUpdateNode, handleUpdatFormSubmit } = useUpdateNode(); // Using custom hook to get update functions
  const resultArray = Object.entries(options).map(([name, label]) => ({
    name,
    label,
  })); // Transforming options into an array of objects with name and label properties
  const [institutions, setInstitutions] = useState(resultArray); // Setting initial state for institutions

  // Function to filter institutions based on search input
  const onSearch = (value: string) => {
    setInstitutions(
      resultArray.filter(({ label }: any) =>
        label.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  // Function to handle adding an account
  const onAddAccountClick = (item: any, label: any) => {
    handleUpdateNode(currentNode, item, openSideBar, label); // Updating the node
    setCurrentNode({}); // Resetting current node
    setOpenSideBar(undefined); // Closing the sidebar
  };

  // Initializing the form with default values
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      dispute_amount: "",
    },
  });

  // Function to handle form submission
  const onSubmit = (data: any) => {
    const { name } = data;
    if (!isNaN(parseFloat(name)) && isFinite(name)) {
      Object.assign(data, {
        name,
      });
    } else {
      Object.assign(data, {
        name: dayjs(name).format("YYYY-MM-DD"), // Formatting date if name is not a number
      });
    }
    handleUpdatFormSubmit(data, currentNode, openSideBar); // Submitting form data
    setCurrentNode({}); // Resetting current node
    setOpenSideBar(undefined); // Closing the sidebar
  };

  return (
    <Box
      bgcolor={`white`}
      borderRadius={"10px"}
      border={"1px solid #EEF2F7"}
      width={"346px"}
      height={"100%"}
      padding={0.9}
      className={styles.ActionOrConditionWrapper}
      sx={{
        maxHeight: globalFlowState?.isExpand
          ? "calc(100vh - 265px)"
          : "calc(100vh - 328px)",
        minHeight: globalFlowState?.isExpand
          ? "calc(100vh - 246px)"
          : "calc(100vh - 310px)",
      }}
    >
      {/* Sidebar Header */}
      <Box>
        <Box display={`flex`} justifyContent={`space-between`} alignItems={`center`}>
          <Box >
            <p className={styles.sidebarLabel}>{openSideBar.name}</p>
            <p className={styles.sidebarSubLabel}>Set the required condition</p>
          </Box>
         <Tooltip placement="top" title="Back to previous step" arrow>
         <Box onClick={() =>  setOpenSideBar(undefined)} sx={{
            display: `flex`,
            justifyContent: `flex-end`,
            cursor: `pointer`,
            border:"1px solid #ccc",
            px: 1,
            borderRadius: 1,
            mr: 2,
          }}>
            Back  <BackIcon /> 
          </Box>
         </Tooltip>
        </Box>
        <Box className={styles.sidebarSubLabelBorder}></Box>
      </Box>

      {openSideBar.type === selectType.DROPSELECT ? (
        // Condition Form for drop select type
        <Box className={styles.institutionsForSelect}>
          <Box className={styles.institutionsList}>
            <ConditionForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              control={control}
              errors={errors}
              resultArray={resultArray}
              openSideBar={openSideBar}
              register={register}
            />
          </Box>
        </Box>
      ) : (
        // List of institutions for other types
        <Box className={styles.institutions}>
          <Search
            type="text"
            placeholder="Search"
            onSearch={onSearch}
            onType={onSearch}
          />
          <Box
            className={styles.institutionsList}
            style={{
              minHeight: globalFlowState?.isExpand
                ? "calc(100vh - 398px)"
                : "calc(100vh - 450px)",
              maxHeight: globalFlowState?.isExpand
                ? "calc(100vh - 386px)"
                : "calc(100vh - 450px)",
              overflowY: "auto",
            }}
          >
            {institutions.map(({ name, label }: { name: string | any; label: string | any }, index: number) => (
              <SingleBox
                key={index}
                name={name}
                onClick={() => onAddAccountClick(name, label)}
                label={label}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ActionOrCondition;
