/**
 * Custom hook that fetches all find active shop from the Redux store
 * and returns an array of select dropdown options for the find active shop.
 */

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getShopFindActiveStoresRequest } from 'redux/actions';

type IStoreOptions = {
  value: number;
  label: string;
};

const useFindActiveStoresList = () => {
  const dispatch = useDispatch();

  // find active store data
  const { data: findActiveStore } = useSelector(
    (state: any) => state?.storesShop?.shopFindActiveStores
  );
  // find active store options
  const [findActiveStoreOptions, setFindActiveStoreOptions] = useState<
    IStoreOptions[]
  >([]);

  useEffect(() => {
    const newArray: IStoreOptions[] =
      findActiveStore && findActiveStore.length > 0
        ? findActiveStore.map((item: any) => ({
          value: item?.id,
          label: item?.name,
        }))
        : [];
    setFindActiveStoreOptions(newArray);
  }, [findActiveStore]);

  useEffect(() => {
    dispatch(getShopFindActiveStoresRequest());
  }, []);

  return {
    findActiveStoreOptions,
    setFindActiveStoreOptions,
  };
};

export default useFindActiveStoresList;
