import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getManagerListRequest } from "redux/actions";

const useManagerList = () => {
  const { manageManager } = useSelector((state: any) => state.manageUser);
  const dispatch = useDispatch();
  const roleParams = 'MANAGER';
  useEffect(() => {
    dispatch(getManagerListRequest(roleParams));
  }, []);
  return { manageManager };
};

export default useManagerList;
