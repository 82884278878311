import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";


export class GetAllListOfManagers {

    async invoke(paylaod:any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGER_LIST(paylaod))
        return res;
    }
    async invokeUpdateManagerShopId(payload:any) {
        const res = await ApiService.getInstance().put(apiConstants.POST_UPDATE_MANAGER_BY_SHOPID, payload)
        return res;
    }
    
    async invokeUpdateManagerByDisputeId(payload:any) {
        const res = await ApiService.getInstance().put(apiConstants.POST_UPDATE_MANAGER_BY_DISPUTEID, payload)
        return res;
    }

    async invokeGetShopFindByManager(payload:any) {
        const res = await ApiService.getInstance().put(apiConstants.GET_SHOP_BY_MANAGER, payload)
        return res;
    }
    
    async invokeGetDisputeFindByManager(payload:any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_BY_MANAGER, payload)
        return res;
    }
    
    async invokeGetUserList() {
        const res = await ApiService.getInstance().get(apiConstants.GET_USER_LIST)
        return res;
    }
    
    async invokePostManageCreateUser(payload:any) {
        const res = await ApiService.getInstance().put(apiConstants.POST_CREATE_USER, payload)
        return res;
    }
    

}