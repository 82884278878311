import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
import { Provider } from "react-redux";
import store from "./redux/store";
import Auth0ProviderWithHistory from "./context/auth/auth0-provider-with-history";

import "./index.scss";
import IntercomCustomProvider from "./context/intercom/intercom-custom-provider";
import { ReactFlowProvider } from "reactflow";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <Provider store={store}>
        <IntercomCustomProvider>
          <ReactFlowProvider>
            <App />
          </ReactFlowProvider>
        </IntercomCustomProvider>
      </Provider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>
  // </React.StrictMode>
);
