import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface storesObj {
    "id": number,
    "externalId": string,
    "appId": string,
    "domain": string,
    "name": string,
    "email": string,
    "shopType": string,
    "scopes": string,
    "onboarded": boolean,
    "riskAccepted": boolean,
    "chargeActive": boolean,
    "uniqueId": string
}

interface ResProp {
    data: {
        count: number,
        storesResponses: storesObj[],
    },
    status:number
}


export function* getShopAllShopsRequest(action: any) {
    try {
        const res: ResProp = yield Api.getShopAllShops(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getShopAllShopsError());
        }else {
            yield put(actions.getShopAllShopsSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getShopAllShopsError());
    }
}

export function* getShopFindShopRequest(action: any) {
    try {
        const res: ResProp = yield Api.getShopFindShop(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getShopFindShopError());
        }else {
            yield put(actions.getShopFindShopSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getShopFindShopError());
    }
}
export function* getShopFindActiveStoresRequest(action: any) {
    try {
        const res: ResProp = yield Api.getShopFindActiveStores(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getShopFindActiveStoresError());
        }else {
            yield put(actions.getShopFindActiveStoresSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getShopFindActiveStoresError());
    }
}
export function* getShopStoreReportRequest(action: any) {
    try {
        const res: ResProp = yield Api.getShopStoreReport(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getShopStoreReportError());
        }else {
            yield put(actions.getShopStoreReportSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getShopStoreReportError());
    }
}

export function* postShopFilterShopsRequest(action: any) {
    try {
        const res: ResProp = yield Api.postShopFilterShops(action.payload);
        yield put(actions.postShopFilterShopsSuccess(res.data));
    } catch (e) {
        yield put(actions.postShopFilterShopsError());
    }
}

export function* postShopsSaveAddonRequest(action: any) {
    try {
        const res: ResProp = yield Api.postShopsSaveAddon(action.payload);
        yield put(actions.postShopsSaveAddonSuccess(res.data));
    } catch (e) {
        yield put(actions.postShopsSaveAddonError());
    }
}

export function* getShopFindShopAddonRequest(action: any) {
    try {
        const res: ResProp = yield Api.getShopFindShopAddon(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getShopFindShopAddonError());
        }else {
            yield put(actions.getShopFindShopAddonSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getShopFindShopAddonError());
    }
}
export function* getPreSignedUrlRequest(action: any) {
    try {
        const res: ResProp = yield Api.getPreSignedUrl(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getPreSignedUrlError());
        }else {
            yield put(actions.getPreSignedUrlSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getPreSignedUrlError());
    }
}

export function* updateShopCollaboratorAccessRequest(action: any) {
    try {
        const res: ResProp = yield Api.updateShopCollaboratorAccess(action.payload);
        yield put(actions.updateShopCollaboratorAccessSuccess(res.data));
    } catch (e) {
        yield put(actions.updateShopCollaboratorAccessError());
    }
}
export function* postShopSaveCompanyAddonRequest(action: any) {
    try {
        const res: ResProp = yield Api.postShopSaveCompanyAddon(action.payload);
        yield put(actions.postShopSaveCompanyAddonSuccess(res.data));
    } catch (e) {
        yield put(actions.postShopSaveCompanyAddonError());
    }
}

export function* postShopSaveCompanyPoliciesAddonRequest(action: any) {
    try {
        const res: ResProp = yield Api.postShopSaveCompanyPoliciesAddon(action.payload);
        yield put(actions.postShopSaveCompanyPoliciesAddonSuccess(res.data));
    } catch (e) {
        yield put(actions.postShopSaveCompanyPoliciesAddonError());
    }
}