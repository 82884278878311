import { Templates } from 'components';
import styles from "./style.module.scss";

const DisputeTemplates = ({ hideHeader }: { hideHeader?: boolean }) => {
  return (
    <>
      <div
        className={styles.disputesWrapper}
        style={
          hideHeader ? { paddingLeft: 0, paddingRight: 0, minHeight: 0 } : {}
        }
      >
        <>
          <Templates {...{ hideHeader }} />
        </>
      </div>
    </>
  );
};

export default DisputeTemplates;
