import { Box, Button } from "@mui/material";
import { ToastContainer } from "react-toastify";
import styles from "./style.module.scss";
import Loading from "components/loading/loading";
import { useDisputeTemplate } from "hooks";
import TiptapEditor from "../TiptapEditor";
import SelectUI from "components/disputeFlow/RelatedCom/Select";
import RenderInput from "components/disputeFlow/RelatedCom/RenderInput";
import { Textarea } from "components/ui";

const CreateDisputeTemplate = () => {
  const {
    editorData,
    templateId,
    control,
    errors,
    isLoading,
    disputeTemplateView,
    mapedForm,
    handleSubmit,
    register,
    setEditorContent,
    onSubmit,
    clearErrors,
    setValue,
  } = useDisputeTemplate();
  return (
    <Box className={styles.disputesWrapper}>
      {disputeTemplateView?.isLoading ? (
        <Box sx={{ background: "#fff", height: "100vh" }}>
          <Loading />
        </Box>
      ) : (
        <>
          <ToastContainer />
          <Box className={styles.name}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box className={styles.header}>
                <p>
                  {templateId &&
                  disputeTemplateView?.isSuccess &&
                  disputeTemplateView?.data?.name
                    ? `${disputeTemplateView?.data?.name} template edit`
                    : "Create new dispute template"}
                </p>
                <Button variant="contained" type="submit">
                  Save
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                flexWrap="wrap"
                gap="10px"
                zIndex={999}
              >
                {mapedForm.map(({ name, label, options, rules, type }) =>
                  type === "text" ? (
                    <RenderInput
                      key={label}
                      {...{
                        rules,
                        errors,
                        name,
                        styles,
                        register,
                        label,
                        placeholder: "Write the template name",
                      }}
                    />
                  ) : type === "textArea" ? (
                    <div className={styles.textAreaWrapper}>
                      <label>Summary</label>
                      <Textarea
                        minRows={3}
                        style={{ height: "80px", overflowY: "scroll" }}
                        {...register("summaryText")}
                        aria-label="maximum height"
                        placeholder="Type here..."
                      />
                    </div>
                  ) : (
                    <SelectUI
                      key={label}
                      {...{
                        styles,
                        control,
                        name,
                        rules,
                        options,
                        label,
                        errors,
                        clearErrors,
                        setValue,
                      }}
                    />
                  )
                )}
              </Box>
            </form>
          </Box>
          <Box className={styles.templateCreatorWrapper}>
            <TiptapEditor {...{ setEditorContent, editorData, setValue }} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreateDisputeTemplate;
