import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getShopStoreReportRequest } from "redux/actions";


const useStoreReportList = ()=>{
  const dispatch = useDispatch()
  const { data: shopStoreReport,isLoading,isSuccess,isError } = useSelector(
    (state: any) => state?.storesShop?.shopStoreReport
  );

  useEffect(()=>{
    dispatch(getShopStoreReportRequest())
  },[])


  return {
    shopStoreReport,
    isLoading,
    isSuccess,
    isError
  }


}


export default useStoreReportList