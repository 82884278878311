export const mockUsersData = {
    count: 0,
    userData: [
        // {
        //     userName: "Mosh Kia",
        //     email: "moshkia23@gmail.com",
        //     accountsManaged: 3,
        // },
        // {
        //     userName: "Adesh Neisal",
        //     email: "kjaba223@gmail.com",
        //     accountsManaged: 5,
        // },
        // {
        //     userName: "Aisha Ambani",
        //     email: "aisha131@gmail.com",
        //     accountsManaged: 56,
        // },
        // {
        //     userName: "Kavisb Reskh",
        //     email: "kasjkb23@gmail.com",
        //     accountsManaged: 22,
        // },
        // {
        //     userName: "Alayna Gonzales",
        //     email: "alayana1321@gmail.com",
        //     accountsManaged: 8,
        // },
        // {
        //     userName: "Jaylah Ross",
        //     email: "jaylash1231@gmail.com",
        //     accountsManaged: 12,
        // },
        // {
        //     userName: "Madalynn Blanchard",
        //     email: "eqeq531@gmail.com",
        //     accountsManaged: 61,
        // },
        // {
        //     userName: "Karsyn Daniels",
        //     email: "hhhnhg564@gmail.com",
        //     accountsManaged: 42,
        // },
        // {
        //     userName: "Zoya George",
        //     email: "vdfd3215@gmail.com",
        //     accountsManaged: 2,
        // },
        // {
        //     userName: "Viviana Cochran",
        //     email: "iyiy8131@gmail.com",
        //     accountsManaged: 5,
        // },
    ]
}