import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface disputeTemplateRes {
    data: {
        "id": number,
        "content": string,
        "type": string,
        "description": string,
        "enabled": boolean,
        "category": string,
        "paymentGateway": string
    },
    status:number
}

export function* postDisputeTemplateRequest(action: any) {
    try {
        const res: disputeTemplateRes = yield Api.postDisputeTemplate(action.payload);
        yield put(actions.postDisputeTemplateSuccess(res.data));
    } catch (e) {
        yield put(actions.postDisputeTemplateError());
    }
}

export function* getDisputeTemplateListRequest(action: any) {
    try {
        const res: disputeTemplateRes = yield Api.getDisputeTemplateList(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeTemplateListError());
        }else{
            yield put(actions.getDisputeTemplateListSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeTemplateListError());
    }
}

export function* getDisputeTemplateFullListRequest(action: any) {
    try {
        const res: disputeTemplateRes = yield Api.getDisputeTemplateFullList(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeTemplateFullListError());
        }else{
            yield put(actions.getDisputeTemplateFullListSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeTemplateFullListError());
    }
}

export function* getDisputeTemplateReasonListRequest(action: any) {
    try {
        const res: disputeTemplateRes = yield Api.getDisputeTemplateReasonList(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeTemplateReasonListError());
        }else{
            yield put(actions.getDisputeTemplateReasonListSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeTemplateReasonListError());
    }
}

export function* getDisputeTemplateActionListRequest(action: any) {
    try {
        const res: disputeTemplateRes = yield Api.getDisputeTemplateActionList(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeTemplateActionListError());
        }else{
            yield put(actions.getDisputeTemplateActionListSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeTemplateActionListError());
    }
}

export function* getDisputeTemplateStageListRequest(action: any) {
    try {
        const res: disputeTemplateRes = yield Api.getDisputeTemplateStageList(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeTemplateStageListError());
        }else{
            yield put(actions.getDisputeTemplateStageListSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeTemplateStageListError());
    }
}

export function* getDisputeTemplateFindByTypeRequest(action: any) {
    try {
        const res: disputeTemplateRes = yield Api.getDisputeTemplateFindByType(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeTemplateFindByTypeError());
        }else{
            yield put(actions.getDisputeTemplateFindByTypeSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeTemplateFindByTypeError());
    }
}

export function* getDisputeTemplateFindByStoreRequest(action: any) {
    try {
        const res: disputeTemplateRes = yield Api.getDisputeTemplateFindByStore(action.payload);
        if(res.status === (500 || 401)){
            yield put(actions.getDisputeTemplateFindByStoreError());
        }else{
            yield put(actions.getDisputeTemplateFindByStoreSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeTemplateFindByStoreError());
    }
}

export function* postDisputeTemplateFilterTemplatesRequest(action: any) {
    try {
        const res: disputeTemplateRes = yield Api.postDisputeTemplateFilterTemplates(action.payload);
        yield put(actions.postDisputeTemplateFilterTemplatesSuccess(res.data));
    } catch (e) {
        yield put(actions.postDisputeTemplateFilterTemplatesError());
    }
}

export function* getDisputeTemplateViewRequest(action: any) {
    try {
        const res: disputeTemplateRes = yield Api.getDisputeTemplateView(action.payload);
        if(res.status === (500 || 401)){
            yield put(actions.getDisputeTemplateViewError());
        }else{
            yield put(actions.getDisputeTemplateViewSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeTemplateViewError());
    }
}
export function* getDisputeTemplatePreviewPdfRequest(action: any) {
    try {
        const res: disputeTemplateRes = yield Api.getDisputeTemplatepreviewPdf(action.payload);
        if(res.status === (500 || 401)){
            yield put(actions.getDisputeTemplatePreviewPdfError());
        }else{
            yield put(actions.getDisputeTemplatePreviewPdfSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeTemplatePreviewPdfError());
    }
}
export function* getDisputeTemplateFetchContentRequest(action: any) {
    try {
        const res: disputeTemplateRes = yield Api.getDisputeTemplateFetchContent(action.payload);
        if(res.status === (500 || 401)){
            yield put(actions.getDisputeTemplateFetchContentError());
        }else{
            yield put(actions.getDisputeTemplateFetchContentSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeTemplateFetchContentError());
    }
}