import { Button } from "@mui/material";
import styles from "./style.module.scss";
import { BreadcumbItem } from "components/ui";
import { useNavigate } from "react-router-dom";
import { AddUserIcon } from "components/disputeFlow/NodeHelpers";
import { AssignedManager } from 'components';

// const data: any = mockUsersData;

const ManageStoreOrDispute = () => {
  const navigate = useNavigate();

  function handleBreadcrumb(page: string) {
    navigate(page);
  }
  const breadCumbs = [
    {
      title: "Home",
      className: "",
      off: false,
      onClick: () => handleBreadcrumb("/home"),
    },
    {
      title: "Users",
      className: "",
      off: true,
      onClick: () => {},
    },
  ];
  return (
    <div className={styles.disputesWrapper}>
      <div className={styles.nav}>
        <h3>Users</h3>
        <div className={styles.navPath}>
          {breadCumbs.map((item, key) => (
            <BreadcumbItem {...item} {...{ key }} />
          ))}
        </div>
      </div>
      <div className={styles.note}>
        <p>Users that can manage the disputes.</p>
      </div>

      <div className={styles.usersSearch}>
        <Button variant="outlined">
          <AddUserIcon />
          <p>Add User</p>
        </Button>
      </div>
      <div>
        <AssignedManager/>
      </div>
    </div>
  );
};

export default ManageStoreOrDispute;
