import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Box, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import React from "react";
import { selectUITypes } from "../types";
import { getDisputeTemplateActionListRequest } from "redux/actions";
import { ErrorMessage } from "../NodeHelpers";

const SelectUI: React.FC<selectUITypes> = ({
  name,
  styles,
  control,
  label,
  placeholder,
  options,
  sideBar,
  errors,
  date,
  rules,
  setValue,
}) => {
  const dispatch = useDispatch();
  return (
  <Tooltip title={options?.length ? "" : "No data found"}>
      <Box className={styles.inputbox} zIndex={9999}>
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        rules={
          rules
            ? rules
            : {
                required: {
                  value: true,
                  message: "This is required",
                },
              }
        }
        render={({ field }) => {
          return (
            <ReactSelect
              className={styles.dropDown}
              placeholder={placeholder ? placeholder : "Select store"}
              options={options ? options : []}
              {...field}
              isClearable
              isDisabled={options?.length === 0 }
              onChange={(e) => {
                if (name === "type") {
                  field.onChange(e);
                  dispatch(getDisputeTemplateActionListRequest(e?.value));
                  setValue("action", "");
                } else {
                  field.onChange(e);
                }
              }}
              value={field.value}
              styles={
                sideBar
                  ? {
                      control: (baseStyles) => ({
                        ...baseStyles,
                        width: sideBar && date ? "170px" : "220px",
                        marginTop: "10px",
                      }),
                    }
                  : {}
              }
            />
          );
        }}
      />
      <ErrorMessage name={name} errors={errors} />
    </Box>
  </Tooltip>
  );
};
export default SelectUI;
