import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDisputeTemplatePreviewPdfRequest } from "redux/actions";

export interface RouteTemplateParams {
  templateId: string;
  [key: string]: string | undefined;
}
const useDisputeTemplatePreview = (templateId: string) => {
  const dispatch = useDispatch();
  const { data, isSuccess, isError } = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplatepreviewPdf
  );
  // const { templateId } = useParams<RouteTemplateParams>();
  useEffect(() => {
    if (templateId) {
      dispatch(getDisputeTemplatePreviewPdfRequest(templateId));
    }
  }, [templateId]);
  return { data, isSuccess, isError };
};
export default useDisputeTemplatePreview;
