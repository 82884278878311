/**
 * Custom hook that fetches all shops from the Redux store
 * and returns an array of select dropdown options for the shops.
 */
import { selectDropDownOption } from "components/disputeFlow/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getShopAllShopsRequest } from "redux/actions";

const useStoreList = () => {
  //initialize the storesOptions
  const [storesOptions, setStoresOptions] = useState<selectDropDownOption[]>(
    []
  );
  const shopAllShops = useSelector(
    (state: any) => state.storesShop.shopAllShops
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const payload = {
      enabled: true,
    };
    dispatch(getShopAllShopsRequest(payload));
  }, [dispatch]);

  useEffect(() => {
    if (shopAllShops?.isSuccess && shopAllShops?.data?.count > 0) {
      let storeOption: any = [];
      shopAllShops?.data?.shopResponseList?.forEach((store: any) => {
        let storeObj = {
          value: store?.id,
          label: store?.name,
        };
        storeOption.push(storeObj);
      });
      setStoresOptions(storeOption);
    }
  }, [shopAllShops?.data?.shopResponseList]);

  return { storesOptions };
};

export default useStoreList;
