import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { Box, Modal as SimpleModal } from "@mui/material";
import Fade from "@mui/material/Fade";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  maxWidth: 800,
  width: "100%",
  borderRadius: "8px",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight:"calc(100vh - 100px)",
  overflowY:"auto",
  p: 4,
};

export default function Modal({ open, setOpen, children, onClose }: any) {
  const handleClose = () => setOpen(false);

  return (
    <SimpleModal
      style={{ zIndex: 10000 }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose ? onClose : handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </SimpleModal>
  );
}
