import { useState } from "react";
import { Search } from "components";
import ApplyFilter from "./applyFilter";
import styles from "./style.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BranchIcon,
} from "components/disputeFlow/NodeHelpers/flowIcons";
import { Button } from "components/ui";

const FlowFilter = ({ handleSearch }: any) => {
  const { storeId } = useParams();
  let location = useLocation();
  const toBeSelected = location?.pathname.includes("store-details");
  const navigate = useNavigate();

  function handleRedirect() {
    if (toBeSelected && storeId) {
      navigate(`/flows/create-new/${storeId}`);
    } else {
      navigate("/flows/create-new");
    }
  }
  return (
    <div className={styles.search}>
      <div className={styles.searchTop}>
        <div className={styles.searchDiv}>
          <Search
            onSearch={(query) => handleSearch(query)}
            onType={(query) => handleSearch(query)}
            type={""}
            placeholder={"Search here ..."}
            className={styles.searchInput}
          />
        </div>
        <div className={styles.createNew}>
          <Button
            btnTitle={"Click to create new flow"}
            startIcon={<BranchIcon />}
            btnLabel={"Create New"}
            className={styles.git}
            onClick={handleRedirect}
          />
        </div>
      </div>
    </div>
  );
};

export default FlowFilter;
