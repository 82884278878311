import { Box, IconButton } from "@mui/material";
import {
  Collapse,
  FullScreenIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  ToolTipWrapper,
} from "../NodeHelpers";
import styles from "./styles.module.scss";
import { useZoomCanvas } from "hooks";
const ControlButtons = ({ handleFullScreen, isExpand }: any) => {
  const { handleIncrementAndDecrementZoom, currentZoom } = useZoomCanvas();
  return (
    <Box display={`flex`} gap={1}>
      <ToolTipWrapper
        title={isExpand ? "Click to collapse" : "Click to expand"}
        placement={"top"}
        component={
          <IconButton
            sx={{
              borderRadius: "8px",
              bgcolor: "#fff",
              border: "1px solid #D0D5DD",
              padding: "14px",
            }}
            onClick={handleFullScreen}
            aria-label="delete"
          >
            {isExpand ? <Collapse /> : <FullScreenIcon />}
          </IconButton>
        }
      />

      <Box
        sx={{
          borderRadius: "8px",
          bgcolor: "#fff",
          border: "1px solid #D0D5DD",
        }}
        padding={1}
        display={`flex`}
        justifyContent={`space-between`}
        alignItems={`center`}
      >
        <IconButton
          onClick={() => handleIncrementAndDecrementZoom("Decrement")}
          aria-label="delete"
        >
          <MinusCircleIcon />
        </IconButton>
        <p className={styles.zoomLabelShowing}>
          {(currentZoom * 100)?.toFixed(0)}%
        </p>
        <IconButton
          onClick={() => handleIncrementAndDecrementZoom("Increment")}
          aria-label="delete"
        >
          <PlusCircleIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ControlButtons;
