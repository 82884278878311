import React from 'react';
import {IntercomProvider} from "react-use-intercom";
interface Props {
  children?: React.ReactNode
}

const IntercomCustomProvider = ({ children }: Props) => {
  const INTERCOM_APP_ID = `${process.env.REACT_APP_INTERCOM_APP_ID}`;
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      {children}
    </IntercomProvider>
  );
};

export default IntercomCustomProvider;