import useManagerList from "hooks/manageUser/useManagerList";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { removeItem } from "utils/localStorage";

const useDisputeList = () => {

    type disputeList = {
        data: any;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
    };
    const DisputeListColumnNames: string[] = [
        "Dispute ID",
        "Store Name",
        "Date",
        "Amount",
        "Reason",
        "Response Due",
        "Decision Date",
        "Status",
        "Carrier",
        "Last Mile Carrier",
        "Response Status",
        "Manager"
    ]
    const { isLoading, isSuccess, isError, data }: disputeList = useSelector(
        (state: any) => state.disputesList.disputesList || {}
    );
    const { manageManager } = useManagerList();
    useEffect(() => {
        return () => {
            removeItem("filteringDispute");
            removeItem("filterPayload");
            removeItem("disputePage");
            removeItem("disputePageLength");
        };
    }, []);

    const handleDisputeStatus = (disputeData: any) => {
        if (
            disputeData?.status &&
            disputeData?.status?.toLowerCase() === "resolved"
        ) {
            if (disputeData?.disputeOutcome === "RESOLVED_SELLER_FAVOUR") {
                return "Won";
            } else if (disputeData?.disputeOutcome === "RESOLVED_BUYER_FAVOUR") {
                return "Lost";
            } else {
                return "Resolved";
            }
        } else {
            return disputeData?.status && disputeData?.status?.split("_").join(" ");
        }
    };


    return {
        isLoading, isSuccess, isError, data, manageManager, DisputeListColumnNames, handleDisputeStatus
    }
}
export default useDisputeList;
