import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";


export class GetReportingList {
    async invoke(paylaod: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_REPORT_LIST(paylaod))
        return res;
    }
    async invokeByStores(paylaod: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_BY_STORES_REPORT_LIST(paylaod))
        return res;
    }
    async invokeByPsp(paylaod: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_BY_PSP_REPORT_LIST(paylaod))
        return res;
    }
    async invokeByReasons(paylaod: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_BY_REASONS_REPORT_LIST(paylaod))
        return res;
    }
    async invokeByMonthly(paylaod: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_BY_MONTHLY_REPORT_LIST(paylaod))
        return res;
    }
}