import { Button as MuiButton, Tooltip } from '@mui/material';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';
type IButtonType = {
  variant?: any;
  placement?: any;
  btnTitle?: string;
  btnLabel?: string;
  noStyle?: boolean;
  endIcon?: any;
  startIcon?: any;
  className?: any;
  size?: any;
  href?: any;
  onClick?: any;
  isDisable?: any;
  rest?: {};
};
export default function Button({
  variant,
  placement,
  btnTitle,
  btnLabel,
  noStyle,
  endIcon,
  startIcon,
  className,
  size,
  href,
  onClick,
  isDisable,
  ...rest
}: IButtonType) {
  return (
    <Tooltip
      title={btnTitle ?? 'Click'}
      arrow
      placement={placement ? placement : 'top'}
    >
      {href ? (
        <Link
          className={className ? className : noStyle ? '' : styles.linkButton}
          to={href}
        >
          {startIcon ?? null}
          <span>{btnLabel}</span>
          {endIcon ?? null}
        </Link>
      ) : (
        <MuiButton
          variant={variant ? variant : 'contained'}
          startIcon={startIcon}
          endIcon={endIcon}
          onClick={onClick}
          disabled={isDisable ?? false}
          className={
            className ? className : noStyle ? '' : styles.saveButtonStyle
          }
          style={{
            background: isDisable ? 'rgba(0, 0, 0, 0.12)' : '#1B4AA2',
            fontWeight: isDisable ? 'bold' : '',
            color: isDisable ? 'rgba(0, 0, 0, 0.26)' : '',
            cursor: isDisable ? 'not-allowed' : 'pointer',
          }}
          size={size ? size : 'medium'}
          {...rest}
        >
          {btnLabel}
        </MuiButton>
      )}
    </Tooltip>
  );
}
