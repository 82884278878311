import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";


export class GetOpenDisputes {
  /**
   * Function to invoke to get open disputes
   */
  async invoke() {
    
    const res = await ApiService.getInstance().get(apiConstants.DASHBOARD_OPEN_DISPUTES);

    return res;
  }

  async invokeByAccount(payload: string) {
    
    const res = await ApiService.getInstance().get(apiConstants.DASHBOARD_OPEN_DISPUTES_BY_ACCOUNT(payload));

    return res;
  }

}