import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface DisputeObj {
    id: string;
    createTime: string;
    updateTime: string;
    status: string;
    reason: string;
    disputeState: string;
    disputeLifeCycleStage: string;
    order: string;
    storeOrderId: string;
    shopName: string;
    shopType: string;
    sellerMerchantId: string;
    disputeAmountValue: number;
    disputeAmountCurrencyCode: string;
    sellerResponseDueDate: string;
    buyerResponseDueDate: string;
    sellerTransactionId: string;
    paypalId: string;
}

interface ResProp {
    data: {
        count: number,
        disputeResponseList: DisputeObj[],
    },
    status: number
}


// Action handlers for awaiting disputes
export function* getAwaitingDisputesRequest(action: any) {
    try {
        const res: ResProp = yield Api.getAwaitingDisputes(action.payload);
        if (res.status === 500 || res.status === 401) {
            yield put(actions.getAwaitingDisputesError());
        } else {
            yield put(actions.getAwaitingDisputesSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getAwaitingDisputesError());
    }
}

export function* getAwaitingDisputesTotalBilledAmountRequest(action: any) {
    try {
        const res: ResProp = yield Api.getAwaitingDisputesTotalBilledAmount(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getAwaitingDisputesTotalBilledAmountError());
        } else {
            yield put(actions.getAwaitingDisputesTotalBilledAmountSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getAwaitingDisputesTotalBilledAmountError());
    }
}

export function* getAwaitingDisputesTotalAmountRequest(action: any) {
    try {
        const res: ResProp = yield Api.getAwaitingDisputesTotalAmount(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getAwaitingDisputesTotalAmountError());
        } else {
            yield put(actions.getAwaitingDisputesTotalAmountSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getAwaitingDisputesTotalAmountError());
    }
}