import { useState, useEffect } from 'react';
import cn from 'classnames';

import { svgIcons } from '../../elements';
import { PaginationLeftArrow, PaginationRightArrow } from 'elements/svgViewer';
import styles from './style.module.scss';

interface PaginationProps {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export default function Pagination(props: PaginationProps) {
  const { page, totalPages, onPageChange } = props;

  const [currentPage, setCurrentPage] = useState<number>(page);
  const [pages, setPages] = useState<(number | '...')[]>([]);

  const isPrevAvailable = currentPage > 1;
  const isNextAvailable = currentPage < totalPages;

  const processSinglePage = (pages: number[]) => {
    if (totalPages <= 6) {
      setPages(pages);
      return;
    }

    const pagesArr = pages;
    const middle = Math.floor(pagesArr.length / 2);
    const left = pagesArr.slice(0, middle);
    const right = pagesArr.slice(middle);

    if (pages.includes(currentPage)) {
      setPages([...left, '...', ...right]);
      return;
    }

    setPages([...left, currentPage, ...right]);
  };
  const processPages = () => {
    const pages = [];

    if (totalPages <= 5) for (let i = 1; i <= totalPages; i++) pages.push(i);

    if (totalPages > 5) {
      for (let i = 1; i <= 3; i++) pages.push(i);
      pages.push(totalPages - 2);
      pages.push(totalPages - 1);
      pages.push(totalPages);
    }

    processSinglePage(pages);
  };

  const handlePageChange = (page: number | '...') => {
    if (page === '...') return;

    processPages();
    setCurrentPage(page);
    onPageChange(page);
  };

  const handlePrev = () => isPrevAvailable && handlePageChange(currentPage - 1);
  const handleNext = () => isNextAvailable && handlePageChange(currentPage + 1);

  useEffect(() => {
    processPages();
  }, [currentPage, totalPages]);

  return (
    <div className={styles.wrapper}>
      <div
        className={cn(styles.left, { [styles.unavailable]: !isPrevAvailable })}
        onClick={handlePrev}
      >
        <PaginationLeftArrow />

        <p>Previous</p>
      </div>
      <div className={styles.pages}>
        {pages.map(page => (
          <button
            key={page}
            className={cn(
              styles.page,
              {
                [styles.active]: currentPage === page,
              },
              { [styles.unavailable]: page === '...' }
            )}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
      </div>
      <div
        className={cn(styles.right, { [styles.unavailable]: !isNextAvailable })}
        onClick={handleNext}
      >
        <p>Next</p>
        <PaginationRightArrow />
      </div>
    </div>
  );
}
