import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getDisputeTemplateFullListRequest } from "redux/actions";

const useDisputeTemplateFullList = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isError, data } = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplateFullList
  );

  const [listPayload, setListPayload] = useState<any>();
  const { storeId } = useParams();
  let location = useLocation();
  const toBeSelected = location?.pathname.includes("store-details");
  const {
    isLoading: singleIsLoading,
    isSuccess: singleIsSuccess,
    isError: singleError,
    data: singleStoreTemplate,
  } = useSelector(
    (state: any) => state.disputeTemplate.disputeTemplateFindByStore
  );
  useEffect(() => {
    const payload1 = {
      enabled: true,
      page: 1,
      pageLength: "",
    };
    dispatch(
      getDisputeTemplateFullListRequest(payload1)
    );
  }, []);
  return {
    data,
    isLoading,
    isSuccess,
    isError,
    singleIsLoading,
    singleIsSuccess,
    singleError,
    singleStoreTemplate,
    toBeSelected,
    storeId,
    listPayload,
    setListPayload,

  };
};
export default useDisputeTemplateFullList;

