import { useDispatch } from "react-redux";
import {postDisputeSubmittedRequest } from "redux/actions";

const useSubmittedDetails = () => {
  const dispatch = useDispatch();
  const handleSubmittedDetails = (data: any) => {
    dispatch(postDisputeSubmittedRequest(data));
  };
  return { handleSubmittedDetails };
};
export default useSubmittedDetails;


