import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";


export class GetShopAllShops {
    /**
     * Function to invoke to stores shop
     */
    async invoke(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_SHOP_ALL_SHOPS(payload))

        return res;
    }

    async invokeShopFindShop(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_SHOP_FIND_SHOP(payload))

        return res;
    }

    async invokeShopFindActiveStores(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_FIND_ACTIVE_STORES(payload))

        return res;
    }
    
    async invokeShopStoreReport(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_SHOP_STORE_REPORT(payload))

        return res;
    }

    async invokeShopFilterShops(payload: any) {
        const {page, pageLength, chargeActive, searchString} = payload;
        const paramPayload = {
            page,
            pageLength
        }
        const apiPayload = {
            chargeActive,
            searchString
        }

        const res = await ApiService.getInstance().post(apiConstants.POST_SHOP_FILTER_SHOPS(paramPayload), apiPayload);

        return res;
    }

    async invokeShopsSaveAddon(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_SHOP_SAVE_ADD_ON, payload);

        return res;
    }

    async invokeShopFindShopAddon(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_SHOP_FIND_SHOP_ADD_ON(payload))

        return res;
    }

    async invokeGetPreSignedUrl(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_PRE_SIGNED_URL(payload))

        return res;
    }

    async invokeShopSaveCompanyAddon(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_SHOP_SAVE_COMPANY_ADD_ON, payload);

        return res;
    }
    async invokeUpdateShopCollaboratorAccess(payload: any) {
        const res = await ApiService.getInstance().put(apiConstants.UPDATE_SHOP_COLLABORATOR_ACCESS(payload));
        return res;
    }

    async invokeShopSaveCompanyPoliciesAddon(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON, payload);

        return res;
    }

}