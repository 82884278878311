import { configs } from 'configs';
import ApiService from 'services/apiService';
import { apiConstants } from '../network';

export class PostManageDispute {
    /**
     * Function to invoke to manage dispute
     */
    async invokeSendMessage(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_MANAGE_DISPUTE_SEND_MESSAGE, payload, { baseURL: configs.BASE_URL as string });

        return res;
    }

    async invokeCarrierOptions() {

        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_CARRIER_OPTIONS);

        return res;
    }

    async invokeEvidenceTypeOptions() {

        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS);

        return res;
    }

    async invokeAppealDispute(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_MANAGE_DISPUTE_APPEAL_DISPUTE, payload, { baseURL: configs.BASE_URL as string });

        return res;
    }

    async invokeManageManuallyDispute(payload: any) {
        const fileUpload = true;
        const res = await ApiService.getInstance(fileUpload).post(apiConstants.POST_MANAGE_MANUALLY_DISPUTE, payload, { baseURL: configs.BASE_URL as string });

        return res;
    }

    async invokeAcknowledgeReturnItem(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM, payload, { baseURL: configs.BASE_URL as string });

        return res;
    }

    async invokeAcceptClaim(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_MANAGE_ACCEPT_CLAIM, payload, { baseURL: configs.BASE_URL as string });

        return res;
    }

    async invokeHateoasLink(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_HATEOAS_LINK(payload));

        return res;
    }
}