import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
import { configs } from '../../../configs/index';
export class PostBillingReportsList {
    /**
     * Function to invoke to get account list
     */
    async invoke(payload: any) {
        const res = await ApiService.getInstance().post(apiConstants.POST_DISPUTE_BILLING(payload.pagination), payload.submitForm, { baseURL: configs.BASE_URL as string });
        return res;
    }
    async invokeApproveDisputes(payload: any) {
        const res = await ApiService.getInstance().post(apiConstants.POST_APPROVED_DISPUTES(payload));
        return res;
    }
    async invokeConfirmBilling(payload: any) {
        const res = await ApiService.getInstance().post(apiConstants.POST_CONFIRM_DISPUTE_BILLING(payload));
        return res;
    }
    async invokeNewBillingList(payload: any) {
        const res = await ApiService.getInstance().post(apiConstants.POST_NEW_BILLING_LIST(payload), payload.body, { baseURL: configs.BASE_URL as string });
        return res;
    }
    async invokeNewBillingReadyDisputeList(payload: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_NEW_BILLING_READY_DISPUTE_LIST(payload), { baseURL: configs.BASE_URL as string });
        return res;
    }

}
