import { Api } from 'services';
import * as actions from "../actions";
import { put } from "redux-saga/effects";

interface disputeFlowRes {
    data: any,
    status:number
}

export function* getManagerListRequest(action:any) {
    try {
        const res: disputeFlowRes = yield Api.getAllManagers(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getManagerListError());
        }else {
            yield put(actions.getManagerListSuccess(res.data));
        }

    } catch (e) {
    }
}
export function* postUpdateManagerByShopIdRequest(action:any) {
    try {
        const res: disputeFlowRes = yield Api.postUpdateManagerByShopId(action.payload);
        yield put(actions.postUpdateManagerByShopIdSuccess(res.data));
    } catch (e) {
        yield put(actions.postUpdateManagerByShopIdError());
    }
}

export function* postUpdateManagerByDisputeIdRequest(action:any) {
    try {
        const res: disputeFlowRes = yield Api.postUpdateManagerByDisputeId(action.payload);
        yield put(actions.postUpdateManagerByDisputeIdSuccess(res.data));
    } catch (e) {
        yield put(actions.postUpdateManagerByDisputeIdError());
    }
}


export function* getShopFindByManagerRequest(action:any) {
    try {
        const res: disputeFlowRes = yield Api.getShopFindByManager(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getShopFindByManagerError());
        }else {
            yield put(actions.getShopFindByManagerSuccess(res.data));
        }

    } catch (e) {
    }
}

export function* getDisputeFindByManagerRequest(action:any) {
    try {
        const res: disputeFlowRes = yield Api.getDisputeFindByManager(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeFindByManagerError());
        }else {
            yield put(actions.getDisputeFindByManagerSuccess(res.data));
        }

    } catch (e) {
    }
}


export function* getUserListRequest() {
    try {
        const res: disputeFlowRes = yield Api.getUserList();
        if (res.status === (500 || 401)){
            yield put(actions.getUserListError());
        }else {
            yield put(actions.getUserListSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getUserListError());
    }
}

export function* postManageCreateUserRequest(action:any) {
    try {
        const res: disputeFlowRes = yield Api.getDisputeFindByManager(action.payload);
        yield put(actions.postManageCreateUserSuccess(res.data));
    } catch (e) {
        yield put(actions.postManageCreateUserError());
    }
}






