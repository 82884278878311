import SystemTemplate from "../systemTemplates";
import SingleAccountBreadcrumb from "components/singleAccountBreadcrumb";
import { getItem } from "utils/localStorage";
import styles from "./style.module.scss";
import { BreadcumbItem } from "components/ui";
import { useBreadCumbs } from "hooks";

const Templates = ({ hideHeader }: { hideHeader?: boolean }) => {
  const SingleAccountCheck = getItem("singleAccountCheck");
  const { breadCumbs } = useBreadCumbs({ pageName: "Templates" });
  return (
    <div className={styles.templateWrapper}>
      {SingleAccountCheck ? (
        <SingleAccountBreadcrumb />
      ) : hideHeader ? null : (
        <div className={styles.nav}>
          <h3>Templates</h3>
          <div className={styles.navPath}>
            {breadCumbs.map((item, key) => (
              <BreadcumbItem {...item} {...{ key }} />
            ))}
          </div>
        </div>
      )}
      <h5>Dispute Templates</h5>

      <SystemTemplate />
    </div>
  );
};

export default Templates;
