import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { BlueTick, svgIcons } from 'elements';
import { getDisputeCurrency } from 'utils/disputeUtils';
import styles from './style.module.scss';
import InlineUpdateManager from 'components/storesView/InlineUpdateManager';
import dayjs from 'dayjs';
import { HtmlTooltip } from 'components/ui';
import ToolTipTitle from 'components/tooltip/ToolTipTitle';
import Loading from 'components/loading/loading';
import Image from 'components/image';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { useResponseSaveContent } from 'hooks';
import { getDisputeResponseFindByDisputeIdRequest, getDisputeResponsePdfByIdRequest } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

interface Dispute {
  id: string;
  paymentProcessor: string;
  submittedByChargePay: boolean;
  shopId: string;
  shopName: string;
  createTime: string;
  disputeAmountCurrencyCode: string;
  disputeAmountValue: number;
  reason: string;
  sellerResponseDueDate: string;
  decisionDate: string;
  status: string;
  carrier?: string;
  lastMileCarrier?: string;
  responseStatus?: string;
  manager?: any; // Adjust the type according to your actual data
  responseId?: any;
  billableAmount: any;
  submissionDate?: any;
}

interface Props {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  noSellerResponse?: boolean;
  data: {
    disputeResponseList: Dispute[];
    count: number;
  };
  manageManager: any;
  DisputeListColumnNames: string[];
  handleDisputeStatus: (dispute: Dispute) => string;
  isShowCollapsibleTablel?: boolean;
}

const NewDisputeList: React.FC<Props> = ({
  isLoading,
  isSuccess,
  isError,
  data,
  noSellerResponse,
  manageManager,
  DisputeListColumnNames,
  handleDisputeStatus,
  isShowCollapsibleTablel,
}) => {
  //TODO make this in a hook
  // to download , use this function in the utils and call everywhere where it's needed
  //file is not downloading according to the name. need to sort that out.

  console.log(data,'this is the table response data')
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const {
    reStructureMacros,
    isLoading: isMacrosLoading,
    handleMissingMacros,
  } = useResponseSaveContent();
  const handleRowClick = (disputeId: string, responseId: number) => {
    setExpandedRow(expandedRow === disputeId ? null : disputeId);
    handleMissingMacros({
      disputeResponseId: responseId, // response id
      responseSection: 'HTML',
    });
  };

  // code for downloading pdf
  const { isLoading: isPdfResponseLoading } = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponsePdfByResponseId
  );

  const disputeResponseFindByDisputeId = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponseFindByDisputeId
  );

  console.log(disputeResponseFindByDisputeId,'this is the dispute response find by dispute id')




  const disputeResponsePdf = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponsePdf
  );
  const disputeResponsePdfById = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponsePdfByResponseId
  );

  const [pdfSwitch, setPdfSwitch] = useState(false);
  const [pdfData, setPdfData] = useState<any>();
  const dispatch = useDispatch();
  const [disputeId,setDisputeId] = useState("");
  const [responseId,setResponseId] = useState<number>()
  const [loadingResponseId, setLoadingResponseId] = useState<number | null>(
    null
  );

  const handleDownloadPdfResponse = (responseId: number,disputeId:string) => {
    setLoadingResponseId(responseId)
    setDisputeId(disputeId)
    setResponseId(responseId);
    const payload = responseId;
    dispatch(getDisputeResponsePdfByIdRequest(payload));
  };

  useEffect(()=>{
    const payload = disputeId;
    console.log(payload,'this is the payload disputeid')
    if (payload){
      dispatch(getDisputeResponseFindByDisputeIdRequest(payload))
    }
  },[disputeId])
  

  useEffect(() => {
    if (
      pdfSwitch &&
      disputeResponsePdf?.isSuccess &&
      disputeResponsePdf?.data
    ) {
      const pdfUrl = window.URL.createObjectURL(
        new Blob([disputeResponsePdf?.data], { type: 'application/pdf' })
      );
      setPdfData(pdfUrl);
    }
  }, [disputeResponsePdf, pdfSwitch]);

  function downloadPdfFromBlob(blobResponse: any, filename = 'download.pdf') {
    // Create a blob from the
    const blob = new Blob([blobResponse], { type: 'application/pdf' });

    // Create a URL for the blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');

    // Set link attributes
    link.href = blobUrl;
    link.download = filename;

    // Append link to the body
    document.body.appendChild(link);

    // Trigger download
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  }

  useEffect(() => {
    if (
      disputeResponsePdfById?.isSuccess &&
      disputeResponsePdfById?.data &&
      responseId
    ) {
      downloadPdfFromBlob(
        disputeResponsePdfById?.data,
        `${disputeId}_${responseId}.pdf`
      );
        setLoadingResponseId(null);
    }
  }, [disputeResponsePdfById, pdfSwitch, responseId,disputeId]);


  return (
    <div className={styles.tableWrapper}>
      {isLoading && <Loading />}
      {(!isLoading && isError) || (isSuccess && !data?.count) ? (
        <div className={styles.NoData}>
          <img src={svgIcons.Coffee_Break} alt='disputes' />
          {isError && <h3>Oops! Something went wrong. Please try again.</h3>}
          <h3>No waiting seller response found</h3>
        </div>
      ) : (
        isSuccess &&
        data?.count > 0 && (
          <>
            <table>
              <thead>
                <tr>
                  {DisputeListColumnNames.length > 0 &&
                    DisputeListColumnNames.map((item: string) => (
                      <th key={item}>{item}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {data?.disputeResponseList.length > 0 &&
                  data?.disputeResponseList.map((dispute: Dispute) => (
                    <React.Fragment key={dispute.id}>
                      <tr className={styles.disputeWrapper}>
                        {isShowCollapsibleTablel && (
                          <td>
                            <Tooltip
                              placement='left'
                              arrow
                              title={
                                expandedRow === dispute.id ? 'Close' : 'Expand'
                              }
                            >
                              <IconButton
                                aria-label='expand row'
                                size='small'
                                onClick={() =>
                                  handleRowClick(dispute.id, dispute.responseId)
                                }
                              >
                                {expandedRow === dispute.id ? (
                                  <KeyboardArrowUp />
                                ) : (
                                  <KeyboardArrowDown />
                                )}
                              </IconButton>
                            </Tooltip>
                          </td>
                        )}
                        <td data-testid='dispute-order'>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {dispute?.paymentProcessor && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {dispute?.submittedByChargePay && (
                                  <div
                                    style={{
                                      marginRight: '5px',
                                      marginTop: '5px',
                                    }}
                                  >
                                    <BlueTick />
                                  </div>
                                )}
                                <Image
                                  icon={dispute?.paymentProcessor}
                                  altText='payment processor'
                                />
                              </div>
                            )}
                            <div>
                              <Link
                                className={styles.id}
                                to={`/dispute/${dispute?.id}`}
                              >
                                <p className={styles.recentDisputeID}>
                                  {dispute?.id ?? 'Missing Dispute'}
                                </p>
                              </Link>
                              <Link
                                className={styles.id}
                                to={
                                  dispute?.shopId
                                    ? `/store-details/${dispute?.shopId}`
                                    : '#'
                                }
                              >
                                <p
                                  className={styles.recentDisputeID}
                                  style={{ marginTop: '10px' }}
                                >
                                  {dispute?.shopName ?? 'Missing Dispute'}
                                </p>
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td>
                          {dispute?.status === 'UNDER_REVIEW'
                            ? dayjs(dispute.createTime).format('MMMM D, YYYY')
                            : dispute?.status === 'WAITING_FOR_SELLER_RESPONSE'
                            ? dayjs(dispute?.sellerResponseDueDate).format(
                                'MMMM D, YYYY'
                              )
                            : ''}
                        </td>
                        <td>
                          {
                            getDisputeCurrency(
                              dispute?.disputeAmountCurrencyCode
                            )?.currencySign
                          }
                          {Number(dispute?.disputeAmountValue)
                            ?.toFixed(2)
                            ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </td>
                        <td>
                          {dispute?.billableAmount !== null &&
                            getDisputeCurrency(
                              dispute?.disputeAmountCurrencyCode
                            )?.currencySign}
                          {dispute?.billableAmount}
                        </td>
                        <td>
                          <div className={styles.reasonType}>
                            <p>{dispute.reason?.split('_').join(' ')}</p>
                          </div>
                        </td>
                        {(!dispute?.carrier ||
                          dispute.carrier.trim() === '' ||
                          dispute.carrier.trim().toLowerCase() === 'null') &&
                        (!dispute?.lastMileCarrier ||
                          dispute.lastMileCarrier.trim() === '' ||
                          dispute.lastMileCarrier.trim().toLowerCase() ===
                            'null') ? (
                          <td colSpan={2}>
                            <p
                              style={{
                                backgroundColor: 'rgb(248, 226, 224)',
                                width: '110px',
                                color: '#000',
                                textAlign: 'center',
                                padding: '5px',
                                borderRadius: '4px',
                              }}
                            >
                              MISSING DATA
                            </p>
                          </td>
                        ) : (
                          <>
                            <td className={styles.carrierInfo}>
                              {dispute?.carrier ? (
                                <div className={styles.carrier}>
                                  <p className={styles.carrierData}>
                                    {dispute.carrier}
                                  </p>
                                </div>
                              ) : (
                                <div className={styles.carrier} />
                              )}
                            </td>
                            <td className={styles.carrierInfo}>
                              {dispute?.lastMileCarrier ? (
                                <div className={styles.carrier}>
                                  <p className={styles.carrierData}>
                                    {dispute.lastMileCarrier}
                                  </p>
                                </div>
                              ) : (
                                <div className={styles.carrier} />
                              )}
                            </td>
                          </>
                        )}
                        <td>
                          {dispute.responseStatus && (
                            <HtmlTooltip
                              placement='top-start'
                              arrow
                              title={<ToolTipTitle toolTipDetails={dispute} />}
                            >
                              <div
                                className={styles.reasonType}
                                style={{
                                  background:
                                    dispute.responseStatus === 'SUBMITTED'
                                      ? 'rgb(188 248 212)'
                                      : dispute.responseStatus ===
                                        'MISSING_INFO'
                                      ? 'rgb(248 226 224)'
                                      : 'rgb(252 228 154)',
                                }}
                              >
                                <p>{dispute.responseStatus}</p>
                              </div>
                            </HtmlTooltip>
                          )}
                        </td>
                        {dispute?.status === 'UNDER_REVIEW' && (
                          <td>
                            {dayjs(dispute?.submissionDate).format(
                              'MMMM D, YYYY'
                            )}
                          </td>
                        )}
                        <td>
                          <Button
                            variant='contained'
                            size='small'
                            onClick={() =>
                              handleDownloadPdfResponse(dispute?.responseId,dispute?.id)
                            }
                          >
                             {loadingResponseId === dispute?.responseId
                            ? 'loading...'
                            : 'Pdf'}
                          </Button>
                        </td>
                      </tr>
                      {expandedRow === dispute.id && (
                        <tr>
                          <td colSpan={DisputeListColumnNames.length}>
                            <Box
                              sx={{
                                margin: 0.5,
                                border: 1,
                                borderColor: 'grey.500',
                                padding: 2,
                                borderRadius: 1,
                              }}
                            >
                              <Typography
                                variant='h5'
                                gutterBottom
                                component='div'
                              >
                                Missing macros
                              </Typography>
                              <div>
                                {isMacrosLoading ? (
                                  <p style={{fontWeight:"bold"}}>loading...</p>
                                ) : (
                                  <div
                                    style={{
                                      display: 'grid',
                                      gridTemplateColumns: 'repeat(3,1fr)',
                                      gap: '15px',
                                    }}
                                    className={styles.collapsTableContainer}
                                  >
                                    {reStructureMacros?.length > 0 &&
                                      reStructureMacros?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <div key={index}>{item?.label}</div>
                                          );
                                        }
                                      )}
                                  </div>
                                )}
                              </div>
                            </Box>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </>
        )
      )}
    </div>
  );
};

export default NewDisputeList;
