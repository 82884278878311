import { Box, Grid, Tooltip } from "@mui/material";
import styles from "./styles.module.scss";
import { NodeUsesTypes } from "./Utils";
import { FirstSideLayerProps } from "../types";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const FirstSideLayer: React.FC<FirstSideLayerProps> = ({
  currentNode,
  setOpenSideBar,
  currentSidebarData,
  handleSidebarClick,
}) => {
  const { globalFlowState } = useSelector((state: any) => state?.disputeFlow);
  return (
    <Box width="286px">
      <Grid
        className={styles.selectionBox}
        spacing={1}
        borderRadius={"10px"}
        border={"1px solid #EEF2F7"}
        height={"100%"}
        flexWrap={"wrap"}
        padding={1}
        sx={{
          maxHeight: globalFlowState?.isExpand
            ? "calc(100vh - 270px)"
            : "calc(100vh - 328px)",
        }}
        container
      >
        {currentSidebarData.map((item: any, index: number) => (
          <Grid
            onClick={() => {
              if(isEmpty(item.options)){
                return;
              }else{
                handleSidebarClick(item, currentNode, setOpenSideBar)
              }
            }}
            item
            key={index}
            xs={6}
          >
            <Tooltip 
              placement="top" 
              arrow 
              title={isEmpty(item.options) ? " Something went wrong! No options available" : ""}
            >

            <Box
              borderRadius={2}
              border={"1px solid #EEF2F7"}
              textAlign={"center"}
              paddingY={2}
              height={"100%"}
              bgcolor={
                currentNode.type === NodeUsesTypes.ConditionNode &&
                item.id !== 9 &&
                item.id !== 1
                  ? "white"
                  : currentNode.type === NodeUsesTypes.StartNode &&
                    item.id === 1
                  ? "white"
                  : currentNode.type === NodeUsesTypes.ActionNode &&
                    item.id === 9
                  ? "white"
                  : "#efefef"
              }
              className={styles.singleBox}
              style={{
                cursor:
                  currentNode.type === NodeUsesTypes.ConditionNode &&
                  item.id !== 9 &&
                  item.id !== 1
                    ? "pointer"
                    : currentNode.type === NodeUsesTypes.StartNode &&
                      item.id === 1
                    ? "pointer"
                    : currentNode.type === NodeUsesTypes.ActionNode &&
                      item.id === 9
                    ? "pointer"
                    : "not-allowed",
              }}
            >
              {item.icon}
              <p>{item.name}</p>
            </Box>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FirstSideLayer;
