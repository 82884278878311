import { Box } from "@mui/material";
import { Panel, useReactFlow } from "reactflow";
import styles from "./styles.module.scss";
import { generateUUID, initialNodes } from "../NodeHelpers/Utils";
import { Button } from "components/ui";
import { useFlow } from "hooks";
import { useEffect, useState } from "react";

const PanelSidebar = () => {
  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    setCurrentUrl(window.location.hostname);
  }, []);

  const { setNodes } = useReactFlow();
  const { handleDownloadJSON } = useFlow();
  const maped = initialNodes.map((item) => {
    const id = generateUUID();
    item.id = `start_${id}`;
    item.data.conditions = [
      {
        label: "Select a reason",
        id: "condition1",
        source: "",
        additionalArguments: null,
      },
    ];
    return item;
  });
  return (
    <Panel position={"top-right"}>
      <Box display={`flex`} gap={3 / 2}>
        <Button
          {...{
            btnLabel: "Reset",
            btnTitle: "Click to reset",
            onClick: () => setNodes(maped),
            className: styles.saveButton,
          }}
        />
        {currentUrl === "localhost" ? (
          <Button
            {...{
              btnLabel: "Save",
              btnTitle: "Click to reset",
              onClick: () => handleDownloadJSON(),
              className: styles.saveButton,
            }}
          />
        ) : (
          ""
        )}
      </Box>
    </Panel>
  );
};

export default PanelSidebar;
