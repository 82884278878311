import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import HardBreak from "@tiptap/extension-hard-break";
import Text from "@tiptap/extension-text";
import Link from "@tiptap/extension-link";
import TextStyle from "@tiptap/extension-text-style";
import Bold from "@tiptap/extension-bold";
import Underline from "@tiptap/extension-underline";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Code from "@tiptap/extension-code";
import Dropcursor from "@tiptap/extension-dropcursor";
import CodeBlock from "@tiptap/extension-code-block";
import History from "@tiptap/extension-history";
import Heading from "@tiptap/extension-heading";
import Placeholder from "@tiptap/extension-placeholder";
import TextAlign from "@tiptap/extension-text-align";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { Editor, mergeAttributes, useEditor } from "@tiptap/react";
import { DnDExtension } from "components/disputeTemplate/TiptapEditor/drag.extension";
import { useCallback, useState } from "react";
import {
  htmlToText,
  openReactTemplateBlockTiptap,
} from "utils/disputeTemplates";
import { useLocation } from "react-router-dom";
import { ExtendedImage } from "components/disputeTemplate/TiptapEditor/ExtendImage";
import {
  TableWrapper
} from "components/disputeTemplate/TiptapEditor/table.extension";
import Gapcursor from '@tiptap/extension-gapcursor'
import customHorizontal from 'components/disputeTemplate/TiptapEditor/customHorizontal';
import CustomSection from "components/disputeTemplate/TiptapEditor/cutomSection";

const useTiptap = (setEditorContent: any, setValue: any) => {
  const { pathname } = useLocation();
  const path = pathname.split("/")[1];
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState<string>("");
  const editor = useEditor({
    extensions: [
      Document,
      History,
      Gapcursor,
      CustomSection,
      Dropcursor.configure({
        color: "#ccc",
        width: 2,
      }),
      CodeBlock.configure({
        languageClassPrefix: "language-",
      }),
      customHorizontal.configure({
        HTMLAttributes: {
          style: 'page-break-before:always;'
        }
      }),
      Placeholder.configure({
        emptyEditorClass: "is-editor-empty",
        placeholder: "Write / drag the templates from the toolbar...",
      }),
      ExtendedImage.configure({
        allowBase64: true,
        inline: true,
      }),
      HardBreak.configure({
        keepMarks: false,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Paragraph.extend({
        parseHTML() {
          return [{ tag: "section" }];
        },
        renderHTML({ HTMLAttributes }) {
          return [
            "section",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            0,
          ];
        },
      }),
      TextStyle,
      Italic,
      TextAlign,
      Text,
      Link.extend({ inclusive: false }).configure({
        autolink: true,
        openOnClick: true,
      }),
      Bold,
      Underline,
      Strike,
      Code,
      Heading.configure({
        levels: [1, 2, 3, 4, 5, 6],
      }),
      TableWrapper,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      DnDExtension,
    ],
    editable: path === "template-view" ? false : true,
    onUpdate({ editor }) {
      setEditorContent(editor.getHTML());
    },
  }) as Editor;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const openModal = useCallback(() => {
    setUrl(editor.getAttributes("link").href);
    setIsModalOpen(true);
  }, [editor]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setUrl("");
  }, []);

  const saveLink = useCallback(() => {
    if (url) {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url, target: "_blank" })
        .run();
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
    editor.chain().focus().insertContent(" ");
    closeModal();
  }, [editor, url, closeModal]);

  const removeLink = useCallback(() => {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
    closeModal();
  }, [editor, closeModal]);

  const toggleBold = useCallback(() => {
    editor.chain().focus().toggleBold().run();
  }, [editor]);

  const toggleUnderline = useCallback(() => {
    editor.chain().focus().toggleUnderline().run();
  }, [editor]);

  const toggleItalic = useCallback(() => {
    editor.chain().focus().toggleItalic().run();
  }, [editor]);

  const toggleStrike = useCallback(() => {
    editor.chain().focus().toggleStrike().run();
  }, [editor]);

  const toggleHeading = useCallback(() => {
    editor.chain().focus().toggleHeading({ level: 1 }).run();
  }, [editor]);
  const toggleHeading2 = useCallback(() => {
    editor.chain().focus().toggleHeading({ level: 2 }).run();
  }, [editor]);
  const toggleHeading3 = useCallback(() => {
    editor.chain().focus().toggleHeading({ level: 3 }).run();
  }, [editor]);

  const removeRow = useCallback(() => {
    editor.chain().focus().deleteRow().run()
    setIsExpanded(false)
  }, [editor])

  const addRow = useCallback(() => {
    editor.chain().focus().addRowAfter().run()
    setIsExpanded(false)
  }, [editor])

  const onDrag = (
    ev: React.DragEvent<HTMLDivElement>,
    Id: any,
    Index: number
  ) => {
    const droppedBlockData = openReactTemplateBlockTiptap(Id, Index) as any;
    ev.dataTransfer.setData("dnd", droppedBlockData.data || "");
    if (Index === 4) {
      setValue("summaryText", htmlToText(droppedBlockData.data));
    }
    editor?.commands.insertContent(" ");
  };
  const handleBlockInsert = async (droppedId: number) => {
    const droppedBlockData = openReactTemplateBlockTiptap(droppedId);
    const newBlockType: any = droppedBlockData?.data; // type of block we want to insert
    editor?.commands?.insertContent(newBlockType ?? "");
    editor?.commands.insertContent(" ");
  };
  const onDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    const droppedDataId = ev.dataTransfer.getData("text/plain");
    droppedDataId && handleBlockInsert(droppedDataId as any);
  };
  return {
    modalIsOpen,
    editor,
    url,
    isExpanded,
    onDrop,
    onDrag,
    toggleHeading,
    toggleHeading2,
    toggleHeading3,
    toggleStrike,
    toggleItalic,
    toggleUnderline,
    toggleBold,
    removeLink,
    saveLink,
    openModal,
    setIsModalOpen,
    setUrl,
    closeModal,
    removeRow,
    addRow,
    setIsExpanded,
  };
};
export default useTiptap;
