import { Node } from '@tiptap/core';

const TableWrapper = Node.create({
  name: 'tableWrapper',
  content: 'table',
  group: "inline",
  isolating: true,
  inline: true,
  parseHTML() {
    return [  
      {
        tag: 'span.table-wrapper',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', { class: 'table-wrapper' }, 0];
  },
});

export { TableWrapper };