import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";

export class GetAwaitingDisputes {
    /**
       * Function to invoke to get awaiting disputes
       */
    async invoke(payload: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_AWAITING_DISPUTES(payload));

        return res;
    }

    
    async invokeByTotalBilledAmount(payload: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_AWAITING_TOTAL_BILLED_AMOUNT(payload));

        return res;
    }

    
    async invokeByTotalAmount(payload: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_AWAITING_TOTAL_AMOUNT(payload));

        return res;
    }



}