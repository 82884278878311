import { useState } from "react";
import styles from "./style.module.scss";

const notificationsData = [
  {
    key: "linkedAccounts",
    title: "Linked Accounts",
    description: "Paypal/ecommerce etc",
    checked: false,
  },
  {
    key: "syncUpdates",
    title: "Sync Updates",
    description: "These are notifications for when someone tags you in a comment, post or story",
    checked: false,
  },
  {
    key: "disputeUpdates",
    title: "Dispute Updates",
    description: "These are notifications to remind you of updates you might have missed",
    checked: false,
  },
  {
    key: "moreActivityAboutYou",
    title: "More activity about you",
    description: "These are notifications for posts on your profile, likes and other reactions to your posts, and more",
    checked: false,
  },
]

const NotificationsAndAlerts = () => {
  const [notifications, setNotifications] = useState([...notificationsData])

  const handleCheck = (notification: any, num: number) => {
    const allNotifications = [...notifications];
    notification.checked = !notification?.checked;
    allNotifications[num] = notification;
    setNotifications([...allNotifications]);
  }

  return (
    <div className={styles.notificationsWrapper}>
      <div className={styles.heading}>
        <h3>Notifications</h3>
        <p className={styles.intro}>
          Turn on and off notifications
        </p>
      </div>
      <div className={styles.infoDiv}>
        {notifications?.map((notification, index) => {
          return (
            <div className={styles.info} key={notification?.key}>
              <div className={styles.notify}>
                <div className={styles.desc}>
                  <h3>{notification?.title}</h3>
                  <p>{notification?.description}</p>
                </div>
                <div className={styles.infoSwitches}>
                  <div className={styles.infoSwitch} onClick={() => handleCheck(notification, index)}>
                    <label className={styles.switch}>
                      <input type="checkbox" checked={notification?.checked} />
                    </label>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default NotificationsAndAlerts;
