import axios from "axios";
import { configs } from "configs";
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { getShopFindShopAddonRequest } from 'redux/actions';
import Swal from "sweetalert2";

const useAdonShopUpload = () => {
  const { storeId } = useParams();
  const dispatch = useDispatch();
  const onFormSubmit = async (
    uploadFile: any,
    type: string,
    callBack: any,
    background?: boolean
  ) => {
    const formData: any = new FormData();
    formData.append("file", uploadFile);
    formData.append("uploadType", type);
    formData.append("shopId", storeId);
    const token = localStorage.getItem("accessToken");
    const API_UPLOAD_CONFIG = {
      timeout: 10000,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await axios.post(
        `${configs.BASE_URL}/shop/addon-file-upload`,
        formData,
        API_UPLOAD_CONFIG
      );
      if (response.data) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your document has been saved successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        if (background) {
          callBack(() => ({
            deliveryPolicy: "",
            refundPolicy: "",
            termsAndConditon: "",
            sizingGuide: "",
            contactPage: "",
            shopAddonCancellation:"",
            loading:false
          }));
          storeId && dispatch(getShopFindShopAddonRequest(storeId));
        }
      } else {
        callBack([]);
      }
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Something is wrong with submission file",
        showConfirmButton: false,
        timer: 1500,
      });
      if (background) {
        callBack(() => ({
          deliveryPolicy: "",
          refundPolicy: "",
          termsAndConditon: "",
          sizingGuide: "",
          contactPage: "",
          shopAddonCancellation:"",
          loading:false
        }));
      } else {
        callBack([]);
      }
      console.error("Error uploading file:", error);
    }
  };
  return { onFormSubmit };
};
export default useAdonShopUpload;
