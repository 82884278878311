import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUserListRequest } from "redux/actions";

const useGetUser = () => {
  const {
    manageUsers,
  } = useSelector((state: any) => state.manageUser);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserListRequest());
  }, []);
  return { manageUsers,};
};

export default useGetUser;
