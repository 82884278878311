import {
  addActionOrConditionNode,
  addNewEdge,
} from "components/disputeFlow/NodeHelpers/Utils";
import { useReactFlow } from "reactflow";

const useNodeClick = () => {
  const { setNodes, setEdges } = useReactFlow();
  const handleNodeClick = (id: string, props: Node) => {
  };
  const handleAddNode = (type: string, props: any, sourceHandleId: string) => {
    const newNode: any = addActionOrConditionNode(type, props, sourceHandleId);
    setNodes((item) => {
      const items = [...item];
      items.push(newNode);
      return items;
    });
    const newEdge = addNewEdge({
      source: props.id,
      target: newNode.id,
      sourceHandleId,
    });
    setEdges((item) => {
      const copyEdges = [...item];
      copyEdges.push(newEdge as any);
      return copyEdges;
    });
  };
  return { handleNodeClick, handleAddNode };
};

export default useNodeClick;
