import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface ResPropTotalRevenueProtected {
    data: {
        "totalRevenueProtected": number,
        "saving": number
    };
    status:number
}

interface ResProp {
    data: {
        "id": number,
        "name": string,
        "email": string,
        "shopType": string
    },
    status:number
}

interface ResPropPayPal {
    data: {
        "id": number,
        "payerId": string,
        "payerEmail": string
    },
    status:number
}

interface ResPropDispute {
    data: [{
        "id": string,
        "createTime": string,
        "updateTime": string,
        "status": string,
        "reason": string,
        "disputeState": string,
        "order": string,
        "storeOrderId": string,
        "shopName": string,
        "shopType": string,
        "sellerMerchantId": string,
        "disputeAmountValue": number,
        "disputeAmountCurrencyCode": string,
        "sellerResponseDueDate": string,
        "buyerResponseDueDate": string,
        "sellerTransactionId": string,
        "paypalId": string
    },];
    status:number
}

interface ResPropOpenDispute {
    data: {
        "totalChallengedAmount": number,
        "monthlyDisputeDetails": [
            {
                "day": number,
                "count": number
            },
        ],
        "prevMonthComparison": number
    };
    status:number
}

interface ResPropCurrentWeekDispute {
    data: {
        "currentWeekDisputeCounts": [
            {
                "day": number,
                "count": number
            },
        ],
        "currentWeekDisputeDetails": [
            {
                "disputeId": string,
                "sellerReponseDueDate": string,
                "disputeAmount": number
            },
        ]
    };
    status:number
}


export function* getTotalRevenueProtectedDataRequest() {
    try {
        const res: ResPropTotalRevenueProtected = yield Api.getTotalRevenueProtected();
        if (res.status === (500 || 401)){
            yield put(actions.getTotalRevenueProtectedError());
        }else{
            yield put(actions.getTotalRevenueProtectedSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getTotalRevenueProtectedError());
    }
}

export function* getTotalRevenueProtectedByAccountDataRequest(action: any) {
    try {
        const res: ResPropTotalRevenueProtected = yield Api.getTotalRevenueProtectedByAccount(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getTotalRevenueProtectedByAccountError());
        }else{
            yield put(actions.getTotalRevenueProtectedByAccountSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getTotalRevenueProtectedByAccountError());
    }
}

export function* getLinkedShopsRequest() {
    try {
        const res: ResProp = yield Api.getLinkedShops();
        if (res.status === (500 || 401)){
            yield put(actions.getLinkedShopsError());
        }else{
            yield put(actions.getLinkedShopsSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getLinkedShopsError());
    }
}

export function* getLinkedShopsByAccountRequest(action: any) {
    try {
        const res: ResProp = yield Api.getLinkedShopsByAccount(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getLinkedShopsByAccountError());
        }else{
            yield put(actions.getLinkedShopsByAccountSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getLinkedShopsByAccountError());
    }
}

export function* deleteLinkedShopsRequest(action: any) {
    try {
        const res: boolean = yield Api.deleteLinkedShops(action.payload);
        yield put(actions.deleteLinkedShopsSuccess(res));
    } catch (e) {
        yield put(actions.deleteLinkedShopsError());
    }
}

export function* getLinkedPayPalRequest() {
    try {
        const res: ResPropPayPal = yield Api.getLinkedPayPal();
        if (res.status === (500 || 401)){
            yield put(actions.getLinkedPayPalError());
        }else{
            yield put(actions.getLinkedPayPalSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getLinkedPayPalError());
    }
}

export function* getLinkedPayPalByAccountRequest(action: any) {
    try {
        const res: ResPropPayPal = yield Api.getLinkedPayPalByAccount(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getLinkedPayPalByAccountError());
        }else{
            yield put(actions.getLinkedPayPalByAccountSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getLinkedPayPalByAccountError());
    }
}

export function* getRecentDisputesDataRequest() {
    try {
        const res: ResPropDispute = yield Api.getRecentDisputes();
        if (res.status === (500 || 401)){
            yield put(actions.getRecentDisputesError());
        }else{
            yield put(actions.getRecentDisputesSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getRecentDisputesError());
    }
}

export function* getRecentDisputesByAccountDataRequest(action: any) {
    try {
        const res: ResPropDispute = yield Api.getRecentDisputesByAccount(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getRecentDisputesByAccountError());
        }else{
            yield put(actions.getRecentDisputesByAccountSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getRecentDisputesByAccountError());
    }
}

export function* getOpenDisputesDataRequest() {
    try {
        const res: ResPropOpenDispute = yield Api.getOpenDisputes();
        if (res.status === (500 || 401)){
            yield put(actions.getOpenDisputesError());
        }else{
            yield put(actions.getOpenDisputesSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getOpenDisputesError());
    }
}

export function* getOpenDisputesByAccountDataRequest(action: any) {
    try {
        const res: ResPropOpenDispute = yield Api.getOpenDisputesByAccount(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getOpenDisputesByAccountError());
        }else{
            yield put(actions.getOpenDisputesByAccountSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getOpenDisputesByAccountError());
    }
}

export function* getRecoveredDisputesDataRequest() {
    try {
        const res: ResPropOpenDispute = yield Api.getRecoveredDisputes();
        if (res.status === (500 || 401)){
            yield put(actions.getRecoveredDisputesError());
        }else{
            yield put(actions.getRecoveredDisputesSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getRecoveredDisputesError());
    }
}

export function* getRecoveredDisputesByAccountDataRequest(action: any) {
    try {
        const res: ResPropOpenDispute = yield Api.getRecoveredDisputesByAccount(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getRecoveredDisputesByAccountError());
        }else{
            yield put(actions.getRecoveredDisputesByAccountSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getRecoveredDisputesByAccountError());
    }
}

export function* getCurrentWeekDisputesDataRequest() {
    try {
        const res: ResPropCurrentWeekDispute = yield Api.getCurrentWeekDisputes();
        if (res.status === (500 || 401)){
            yield put(actions.getCurrentWeekDisputesError());
        }else{
            yield put(actions.getCurrentWeekDisputesSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getCurrentWeekDisputesError());
    }
}

export function* getCurrentWeekDisputesByAccountDataRequest(action: any) {
    try {
        const res: ResPropCurrentWeekDispute = yield Api.getCurrentWeekDisputesByAccount(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getCurrentWeekDisputesByAccountError());
        }else{
            yield put(actions.getCurrentWeekDisputesByAccountSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getCurrentWeekDisputesByAccountError());
    }
}