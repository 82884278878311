import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "@mui/material";
import cn from "classnames";
import { Pagination, Loading } from "components";
import styles from "./style.module.scss";
import ReactSelect from "react-select";
import { reportingPSP } from "utils/disputeUtils";
import { CalendarIcon } from "components/disputeFlow/NodeHelpers";
import { ReportingTable } from "components/reporting/ReportingTable";
import { useGlobalReporting } from "hooks";

type TShop = {
  value: number;
  label: string;
};
interface Props {
  shop?: TShop;
}
const GlobalReport = ({ shop }: Props) => {
  const {
    paymentProcessor,
    storesOptions,
    reasonsOptions,
    datePickerRef,
    isLoading,
    isSuccess,
    isError,
    reportingData,
    reasonsList,
    shopList,
    disputeStatusButton,
    startDate,
    endDate,
    selectRef,
    tableList,
    page,
    onPageChange,
    handleKeyDown,
    handleDisputeStatus,
    setDateFilterCheck,
    setDateRange,
    setPaymentProcessor,
    setShopList,
    openDatePicker,
    setReasonsList,
  } = useGlobalReporting(shop)

  return (
    <div
      className={styles.disputesWrapper}
    >
      <div className={styles.status}>
        {disputeStatusButton?.map((statusBtn, index) => {
          return (
            <Button
              key={statusBtn.title}
              data-testid="dispute-status-button"
              className={cn(
                statusBtn.status && styles.allDisputes,
                styles.statusDiv
              )}
              onClick={() => handleDisputeStatus({ ...statusBtn }, index)}
            >
              <div
                className={cn(styles.open)}
                style={{
                  background: statusBtn.status ? "#ffffff" : statusBtn.color,
                }}
              ></div>
              {statusBtn?.title?.split("_").join(" ")}
            </Button>
          );
        })}
      </div>
      <div className={styles.search}>
        <div className={styles.searchTop}>
          <div onClick={openDatePicker} className={styles.date}>
            <CalendarIcon className={styles.icon} />
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selectsRange={true}
              placeholderText="Decision Date..."
              ref={datePickerRef}
              startDate={startDate}
              endDate={endDate}
              onChange={(update: any) => {
                setDateRange(update);
                setDateFilterCheck(true); //to control the API call
              }}
              className={styles.datePicker}
              monthsShown={2}
              isClearable
            />
          </div>
          <div className={styles.searchDiv}>
            <ReactSelect
              ref={selectRef}
              className={styles.multipleSelect}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  minHeight: "44px",
                }),
              }}
              closeMenuOnSelect={false}
              onKeyDown={handleKeyDown}
              placeholder={"Select reason.."}
              options={reasonsOptions}
              isMulti
              value={reasonsList}
              onChange={(e: any) => setReasonsList(e)}
            />
          </div>
          <div className={styles.searchDiv}>
            <ReactSelect
              ref={selectRef}
              className={styles.multipleSelect}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  minHeight: "44px",
                }),
              }}
              closeMenuOnSelect={false}
              onKeyDown={handleKeyDown}
              placeholder={"Select shops.."}
              options={storesOptions}
              isMulti
              value={shopList}
              onChange={(e: any) => setShopList(e)}
            />
          </div>

          <div className={styles.searchDiv}>
            <ReactSelect
              ref={selectRef}
              className={styles.multipleSelect}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  minHeight: "44px",
                }),
              }}
              closeMenuOnSelect={false}
              onKeyDown={handleKeyDown}
              placeholder={"Select payment processor.."}
              options={reportingPSP}
              isMulti
              value={paymentProcessor}
              onChange={(e: any) => setPaymentProcessor(e)}
            />
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <div style={{ background: "#fff" }}>
            <Loading />
          </div>
        ) : (
          <ReportingTable
            {...{
              data: reportingData,
              isLoading,
              isSuccess,
              isError,
            }}
          />
        )}
      </div>
      <div>
        {Math.ceil(reportingData?.length / tableList) > 1 && (
          <Pagination
            onPageChange={(pageNum) => onPageChange(pageNum)}
            page={page}
            totalPages={Math.ceil(reportingData?.length / 10)}
          />
        )}
      </div>
    </div>
  );
};

export default GlobalReport;
