import * as ApiConstants from '../configs';
import axios from "axios";

function responseTransformer(res: any, transformResponse?: any) {
  //document why this function 'responseTransformer' is empty

}

class ApiService {

  axiosClient: any;
  private static rootStore: any;


  constructor(authToken = '', fileUpload = false) {
    // @ts-ignore
    authToken && (ApiConstants.API_CONFIG.headers.common['Authorization'] = `Bearer ${authToken}`)
    // @ts-ignore
    authToken && (ApiConstants.API_UPLOAD_CONFIG.headers.common['Authorization'] = `Bearer ${authToken}`);

    this.axiosClient = fileUpload ? (
      // @ts-ignore
      axios.create({ ...ApiConstants.API_UPLOAD_CONFIG, baseURL: ApiConstants.configs.BASE_URL })
    ) : (
      // @ts-ignore
      axios.create({ ...ApiConstants.API_CONFIG, baseURL: ApiConstants.configs.BASE_URL })
    )
  }

  static init(rootStore: any) {
    this.rootStore = rootStore
    return new ApiService()
  }

  static getInstance = (fileUpload?: boolean, token?: string | null) => {
    const authToken: string | null = token || localStorage.getItem('accessToken');
    return new ApiService(authToken!, fileUpload)
  }

  
  get = async (apiPath: any, options?: { baseURL: string; extras?: { timeout: any; }; transformRequest?: (arg0: any, arg1: any) => any; }) => {

    const source = axios.CancelToken.source()
    const timeout = setTimeout(source.cancel, options?.extras?.timeout || ApiConstants.API_CONFIG.timeout)
    return this.axiosClient
      .get(apiPath, {
        baseURL: options?.baseURL,
        cancelToken: source.token,
        transformRequest: [
          (data: any, headers: any) => {
            return options?.transformRequest ? options.transformRequest(data, headers) : data
          },
        ],
        validateStatus: () => {
          return true
        },
        ...options?.extras

      }).then((response: any) => {
        if (response && response?.status === 401) {
        } else if (response?.status !== 401) {
          clearTimeout(timeout)
          return response
        }
      }).catch((actualError: { name: string; }) => {
        clearTimeout(timeout)
        if (actualError.name === 'TypeError') {
          const customError = new Error('Network Error')
          customError.name = 'APIError'
          throw customError

        } else {
          throw actualError
        }
      })
  }

  getPdf = async (apiPath: any, options?: { baseURL: string; extras?: { timeout: any; }; transformRequest?: (arg0: any, arg1: any) => any; }) => {

    const source = axios.CancelToken.source()
    const timeout = setTimeout(source.cancel, options?.extras?.timeout || ApiConstants.API_CONFIG.timeout)
    return this.axiosClient
      .get(apiPath, {
        baseURL: options?.baseURL,
        cancelToken: source.token,
        transformRequest: [
          (data: any, headers: any) => {
            return options?.transformRequest ? options.transformRequest(data, headers) : data
          },
        ],
        validateStatus: () => {
          return true
        },
        responseType: 'blob',
        ...options?.extras

      }).then((response: any) => {
        if (response && response?.status === 401) {
          // clearItems() //clearing localStorage
          // window.location.href = "/login"; //redirect to login
        } else if (response?.status !== 401) {
          clearTimeout(timeout)
          return response
        }
      }).catch((actualError: { name: string; }) => {
        clearTimeout(timeout)
        if (actualError.name === 'TypeError') {
          const customError = new Error('Network Error')
          customError.name = 'APIError'
          throw customError

        } else {
          throw actualError
        }
      })
  }


  post = async (apiPath: string, payload?: any, options?: { baseURL: string; extras?: any; transformRequest?: any; }) => {
    const source = axios.CancelToken.source()
    const timeout = setTimeout(source.cancel, options?.extras?.timeout || ApiConstants.API_CONFIG.timeout)

    return this.axiosClient
      .post(apiPath, JSON.stringify(payload), {
        baseURL: options?.baseURL,
        cancelToken: source.token,
        headers: this.axiosClient.defaults.headers.common,
        transformRequest: [
          (data: any, headers: any) => {
            return options?.transformRequest ? options.transformRequest(data, headers) : data
          },
        ],
        /*
            validateStatus returns the response in case of error
        */
        validateStatus: () => {
          return true
        },
      }).then((response: any) => {
        clearTimeout(timeout)
        return response
      }).catch((actualError: { name: string; }) => {
        clearTimeout(timeout)
        if (actualError.name === 'TypeError') {

          const customError = new Error('Network Error')
          customError.name = 'APIError'
          throw customError

        } else {
          throw actualError
        }
      })
  }


  put = async (apiPath: any, payload?: any, options?: { extras: { timeout: any; }; baseURL: any; transformResponse: any; }) => {
    const source = axios.CancelToken.source()
    const timeout = setTimeout(source.cancel, options?.extras?.timeout || ApiConstants.API_CONFIG.timeout)

    return this.axiosClient
      .put(apiPath, JSON.stringify(payload), {
        baseURL: options?.baseURL,
        cancelToken: source.token,
        headers: this.axiosClient.defaults.headers.common,
        transformResponse: (res: any) => (responseTransformer(res, options?.transformResponse)),
        /*
         validateStatus returns the response in case of error
        */
        validateStatus: () => {
          return true
        },
      }).then((response: any) => {
        clearTimeout(timeout)
        return response
      }).catch((actualError: { name: string; }) => {
        clearTimeout(timeout)
        if (actualError.name === 'TypeError') {

          const customError = new Error('Network Error')
          customError.name = 'APIError'
          throw customError

        } else {
          throw actualError
        }
      })
  }

  delete = async (apiPath: any) => {
    return this.axiosClient.delete(apiPath).then((response: any) => response)
  }
}


export default ApiService