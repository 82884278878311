import {handleActions} from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState ={
    awaitingDisputes: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    awaitingSellerTotalBilledAmount: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    awaitingSellerTotalAmount: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    }
}

// awaiting-seller-response
const getAwaitingDisputesRequest = (state: any, action: any) => {
    return update(state, {
        awaitingDisputes: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getAwaitingDisputesSuccess = (state: any, action: any) => {
    return update(state, {
        awaitingDisputes: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getAwaitingDisputesError = (state: any, action: any) => {
    return update(state, {
        awaitingDisputes: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
}


// under awaiting seller response disputes total billed amount
const getAwaitingDisputesTotalBilledAmountRequest = (state: any, action: any) => {
    return update(state, {
        awaitingSellerTotalBilledAmount: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getAwaitingDisputesTotalBilledAmountSuccess = (state: any, action: any) => {
    return update(state, {
        awaitingSellerTotalBilledAmount: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getAwaitingDisputesTotalBilledAmountError = (state: any, action: any) => {
    return update(state, {
        awaitingSellerTotalBilledAmount: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// under awaiting seller response disputes total amount
const getAwaitingDisputesTotalAmountRequest = (state: any, action: any) => {
    return update(state, {
        awaitingSellerTotalAmount: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getAwaitingDisputesTotalAmountSuccess = (state: any, action: any) => {
    return update(state, {
        awaitingSellerTotalAmount: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getAwaitingDisputesTotalAmountError = (state: any, action: any) => {
    return update(state, {
        awaitingSellerTotalAmount: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
    export default handleActions(
        {
            [constants.GET_AWAITING_DISPUTES_REQUEST]: getAwaitingDisputesRequest,
            [constants.GET_AWAITING_DISPUTES_SUCCESS]: getAwaitingDisputesSuccess,
            [constants.GET_AWAITING_DISPUTES_ERROR]: getAwaitingDisputesError,

            [constants.GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_REQUEST]: getAwaitingDisputesTotalBilledAmountRequest,
            [constants.GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_SUCCESS]: getAwaitingDisputesTotalBilledAmountSuccess,
            [constants.GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_ERROR]: getAwaitingDisputesTotalBilledAmountError,

            [constants.GET_AWAITING_DISPUTES_TOTAL_AMOUNT_REQUEST]: getAwaitingDisputesTotalAmountRequest,
            [constants.GET_AWAITING_DISPUTES_TOTAL_AMOUNT_SUCCESS]: getAwaitingDisputesTotalAmountSuccess,
            [constants.GET_AWAITING_DISPUTES_TOTAL_AMOUNT_ERROR]: getAwaitingDisputesTotalAmountError   ,

        },
        initialState
    )