import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  getNewBillingTotalAmountRequest,
  getNewBillingTotalBilledAmountRequest,
  getNewBillingReadyDisputeListRequest,
  removeDisputeApproveRequest,
} from 'redux/actions';

type IStoreOptions = {
  value: number;
  label: string;
};

const useNewBillingTabs = (page: number, pageLength: number) => {
  const dispatch = useDispatch();
  const [callApi, setCallApi] = useState(false);
  const selectRef: any = useRef(null);
  const [open, setOpen] = useState(false);
  const [updatedDisputeIds, setUpdatedDisputeIds] = useState<string[]>([]);
  const [disputesPayload, setDisputesPayload] = useState<any>();
  
  // this is the code for shop filtering
  const [shopList, setShopList] = useState<IStoreOptions[]>([]);
  // this is our main table data
  const {
    data: newBillingTableData,
    isLoading,
    isError,
    isSuccess,
  } = useSelector((state: any) => state?.billing?.newBillingReadyDisputes);

  // this is for cardTotalBilledAmountData
  const {
    data: newBillingTotalBilledAmount,
    isLoading: isTotalBilledLoading,
    isError: isTotalBilledError,
    isSuccess: isTotalBilledSuccess,
  } = useSelector((state: any) => state?.billing?.newBillingTotalBilledAmount);

  // this is for cardTotalAmountData
  const {
    data: newBillingTotalAmount,
    isLoading: isTotalAmountLoading,
    isError: isTotalAmountError,
    isSuccess: isTotalAmountSuccess,
  } = useSelector((state: any) => state?.billing?.newBillingTotalAmount);

  // this is for approvedDisputes
  const {
    data: billedDisputes,
    isLoading: isApprovedLoading,
    isError: isApprovedError,
    isSuccess: isApprovedSuccess,
  } = useSelector((state: any) => state?.billing?.disputeApprove);

  // this function is for close the modal
  const handleClose = () => {
    setOpen(false);
    dispatch(removeDisputeApproveRequest());
  };

  useEffect(() => {
    if (billedDisputes?.disputeIds?.length && callApi) {
      setUpdatedDisputeIds(billedDisputes?.disputeIds);
      setOpen(true);
    }
  }, [billedDisputes?.disputeIds, callApi]);


  useEffect(()=>{
    const shopIds = shopList && shopList.length > 0 ?  shopList.map((item)=> item.value): []
    const payload = {
      page,
      pageLength,
      shopIds
    }
    setDisputesPayload(payload)
  },[shopList,page])

  useEffect(() => {
    const payload = {
      page: page,
      pageLength: pageLength,
      ...disputesPayload
    };
    dispatch(getNewBillingReadyDisputeListRequest(disputesPayload));
    dispatch(getNewBillingTotalAmountRequest());
    dispatch(getNewBillingTotalBilledAmountRequest());
  }, [page,disputesPayload]);

  useEffect(() => {
    if (callApi && isApprovedSuccess) {
      const payload = { 
        page: 1,
        pageLength: 15,
        ...disputesPayload
      };
      dispatch(getNewBillingReadyDisputeListRequest(disputesPayload ));
      setCallApi(false);
    }
  }, [callApi, isApprovedSuccess,disputesPayload]);

  return {
    newBillingTableData,
    isLoading,
    isSuccess,
    isError,
    billedDisputes,
    isApprovedLoading,
    isApprovedSuccess,
    isApprovedError,
    open,
    setOpen,
    handleClose,
    callApi,
    setCallApi,
    newBillingTotalBilledAmount,
    newBillingTotalAmount,
    isTotalBilledLoading,
    isTotalAmountLoading,
    selectRef,
    updatedDisputeIds,
    shopList,
    setShopList
  };
};

export default useNewBillingTabs;
