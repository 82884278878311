import { useSelector } from "react-redux";
import { getDisputeCurrency } from "utils/disputeUtils";
import { svgIcons } from "elements";
import styles from "./style.module.scss";
import { Loading } from "../../index";

const DisputeWon = () => {
  const { isLoading, isSuccess, data } = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );

  return (
    <div className={styles.disputeWonWrapper}>
      {isLoading ? (
        <Loading />
      ) : (
        isSuccess && (
          <div className={styles.disputeWon}>
            <div className={styles.underWinner}>
              <img src={svgIcons.Undraw_Winner} alt="Undraw_Winner" />
            </div>
            <div className={styles.statusStatement}>
              <h2>Congratulation! You won the dispute</h2>
              <h3>{`You recovered ${
                getDisputeCurrency(data?.disputeAmountCurrencyCode)
                  ?.currencySign
              }${data.disputeAmountValue
                ?.toFixed(2)
                ?.replace(
                  /(\d)(?=(\d{3})+(?!\d))/g,
                  "$1,"
                )} by winning this dispute.`}</h3>
              <p>{`Amount has been refunded and clear in your account by ${data?.paymentProcessor}`}</p>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default DisputeWon;
