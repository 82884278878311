import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import cn from "classnames";

import SendMessage from "./sendMessage";
// import AppealDispute from "./appealDispute";
// import AcknowledgeReturnedItem from "./acknowledgeReturnedItem";
// import AcceptClaim from "./acceptClaim";
import {
  getDisputeResponseFindByDisputeIdRequest,
  // getDisputeTemplateFindByTypeRequest,
  getManageDisputeCarrierOptionsRequest,
  getManageDisputeEvidenceTypeOptionsRequest,
  getManageDisputeHateoasLinkRequest,
} from "redux/actions";
import Loading from "components/loading/loading";
import styles from "./style.module.scss";
import ManageManually from "./manageManually";
import { svgIcons } from "elements";
import DisputeResponse from "../disputeResponse";
import { useParams } from "react-router-dom";
import CreateResponse from "./createResponse";
import CreateAudit from "./audit";
import SupportingEvidence from "./supportingEvidence";
import NoDataFound from "components/noDataFound";
import DisputeEvidence from './disputeEvidence';

const ManageDispute = ({
  setDisputeResponsePreview,
  clickedTab,
  setClickedTab,
}: any) => {
  const dispatch = useDispatch();

  const { disputeId } = useParams();
  const [hateoasSwitch, setHateoasSwitch] = useState(false);
  const [shopifypaymentsManageSwitch, setShopifyPaymentsManageSwitch] =
    useState(false);

  const [sendMessageTab, setSendMessageTab] = useState(false);
  const [acceptClaimTab, setAcceptClaimTab] = useState(false);
  // const [acknowledgeReturnedItemTab, setAcknowledgeReturnedItemTab] =
  //   useState(true);
  // const [appealDisputeTab, setAppealDisputeTab] = useState(true);
  const disputeResponseFindByDisputeId = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponseFindByDisputeId
  );
  const { disputeDetails, manageDisputeHateoasLink } = useSelector(
    (state: any) => state?.disputeDetailsStore
  );

  useEffect(() => {
    if (
      hateoasSwitch &&
      manageDisputeHateoasLink?.isSuccess &&
      manageDisputeHateoasLink?.data?.length
    ) {
      manageDisputeHateoasLink?.data?.forEach((dataLink: any) => {
        switch (dataLink?.rel) {
          case "send_message":
            setSendMessageTab(true);
            clickedTab === "sendMessage" && setClickedTab("sendMessage");
            break;
          case "accept_claim":
            setAcceptClaimTab(true);
            // clickedTab !== "sendMessage" && setClickedTab("acceptClaim");
            break;
          case "self":
            break;
          case "make_offer":
            break;
          case "escalate":
            break;
        }
      });
    }
  }, [manageDisputeHateoasLink]);

  useEffect(() => {
    if (
      !hateoasSwitch &&
      disputeDetails?.isSuccess &&
      disputeDetails?.data?.paymentProcessor &&
      disputeDetails?.data?.paymentProcessor?.toLowerCase() === "paypal" &&
      disputeDetails?.data?.id
    ) {
      dispatch(getManageDisputeHateoasLinkRequest(disputeDetails?.data?.id));
      setHateoasSwitch(true);
    } else if (
      !shopifypaymentsManageSwitch &&
      disputeDetails?.isSuccess &&
      disputeDetails?.data?.paymentProcessor &&
      disputeDetails?.data?.paymentProcessor?.toLowerCase() ===
        "shopifypayments"
    ) {
      setClickedTab("disputeResponse");
      setShopifyPaymentsManageSwitch(true);
    }
  }, [disputeDetails, dispatch]);

  useEffect(() => {
    dispatch(getManageDisputeCarrierOptionsRequest());
    dispatch(getManageDisputeEvidenceTypeOptionsRequest());
    if (disputeId) {
      dispatch(getDisputeResponseFindByDisputeIdRequest(disputeId));
    }
  }, [dispatch]);
  const disputePaymentGateway = disputeDetails?.data?.paymentProcessor;

  return (
    <div className={styles.disputeWrapper}>
      {/* TODO ~ that could a component */}
      <div className={styles.manageHead}>
        <h6>Manage dispute &nbsp;|</h6>
        {disputePaymentGateway &&
          disputePaymentGateway?.toLowerCase() === "paypal" && (
            <img src={svgIcons.PayPal_Icon_Text} alt="PayPal" />
          )}
        {disputePaymentGateway &&
          disputePaymentGateway?.toLowerCase() === "klarna" && (
            <img src={svgIcons.Klarna} alt="Klarna" />
          )}
        {disputePaymentGateway &&
          disputePaymentGateway?.toLowerCase() === "stripe" && (
            <img src={svgIcons.Stripe_Icon_Text} alt="Stripe" />
          )}
        {disputePaymentGateway &&
          disputePaymentGateway?.toLowerCase() === "braintree" && (
            <div className={styles.summaryLogo}>
              <img src={svgIcons.BrainTree} alt="BrainTree" />
              <p>Braintree</p>
            </div>
          )}
        {disputePaymentGateway &&
          disputePaymentGateway?.toLowerCase() === "shopifypayments" && (
            <div className={styles.summaryLogo}>
              <img src={svgIcons.Lachiepor} alt="ShopifyPayments" />
              <p>Shopify Payments</p>
            </div>
          )}
      </div>
      <div className={styles.manageFormWrapper}>
        {disputeDetails?.isLoading ? (
          <Loading />
        ) : (
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className={styles.manageFormTabsWrapper}
            >
              <div className={styles.integrationTabs}>
                <Button
                  className={
                    clickedTab === "disputeResponse"
                      ? cn(styles.integrationTab, styles.activeTab)
                      : styles.integrationTab
                  }
                  onClick={() => setClickedTab("disputeResponse")}
                >
                  <p>Dispute Response</p>
                </Button>

                <Button
                  className={
                    clickedTab === "createResponse"
                      ? cn(styles.integrationTab, styles.activeTab)
                      : styles.integrationTab
                  }
                  onClick={() => setClickedTab("createResponse")}
                >
                  <p>Create Response</p>
                </Button>

                <Button
                  className={
                    clickedTab === "audit"
                      ? cn(styles.integrationTab, styles.activeTab)
                      : styles.integrationTab
                  }
                  onClick={() => setClickedTab("audit")}
                >
                  <p>Audit</p>
                </Button>
                <Button
                  className={
                    clickedTab === "dispute_evidence"
                      ? cn(styles.integrationTab, styles.activeTab)
                      : styles.integrationTab
                  }
                  onClick={() => setClickedTab("dispute_evidence")}
                >
                  <p>Dispute Evidence</p>
                </Button>
                <Button
                  className={
                    clickedTab === "supporting_evidence"
                      ? cn(styles.integrationTab, styles.activeTab)
                      : styles.integrationTab
                  }
                  onClick={() => setClickedTab("supporting_evidence")}
                >
                  <p>Supporting Evidence</p>
                </Button>

                {sendMessageTab && (
                  <Button
                    className={
                      clickedTab === "sendMessage"
                        ? cn(styles.integrationTab, styles.activeTab)
                        : styles.integrationTab
                    }
                    onClick={() => setClickedTab("sendMessage")}
                  >
                    <p>Send message</p>
                  </Button>
                )}
                {/* {acceptClaimTab && (
                  <Button
                    className={
                      clickedTab === "acceptClaim"
                        ? cn(styles.integrationTab, styles.activeTab)
                        : styles.integrationTab
                    }
                    onClick={() => setClickedTab("acceptClaim")}
                  >
                    <p>Accept claim</p>
                  </Button>
                )} */}
                {/* {disputeDetails?.data?.paymentProcessor?.toLowerCase() !==
                  "shopifypayments" &&
                  acknowledgeReturnedItemTab && (
                    <Button
                      className={
                        clickedTab === "acknowledgeReturnedItem"
                          ? cn(styles.integrationTab, styles.activeTab)
                          : styles.integrationTab
                      }
                      onClick={() => setClickedTab("acknowledgeReturnedItem")}
                    >
                      <p>Acknowledge returned item</p>
                    </Button>
                  )} */}
                {/* {disputeDetails?.data?.paymentProcessor?.toLowerCase() !==
                  "shopifypayments" &&
                  appealDisputeTab && (
                    <Button
                      className={
                        clickedTab === "appealDispute"
                          ? cn(styles.integrationTab, styles.activeTab)
                          : styles.integrationTab
                      }
                      onClick={() => setClickedTab("appealDispute")}
                    >
                      <p>Appeal Dispute</p>
                    </Button>
                  )} */}
                <Button
                  className={
                    clickedTab === "manageManually"
                      ? cn(styles.integrationTab, styles.activeTab)
                      : styles.integrationTab
                  }
                  onClick={() => setClickedTab("manageManually")}
                >
                  <p>Manage Manually</p>
                </Button>
              </div>
            </Box>
            {clickedTab === "sendMessage" && (
              <div>
                <SendMessage />
              </div>
            )}
            {/* {clickedTab === "acceptClaim" && (
              <div>
                <AcceptClaim />
              </div>
            )} */}
            {/* {clickedTab === "acknowledgeReturnedItem" && (
              <div>
                <AcknowledgeReturnedItem />
              </div>
            )} */}
            {/* {clickedTab === "appealDispute" && (
              <div>
                <AppealDispute />
              </div>
            )} */}
            {clickedTab === "manageManually" && (
              <div>
                <ManageManually {...{ disputePaymentGateway }} />
              </div>
            )}
            {clickedTab === "disputeResponse" && (
              <div>
                {disputeResponseFindByDisputeId?.isSuccess &&
                disputeResponseFindByDisputeId?.data?.length > 0 ? (
                  <div className={styles.disputeResolver}>
                    <DisputeResponse
                      setDisputeResponsePreview={setDisputeResponsePreview}
                    />
                  </div>
                ) : (
                  ((!disputeResponseFindByDisputeId?.isLoading &&
                    disputeResponseFindByDisputeId?.isError) ||
                    (disputeResponseFindByDisputeId?.isSuccess &&
                      disputeResponseFindByDisputeId?.data?.length === 0)) && (
                    <NoDataFound
                      icon={svgIcons?.UnderReview}
                      isError={disputeResponseFindByDisputeId?.isError}
                      errorStatement="Oops! Something went wrong. Please try again."
                      notFoundMessage="No dispute response"
                    />
                  )
                )}
              </div>
            )}

            {clickedTab === "createResponse" && (
              <div>
                <CreateResponse
                  paymentProcessor={disputeDetails?.data?.paymentProcessor}
                />
              </div>
            )}
            {clickedTab === "supporting_evidence" && (
              <div>
                <SupportingEvidence />
              </div>
            )}
            {clickedTab === "dispute_evidence" && (
              <div>
                <DisputeEvidence />
              </div>
            )}

            {clickedTab === "audit" && (
              <div>
                <CreateAudit />
              </div>
            )}
          </Box>
        )}
      </div>
    </div>
  );
};

export default ManageDispute;
