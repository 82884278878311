import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
export class GetDisputeDetails {
    /**
     * Function to invoke to get dispute details
     */
    async invoke(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_DETAILS(payload));

        return res;
    }
    async invokeByTrackingDetails(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_TRACKING_DETAILS(payload));

        return res;
    }

    async invokeByGetTrackingDetails(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_GETTRACKING_DETAILS(payload));

        return res;
    }

    async invokeByResponseJsonById(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_RESPONSE_JSON_BY_ID(payload));

        return res;
    }

    async invokeByFraudAnalysisDetails(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_FRAUD_ANALYSIS_DETAILS(payload));

        return res;
    }

    async invokeByShippingDetails(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_SHIPPING_DETAILS(payload));

        return res;
    }

    async invokeBySubmitedDetails(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_MANAGE_DISPUTE_SUBMITTED_BY_CHARGEPAY(payload));

        return res;
    }



}