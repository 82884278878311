import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface DisputeObj {
    "id": string,
    "createTime": string,
    "updateTime": string,
    "status": string,
    "reason": string,
    "disputeState": string,
    "disputeLifeCycleStage": string,
    "order": string,
    "storeOrderId": string,
    "shopName": string,
    "shopType": string,
    "sellerMerchantId": string,
    "disputeAmountValue": number,
    "disputeAmountCurrencyCode": string,
    "sellerResponseDueDate": string,
    "buyerResponseDueDate": string,
    "sellerTransactionId": string,
    "paypalId": string
}

interface ResProp {
    data: {
        count: number,
        disputeResponseList: DisputeObj[],
    },
    status: number
}
interface ResPropProcessDispute {
    data: {
        "totalChallengedAmount": number,
        "monthlyDisputeDetails": [
            {
                "day": number,
                "count": number
            },
        ],
        "prevMonthComparison": number
    },
    status: number
}

export function* getDisputesListDataRequest(action: any) {
    try {
        const res: ResProp = yield Api.getDisputesList(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputesListError());
        } else {
            yield put(actions.getDisputesListSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getDisputesListError());
    }
}

export function* getFilteredDisputesDataRequest(action: any) {
    try {
        const res: ResProp = yield Api.getFilteredDisputes(action.payload);
        yield put(actions.getFilteredDisputesSuccess(res.data));
    } catch (e) {
        yield put(actions.getFilteredDisputesError());
    }
}

export function* getProcessingDisputesDataRequest() {
    try {
        const res: ResPropProcessDispute = yield Api.getProcessingDisputes();
        if (res.status === (500 || 401)) {
            yield put(actions.getProcessingDisputesError());
        } else {
            yield put(actions.getProcessingDisputesSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getProcessingDisputesError());
    }
}

export function* getProcessingDisputesByAccountDataRequest(action: any) {
    try {
        const res: ResPropProcessDispute = yield Api.getProcessingDisputesByAccount(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getProcessingDisputesByAccountError());
        } else {
            yield put(actions.getProcessingDisputesByAccountSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getProcessingDisputesByAccountError());
    }
}

export function* getAwaitingResponseDisputesDataRequest() {
    try {
        const res: ResPropProcessDispute = yield Api.getAwaitingResponseDisputes();
        if (res.status === (500 || 401)) {
            yield put(actions.getAwaitingResponseDisputesError());
        } else {
            yield put(actions.getAwaitingResponseDisputesSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getAwaitingResponseDisputesError());
    }
}

export function* getAwaitingResponseDisputesByAccountDataRequest(action: any) {
    try {
        const res: ResPropProcessDispute = yield Api.getAwaitingResponseDisputesByAccount(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getAwaitingResponseDisputesByAccountError());
        } else {
            yield put(actions.getAwaitingResponseDisputesByAccountSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getAwaitingResponseDisputesByAccountError());
    }
}

export function* postDisputesListFetchByAccountDataRequest(action: any) {
    try {
        const res: ResProp = yield Api.postDisputesFetchByAccount(action.payload);
        yield put(actions.postDisputesListFetchByAccountSuccess(res.data));
    } catch (e) {
        yield put(actions.postDisputesListFetchByAccountError());
    }
}

// // awaiting-sellar-response
// export function* getAwaitingDisputesRequest(action:any) {
//     try {
//         const res: ResPropProcessDispute = yield Api.getAwaitingDisputes(action.payload);
        
//         if (res.status === (500 || 401)) {
//             yield put(actions.getAwaitingDisputesError());
//         } else {
//             yield put(actions.getAwaitingDisputesSuccess(res.data));
//         }

//     } catch (e) {
//         yield put(actions.getAwaitingDisputesError());
//     }
// }

