import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    connectPaypal: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    }
}

const postConnectPaypalRequest = (state: any, action: any) => {
    return update(state, {
        connectPaypal: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postConnectPaypalSuccess = (state: any, action: any) => {
    return update(state, {
        connectPaypal: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const postConnectPaypalError = (state: any, action: any) => {
    return update(state, {
        connectPaypal: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.POST_CONNECT_PAYPAL_REQUEST]: postConnectPaypalRequest,
        [constants.POST_CONNECT_PAYPAL_SUCCESS]: postConnectPaypalSuccess,
        [constants.POST_CONNECT_PAYPAL_ERROR]: postConnectPaypalError,
    },
    initialState
);