import { useSelector } from "react-redux";
import {useCallback} from "react";
import { useDispatch } from "react-redux";
import { getPreSignedUrlRequest } from "redux/actions";

const useHandlePreview = () => {
  const dispatch = useDispatch();
  const { data, isLoading, isError, isSuccess } = useSelector(
    (state: any) => state.storesShop.singleFilePreview
  );
  const handlePreview = useCallback((dispute: any) => {
    dispatch(getPreSignedUrlRequest(dispute));
  }, []);
  return { handlePreview, data, isLoading, isError, isSuccess };
};
export default useHandlePreview;
