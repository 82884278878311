import { Routes, Route, Outlet } from "react-router-dom";
import { Sidebar } from "./components";
import ProtectedRoute from "./context/auth/protect-route";
import AuthenticateStore from "./stores/AuthenticateStore";
import {
  LoginPage,
  RegisterPage,
  Paypal,
  TrialPage,
  PaddleBuyPage,
  Home,
  NotificationPage,
  Disputes,
  NotFound,
  DisputeDetails,
  // Accounts,
  DisputeFlows,
  WaitingSellerResponse,
  SystemFlows,
  DisputeTemplates,
  TemplateView,
  Users,
  Stores,
  StoreView,
  Settings,
  CreateOrEditTemplate,
  CreateOrEditFlow,
  ManageStoreOrDispute,
  // Billing,
  CurrentBilling,
  StoreReports
} from "./views";
import Reporting from 'views/dashboard/reporting';
import Macros from 'views/dashboard/macros/Macros';
import UnderReviewDisputes from "views/dashboard/underReviewDisputes/UnderReviewDisputes";
// import { useEffect } from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
// import { isEmpty } from 'lodash';
// import { useAuth0 } from '@auth0/auth0-react';
// import { clearItems } from 'utils/localStorage';
// import { useEffect } from 'react';

const PageLayout = () => {
  return (
    <div className="pageWrapper">
      <Sidebar />
      <div className="pageBody">
        <Outlet />
      </div>
    </div>
  );
};

export default function App() {
  // const { loginWithRedirect, isAuthenticated } = useAuth0();
  // const { user = {}, logout } = useAuth0();

  // const handleLogout = () => {
  //   logout({
  //     returnTo: window.location.origin,
  //   });
  // };
  // const token = localStorage.getItem("accessToken");
  // console.log({isAuthenticated , token})
  // useEffect(() => {
  //   setTimeout(() => {
  //   if (isEmpty(token) && !isAuthenticated) {
  //         logout({
  //           returnTo: window.location.origin,
  //         });
  //       }
  //     }, 3000)
  // }, [token, isAuthenticated]);

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/paypal" element={<Paypal />} />
      <Route path="/trial" element={<TrialPage />} />
      <Route path="/option" element={<PaddleBuyPage />} />
      <Route path="/" element={<LoginPage />} />
      <Route element={<PageLayout />}>
        <Route path="/home" element={<ProtectedRoute component={Home} />} />
        <Route
          path="/home/:accountId"
          element={<ProtectedRoute component={Home} />}
        />
        <Route
          path="/notification"
          element={<ProtectedRoute component={NotificationPage} />}
        />
        {/* <Route path="/accounts" element={<ProtectedRoute component={Accounts} />} /> */}
        <Route
          path="/disputes"
          element={<ProtectedRoute component={Disputes} />}
        />
        <Route
          path="/disputes/:accountId"
          element={<ProtectedRoute component={Disputes} />}
        />
        <Route
        path='/waitingSellerResponse'
        element ={<ProtectedRoute component={WaitingSellerResponse}/>}
        />
        
        <Route
        path='/underReviewDisputes'
        element ={<ProtectedRoute component={UnderReviewDisputes}/>}
        />
        
        <Route
        path='/storeReports'
        element ={<ProtectedRoute component={StoreReports}/>}
        />

        <Route
          path="/dispute/:disputeId"
          element={<ProtectedRoute component={DisputeDetails} />}
        />
        <Route
          path="/flows"
          element={<ProtectedRoute component={DisputeFlows} />}
        />
        <Route
          path="/flows/create-new"
          element={<ProtectedRoute component={CreateOrEditFlow} />}
        />
        <Route
          path="/flows/create-new/:storeId"
          element={<ProtectedRoute component={CreateOrEditFlow} />}
        />
        <Route
          path="/flows/:accountId"
          element={<ProtectedRoute component={DisputeFlows} />}
        />
        <Route
          path="/flows-edit/:flowId"
          element={<ProtectedRoute component={CreateOrEditFlow} />}
        />
        <Route
          path="/flows/system-flows/item-not-received"
          element={<ProtectedRoute component={SystemFlows} />}
        />
        <Route
          path="/templates"
          element={<ProtectedRoute component={DisputeTemplates} />}
        />
        <Route
          path="/templates/:accountId"
          element={<ProtectedRoute component={DisputeTemplates} />}
        />
        <Route
          path="/templates/create-new"
          element={<ProtectedRoute component={CreateOrEditTemplate} />}
        />
        <Route
          path="/templates/create-new/:storeId"
          element={<ProtectedRoute component={CreateOrEditTemplate} />}
        />
        <Route
          path="/template-view/:templateId"
          element={<ProtectedRoute component={TemplateView} />}
        />
        <Route
          path="/template-edit/:templateId"
          element={<ProtectedRoute component={CreateOrEditTemplate} />}
        />
        <Route path="/users" element={<ProtectedRoute component={Users} />} />
        <Route path="/macros" element={<ProtectedRoute component={Macros} />} />
        <Route path="/billing" element={<ProtectedRoute component={CurrentBilling} />} />
        <Route path="/reporting" element={<ProtectedRoute component={Reporting} />} />
        {/* <Route path="/billing" element={<ProtectedRoute component={Billing} />} /> */}
        <Route
          path="/users/managers/:managerId"
          element={<ProtectedRoute component={ManageStoreOrDispute} />}
        />
        <Route path="/stores" element={<ProtectedRoute component={Stores} />} />
        <Route
          path="/store-details/:storeId"
          element={<ProtectedRoute component={StoreView} />}
        />
        <Route
          path="/settings"
          element={<ProtectedRoute component={Settings} />}
        />
        <Route path="/square/authenticate" element={<AuthenticateStore />} />
        <Route path="/ecwid/authenticate" element={<AuthenticateStore />} />
        <Route
          path="/bigcommerce/authenticate"
          element={<AuthenticateStore />}
        />
        <Route path="/wix/authenticate" element={<AuthenticateStore />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
