import { Box, Button, TextField } from "@mui/material";
import { useResponseSaveContent } from "hooks";
import styles from "./style.module.scss";
import { Controller, useForm } from "react-hook-form";
import Loading from "components/loading/loading";

const MissingMacros = ({ id }: any) => {
  const { reStructureMacros, isLoading, handleSavingMissingMacros } =
    useResponseSaveContent();
  const { handleSubmit, reset, control } = useForm();
  const onSubmit = async (data: any) => {
    const newItem = {
      disputeResponseId: id,
      responseSection: "ALL",
      fieldValueMap: data,
    };
    handleSavingMissingMacros(newItem);
    reset();
  };

  return (
    <div className={styles.disputeWrapper}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {reStructureMacros && reStructureMacros.length > 0 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <h3>Missing Macros</h3>
              {reStructureMacros &&
                reStructureMacros.length > 0 &&
                reStructureMacros.map((item: any, index: number) => (
                  <Box width="100%" marginBottom="8px" key={index}>
                    <p style={{ marginBottom: "4px" }}>{item.label}</p>
                    <Controller
                      control={control}
                      name={item.name}
                      rules={{
                        required: {
                          value: false,
                          message: "This is required",
                        },
                      }}
                      render={({ field }) => {
                        return (
                          <>
                            {item.name.includes("img_".toLowerCase()) ? (
                              <>
                                <div>
                                  <TextField
                                    disabled
                                    fullWidth
                                    label={item?.label}
                                    helperText="use dispute evidence for submitting the
                                    screenshot"
                                    FormHelperTextProps={{
                                      className: styles.helperText,
                                    }}
                                  ></TextField>
                                </div>
                              </>
                            ) : (
                              <TextField
                                fullWidth
                                label={item?.label}
                                {...field}
                              />
                            )}
                          </>
                        );
                      }}
                    />
                  </Box>
                ))}
              <Box mt="16px">
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default MissingMacros;
