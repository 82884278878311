import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface DisputeObj {
    "id": string,
    "createTime": string,
    "updateTime": string,
    "status": string,
    "reason": string,
    "disputeState": string,
    "disputeLifeCycleStage": string,
    "order": string,
    "storeOrderId": string,
    "shopName": string,
    "shopType": string,
    "sellerMerchantId": string,
    "disputeAmountValue": number,
    "disputeAmountCurrencyCode": string,
    "sellerResponseDueDate": string,
    "buyerResponseDueDate": string,
    "sellerTransactionId": string,
    "paypalId": string
}

interface ResProp {
    data: {
        count: number,
        disputeResponseList: DisputeObj[],
    },
    status: number
}

export function* getFetchUnderReviewListRequest(action: any) {
    try {
        const res: ResProp = yield Api.getUnderReviewDisputes(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getFetchUnderReviewDisputesError());
        } else {
            yield put(actions.getFetchUnderReviewDisputesSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getFetchUnderReviewDisputesError());
    }
}

export function* getFetchUnderReviewTotalBilledAmountRequest(action: any) {
    try {
        const res: ResProp = yield Api.getUnderReviewDisputesTotalBilledAmount(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getFetchUnderReviewTotalBilledAmountError());
        } else {
            yield put(actions.getFetchUnderReviewTotalBilledAmountSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getFetchUnderReviewTotalBilledAmountError());
    }
}

export function* getFetchUnderReviewTotalAmountRequest(action: any) {
    try {
        const res: ResProp = yield Api.getUnderReviewDisputesTotalAmount(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getFetchUnderReviewTotalAmountError());
        } else {
            yield put(actions.getFetchUnderReviewTotalAmountSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getFetchUnderReviewTotalAmountError());
    }
}   
