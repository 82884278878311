import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import dayjs from "dayjs";
interface CalenderProps {
  className: string;
  control: any;
  name: string;
  errors: any;
}
export default function Calendar({
  className,
  control,
  name,
  errors,
}: CalenderProps) {
  // const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          control={control}
          name={name}
          rules={{
            required: {
              value: true,
              message: "This is required",
            },
          }}
          render={({ field }) => {
            return (
              <DatePicker
                value={dayjs(field.value)}
                sx={{
                  height: "40px",
                  width:'100%'
                }}

                // views={["year", "month", "day"]}
                format="DD/MM/YYYY"
                className={className}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date);
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
      <ErrorMessage name={name} errors={errors} />
    </div>
  );
}
