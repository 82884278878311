import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getDisputeShippingDetailsRequest,
  getDisputeTrackingDetailsRequest,
  getDisputeTrackingRequest,
} from "redux/actions";

interface RootState {
  disputeDetailsStore: {
    manageDisputeGetTrackingDetails: {
      isSuccess: boolean;
      data: any;
    };
  };
}

const useGetTracking = () => {
  const { disputeId } = useParams();
  const dispatch = useDispatch();
  const [trackCheck, setTrackCheck] = useState(false);
  const { isSuccess, data } = useSelector(
    (state: RootState) => state?.disputeDetailsStore?.manageDisputeGetTrackingDetails
  );
  const handleGetTracking = () => {
    dispatch(getDisputeTrackingRequest(disputeId));
    setTrackCheck(true);
  };
  useEffect(() => {
    if (trackCheck && data?.status === 200 && isSuccess) {
      dispatch(getDisputeShippingDetailsRequest(disputeId));
      dispatch(getDisputeTrackingDetailsRequest(disputeId));
      setTrackCheck(false);
    }
  }, [data?.status, trackCheck]);
  return { handleGetTracking };
};

export default useGetTracking;
