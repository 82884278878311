import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { svgIcons } from "elements";
import DisputeTrackiScore from "../disputeTrackiScore";

import styles from "./style.module.scss";

const DisputeUnderReview = ({ setDisputeState }: any) => {
  const { data } = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );

  const handleViewSubmittedDispute = () => {
    setDisputeState("response template");
  };

  return (
    <div className={styles.disputeUnderReview}>
      <div className={styles.review}>
        <div className={styles.getCoffee}>
          <img src={svgIcons.GettingCoffee} alt="GettingCoffee" />
        </div>
        <div className={styles.statusStatement}>
          <h2>Sit Back and Relax, Your Dispute is Under Review</h2>
          <p>Here are more details about your dispute</p>
        </div>
        <div className={styles.reviewInfo}>
          <div className={styles.trackiScore}>
            <DisputeTrackiScore disputeState={data?.disputeState} />
          </div>

          <div className={styles.timeEstimate}>
            <h5>Time Estimate</h5>
            <p>
              {data?.resolutionEstimatedTime &&
                `${data?.resolutionEstimatedTime} Days`}
            </p>
          </div>
        </div>

        <div className={styles.viewSubmittedDispute}>
          <Button
            variant="contained"
            onClick={handleViewSubmittedDispute}
            data-testid="view-submitted-dispute-details"
          >
            View Submitted Dispute Details
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DisputeUnderReview;
