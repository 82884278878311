import { Box, Button } from "@mui/material";
import { Handle, NodeProps, Position } from "reactflow";
import styles from "./style.module.scss";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import {
  ConditionItem,
  SquareEdit,
  ToolTipWrapper,
} from "components/disputeFlow/NodeHelpers";
import useUpdateNode from "hooks/flowHooks/useUpdateNode";
import BasicPopover from "components/disputeFlow/NodeHelpers/FlowPopover";

const ConditionNode = (props: NodeProps<any>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentClicked, setCurrentClicked] = useState<string>("");
  const { handleMultipleCondition, handleDeleteCondition } = useUpdateNode();
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    data: string
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentClicked(data);
  };

  return (
    <Box
      padding={4}
      minWidth={"268px"}
      minHeight={100}
      boxShadow={2}
      border={1}
      borderColor={props.selected ? "black" : "#cccccc"}
      borderRadius={"12px"}
      position={"relative"}
      bgcolor={`white`}
      className={styles.conditionWrapper}
    >
      <Handle
        type={"target"}
        id={"top"}
        position={Position.Top}
        className={styles.targetTopConditionStyle}
      ></Handle>

      <Box>
        <Box display={"flex"} gap={1}>
          <SquareEdit />
          <p className={styles.labelStyle}>{props?.data?.label}</p>
        </Box>
        <ConditionItem
          conditions={props.data.conditions}
          id={props.id}
          styles={styles}
          type="condition"
          handleDeleteCondition={handleDeleteCondition}
        />
      </Box>
      <Box marginTop={2}>
        <ToolTipWrapper
          title="Click to add new condition"
          component={
            <Button
              onClick={() => handleMultipleCondition(props.id)}
              className={styles.buttonStyle}
              variant="outlined"
              startIcon={<Add />}
            >
              And
            </Button>
          }
        />
      </Box>

      <BasicPopover
        props={props}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        currentClicked={currentClicked}
      />
      <ToolTipWrapper
        title="Click to add new node"
        component={
          <div
            className={styles.basicPopoverLeft}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e as any, "otherwise");
            }}
          >
            <Handle
              type="source"
              style={{ visibility: "hidden" }}
              position={Position.Bottom}
              id="otherwise"
            ></Handle>
            <p className={styles.paraStyle}>Otherwise</p>
          </div>
        }
      />

      <ToolTipWrapper
        title="Click to add new node"
        component={
          <div
            className={styles.basicPopoverRight}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e as any, "then");
            }}
          >
            <Handle
              type="source"
              style={{ visibility: "hidden" }}
              position={Position.Bottom}
              id="then"
            ></Handle>
            <p className={styles.paraStyle}>Then</p>
          </div>
        }
      />
    </Box>
  );
};

export default ConditionNode;
