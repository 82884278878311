import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import cn from "classnames";
import { getDisputeDetailsRequest } from "redux/actions";
import { BlueTick, svgIcons } from "elements";
import { getColorByReason, getColorByStatus } from "utils/disputeUtils";
import styles from "./style.module.scss";
// import DisputeTrackiScore from "../disputeTrackiScore";
import { getDisputeCurrency } from "utils/disputeUtils";
import { Button } from "components/ui";
import { useSubmittedDetails } from "hooks";

interface DisputeSummaryProps {
  disputeState: string;
}

const DisputeSummary = ({ disputeState }: DisputeSummaryProps) => {
  const dispatch = useDispatch();
  const { data } = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );
  const { handleSubmittedDetails } = useSubmittedDetails();
  const { disputeId } = useParams();
  useEffect(() => {
    if (disputeId) {
      const payload = disputeId;
      dispatch(getDisputeDetailsRequest(payload));
    }
  }, [disputeId]);

  const handleSubmittedByChargePay = () => {
    const payload = {
      disputeId,
      submittedByChargePay: true,
    };
    handleSubmittedDetails(payload);
  };

  return (
    <div className={styles.DisputeSummarySection}>
      {/*      <DisputeTrackiScore disputeState={disputeState} /> */}
      {disputeState !== "RESOLVED" &&
      disputeState !== "UNDER_PAYPAL_REVIEW" &&
      disputeState !== "REQUIRED_OTHER_PARTY_ACTION" &&
      disputeState !== "response template" &&
      disputeState !== "dispute lost" ? (
        <>
          {data?.submittedByChargePay && (
            <div className={styles.handleBySection}>
              <div className={styles.handleByText}>
                <p className={styles.handleHeading}>Handled by ChargePay</p>
                <div className={styles.handleTrackiPal}>
                  <div>
                    <div>
                      <p className={styles.reason}>
                        {data?.reason?.split("_").join(" ")}
                      </p>
                      {/* <p>{`(Flow ID: ${data?.disputeFlowId ?? 0})`}</p> */}
                    </div>
                    {/* <img src={svgIcons.ArrowRight} alt="Arrow" /> */}
                  </div>
                </div>
              </div>
              <BlueTick />
            </div>
          )}
        </>
      ) : (
        <>
          {/*           <div className={cn(styles.disputeInfo, styles.timeEstimate)}> */}
          {/*             <div className={styles.disputeData}> */}
          {/*               <p>Resolution Time Estimate</p> */}
          {/*               <div className={styles.disputeValue}> */}
          {/*                 {data?.resolutionEstimatedTime && */}
          {/*                   `Around ${data?.resolutionEstimatedTime}days`} */}
          {/*               </div> */}
          {/*             </div> */}
          {/*           </div> */}
        </>
      )}

      <div className={styles.disputeInfoHead}>
        <p>Dispute Infomation</p>
      </div>

      <div className={styles.disputeInfo}>
        <div className={styles.disputeData}>
          <p>Dispute ID</p>
          <div className={styles.disputeValue}>{data?.id}</div>
        </div>
        <div className={styles.disputeData}>
          <p>Transaction ID</p>
          <div className={styles.disputeValue}>{data?.sellerTransactionId}</div>
        </div>
      </div>

      <div className={styles.disputeInfo}>
        <div className={styles.disputeData}>
          <p>Dispute Amount</p>
          <div className={styles.disputeValue}>
            {getDisputeCurrency(data?.disputeAmountCurrencyCode)?.currencySign}
            {data?.disputeAmountValue
              ?.toFixed(2)
              ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
            {data?.disputeAmountCurrencyCode}
          </div>
        </div>
        <div className={styles.disputeData}>
          <p>Opened On</p>
          <div className={styles.disputeValue}>
            {data?.createTime &&
              moment(data?.createTime).format("Do MMMM YYYY")}
          </div>
        </div>
      </div>

      <div className={styles.disputeInfo}>
        <div className={styles.disputeData}>
          <p>Reason</p>
          <div
            className={cn(styles.disputeValue, styles.text)}
            style={{
              backgroundColor: getColorByReason(data?.reason)?.backgroundColor,
              color: getColorByReason(data?.reason)?.textColor,
            }}
          >
            {data?.reason?.split("_").join(" ")}
          </div>
        </div>
        <div className={styles.disputeData}>
          <p>Decision Date</p>
          <div className={styles.disputeValue}>
            {data?.decisionDate &&
              moment(data?.decisionDate).format("Do MMMM YYYY")}
          </div>
        </div>
      </div>

      <div className={styles.disputeInfo}>
        <div className={styles.disputeData}>
          {disputeState !== "RESOLVED" &&
          disputeState !== "UNDER_PAYPAL_REVIEW" &&
          disputeState !== "REQUIRED_OTHER_PARTY_ACTION" &&
          disputeState !== "response template" &&
          disputeState !== "dispute lost" ? (
            <>
              <p>Status</p>
              {data?.status !== 500 && (
                <div
                  className={cn(styles.disputeValue, styles.text)}
                  style={{
                    backgroundColor: getColorByStatus(data?.status)
                      ?.backgroundColor,
                    color: getColorByStatus(data?.status)?.textColor,
                  }}
                >
                  {data?.status?.split("_").join(" ")}
                </div>
              )}
            </>
          ) : (
            <>
              <p>Type</p>
              <div className={styles.typePayPal}>
                {data?.paymentProcessor &&
                  data?.paymentProcessor?.toLowerCase() === "paypal" && (
                    <>
                      <img src={svgIcons.PayPalFill} alt="payPal" />
                      <p>Paypal</p>
                    </>
                  )}
                {data?.paymentProcessor &&
                  data?.paymentProcessor?.toLowerCase() === "stripe" && (
                    <>
                      <img src={svgIcons.Stripe} alt="stripe" />
                      <p>Stripe</p>
                    </>
                  )}
                {data?.paymentProcessor &&
                  data?.paymentProcessor?.toLowerCase() === "braintree" && (
                    <>
                      <img src={svgIcons.BrainTree} alt="braintree" />
                      <p>Braintree</p>
                    </>
                  )}
                {data?.paymentProcessor &&
                  data?.paymentProcessor?.toLowerCase() === "klarna" && (
                    <>
                      <img src={svgIcons.Klarna} alt="klarna" />
                      <p>Klarna</p>
                    </>
                  )}
                {data?.paymentProcessor &&
                  data?.paymentProcessor?.toLowerCase() ===
                    "shopifypayments" && (
                    <>
                      <img src={svgIcons.Lachiepor} alt="shopifypayments" />
                      <p>Shopify Payments</p>
                    </>
                  )}
              </div>
            </>
          )}
        </div>
        <div className={styles.disputeData}>
          {disputeState !== "RESOLVED" &&
          disputeState !== "UNDER_PAYPAL_REVIEW" &&
          disputeState !== "REQUIRED_OTHER_PARTY_ACTION" &&
          disputeState !== "response template" &&
          disputeState !== "dispute lost" ? (
            <>
              <p>
                {data?.paymentProcessor &&
                  data?.paymentProcessor?.toLowerCase() === "paypal" &&
                  "PayPal"}
                {data?.paymentProcessor &&
                  data?.paymentProcessor?.toLowerCase() === "stripe" &&
                  "Stripe"}
                {data?.paymentProcessor &&
                  data?.paymentProcessor?.toLowerCase() === "braintree" &&
                  "Braintree"}
                {data?.paymentProcessor &&
                  data?.paymentProcessor?.toLowerCase() === "shopifypayments" &&
                  "Shopify Payments"}
                {data?.paymentProcessor &&
                  data?.paymentProcessor?.toLowerCase() === "klarna" &&
                  "Klarna"}
                {!data?.paymentProcessor && "Payer Email"}
              </p>
              <div className={styles.disputePayPalEmail}>
                {data?.payerEmail ?? data?.email}
              </div>
            </>
          ) : (
            <>
              <p>Amount</p>
              <div className={styles.disputeValue}>
                {
                  getDisputeCurrency(data?.disputeAmountCurrencyCode)
                    ?.currencySign
                }
                {data?.disputeAmountValue
                  ?.toFixed(2)
                  ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                {data?.disputeAmountCurrencyCode}
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.disputeInfo}>
        <div className={styles.disputeData}>
          <p>Store Order Id</p>
          {data?.storeOrderId && (
            <a
              target="_blank"
              href={data?.orderLink}
              className={styles.disputeValue}
              rel="noreferrer"
            >
              {data?.storeOrderId}
            </a>
          )}
        </div>
        <div className={styles.disputeData}>
          <p>Order name</p>
          {data?.orderName && <p>{data?.orderName}</p>}
        </div>
      </div>
      {!data?.submittedByChargePay && (
        <div className={styles.disputeInfo}>
          <Button
            {...{
              size: "small",
              btnLabel: "Handle Dispute",
              btnTitle: "Click to handle dispute",
            }}
            onClick={handleSubmittedByChargePay}
          />
        </div>
      )}
    </div>
  );
};

export default DisputeSummary;
