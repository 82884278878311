import { Popover, Box } from "@mui/material";
import { NodeUsesTypes } from "./Utils";
import useNodeClick from "hooks/flowHooks/useNodeClick";
import styles from "./styles.module.scss";
import { BlazeIcon, ConditionIcon } from "./flowIcons";
import { anchorOrigin, basicPopoverTypes, transformOrigin } from "../types";

const BasicPopover: React.FC<basicPopoverTypes> = ({
  anchorEl,
  setAnchorEl,
  props,
  currentClicked,
}) => {
  const { handleAddNode } = useNodeClick();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openingPopoverModule = [
    {
      icon: <ConditionIcon />,
      title: "Condition",
      type: NodeUsesTypes.ConditionNode,
    },
    {
      icon: <BlazeIcon />,
      title: "Action",
      type: NodeUsesTypes.ActionNode,
    },
  ];

  const anchorOrigin: anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
  };
  const transformOrigin: transformOrigin = {
    vertical: "top",
    horizontal: "center",
  };
  const handleDivClick = (event: any) => {
    const parentElement = event.target.parentNode;
    const parentHeight = parentElement.offsetHeight;
  };
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        className={styles.popoverSideBar}
      >
        <Box
          margin={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {openingPopoverModule.map(({ icon, title, type }, index) => (
            <Box
              key={index}
              onClick={(e) => {
                handleAddNode(type, props, currentClicked);
                handleDivClick(e);
                handleClose();
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding={"12px"}
              minWidth={"84px"}
              borderRight={index === 0 ? "1px solid #EAEAEA" : ""}
              className={styles.mainBox}
            >
              {icon}
              <p>{title}</p>
            </Box>
          ))}
        </Box>
      </Popover>
    </div>
  );
};
export default BasicPopover;
