import { useEffect, useState } from "react";
import useDisputeflow from "./useDisputeflow";
import {
  sideBarInterface,
  disputeTemplates,
  disputeTemplateDTOListProps,
} from "components/disputeFlow/types";
import { NodeProps } from "reactflow";
import { useDisputeTemplateFullList } from "hooks";
import {
  NodeUsesTypes,
  arrayToObject,
} from "components/disputeFlow/NodeHelpers/Utils";
const useFlowSidebar = (sideBarData: sideBarInterface[]) => {
  const { reasons, stages } = useDisputeflow();
  const [currentSidebarData, setCurrentSidebarData] =
    useState<sideBarInterface[]>(sideBarData);
  const { data } = useDisputeTemplateFullList();
  const [disputeTemplates, setDisputeTemplates] = useState<disputeTemplates[]>(
    []
  );
  useEffect(() => {
    //TODO -> any should changed to the type
    // sorted
    const optionsForDisputeTemplate =
      data?.count > 0 &&
      data.disputeTemplateDTOList.map((item: disputeTemplateDTOListProps) => ({
        value: item.id,
        label: item.name,
      }));
    setDisputeTemplates(optionsForDisputeTemplate);
  }, [data]);
  useEffect(() => {
    if (reasons) {
      setCurrentSidebarData((prev: any) => {
        const maped = prev.map((item: any) => {
          if (item.id === 1) {
            Object.assign(item, { options: reasons && arrayToObject(reasons) });
            return item;
          }
          if (item.id === 4) {
            Object.assign(item, { options: stages && arrayToObject(stages) });
            return item;
          }
          if (item.id === 9) {
            Object.assign(item, {
              options: arrayToObject(disputeTemplates || []),
            });
            return item;
          }
          return item;
        });
        return maped;
      });
    }
  }, [reasons, stages, disputeTemplates]);

  const handleSidebarClick = (
    item: any,
    currentNode: NodeProps,
    setOpenSideBar: (isOpen: boolean) => void
  ) => {
    if (
      currentNode.type === NodeUsesTypes.ConditionNode &&
      item.id !== 9 &&
      item.id !== 1
    ) {
      setOpenSideBar(item);
    }
    if (currentNode.type === NodeUsesTypes.StartNode && item.id === 1) {
      setOpenSideBar(item);
    }
    if (currentNode.type === NodeUsesTypes.StartNode && item.id !== 1) {
      return;
    }
    if (currentNode.type === NodeUsesTypes.ActionNode && item.id !== 9) {
      return;
    }
    if (currentNode.type === NodeUsesTypes.ActionNode && item.id === 9) {
      setOpenSideBar(item);
    }
  };

  return {
    handleSidebarClick,
    currentSidebarData,
  };
};
export default useFlowSidebar;
