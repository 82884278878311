import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Pagination, Loading } from "components";
import styles from "./style.module.scss";
import { CalendarIcon } from "components/disputeFlow/NodeHelpers";
import { ByStoresReportingTable } from "components/reporting/ReportingTable";
import { useByReasonsReporting } from "hooks";

const ReportByReason = () => {
  const {
    page,
    tableList,
    datePickerRef,
    isLoading,
    isSuccess,
    isError,
    reportingData,
    startDate,
    endDate,
    onPageChange,
    setDateFilterCheck,
    openDatePicker,
    setDateRange,
  } = useByReasonsReporting()
  return (
    <div className={styles.disputesWrapper}>
      <div className={styles.search}>
        <div className={styles.searchTop}>
          <div onClick={openDatePicker} className={styles.date}>
            <CalendarIcon className={styles.icon} />
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selectsRange={true}
              placeholderText="Decision Date..."
              ref={datePickerRef}
              startDate={startDate}
              endDate={endDate}
              onChange={(update: any) => {
                setDateRange(update);
                setDateFilterCheck(true); //to control the API call
              }}
              className={styles.datePicker}
              monthsShown={2}
              isClearable
            />
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <div style={{ background: "#fff" }}>
            <Loading />
          </div>
        ) : (
          <ByStoresReportingTable
            {...{
              // data: reportingData?.data,
              data: reportingData?.byReportResponses?.[0]?.data,
              isLoading,
              isSuccess,
              isError,
            }}
          />
        )}
      </div>
      <div>
        {Math.ceil(reportingData?.byReportResponses?.[0]?.data?.length / tableList) > 1 && (
          <Pagination
            onPageChange={(pageNum) => onPageChange(pageNum)}
            page={page}
            totalPages={Math.ceil(reportingData?.byReportResponses?.[0]?.data?.length / 10)}
          />
        )}
      </div>
    </div>
  );
};

export default ReportByReason;
