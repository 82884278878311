import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from "axios";
import Loading from "./Loading";
import { setItem } from "../utils/localStorage";
import { getAuthorizeResult } from "../utils/GenerateStoreUrls";

export const AuthenticateStore = () => {
    const navigate = useNavigate();  
    const [state, setState] = useState({
      showResult: false,
      apiMessage: "",
      error: null,
    });
    
    
    const {
      getAccessTokenSilently,
      loginWithPopup,
      getAccessTokenWithPopup,
    } = useAuth0();
    useEffect(()=>{
      postShop();
    },[]);
    const handleConsent = async () => {
      try {
        await getAccessTokenWithPopup();
        setState({
          ...state,
          error: null,
        });
      } catch (error:any) {
        setState({
          ...state,
          error: error.error,
        });
      }
  
      await postShop();
    };
  
    const handleLoginAgain = async () => {
      try {
        await loginWithPopup();
        setState({
          ...state,
          error: null,
        });
      } catch (error:any) {
        setState({
          ...state,
          error: error.error,
        });
      }
  
      await postShop();
    };
    const postShop = async () => {
        const token = await getAccessTokenSilently();
        const {data,url} =  getAuthorizeResult(window.location.pathname);
        axios({
        method: "post",
        url: url,
        data: data,
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        })
      .then(function (response) {
        setState({
          ...state,
          showResult: true,
          apiMessage: response.data,
        });
        setItem("AddAccount", true);
        navigate("/home");
      })
      .catch(function (error) {
        setState({
          ...state,
          error: error.error,
        });
        setItem("AddAccount", false);
      });
    }
  
    const handle = (e:any, fn:any) => {
      e.preventDefault();
      fn();
    };
  
    return (
      <>
        <div className="mb-5">
          {state.error === "consent_required" && (
            <Alert severity="warning">
              You need to{" "}
              <a
                href="#/"
                className="alert-link"
                onClick={(e) => handle(e, handleConsent)}
              >
                consent to get access to users api
              </a>
            </Alert>
          )}
  
          {state.error === "login_required" && (
            <Alert severity="warning">
              You need to{" "}
              <a
                href="#/"
                className="alert-link"
                onClick={(e) => handle(e, handleLoginAgain)}
              >
                log in again
              </a>
            </Alert>
          )}
  
         {state.apiMessage!=="" && (<h1> Finished Setting up Store...</h1>)}
        </div>
  
      </>
    );
  };
  
  export default withAuthenticationRequired(AuthenticateStore, {
    onRedirecting: () => <div className="loading"><div><Loading /></div>
                          <div>Adding Store...</div></div>,
  });
  