import { useEffect } from "react";
import { getDisputeFlowViewRequest } from "redux/actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const useDisputeFlowViewData = (flowId: any) => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isError, data } = useSelector(
    (state: any) => state?.disputeFlow?.disputeFlowView
  );
  useEffect(() => {
    if (flowId) {
      dispatch(getDisputeFlowViewRequest(flowId));
    }
  }, [dispatch, flowId]);
  return {
    isLoading,
    isSuccess,
    isError,
    data,
  };
};
export default useDisputeFlowViewData;
