import React from "react";
import Tooltip from "@mui/material/Tooltip";

interface toolTipWrapperType {
  title: string;
  component: any;
  placement?: any;
}

const ToolTipWrapper: React.FC<toolTipWrapperType> = ({
  title,
  component,
  placement,
}) => {
  return (
    <div>
      <Tooltip placement={placement ?? "top"} arrow title={title}>
        {component}
      </Tooltip>
    </div>
  );
};

export default ToolTipWrapper;
