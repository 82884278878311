import { configs } from "configs";
import ApiService from "services/apiService";
import { apiConstants } from "../network";

export class PostDisputesFetchByAccount {
    /**
     * Function to invoke to post disputes fetch by account
     */
    async invoke(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_DISPUTES_FETCH_BY_ACCOUNT, payload, { baseURL: configs.BASE_URL as string });

        if (res.data.count) {
            return res;
        } else {
            let newError = new Error()
            newError = { ...newError }
            throw newError
        }
    }
}