import { TextField, Button, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getPreSignedUrlClear,
  postShopSaveCompanyPoliciesAddonRequest,
} from "redux/actions";
import styles from "./style.module.scss";
import FileUpload from "components/fileUpload";
import Modal from "components/ui/Modal";
import Loading from "stores/Loading";
import { useAdonShopUpload, useHandlePreview } from "hooks";

interface companyPoliciesProp {
  setShopAddonCheck: React.Dispatch<React.SetStateAction<boolean>>;
}

type CompanyPolicesFromProps = {
  refundPolicyUrl: string;
  refundPolicy: string;
  termsAndConditionsUrl: string;
  termsAndConditions: string;
  deliveryPolicyUrl: string;
  deliveryPolicy: string;
  cancellationTimeFrame: string;
  deliveryTimeFrame: string;
  contactPageUrl: string;
  sizingGuideUrl: string;
  accountForInstagram: string;
  accountForFacebook: string;
  accountForYoutube: string;
  accountForPinterest: string;
  accountForTiktok: string;
  returnShippingAddressLine1: string;
  returnShippingAddressLine2: string;
  returnShippingAddressState: string;
  returnShippingAddressZip: string;
  returnShippingAddressCountryCode: string;
  returnShippingAddressCountry: string;
};

const CompanyPolicies = ({ setShopAddonCheck }: companyPoliciesProp) => {
  const { onFormSubmit } = useAdonShopUpload();
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const [refundPolicyUrl, setRefundPolicyUrl] = useState("");
  const [refundPolicy, setRefundPolicy] = useState("");
  const [termsAndConditionsUrl, setTermsAndConditionsUrl] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [deliveryPolicyUrl, setDeliveryPolicyUrl] = useState("");
  const [contactPageUrl, setContactPageUrl] = useState("");
  const [sizingGuideUrl, setSizingGuideUrl] = useState("");
  const [cancellationTimeFrame, setCancellationTimeFrame] = useState("");
  const [deliveryTimeFrame, setDeliveryTimeFrame] = useState("");
  const [shippingPolicyUrl, setShippingPolicyUrl] = useState("");
  const [deliveryPolicy, setDeliveryPolicy] = useState("");
  const [returnShippingAddressLine1, setReturnShippingAddressLine1] =
    useState("");
  const [accountForInstagram, setAccountForInstagram] = useState("");
  const [accountForFacebook, setAccountForFacebook] = useState("");
  const [accountForYoutube, setAccountForYoutube] = useState("");
  const [accountForPinterest, setAccountForPinterest] = useState("");
  const [accountForTiktok, setAccountForTiktok] = useState("");
  const [returnShippingAddressLine2, setReturnShippingAddressLine2] =
    useState("");
  const [returnShippingAddressState, setReturnShippingAddressState] =
    useState("");
  const [returnShippingAddressZip, setReturnShippingAddressZip] = useState("");
  const [
    returnShippingAddressCountryCode,
    setReturnShippingAddressCountryCode,
  ] = useState("");
  const [returnShippingAddressCountry, setReturnShippingAddressCountry] =
    useState("");

  const { register, handleSubmit } = useForm<CompanyPolicesFromProps>({});

  const { shopFindShopAddon } = useSelector((state: any) => state?.storesShop);
  const onSubmit = (data: any) => {
    const payload = {
      id: null,
      companyName: shopFindShopAddon?.data?.companyName
        ? shopFindShopAddon?.data?.companyName
        : null,
      background: shopFindShopAddon?.data?.background
        ? shopFindShopAddon?.data?.background
        : null,
      refundPolicy: refundPolicy ? refundPolicy : null,
      termsAndConditions: termsAndConditions ? termsAndConditions : null,
      deliveryPolicy: deliveryPolicy ? deliveryPolicy : null,
      refundPolicyUrl: refundPolicyUrl ? refundPolicyUrl : null,
      termsAndConditionsUrl:
        termsAndConditionsUrl ?? data?.termsAndConditionsUrl,
      deliveryPolicyUrl: deliveryPolicyUrl ? deliveryPolicyUrl : null,
      accountForInstagram: accountForInstagram ?? data.accountForInstagram,
      accountForFacebook: accountForFacebook ?? data.accountForFacebook,
      accountForYoutube: accountForYoutube ?? data.accountForYoutube,
      contactPageUrl: contactPageUrl ?? data.contactPageUrl,
      accountForPinterest: accountForPinterest ?? data.accountForPinterest,
      accountForTiktok: accountForTiktok ?? data.accountForTiktok,
      cancellationTimeFrame:
        cancellationTimeFrame ?? data.cancellationTimeFrame,
      deliveryTimeFrame: deliveryTimeFrame ?? data.deliveryTimeFrame,
      shippingPolicyUrl: shippingPolicyUrl ?? data.shippingPolicyUrl,
      sizingGuideUrl: sizingGuideUrl ?? data.sizingGuideUrl,
      cancellationTimeFrameImageUrl:
        shopFindShopAddon?.data?.cancellationTimeFrameImageUrl,
      shippingPolicyImageUrl: shopFindShopAddon?.data?.shippingPolicyImageUrl,
      deliveryPolicyImageUrl: shopFindShopAddon?.data?.deliveryPolicyImageUrl,
      refundPolicyImageUrl: shopFindShopAddon?.data?.refundPolicyImageUrl,
      termsAndConditionsImageUrl:
        shopFindShopAddon?.data?.termsAndConditionsImageUrl,
      sizingGuideImageUrl: shopFindShopAddon?.data?.sizingGuideImageUrl,
      contactPageImageUrl: shopFindShopAddon?.data?.contactPageImageUrl,
      address: {
        id: null,
        line1: shopFindShopAddon?.data?.line1
          ? shopFindShopAddon?.data?.line1
          : null,
        line2: shopFindShopAddon?.data?.line2
          ? shopFindShopAddon?.data?.line2
          : null,
        state: shopFindShopAddon?.data?.state
          ? shopFindShopAddon?.data?.state
          : null,
        zip: shopFindShopAddon?.data?.zip ? shopFindShopAddon?.data?.zip : null,
        countryCode: shopFindShopAddon?.data?.countryCode
          ? shopFindShopAddon?.data?.countryCode
          : null,
        country: shopFindShopAddon?.data?.country
          ? shopFindShopAddon?.data?.country
          : null,
      },
      returnShippingAddress: {
        id: null,
        line1: returnShippingAddressLine1 ? returnShippingAddressLine1 : null,
        line2: returnShippingAddressLine2 ? returnShippingAddressLine2 : null,
        state: returnShippingAddressState ? returnShippingAddressState : null,
        zip: returnShippingAddressZip ? returnShippingAddressZip : null,
        countryCode: returnShippingAddressCountryCode
          ? returnShippingAddressCountryCode
          : null,
        country: returnShippingAddressCountry
          ? returnShippingAddressCountry
          : null,
      },
      shopId: Number(storeId),
    };
    setShopAddonCheck(true);
    dispatch(postShopSaveCompanyPoliciesAddonRequest(payload));
  };
  const handleShopAddOnData = () => {
    shopFindShopAddon?.data?.refundPolicyUrl &&
      setRefundPolicyUrl(shopFindShopAddon?.data?.refundPolicyUrl);
    shopFindShopAddon?.data?.refundPolicy &&
      setRefundPolicy(shopFindShopAddon?.data?.refundPolicy);
    shopFindShopAddon?.data?.termsAndConditionsUrl &&
      setTermsAndConditionsUrl(shopFindShopAddon?.data?.termsAndConditionsUrl);
    shopFindShopAddon?.data?.termsAndConditions &&
      setTermsAndConditions(shopFindShopAddon?.data?.termsAndConditions);
    shopFindShopAddon?.data?.deliveryPolicyUrl &&
      setDeliveryPolicyUrl(shopFindShopAddon?.data?.deliveryPolicyUrl);
    shopFindShopAddon?.data?.contactPageUrl &&
      setContactPageUrl(shopFindShopAddon?.data?.contactPageUrl);
    shopFindShopAddon?.data?.sizingGuideUrl &&
      setSizingGuideUrl(shopFindShopAddon?.data?.sizingGuideUrl);
    shopFindShopAddon?.data?.cancellationTimeFrame &&
      setCancellationTimeFrame(shopFindShopAddon?.data?.cancellationTimeFrame);
    shopFindShopAddon?.data?.deliveryTimeFrame &&
      setDeliveryTimeFrame(shopFindShopAddon?.data?.deliveryTimeFrame);
    shopFindShopAddon?.data?.shippingPolicyUrl &&
      setShippingPolicyUrl(shopFindShopAddon?.data?.shippingPolicyUrl);
    shopFindShopAddon?.data?.deliveryPolicy &&
      setDeliveryPolicy(shopFindShopAddon?.data?.deliveryPolicy);

    shopFindShopAddon?.data?.returnShippingAddress?.line1 &&
      setReturnShippingAddressLine1(
        shopFindShopAddon?.data?.returnShippingAddress?.line1
      );
    shopFindShopAddon?.data?.accountForInstagram &&
      setAccountForInstagram(shopFindShopAddon?.data?.accountForInstagram);
    shopFindShopAddon?.data?.accountForFacebook &&
      setAccountForFacebook(shopFindShopAddon?.data?.accountForFacebook);
    shopFindShopAddon?.data?.accountForYoutube &&
      setAccountForYoutube(shopFindShopAddon?.data?.accountForYoutube);
    shopFindShopAddon?.data?.accountForPinterest &&
      setAccountForPinterest(shopFindShopAddon?.data?.accountForPinterest);
    shopFindShopAddon?.data?.accountForTiktok &&
      setAccountForTiktok(shopFindShopAddon?.data?.accountForTiktok);
    shopFindShopAddon?.data?.returnShippingAddress?.line2 &&
      setReturnShippingAddressLine2(
        shopFindShopAddon?.data?.returnShippingAddress?.line2
      );
    shopFindShopAddon?.data?.returnShippingAddress?.state &&
      setReturnShippingAddressState(
        shopFindShopAddon?.data?.returnShippingAddress?.state
      );
    shopFindShopAddon?.data?.returnShippingAddress?.zip &&
      setReturnShippingAddressZip(
        shopFindShopAddon?.data?.returnShippingAddress?.zip
      );
    shopFindShopAddon?.data?.returnShippingAddress?.countryCode &&
      setReturnShippingAddressCountryCode(
        shopFindShopAddon?.data?.returnShippingAddress?.countryCode
      );
    shopFindShopAddon?.data?.returnShippingAddress?.country &&
      setReturnShippingAddressCountry(
        shopFindShopAddon?.data?.returnShippingAddress?.country
      );
  };
  useEffect(() => {
    if (shopFindShopAddon?.isSuccess && shopFindShopAddon?.data) {
      handleShopAddOnData();
    }
  }, [shopFindShopAddon]);
  const { handlePreview, data, isLoading, isSuccess } = useHandlePreview();

  const initState = {
    deliveryPolicy: "",
    refundPolicy: "",
    termsAndConditon: "",
    sizingGuide: "",
    contactPage: "",
    shopAddonCancellation: "",
    loading: false,
  };
  const [uploadFile, setUploadFile] = useState<any>(initState);
  const handleFileUpload = (doc: any, type: string) => {
    if (type === "SHOP_ADDON_DELIVERY_POLICY") {
      setUploadFile(() => ({
        ...initState,
        deliveryPolicy: doc[0],
        loading: true,
      }));
      onFormSubmit(doc[0], type, setUploadFile, true);
    } else if (type === "SHOP_ADDON_REFUND_POLICY") {
      setUploadFile(() => ({
        ...initState,
        refundPolicy: doc[0],
        loading: true,
      }));
      onFormSubmit(doc[0], type, setUploadFile, true);
    } else if (type === "SHOP_ADDON_TERMS_AND_CONDITIONS") {
      setUploadFile(() => ({
        ...initState,
        termsAndConditon: doc[0],
        loading: true,
      }));
      onFormSubmit(doc[0], type, setUploadFile, true);
    } else if (type === "SHOP_ADDON_SIZING_GUIDE") {
      setUploadFile(() => ({
        ...initState,
        sizingGuide: doc[0],
        loading: true,
      }));
      onFormSubmit(doc[0], type, setUploadFile, true);
    } else if (type === "SHOP_ADDON_CONTACT") {
      setUploadFile(() => ({
        ...initState,
        contactPage: doc[0],
        loading: true,
      }));
      onFormSubmit(doc[0], type, setUploadFile, true);
    } else if (type === "SHOP_ADDON_CANCELLATION_TIME_FRAME") {
      setUploadFile(() => ({
        ...initState,
        shopAddonCancellation: doc[0],
        loading: true,
      }));
      onFormSubmit(doc[0], type, setUploadFile, true);
    }
  };
  return (
    <div className={styles.formWrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal
          open={isSuccess}
          onClose={() => dispatch(getPreSignedUrlClear())}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <img
              style={{
                maxWidth: "100%",
                height: "auto",
                display: "block",
                margin: "auto", // Optional: center the image
              }}
              src={data}
              alt=""
            />
          )}
        </Modal>
        <div className={styles.formBuild}>
          <div className={styles.textFields}>
            <div className={styles.inputbox}>
              <label htmlFor="templateContent">Refund Policy Url</label>
              <input
                placeholder="Refund Policy Url..."
                {...register("refundPolicyUrl")}
                onChange={(e) => setRefundPolicyUrl(e.target.value)}
                value={refundPolicyUrl}
              />
            </div>
            <div className={styles.message}>
              <label>Refund Policy</label>
              <TextField
                placeholder="Refund Policy..."
                {...register("refundPolicy")}
                multiline
                rows={4}
                className={styles.textField}
                onChange={(e) => setRefundPolicy(e.target.value)}
                value={refundPolicy}
              />
            </div>
            <FileUpload
              {...{
                styles,
                handleFileUpload,
                checkFile: uploadFile?.refundPolicy,
                type: "SHOP_ADDON_REFUND_POLICY",
                label: "Refund Policy Image Url",
                imageUrl: shopFindShopAddon?.data?.refundPolicyImageUrl,
                handlePreview,
              }}
            />
            <div className={styles.inputbox}>
              <label htmlFor="templateContent">Terms and Conditions Url</label>
              <input
                placeholder="Terms and Conditions Url..."
                {...register("termsAndConditionsUrl")}
                onChange={(e) => setTermsAndConditionsUrl(e.target.value)}
                value={termsAndConditionsUrl}
              />
            </div>
            <div className={styles.message}>
              <label>Terms and Conditions</label>
              <TextField
                placeholder="Terms and Conditions..."
                {...register("termsAndConditions")}
                multiline
                rows={4}
                className={styles.textField}
                onChange={(e) => setTermsAndConditions(e.target.value)}
                value={termsAndConditions}
              />
            </div>
            <FileUpload
              {...{
                styles,
                handleFileUpload,
                checkFile: uploadFile?.termsAndConditon,
                type: "SHOP_ADDON_TERMS_AND_CONDITIONS",
                label: "Terms And Conditions Image Url",
                imageUrl: shopFindShopAddon?.data?.termsAndConditionsImageUrl,
                handlePreview,
              }}
            />

            <div className={styles.inputbox}>
              <label htmlFor="templateContent">Delivery Policy Url</label>
              <input
                placeholder="Delivery Policy Url..."
                {...register("deliveryPolicyUrl")}
                onChange={(e) => setDeliveryPolicyUrl(e.target.value)}
                value={deliveryPolicyUrl}
              />
            </div>
            <div className={styles.message}>
              <label>Delivery Policy</label>
              <TextField
                placeholder="Delivery Policy..."
                {...register("deliveryPolicy")}
                multiline
                rows={4}
                className={styles.textField}
                onChange={(e) => setDeliveryPolicy(e.target.value)}
                value={deliveryPolicy}
              />
            </div>
            <FileUpload
              {...{
                styles,
                handleFileUpload,
                checkFile: uploadFile?.deliveryPolicy,
                type: "SHOP_ADDON_DELIVERY_POLICY",
                label: "Delivery Policy Image Url",
                imageUrl: shopFindShopAddon?.data?.deliveryPolicyImageUrl,
                handlePreview,
              }}
            />
            <div className={styles.message}>
              <label>Delivery Time Frame</label>
              <TextField
                placeholder="Delivery Time Frame"
                {...register("deliveryTimeFrame")}
                multiline
                rows={4}
                className={styles.textField}
                onChange={(e) => setDeliveryTimeFrame(e.target.value)}
                value={deliveryTimeFrame}
              />
            </div>
            {/* Cancellation time frame */}
            <div className={styles.message}>
              <label>Cancellation Time Frame</label>
              <TextField
                placeholder="Cancellation Time Frame"
                {...register("cancellationTimeFrame")}
                multiline
                rows={4}
                className={styles.textField}
                onChange={(e) => setCancellationTimeFrame(e.target.value)}
                value={cancellationTimeFrame}
              />
            </div>

            <FileUpload
              {...{
                styles,
                handleFileUpload,
                checkFile: uploadFile?.shopAddonCancellation,
                type: "SHOP_ADDON_CANCELLATION_TIME_FRAME",
                label: "Cancellation TimeFrame Image",
                imageUrl:
                  shopFindShopAddon?.data?.cancellationTimeFrameImageUrl,
                handlePreview,
              }}
            />

            {/* Shipping Policy */}
            {/* <div className={styles.inputbox}>
              <label htmlFor="templateContent">Shipping Policy Url</label>
              <input
                placeholder="Shipping Policy Url..."
                {...register("shippingPolicyUrl")}
                onChange={(e) => setShippingPolicyUrl(e.target.value)}
                value={shippingPolicyUrl}
              />
            </div>
            <div className={styles.message}>
              <label>Shipping Policy</label>
              <TextField
                placeholder="Shipping Policy"
                {...register("shippingPolicy")}
                multiline
                rows={4}
                className={styles.textField}
                onChange={(e) => setDeliveryPolicy(e.target.value)}
                value={deliveryPolicy}
              />
            </div>

            <FileUpload
              {...{
                styles,
                handleFileUpload,
                checkFile: uploadFile?.deliveryPolicy, //could be shipping policy
                type: "SHOP_ADDON_SHIPPING_POLICY",
                label: "Shipping Policy Image",
                imageUrl: shopFindShopAddon?.data?.shippingPolicyImageUrl,
                handlePreview,
              }}
            /> */}
          </div>
          <div className={styles.textFieldsFourColumn}>
            <div className={styles.inputbox}>
              <label htmlFor="templateContent">Contact Page Url</label>
              <input
                placeholder="Contact Page Url..."
                {...register("contactPageUrl")}
                onChange={(e) => setContactPageUrl(e.target.value)}
                value={contactPageUrl}
              />
            </div>

            <FileUpload
              {...{
                styles,
                handleFileUpload,
                checkFile: uploadFile?.contactPage,
                type: "SHOP_ADDON_CONTACT",
                label: "Contact Page Image Url",
                imageUrl: shopFindShopAddon?.data?.contactPageImageUrl,
                handlePreview,
              }}
            />
            <div className={styles.inputbox}>
              <label htmlFor="templateContent">Sizing Guide Url</label>
              <input
                placeholder="Sizing Guide Url..."
                {...register("sizingGuideUrl")}
                onChange={(e) => setSizingGuideUrl(e.target.value)}
                value={sizingGuideUrl}
              />
            </div>

            <FileUpload
              {...{
                styles,
                handleFileUpload,
                checkFile: uploadFile?.sizingGuide,
                type: "SHOP_ADDON_SIZING_GUIDE",
                label: "Sizing Guide Image Url",
                imageUrl: shopFindShopAddon?.data?.sizingGuideImageUrl,
                handlePreview,
              }}
            />
          </div>
          {/* Social Links */}
          <Box className={styles.addressWrapper}>
            <label htmlFor="address" className={styles.addressHead}>
              Social Links:
            </label>
            <Box>
              <div className={styles.linesAddressWrapper}>
                <div className={styles.linesAddress}>
                  <label>Account For Instagram</label>
                  <input
                    placeholder="Account For Instagram"
                    {...register("accountForInstagram")}
                    onChange={(e) => setAccountForInstagram(e.target.value)}
                    value={accountForInstagram}
                  />
                </div>
                <div className={styles.linesAddress}>
                  <label>Account For Facebook</label>
                  <input
                    placeholder="Account For Facebook"
                    {...register("accountForFacebook")}
                    onChange={(e) => setAccountForFacebook(e.target.value)}
                    value={accountForFacebook}
                  />
                </div>
              </div>
              <div className={styles.linesAddressWrapper}>
                <div className={styles.linesAddress}>
                  <label>Account For Youtube</label>
                  <input
                    placeholder="Set Account For Youtube"
                    {...register("accountForYoutube")}
                    onChange={(e) => setAccountForYoutube(e.target.value)}
                    value={accountForYoutube}
                  />
                </div>
                <div className={styles.linesAddress}>
                  <label>Account For Pinterest</label>
                  <input
                    placeholder="Account For Pinterest"
                    {...register("accountForPinterest")}
                    onChange={(e) => setAccountForPinterest(e.target.value)}
                    value={accountForPinterest}
                  />
                </div>
              </div>
              <div className={styles.linesAddressWrapper}>
                <div className={styles.linesAddress}>
                  <label>Account For Tiktok</label>
                  <input
                    placeholder="Set Account For Tiktok"
                    {...register("accountForTiktok")}
                    onChange={(e) => setAccountForTiktok(e.target.value)}
                    value={accountForTiktok}
                  />
                </div>
              </div>
            </Box>
          </Box>
          {/* returnShippingAddress */}
          <div className={styles.addressWrapper}>
            <label htmlFor="address" className={styles.addressHead}>
              Return Shipping Address:
            </label>

            <div className={styles.linesAddressWrapper}>
              <div className={styles.linesAddress}>
                <label>Line1</label>
                <input
                  placeholder="Enter Value Line 1"
                  {...register("returnShippingAddressLine1")}
                  onChange={(e) =>
                    setReturnShippingAddressLine1(e.target.value)
                  }
                  value={returnShippingAddressLine1}
                />
              </div>
              <div className={styles.linesAddress}>
                <label>Line2</label>
                <input
                  placeholder="Enter Value Line 2"
                  {...register("returnShippingAddressLine2")}
                  onChange={(e) =>
                    setReturnShippingAddressLine2(e.target.value)
                  }
                  value={returnShippingAddressLine2}
                />
              </div>
            </div>

            <div className={styles.addressWithCodes}>
              <div className={styles.addressState}>
                <label>State</label>
                <input
                  placeholder="Enter State"
                  {...register("returnShippingAddressState")}
                  onChange={(e) =>
                    setReturnShippingAddressState(e.target.value)
                  }
                  value={returnShippingAddressState}
                />
              </div>
              <div className={styles.addressCode}>
                <label>ZIP</label>
                <input
                  placeholder="XXXX"
                  {...register("returnShippingAddressZip")}
                  onChange={(e) => setReturnShippingAddressZip(e.target.value)}
                  value={returnShippingAddressZip}
                />
              </div>
              <div className={styles.addressCode}>
                <label>Country Code</label>
                <input
                  placeholder="XXXX"
                  {...register("returnShippingAddressCountryCode")}
                  onChange={(e) =>
                    setReturnShippingAddressCountryCode(e.target.value)
                  }
                  value={returnShippingAddressCountryCode}
                />
              </div>
              <div className={styles.addressState}>
                <label>Country</label>
                <input
                  placeholder="Enter Country"
                  {...register("returnShippingAddressCountry")}
                  onChange={(e) =>
                    setReturnShippingAddressCountry(e.target.value)
                  }
                  value={returnShippingAddressCountry}
                />
              </div>
            </div>
          </div>
        </div>
        <Button variant="contained" type="submit">
          Save
        </Button>
      </form>
    </div>
  );
};

export default CompanyPolicies;
