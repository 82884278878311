import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";
const ComponentIndex = (props: any) => {
  return (
    <NodeViewWrapper data-drag-handle="" draggable={true}>
      <p
        style={{
          padding: "4px 0px",
          backgroundColor: "#f0f0f0",
          cursor: "pointer",
        }}
      ></p>
    </NodeViewWrapper>
  );
};
export default Node.create({
  name: "customHorizontal",
  group: "block",
  atom: true,
  inline: false,
  draggable: true,
  parseHTML() {
    return [
      {
        tag: "customHorizontal",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["customHorizontal", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ComponentIndex);
  },
});
