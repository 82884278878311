import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";


export class GetBillingReportList {
    async invokeTotalBilledAmount(paylaod: any) {
      const res = await ApiService.getInstance().get(apiConstants.GET_TOTAL_BILLED_AMOUNT(paylaod))
      return res;
  }
    async invokeTotalAmount(paylaod: any) {
      const res = await ApiService.getInstance().get(apiConstants.GET_TOTAL_AMOUNT(paylaod))
      return res;
  }
    async invokeNewBillingTotalBilledAmount(paylaod: any) {
      const res = await ApiService.getInstance().get(apiConstants.GET_NEW_BILLING_TOTAL_BILLED_AMOUNT(paylaod))
      return res;
  }
    async invokeNewBillingTotalAmount(paylaod: any) {
      const res = await ApiService.getInstance().get(apiConstants.GET_NEW_BILLING_TOTAL_AMOUNT(paylaod))
      return res;
  }
    async invokeReadyDisputeTotalBilledAmount(paylaod: any) {
      const res = await ApiService.getInstance().get(apiConstants.GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT(paylaod))
      return res;
  }
    async invokeReadyDisputeTotalAmount(paylaod: any) {
      const res = await ApiService.getInstance().get(apiConstants.GET_READY_DISPUTE_TOTAL_AMOUNT(paylaod))
      return res;
  } 
}