import { useState } from "react";
import { Box } from "@mui/material";
import {
  Handle,
  NodeProps,
  Position,
  getConnectedEdges,
  useReactFlow,
} from "reactflow";
import {
  AddCircleRounded,
  ArrowDownwardRounded,
  PlayArrowRounded,
} from "@mui/icons-material";
import styles from "./style.module.scss";
import {
  ConditionItem,
  PlayIcon,
  ToolTipWrapper,
} from "components/disputeFlow/NodeHelpers";
import BasicPopover from "components/disputeFlow/NodeHelpers/FlowPopover";

const StartNode = (props: NodeProps<any>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { getEdges } = useReactFlow();
  const getConnectedEdge = getConnectedEdges([props] as any, getEdges());
  return (
    <Box
      minWidth={"268px"}
      minHeight={100}
      boxShadow={2}
      border={1}
      borderColor={props.selected ? "black" : "#cccccc"}
      borderRadius={"12px"}
      position={"relative"}
      className={styles.startNodeWrapper}
    >
      <PlayArrowRounded fontSize="large" className={styles.playrightIcon} />
      <Box padding={4}>
        <Box
          mb={1}
          display={`flex`}
          justifyContent={`start`}
          alignItems={`center`}
          gap={1}
        >
          <PlayIcon />
          <p className={styles.label}>{props.data?.label}</p>
        </Box>
        {!props.data.conditions[0].source && (
          <p className={styles.subLabel}>Reason is</p>
        )}
        <ConditionItem
          styles={styles}
          conditions={props.data.conditions}
          id={props.id}
        />
      </Box>

      <BasicPopover
        props={props}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      <div
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e as any);
        }}
      >
        <ToolTipWrapper
          title="Click to add new node"
          component={
            <Handle
              className={styles.startNodeHandle}
              type="source"
              position={Position.Bottom}
              style={{
                background: getConnectedEdge.length ? "#225CCB" : "white",
              }}
            >
              {getConnectedEdge.length ? (
                <ArrowDownwardRounded className={styles.arrowDownwardStyle} />
              ) : (
                <AddCircleRounded className={styles.arrowCircleRoundStyle} />
              )}
            </Handle>
          }
        />
      </div>
    </Box>
  );
};

export default StartNode;
