import { useDispatch } from "react-redux";
import { postUpdateManagerByDisputeIdRequest, postUpdateManagerByShopIdRequest } from "redux/actions";

const useUpdateManager = () => {
  const dispatch = useDispatch();
const handleUpdateManager = (data:any, dispute?:boolean) => {
    dispute ? dispatch(postUpdateManagerByDisputeIdRequest(data)) : dispatch(postUpdateManagerByShopIdRequest(data)) 
}
  return { handleUpdateManager };
};

export default useUpdateManager;

