import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
export class GetDisputeTransactionDetails {
    /**
     * Function to invoke to get dispute transaction details
     */
    async invoke(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_TRANSACTION_DETAILS(payload));

        return res;
    }

    async invokeOrderSummary(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_ORDER_SUMMARY(payload));

        return res;
    }
}