import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Avatar, Box, Tooltip } from "@mui/material";
import styles from "./style.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useUpdateManager } from "hooks";
import { useEffect, useState } from "react";
interface UpdateManagerTypes {
  toUsers?: any;
  singleStore?: any;
  dispute?: boolean;
}

const InlineUpdateManager = ({
  toUsers,
  singleStore,
  dispute,
}: UpdateManagerTypes) => {
  const [selectedValue, setSelectedValue] = useState<any>();
  const defaultValue =
    toUsers?.length > 0 &&
    toUsers?.find((item: any) => item?.userName === singleStore?.manager);
  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    } else {
      setSelectedValue("");
    }
  }, [defaultValue]);
  const { handleUpdateManager } = useUpdateManager();
  const handleUpdate = (e: SelectChangeEvent, f: any) => {
    const { value, children } = f.props;
    const dataSubmitted = {
      id: value,
      userName: children,
    };
    setSelectedValue(dataSubmitted);
    const data = {
      shopId: dispute ? singleStore.shopId : singleStore.id,
      disputeId: dispute ? singleStore.id : null,
      managerId: dataSubmitted.id,
    };
    handleUpdateManager(data, dispute);
  };

  return (
    <Box className={styles.selectUpdateWrapper}>
      <FormControl id="blur-on-select" variant="standard">
        <Select
          labelId="demo-select-small-label"
          id="blur-on-select"
          onChange={(e, f) => handleUpdate(e, f as any)}
          value={selectedValue?.id ?? ""}
          IconComponent={KeyboardArrowDownIcon}

          renderValue={(selected) => (
            <Tooltip
              title={selectedValue?.userName ?? "manager"}
              arrow
              placement="top"
            >
              <Avatar
                className={styles.avatarStyle}
                sx={{
                  width: 38,
                  height: 38,
                  padding: "10px",
                  bgcolor: "#DDDFE2",
                }}
              >
                <span>
                  {selectedValue?.userName?.split("")[0]}
                  {selectedValue?.userName?.split("")[1]}
                </span>
              </Avatar>
            </Tooltip>
          )}
          className={styles.selectStyle}
        >
          {toUsers &&
            toUsers.length &&
            toUsers.map((item: any, index: any) => (
              <MenuItem value={item?.id} key={index}>
                {item.userName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default InlineUpdateManager;
