import { NodeUsesTypes, generateUUID } from 'components/disputeFlow/NodeHelpers/Utils';
import { NodeProps, useReactFlow } from "reactflow";

const useUpdateNode = () => {
  const { setNodes } = useReactFlow();
  const handleUpdateNode = (currentNode: any, eventData: string, openSideBar:any,label:any) => {
    if(Object.values(NodeUsesTypes).includes(currentNode.type) && currentNode.conditionId){
      const conditions = currentNode.data.conditions;
      const index = conditions.findIndex((item:any) => item.id === currentNode.conditionId);
      if (index !== -1) {
        conditions[index]["label"] = currentNode.type === NodeUsesTypes.ActionNode ? label :  eventData;
        conditions[index]["source"] = openSideBar.name;
        if(currentNode.type === NodeUsesTypes.ActionNode){
          conditions[index]["id"] = eventData;
        }
        setNodes((nodes) => {
          const clonedNodes = [...nodes];
          const maped = clonedNodes.map((item) => {
            if (currentNode.id === item.id) {
              item.data = Object.assign({}, item.data, { conditions });
              return item;
            }
            return item;
          });
          return maped;
        });
      }
    }else{
      setNodes((nodes) => {
        const clonedNodes = [...nodes];
        const maped = clonedNodes.map((item) => {
          if (currentNode.id === item.id) {
            item.data.condition = eventData;
            return item;
          }
          return item;
        });
        return maped;
      });
    }
  };
  const handleUpdatFormSubmit = (data: any, currentNode: any, openSideBar:any) => {
    if(currentNode.type === NodeUsesTypes.ConditionNode && currentNode.conditionId){
      const conditions = currentNode.data.conditions;
      const conditionLabel = `${data.name}`
      const sourceLabel = `${openSideBar.name} ${data.dispute_amount.label}`
      const index = conditions.findIndex((item:any) => item.id === currentNode.conditionId);
      if (index !== -1) {
        conditions[index]["label"] = conditionLabel;
        conditions[index]["source"] = sourceLabel;
        setNodes((nodes) => {
          const clonedNodes = [...nodes];
          const maped = clonedNodes.map((item) => {
            if (currentNode.id === item.id) {
              item.data.conditions = conditions;
              return item;
            }
            return item;
          });
          return maped;
        });
      }
    }else{
      setNodes((nodes) => {
        const clonedNodes = [...nodes];
        const maped = clonedNodes.map((item) => {
          if (currentNode.id === item.id) {
            item.data.condition = data;
            return item;
          }
          return item;
        });
        return maped;
      });
    }

  };
const handleSubmitCheck = (checked:boolean,currentNode:NodeProps) => {
  setNodes((nodes) => {
    const clonedNodes = [...nodes];
    const maped = clonedNodes.map((item) => {
      if (currentNode.id === item.id) {
        item.data.conditions[0].additionalArguments.hasAttachment = checked
        return item;
      }
      return item;
    });
    return maped;
  });
}

  const handleMultipleCondition = (id: string) => {
    const newObj = {
      label: "Select a condition",
      id: generateUUID(),
      source:""
    };
    setNodes((nodes) => {
      const clonedNodes = [...nodes];
      const maped = clonedNodes.map((item) => {
        if (item.id === id) {
          item.data.conditions.push(newObj);
          return item;
        }
        return item;
      });
      return maped;
    });
  };
  const handleDeleteCondition = (id: string, nodeId: string) => {
    setNodes((nodes) => {
      const clonedNodes = [...nodes];
      const maped = clonedNodes.map((item) => {
        if (item.id === nodeId) {
          const newlyState = item.data.conditions.filter(
            (items: any) => items.id !== id
          );
          item.data.conditions = newlyState;
          return item;
        }
        return item;
      });
      return maped;
    });
  };

  return {
    handleUpdateNode,
    handleUpdatFormSubmit,
    handleMultipleCondition,
    handleDeleteCondition,
    handleSubmitCheck
  };
};


export default useUpdateNode;
