import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postConnectPayPalRequest } from "redux/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { generatePayPalAuthorizeUrl } from "utils/GenerateStoreUrls";
import styles from "./style.module.scss";
import { svgIcons } from "elements";
import { Loading } from "../index";
interface Props {
  handleConnect: () => void;
}

const Connect: FC<Props> = ({ handleConnect }) => {
  const dispatch = useDispatch();

  const paypalStatus = useSelector((state: any) => state.connectPayPalStore);
  const { isLoading, isError, isSuccess } = paypalStatus.connectPaypal;

  const code = new URLSearchParams(window.location.search).get("code");

  const onAddAccountClick = (store: string) => {
    let redirectUrl = "";
    if (store === "PayPal") {
      redirectUrl = generatePayPalAuthorizeUrl();
    }
    return redirectUrl;
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("PayPal connect successfully", {
        theme: "colored",
      });
      setTimeout(() => {
        handleConnect();
      }, 4000);
    } else if (isError) {
      toast.error("Oops! PayPal connect fail. Please try again", {
        theme: "colored",
      });
    }
  }, [paypalStatus]);

  useEffect(() => {
    if (code) {
      const payload = {
        code,
      };
      dispatch(postConnectPayPalRequest(payload));
    }
  }, [code]);

  return (
    <div className={styles.connectWrapper}>
      <ToastContainer />
      <div className={styles.connectLeft}>
        <div className={styles.lineConnect}>
          <img src={svgIcons.TPConnect} alt="tp" />
          <img src={svgIcons.TPConnect} alt="tp" />
          <img src={svgIcons.TPConnect} alt="tp" />
          <div className={styles.line} />
        </div>
        <div>
          <h3>Connect your account</h3>
          <p>
            Your store details are synced below!Now it's time to connect to your
            PayPal
          </p>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.info}>
            <div className={styles.icon}></div>
            BigCommerce
          </div>
          <div className={styles.info}>
            <div className={styles.icon}></div>
            BreakPie Store
          </div>
          <div className={styles.info}>
            <div className={styles.icon}></div>
            info@breakpie.com
          </div>
          <div className={styles.secure}>100% secure connection</div>
        </div>
      </div>
      <div className={styles.connectRight}>
        <p>Please connect an account to get started with us</p>
        <button>
          {!isLoading ? (
            <a target="_blank" href={onAddAccountClick("PayPal")}>
              <img src={svgIcons.PayPalFill} alt="tp" />
              Connect PayPal
              <p className={styles.plus}>&#43;</p>
            </a>
          ) : (
            <div>
              <Loading />
            </div>
          )}
        </button>

        {/* please remove it later */}
        <button onClick={() => handleConnect()}>Next, temporary button</button>

        <img
          className={styles.paypalBig}
          src={svgIcons.PaypalBig}
          alt="paypalBig"
        />
      </div>
    </div>
  );
};

export default Connect;
