import { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import styles from "./style.module.scss";
import {
  useBreadCumbs,
} from "hooks";
import { BreadcumbItem } from "components/ui";
import GlobalReport from "components/globalReports";
import ReportByStores from "components/ReportByStores";
import ReportByPsp from "components/reportByPsp";
import ReportByReason from "components/reportByReasons";
import ReportByMonthly from "components/reportsByMonthly";

type TShop = {
  value: number;
  label: string;
};
interface Props {
  shop?: TShop;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt:"16px" }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Reporting = ({ shop }: Props) => {
  // handling tabs
  const [value, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  //custom hooks
  const { breadCumbs } = useBreadCumbs({ pageName: "Reporting" });
  const tabItems = [
    {
      label: "Global Report",
      func:a11yProps,
    },
    {
      label: "By Stores",
      func: a11yProps,
    },
    {
      label: "By PSP",
      func: a11yProps,
    },
    {
      label: "By Reason",
      func:a11yProps,
    },
    {
      label: "By Monthly",
      func:a11yProps,
    },
  ];

  return (
    <div
      style={shop ? { paddingLeft: 0, paddingRight: 0, minHeight: 0 } : {}}
      className={styles.disputesWrapper}
    >
      <div className={styles.nav}>
          <h3>Reporting</h3>
          <div className={styles.navPath}>
            {breadCumbs.map((item, key) => (
              <BreadcumbItem {...item} {...{ key }} />
            ))}
          </div>
        </div>
      {/* implementation of tabs */}
      <Box sx={{ width: "100%"}}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: "#fff",
            borderRadius: "8px",
            padding:"0 25px"
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {
              tabItems.map((tab, index) => {
                return (
                  <Tab
                    sx={{ textTransform: "capitalize" }}
                    label={tab.label}
                    {...tab.func(index)}
                  />
                );
              })
            }
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <GlobalReport />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ReportByStores />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ReportByPsp />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <ReportByReason />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <ReportByMonthly />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default Reporting;
