import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getKlarnaByDisputeRequest } from "redux/actions";

const useKlarnaByDispute = (checked:boolean) => {
  const dispatch = useDispatch();
  const { disputeId } = useParams();

  const { isSuccess, data, isLoading } = useSelector(
    (state: any) => state?.klarnaStore?.klarnaRequestedField
  );
  useEffect(() => {
    if (disputeId && checked) {
      const payload = disputeId;
      dispatch(getKlarnaByDisputeRequest(payload));
    }
  }, [disputeId]);
  return { data, isSuccess, isLoading };
};

export default useKlarnaByDispute;
