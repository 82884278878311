/**
 * Hook for updating a collaborator's access level for a shop.
 * Dispatches an redux action to update the collaborator access in the store on change.
 * Handles initializing and updating the select value from the collaboratorAccess prop.
 */
import { SelectChangeEvent } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { updateShopCollaboratorAccessRequest } from "redux/actions";

const useUpdateCollaborator = (collaboratorAccess: string) => {
  const toUsers = [
    {
      id: 1,
      value: "Uninstalled",
    },
    {
      id: 2,
      value: "Requested",
    },
    {
      id: 3,
      value: "Approved",
    },
    {
      id: 4,
      value: "Access_Pending",
    },
    {
      id: 5,
      value: "Closed_Store",
    },
  ];
  const { storeId } = useParams();
  const dispatch = useDispatch();
  const [access, setAccess] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setAccess(event.target.value as any);
    const access: any = toUsers.find(
      (item: any) => item.id === event.target.value
    );
    dispatch(
      updateShopCollaboratorAccessRequest({
        id: storeId,
        collaboratorAccess: access.value,
      })
    );
  };

  React.useEffect(() => {
    if (collaboratorAccess) {
      const access: any = toUsers.find(
        (item) => item.value === collaboratorAccess
      );
      setAccess(access?.id);
    }
  }, [collaboratorAccess]);

  return {
    handleChange,
    access,
    toUsers,
  };
};

export default useUpdateCollaborator;
