import { useRef } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Node,
  Panel,
} from "reactflow";
import "reactflow/dist/style.css";
import { Button } from "@mui/material";
import { LogoutRounded } from "@mui/icons-material";
import styles from "./styles.module.scss";
import { FlowBuilderProps } from "../types";
import { useNavigate } from "react-router-dom";
import PanelSidebar from "./PanelSidebar";
import { FlowSideBar, ToolTipWrapper } from "../NodeHelpers";
import ControlButtons from "./ControlButtons";
/**
 *FlowBuilder component renders the Reactflow diagram
 * It handles events like node/edge clicks to allow interacting with the diagram
 * It also renders panels with buttons around the diagram.
 * The component receives all the data needed to render the diagram like nodes, edges etc
 * It also receives callbacks to handle events and update the diagram state in the parent component
 */
const FlowBuilder: React.FC<FlowBuilderProps> = ({
  edges,
  nodes,
  nodeTypes,
  proOptions,
  openSideBar,
  currentNode,
  isExpand,
  handleNodeClick,
  handleEdgeClick,
  onNodesChange,
  onEdgesChange,
  setCurrentNode,
  onPaneClick,
  setOpenSideBar,
  handleFullScreen,
}) => {
  const reactFlowWrapper = useRef(null);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/flows");
  };
  return (
    <div ref={reactFlowWrapper} className={styles.flowWrapper}>
      <ReactFlow
        {...{
          nodes,
          edges,
          nodeTypes,
          onNodesChange,
          onEdgesChange,
          onPaneClick,
          proOptions,
          minZoom: 0.4,
          maxZoom: 1,
          panOnScroll: true,
          nodesConnectable: false,
          onNodeClick: (event: React.MouseEvent, node: Node) => {
            handleNodeClick(event as any, node as any);
          },
          onEdgeClick: (event, edge) =>
            handleEdgeClick(event as any, edge as any),
        }}
      >
        <Background
          gap={30}
          variant={BackgroundVariant.Dots}
          color="#ccc"
          size={2}
        />
        <>
          <Panel position={"top-left"} style={{ display: "flex" }}>
            <ToolTipWrapper
              title="Click to exit without save"
              component={
                <Button
                  variant="outlined"
                  className={styles.exitButton}
                  endIcon={<LogoutRounded />}
                  onClick={handleNavigate}
                >
                  Exit
                </Button>
              }
            />
          </Panel>
          <PanelSidebar />
          <Panel
            position={"top-right"}
            style={{
              marginTop: "76px",
            }}
            className={styles.secondPanel}
          >
            <FlowSideBar
              {...{ currentNode, setCurrentNode, openSideBar, setOpenSideBar }}
            />
          </Panel>
          <Panel position={"bottom-left"}>
            <ControlButtons {...{ handleFullScreen, isExpand }} />
          </Panel>
        </>
      </ReactFlow>
    </div>
  );
};
export default FlowBuilder;
