import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
import { configs } from '../../../configs/index';
export class PostDisputeTemplate {
    /**
     * Function to invoke to dispute-template
     */
    async invoke(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_DISPUTE_TEMPLATE, payload, { baseURL: configs.BASE_URL as string });

        return res;
    }

    async invokeList(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_TEMPLATE_LIST(payload));

        return res;
    }

    async invokeFullList(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_TEMPLATE_FULL_LIST(payload));

        return res;
    }

    async invokeReasonList(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_TEMPLATE_REASON_LIST(payload));

        return res;
    }

    async invokeActionList(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_TEMPLATE_ACTION_LIST(payload));

        return res;
    }

    async invokeStageList(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_TEMPLATE_STAGE_LIST(payload));

        return res;
    }

    async invokeFindByType(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_TEMPLATE_FIND_BY_TYPE(payload));

        return res;
    }

    async invokeFindByStore(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_TEMPLATE_FIND_BY_STORE(payload));

        return res;
    }

    async invokeFilterTemplates(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES, payload, { baseURL: configs.BASE_URL as string });

        return res;
    }

    async invokeView(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_TEMPLATE_VIEW(payload));

        return res;
    }
    async invokeTemplatePreview(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_TEMPLATE_PREVIEW(payload));

        return res;
    }
    async invokeTemplateFetchContent(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_TEMPLATE_FETCH_CONTENT(payload));

        return res;
    }
}