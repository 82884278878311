import React, {
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy, cloneDeep } from 'lodash';
import { getFetchUnderReviewDisputesRequest, getFetchUnderReviewTotalBilledAmountRequest,getFetchUnderReviewTotalAmountRequest } from 'redux/actions';
import { SingleAccountBreadcrumb, Pagination, DisputesWidget } from 'components';
import styles from './style.module.scss';
import NewDisputeList from 'components/disputeDashboard/newDisputeList';
import { BreadcumbItem } from 'components/ui';
import { getItem } from 'utils/localStorage';
import { useBreadCumbs, useFindActiveStores, useStorelist, useStoreReportList } from 'hooks';
import Loading from 'components/loading/loading';
import cn from 'classnames';
import { Button } from '@mui/material';
import { underReviewStatusSection } from 'utils/disputeUtils';
import ReactSelect from 'react-select';
import { IconRender } from 'elements';

type ManagerUserType = {
  auth0Id: string;
  disputeCount: number;
  email: string;
  id: number;
  role: string;
  userId: string;
  userName: string;
};
type TShop = {
  value: number;
  label: string;
};  
const awaitingPageTableHeading = [
  
  ' Dispute ID + Store',
  'Create Time',
  'Dispute Amount',
  'Billable Amount',
  'Reason',
  'Carrier',
  'Last Mile Carrier',
  'Response Status',
  'Submission Date',
  // 'Manager',
  'Action',
];
interface Props {
  shop?: TShop;
}
const StoreReports = ({ shop }: Props) => {
  const dispatch = useDispatch();
  const SingleAccountCheck = getItem('singleAccountCheck');
  const { breadCumbs } = useBreadCumbs({ pageName: 'Store Reports' });
  const selectRef: any = useRef(null);
  const {findActiveStoreOptions} = useFindActiveStores()
  const [shopList, setShopList] = useState<TShop[]>([]);
  const [disputesPayload, setDisputesPayload] = useState<any>();
  const [page, setPage] = useState(1);
  const pageLength = 15;
  const [disputeStatusBtnCheck, setDisputeStatusBtnCheck] = useState(false);
  const [disputeClick, setDisputeClick] = useState(false);
  // this is for table main data
  const { data, isLoading, isSuccess, isError } = useSelector(
    (state: any) => state?.underReviewDisputes?.underReviewDisputesList
  );

  const {shopStoreReport} = useStoreReportList()
  console.log(shopStoreReport,'this is the actual data from the storeReports')

  // this is for under review totalBilled amount
  const { data:underReviewTotalBilledAmount } = useSelector(
    (state: any) => state?.underReviewDisputes?.underReviewTotalBilledAmount
  );
  // this is for under review totalAmount
  const { data:underReviewTotalAmount } = useSelector(
    (state: any) => state?.underReviewDisputes?.underReviewTotalAmount
  );
  // for pagination
  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const [disputeStatusButton, setDisputeStatusButton] = useState([
    ...underReviewStatusSection,
  ]);

  const handleDisputeStatus = (disputeStatus: any, index: number) => {
    const disputeBtn = cloneDeep(disputeStatusButton);
    disputeStatus.status = !disputeStatus.status;
    setDisputeStatusBtnCheck(!disputeStatusBtnCheck);
    setDisputeClick(true);
    disputeBtn[index] = disputeStatus;
    setDisputeStatusButton([...disputeBtn]);
  };
  // for handleStatus filter payload
  const handleStatusFilterPayload = (statusFilter: string[]) => {
    if (!SingleAccountCheck) {
      return {
        ...disputesPayload,
        paymentProcessors: statusFilter?.length ? statusFilter : null,
      };
    }
  };

  useEffect(() => {
    if (disputeClick) {
      const statusArr = disputeStatusButton.filter(
        statusData => statusData.status
      );
      const statusFilterArr = statusArr.map(item => {
        return item?.title?.toUpperCase();
      });
      const payload = handleStatusFilterPayload(statusFilterArr);
      setDisputesPayload(payload); //set the payload by filtering status button
    }
  }, [disputeStatusBtnCheck, disputeStatusButton]);

  // this is the modified code for getting the managers name
  const [manageUsersData, setManagerUsersData] = useState<ManagerUserType>();
  useEffect(() => {
    const modifyManagerData =
      data?.disputeResponseList?.length > 0 &&
      data?.disputeResponseList?.map((item: any) => {
        return {
          auth0Id: 'autho1',
          disputeCount: 0,
          email: `${item?.manager?.toLowerCase()}@gmail.com`,
          id: item?.id,
          role: 'MANAGER',
          userId: `${item?.manager?.toLowerCase()}@gmail.com`,
          userName: item?.manager,
        };
      });
    setManagerUsersData(modifyManagerData?.[0]);
  }, [data]);

  // for handle keydown in select component
  const blur = () => {
    selectRef.current?.blur();
  };
  const handleKeyDown: KeyboardEventHandler = (
    event: React.KeyboardEvent<Element>
  ) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        blur();
        const maped: any = shopList.map((item: any) => item.value);
        const payload = {
          ...disputesPayload,
          shopIds: maped.length ? maped : null,
        };
        setDisputesPayload(payload);
        event.preventDefault();
    }
  };

  // for shop filtering code
  useEffect(() => {
    if (shop) {
      setShopList(prev => uniqBy([...prev, shop], 'value'));
    }
  }, [shop]);
  useEffect(() => {
    if (shopList.length > 0) {
      const maped: any = shopList.map((item: any) => item.value);
      const payload = {
        ...disputesPayload,
        shopIds: maped.length ? maped : null,
      };
      setDisputesPayload(payload);
    }
  }, [shopList]);

  // for calling all api
  useEffect(() => {
    const payload = {
      page,
      pageLength,
      ...disputesPayload,
    };
    dispatch(getFetchUnderReviewDisputesRequest(payload));
    dispatch(getFetchUnderReviewTotalAmountRequest({...payload,cardType:'totalAmount'}))
    dispatch(getFetchUnderReviewTotalBilledAmountRequest({...payload,cardType:"totalBilledAmount"}))
  }, [page, disputesPayload]);

  return (
    <div className={styles.disputesWrapper}>
      {SingleAccountCheck === 'true' ? (
        <SingleAccountBreadcrumb />
      ) : (
        <div className={styles.nav}>
          <h3>Store Reports</h3>
          <div className={styles.navPath}>
            {breadCumbs?.map((item, key) => (
              <BreadcumbItem key={key} {...item} />
            ))}
          </div>
        </div>
      )}

      <div className={styles.search}>
        <div className={styles.searchTop}>
          <div className={styles.searchDiv}>
            <ReactSelect
              ref={selectRef}
              className={styles.multipleSelect}
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  minHeight: '44px',
                }),
              }}
              closeMenuOnSelect={false}
              onKeyDown={handleKeyDown}
              placeholder={'Select shops..'}
              options={findActiveStoreOptions}
              isMulti
              value={shopList}
              onChange={(e: any) => setShopList(e)}
            />
          </div>
        </div>
      </div>

      <div>
        {isLoading ? (
          <div style={{ background: '#fff', borderRadius: '8px' }}>
            <Loading />
          </div>
        ) : (
          <NewDisputeList
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            noSellerResponse={true}
            data={data}
            manageManager={{ data: [manageUsersData ?? []] }}
            DisputeListColumnNames={awaitingPageTableHeading}
            handleDisputeStatus={dispute => dispute.status}
            isShowCollapsibleTablel={false}
          />
        )}
      </div>
      {/* {Math.ceil(data?.count / pageLength) > 1 && (
        <div>
          <Pagination
            onPageChange={pageNum => onPageChange(pageNum)}
            page={page}
            totalPages={Math.ceil(data?.count / pageLength)}
          />
        </div>
      )} */}
    </div>
  );
};

export default StoreReports;
