import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
export class PostConnectPayPal {
  /**
   * Function to invoke to paypal connect
   */
  async invoke(payload: any) {
      const res = await ApiService.getInstance().post(apiConstants.CONNECT_PAYPAL(payload.code));
  
    if(res.data.id){
      return res;
    }else{
      let newError = new Error()
      newError = { ...newError }
      throw newError
    }
  }
}