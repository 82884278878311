import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getDisputeOrderSummaryRequest } from "redux/actions";
import OrderSummary from "./orderSummary";
import TransactionDetails from "./transactionDetails";
import Evidence from "./evidence";
import FraudAnalysis from "./fraudAnalysis";
import styles from "./style.module.scss";
import {
  BankIcon,
  ChartLineUpIcon,
  CoinIcon,
  OrderStatusIcon,
  TrackingLocationIcon,
} from "components/disputeFlow/NodeHelpers";
import OrderFulfillment from "./orderFulfillment";
import CustomerInformation from "./customerInformation";
import { CustomerInformationIcon, OrderFullfillment } from "elements";
import OrderStatus from "./orderStatus";
import TrackingJourney from "./trackingJourney";
import Image from 'components/image';

export default function DisputeAccordion() {
  const { disputeId } = useParams();
  const dispatch = useDispatch();

  const { data } = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );
  const disputeOrderSummary = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeOrderSummary
  );

  useEffect(() => {
    if (disputeId) dispatch(getDisputeOrderSummaryRequest(disputeId));
  }, [dispatch, disputeId]);

  return (
    <div>
      {disputeOrderSummary?.isSuccess &&
        disputeOrderSummary?.data?.length > 0 && (
          <Accordion
            defaultExpanded
            style={{ borderRadius: "12px 12px 0 0" }}
            className={styles.accordianWrapper}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={styles.accordianSummary}
              style={{ borderRadius: "12px 12px 0 0" }}
            >
              <Typography className={styles.accordianTitle}>
                {data?.shopType ? (
                  <Image icon={data?.shopType} altText="shop type" />
                ) : (
                  data?.paymentProcessor && (
                    <Image icon={data?.paymentProcessor} altText="payment Processor" />
                  )
                )}
                <p>Order Summary</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <OrderSummary />
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}

      <Accordion className={styles.accordianWrapper} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={styles.accordianSummary}
        >
          <Typography className={styles.accordianTitle}>
            <OrderFullfillment />
            <p>Order Fulfillment</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordianDetails}>
          <Typography>
            <OrderFulfillment />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className={styles.accordianWrapper} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={styles.accordianSummary}
        >
          <Typography className={styles.accordianTitle}>
            <OrderStatusIcon />
            <p>Order Status Page</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordianDetails}>
          <Typography>
            <OrderStatus />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className={styles.accordianWrapper}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={styles.accordianSummary}
        >
          <Typography className={styles.accordianTitle}>
            <CustomerInformationIcon />
            <p>Customer Information</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <CustomerInformation />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className={styles.accordianWrapper}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={styles.accordianSummary}
        >
          <Typography className={styles.accordianTitle}>
            <TrackingLocationIcon />
            <p>Full Tracking Journey</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <TrackingJourney />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className={styles.accordianWrapper}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={styles.accordianSummary}
        >
          <Typography className={styles.accordianTitle}>
            <BankIcon />
            <p>Transaction Details</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordianDetails}>
          <Typography>
            <TransactionDetails />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className={styles.accordianWrapper}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={styles.accordianSummary}
        >
          <Typography className={styles.accordianTitle}>
            <CoinIcon />
            <p>Evidence</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordianDetails}>
          <Typography>
            <Evidence />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          borderRadius: "0 0 12px 12px",
        }}
        className={styles.accordianWrapper}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={styles.accordianSummary}
          style={{ borderRadius: "0 0 12px 12px" }}
        >
          <Typography className={styles.accordianTitle}>
            <ChartLineUpIcon />
            <p>Fraud Analysis</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ borderRadius: "0 0 12px 12px" }}>
          <Typography>
            <FraudAnalysis />
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* <Accordion
        defaultExpanded
        style={{ margin: "0px", borderRadius: "0 0 12px 12px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          style={{ background: "#F9F9FB", borderRadius: "0 0 12px 12px" }}
        >
          <Typography className={styles.accordianTitle}>
            <SupportiveEvidenceIcon />
            <p>Add Supporting Evidence </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ background: "#fff", borderRadius: "0 0 12px 12px" }}
        >
          <Typography>
            <SupportiveEvidence />
          </Typography>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
}
