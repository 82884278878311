import { svgIcons } from "elements";
import { Loading } from "components";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

const usersColumns = ["Name", "Email", "Role"];

const UsersTable = ({ data, isLoading, isSuccess, isError }: any) => {
  const navigate = useNavigate();
  const handleClick = (id: number) => {
    navigate(`/users/managers/${id}`);
  };
  return (
    <div>
      <div className={styles.tableWrapper}>
        {isLoading && (
          <div className={styles.loader}>
            <Loading />
          </div>
        )}
        {!isLoading && isSuccess && data && data?.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                {usersColumns.map((usersColumn) => (
                  <th key={usersColumn}>{usersColumn}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((user: any, index: number) => (
                <tr key={index} className={styles.tableDataRow}>
                  <td>{user?.userName}</td>
                  <td>{user?.email}</td>
                  <td
                    style={{
                      cursor: user.role === "MANAGER" ? "pointer" : "default",
                    }}
                    onClick={() =>
                      user.role === "MANAGER" && handleClick(user.id)
                    }
                  >
                    <Tooltip
                      title={
                        user.role === "MANAGER"
                          ? "Click to see details manager"
                          : ""
                      }
                      arrow
                      placement="top"
                    >
                      <span>{user?.role}</span>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ((!isLoading && isError) || (isSuccess && data?.length === 0)) && (
            <div className={styles.NoData}>
              <img src={svgIcons.Coffee_Break} alt="disputes" />
              <h3>No user</h3>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default UsersTable;
