import { useEffect, useState } from "react";
import { TemplateFilter, TemplateTable } from "../index";
import styles from "./style.module.scss";
import Pagination from "components/pagination/Pagination";
import { useDisputeTemplateList } from "hooks";

const SystemTemplate = () => {
  const {
    data,
    isLoading,
    isSuccess,
    isError,
    singleIsLoading,
    singleIsSuccess,
    singleError,
    singleStoreTemplate,
    toBeSelected,
    storeId,
    page,
    tableList,
    onPageChange,
    setListPayload,
  } = useDisputeTemplateList();

  // code for search filtering
  const [searchItem, setSearchItem] = useState("");
  const [newFilterArray, setNewFilterArray] = useState(
    data?.disputeTemplateDTOList
  );

  const handleSearch = (searchQuery: string) => {
    setSearchItem(searchQuery);
  };
  useEffect(() => {
    const fetchFilteredData = () => {
      try {
        if (
          data &&
          data?.disputeTemplateDTOList &&
          data?.disputeTemplateDTOList?.length > 1
        ) {
          const filteredArray =
            data?.disputeTemplateDTOList?.length > 1 &&
            data?.disputeTemplateDTOList.filter((data: any) =>
              data.name.toLowerCase().includes(searchItem.toLowerCase())
            );
          setNewFilterArray(filteredArray);
        }
      } catch (error) {
        console.error("error", error);
      }
    };
    fetchFilteredData();
  }, [searchItem, data]);

  return (
    <div className={styles.disputesWrapper}>
      <div>
        <TemplateFilter
          setListPayload={setListPayload}
          handleSearch={handleSearch}
        />
      </div>

      <div>
        <TemplateTable
          isLoading={storeId ? singleIsLoading : isLoading}
          isSuccess={storeId ? singleIsSuccess : isSuccess}
          isError={storeId ? singleError : isError}
          data={
            storeId
              ? singleStoreTemplate?.disputeTemplateDTOList
              : newFilterArray
          }
        />
      </div>

      {!storeId && !toBeSelected && Math.ceil(data?.count / tableList) > 1 && (
        <div>
          <Pagination
            onPageChange={(pageNum) => onPageChange(pageNum)}
            page={page}
            totalPages={Math.ceil(data?.count / tableList)}
          />
        </div>
      )}
    </div>
  );
};

export default SystemTemplate;
