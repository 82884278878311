import { OrderStatusLinkIcon } from "components/disputeFlow/NodeHelpers";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import { useCustomerInformation } from 'hooks';

const OrderStatus = () => {
  const { data } = useCustomerInformation();
  return (
    <div>
      {data ? (
        <div className={styles.orderStatusWrapper}>
          <OrderStatusLinkIcon />
          <Link
            to={data?.orderStatusUrl}
            className={styles.orderStatus}
            target="_blank"
          >
            {data?.orderStatusUrl}
          </Link>
        </div>
      ) : (
        <div
          style={{
            fontSize: "12px",
            color: "#98A2B3",
            minHeight: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          No order status
        </div>
      )}
    </div>
  );
};

export default OrderStatus;
