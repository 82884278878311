import ApiService from 'services/apiService';
import { apiConstants } from '../network';
export class GetDisputeOrderFulfillmentDetails {
    /**
     * Function to invoke to get dispute fullfillment details
     */
    async invoke(payload: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_FULFILLMENT(payload));
        return res;
    }
}