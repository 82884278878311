
export const generatePayPalAuthorizeUrl = () => {
    let rebuiltAuthorizeUrl;
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_PAYPAL_CLIENT_ID!);
    params.append("scope", process.env.REACT_APP_PAYPAL_SCOPES!);
    params.append("redirect_uri", process.env.REACT_APP_PAYPAL_REDIRECT_URL!);
    rebuiltAuthorizeUrl= `${process.env.REACT_APP_PAYPAL_AUTHORIZE_URL}${params.toString()}`
    return rebuiltAuthorizeUrl;
}
export const generateStripeAuthorizeUrl = () => {
    let rebuiltAuthorizeUrl;
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_STRIPE_CLIENT_ID!);
    params.append("scope", process.env.REACT_APP_STRIPE_SCOPES!);
    params.append("redirect_uri", process.env.REACT_APP_STRIPE_REDIRECT_URL!);
    rebuiltAuthorizeUrl= `${process.env.REACT_APP_STRIPE_AUTHORIZE_URL}${params.toString()}`
    return rebuiltAuthorizeUrl;
}
export const generateInterComAuthorizeUrl = () => {
    let rebuiltAuthorizeUrl;
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_INTERCOM_CLIENT_ID!);
    params.append("scope", process.env.REACT_APP_INTERCOM_SCOPES!);
    params.append("redirect_uri", process.env.REACT_APP_INTERCOM_REDIRECT_URL!);
    rebuiltAuthorizeUrl= `${process.env.REACT_APP_INTERCOM_AUTHORIZE_URL}${params.toString()}`
    return rebuiltAuthorizeUrl;
}
export const generateBigCommerceAuthorizeUrl = () => {
    let rebuiltAuthorizeUrl;
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_BIG_COMMERCE_CLIENT_ID!);
    params.append("context", process.env.REACT_APP_BIG_COMMERCE_CONTEXT!);
    params.append("redirect_uri", process.env.REACT_APP_BIG_COMMERCE_REDIRECT_URL!);
    params.append("response_type", process.env.REACT_APP_BIG_COMMERCE_RESPONSE_TYPE!);
    params.append("scope", process.env.REACT_APP_BIG_COMMERCE_SCOPES!);
    rebuiltAuthorizeUrl = `${process.env.REACT_APP_BIG_COMMERCE_AUTHORIZE_URL}${params.toString()}`;
    return rebuiltAuthorizeUrl;
}
export const generateShopifyAuthorizeUrl = () => {
    let rebuiltAuthorizeUrl;
    rebuiltAuthorizeUrl = `${process.env.REACT_APP_SHOPIFY_AUTHORIZE_URL}`;
    return rebuiltAuthorizeUrl;
}
export const generateEcwidAuthorizeUrl = (isUpdate: boolean) => {
    let rebuiltAuthorizeUrl;
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_ECWID_CLIENT_ID!);
    params.append("response_type", process.env.REACT_APP_ECWID_RESPONSE_TYPE!)
    if (isUpdate) {
        params.append("scope", process.env.REACT_APP_ECWID_SCOPES_EXTENDED!);
        params.append("redirect_uri", process.env.REACT_APP_ECWID_REDIRECT_UPDATER_URL!);
    } else {
        params.append("scope", process.env.REACT_APP_ECWID_SCOPES!);
        params.append("redirect_uri", process.env.REACT_APP_ECWID_REDIRECT_URL!);
    }
    rebuiltAuthorizeUrl = `${process.env.REACT_APP_ECWID_AUTHORIZE_URL}${params.toString()}`;
    return rebuiltAuthorizeUrl;
}
export const generateSquareSpaceAuthorizeUrl = () => {
    let rebuiltAuthorizeUrl;
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_SQUARESPACE_CLIENT_ID!);
    params.append("access_type", process.env.REACT_APP_SQUARESPACE_ACCESS_TYPE!);
    params.append("redirect_uri", process.env.REACT_APP_SQUARESPACE_REDIRECT_URL!);
    params.append("state", process.env.REACT_APP_SQUARESPACE_STATE!);
    params.append("scope", process.env.REACT_APP_SQUARESPACE_SCOPE!);
    rebuiltAuthorizeUrl = `${process.env.REACT_APP_SQUARESPACE_AUTHORIZE_URL}${params.toString()}`;
    return rebuiltAuthorizeUrl;
}
export const generateWixAuthorizeUrl = (isUpdate: boolean) => {
    let rebuiltAuthorizeUrl;
    const params = new URLSearchParams();
    params.append("appId", process.env.REACT_APP_WIX_CLIENT_ID!);
    if (isUpdate) {
        params.append("redirectUrl", process.env.REACT_APP_WIX_REDIRECT_UPDATE_URL!);
    } else {
        params.append("redirectUrl", process.env.REACT_APP_WIX_REDIRECT_URL!);
    }
    rebuiltAuthorizeUrl = `${process.env.REACT_APP_WIX_AUTHORIZE_URL}${params.toString()}`;
    return rebuiltAuthorizeUrl;
}
export const getAuthorizeResult = (pathName: string) => {
    const apiOrigin = process.env.REACT_APP_BACKEND_API_BASE_URL;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let data;
    let url;
    if (pathName.includes("wix/authenticate")) {
        url = `${apiOrigin}/shops/add-wix-shop`;
        data = {
            code: params.get("code"),
            instanceId: params.get("instanceId")
        }
    } else if (pathName.includes("bigcommerce/authenticate")) {
        url = `${apiOrigin}/shops/add-bigcommerce-shop`;
        data = {
            code: params.get("code"),
            scope: params.get("scope"),
            context: params.get("context")
        }
    } else if (pathName.includes("square/authenticate")) {
        url = `${apiOrigin}/shops/add-squarespace-shop`;
        data = {
            code: params.get("code"),
            state: params.get("state"),
            error: params.get("error")
        }
    } else if (pathName.includes("ecwid/authenticate")) {
        url = `${apiOrigin}/shops/add-ecwid-shop`;
        data = {
            code: params.get("code"),
            storeId: params.get("store_id")
        }
    } else if (pathName.includes("shopify/authenticate")) {
        url = `${apiOrigin}/shops/add-shopify-shop`;
        data = {
            hmac: params.get("hmac"),
            shop: params.get("shop")
        }
    }
    return { data, url };
};