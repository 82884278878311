import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getByMonthlyReportingListRequest } from "redux/actions";
import { setItem } from "utils/localStorage";

const useByMonthlyReporting = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const tableList = 15;

    const {
        isLoading,
        isSuccess,
        isError,
        data: monthlyReportingData,
    } = useSelector((state: any) => state?.reporting?.byMonthlyReportList);
    const [submittedByChargePay,setSumittedByChargePay] = useState(false)
    const [disputesPayload, setDisputesPayload] = useState<any>();

    const handleSubmitByChargePay = ()=>{
        setSumittedByChargePay(!submittedByChargePay)
    }
    // set submittedByChargePay to the disputes payload for data filtering
    useEffect(()=>{
        setPage(1);
        setDisputesPayload({
            ...disputesPayload,
            submittedByChargePay
        })

    },[submittedByChargePay])

    //to call the api for data fetching
    useEffect(() => {
        if (disputesPayload) {
            const payload = {
                ...disputesPayload,
                page:page,
                pageLength:tableList,
            }
            dispatch(getByMonthlyReportingListRequest(payload));
        }
    }, [disputesPayload,page]);

    // this is the function for pagination page changing
    const onPageChange = (pageNumber: number) => {
        setItem("disputePage", pageNumber);
        setItem("disputePageLength", tableList);
        setPage(pageNumber);
    };
    return {
        page,
        tableList,
        isLoading,
        isSuccess,
        isError,
        monthlyReportingData,
        onPageChange,
        handleSubmitByChargePay,
        submittedByChargePay,
    }
}

export default useByMonthlyReporting;