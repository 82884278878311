import * as React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearDeterminate() {
  const { data } = useSelector((state: any) => state?.disputeDetailsStore?.disputeDetails);
    const progressPercentage = data?.trackiScore ?? 0;

//   React.useEffect(() => {
//     const timer = setInterval(() => {
//       setProgress((oldProgress) => {
//         if (oldProgress === 100) {
//           return 0;
//         }
//         const diff = Math.random() * 10;
//         return Math.min(oldProgress + diff, 100);
//       });
//     }, 500);

//     return () => {
//       clearInterval(timer);
//     };
//   }, []);

  return (
    <Box sx={{ width: '100%', height: '6.51px' }}>
      <LinearProgress 
      variant="determinate" 
      value={progressPercentage}
      style={{
        height: '6.51px',
        borderRadius: '5px',
      }}
    />
    </Box>
  );
}
