import { useSelector } from "react-redux";
import moment from "moment";
import { Loading } from "../../index";
import { svgIcons } from "elements";
import styles from "./style.module.scss";
import { getDisputeResponseFetchContentRequest } from "redux/actions";
import { useDispatch } from "react-redux";
import { Box, Tooltip } from "@mui/material";
import { useCreateResponse, useResponseSaveContent } from "hooks";

interface disputeResponseProp {
  setDisputeResponsePreview: React.Dispatch<any>;
}
const disputeResponseColumns = [
  "ID",
  "Flow",
  "Template",
  "Status",
  "Submitted at",
  "",
];

const DisputeResponse = ({
  setDisputeResponsePreview,
}: disputeResponseProp) => {
  const { handleDeleteResponse } = useCreateResponse();
  const { handleMissingMacros } = useResponseSaveContent();
  const disputeResponseFindByDisputeId = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponseFindByDisputeId
  );
  const dispatch = useDispatch();
  const handlePreview = (response: any) => {
    setDisputeResponsePreview(response);
    if (response.id) {
      handleMissingMacros({
        disputeResponseId: response?.id,
        responseSection: "ALL",
      });
      dispatch(getDisputeResponseFetchContentRequest(response.id));
    }
  };
  const handleDeletePreview = (response: any) => {
    if (response.id) {
      handleDeleteResponse(response.id);
    }
  };
  const getColorAndTooltip = [
    { id: 1, color: "#FFC61A", title: "Warning" },
    { id: 2, color: "#12B76A", title: "Success" },
    { id: 3, color: "#B42318", title: "Error" },
  ];
  return (
    <div className={styles.disputeWrapper}>
      <Box className={styles.manageHead}>
        <h6>Dispute response</h6>
        <Box gap={1} display={`flex`} justifyContent={`start`}>
          {getColorAndTooltip.map((item, index) => (
            <Box
              gap={1}
              key={index}
              display={`flex`}
              justifyContent={`start`}
              alignItems={`baseline`}
            >
              <p
                style={{
                  padding: 5,
                  background: item.color,
                  borderRadius: "50%",
                  height: "5px",
                  width: "5px",
                }}
              ></p>
              <p style={{ fontSize: "12px", fontWeight: 400 }}>{item.title}</p>
            </Box>
          ))}
        </Box>
      </Box>

      <div className={styles.responseTable}>
        {disputeResponseFindByDisputeId?.isLoading && <Loading />}
        {disputeResponseFindByDisputeId?.isSuccess &&
          disputeResponseFindByDisputeId?.data?.length > 0 && (
            <table>
              <thead>
                <tr>
                  {disputeResponseColumns.map((item) => (
                    <th key={item}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {disputeResponseFindByDisputeId?.data?.length > 0 &&
                  disputeResponseFindByDisputeId?.data?.map((dispute: any) => (
                    <tr
                      style={{
                        background:
                          dispute.status === "SUBMITTED"
                            ? "#F3FDF7"
                            : dispute.status === "MISSING_INFO"
                            ? "#FEF5F4"
                            : "#e8e6e0",
                      }}
                      key={dispute.id}
                      className={styles.disputeWrapper}
                    >
                      <td className={styles.id} data-testid="dispute-order">
                        <Box
                          display="flex"
                          justifyContent="start"
                          alignItems="center"
                          gap="10px"
                        >
                          <p
                            style={{
                              padding: 5,
                              background:
                                dispute.status === "SUBMITTED"
                                  ? "#12B76A"
                                  : dispute.status === "MISSING_INFO"
                                  ? "#B42318"
                                  : "#e8e6e0",
                              borderRadius: "50%",
                              height: "5px",
                              width: "5px",
                            }}
                          ></p>
                          {dispute?.id}
                        </Box>
                      </td>

                      <td>{dispute?.flowId}</td>
                      <td>{dispute?.disputeTemplateId}</td>
                      <td>
                        <div className={styles.status}>
                          <p>{dispute?.status?.split("_").join(" ")}</p>
                        </div>
                      </td>
                      <td className={styles.store}>
                        {dispute?.submittedAt
                          ? moment(dispute?.submittedAt).format(
                              "DD/MM/YY | hh:mm"
                            )
                          : "-"}
                      </td>
                      <td>
                        <div className={styles.eyeTrash}>
                          <Tooltip
                            arrow
                            placement="top"
                            title={
                              dispute.status === "SUBMITTED"
                                ? "This dispute response cannot editable"
                                : "Click to preview"
                            }
                          >
                            <div
                              style={{
                                cursor:
                                  dispute.status === "SUBMITTED"
                                    ? "no-drop"
                                    : "pointer",
                              }}
                              onClick={() =>
                                dispute.status === "SUBMITTED"
                                  ? null
                                  : handlePreview(dispute)
                              }
                              data-testid="eyePreviewTestId"
                            >
                              <img src={svgIcons.Eye_2} alt="eye" />
                            </div>
                          </Tooltip>
                          <Tooltip
                            arrow
                            placement="top"
                            title={
                              dispute.status === "SUBMITTED"
                                ? "This dispute response cannot deleteable"
                                : "Click to delete"
                            }
                          >
                            <div
                              style={{
                                cursor:
                                  dispute.status === "SUBMITTED"
                                    ? "no-drop"
                                    : "pointer",
                              }}
                              onClick={() =>
                                dispute?.status === "SUBMITTED"
                                  ? null
                                  : handleDeletePreview(dispute)
                              }
                            >
                              <img src={svgIcons.Trash_3} alt="trash" />
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
      </div>
    </div>
  );
};

export default DisputeResponse;
