import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";


export class GetRecentDisputes {
  /**
   * Function to invoke to get recent disputes
   */
  async invoke() {

    const res = await ApiService.getInstance().get(apiConstants.DASHBOARD_RECENT_DISPUTES)

    if (res.status === 200) {
      return res;
    } else {
      let newError = new Error()
      newError = { ...newError }
      throw newError
    }
  }

  async invokeByAccount(payload: string) {

    const res = await ApiService.getInstance().get(apiConstants.DASHBOARD_RECENT_DISPUTES_BY_ACCOUNT(payload))

    return res;
  }

}