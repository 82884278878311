import styles from "./style.module.scss";
import { ItemsNotReceived } from "components";

const SystemFlows = () => {
  return (
    <>
      <div className={styles.disputesWrapper}>
        <ItemsNotReceived />
      </div>
    </>
  );
};

export default SystemFlows;
