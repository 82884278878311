import dayjs from "dayjs";
import { useDisputeReasonList, useOpenDatePicker, useStorelist } from "hooks";
import { cloneDeep, isEmpty, uniqBy } from "lodash";
import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getReportingListRequest } from "redux/actions";
import { reportingStatusSection } from "utils/disputeUtils";
import { getItem, setItem } from "utils/localStorage";
type TShop = {
    value: number;
    label: string;
};
type SelectFilterType = {
    value: number;
    label: string;
};
type ReasonSelectProps = {
    description: string;
    disputeReasonValue: string;
};
const useGlobalReporting = (shop: any) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const selectRef: any = useRef(null);
    const tableList = 15;

    //custom hooks
    const { storesOptions } = useStorelist();
    const { reasonList: reasonsOptions } = useDisputeReasonList();
    const { openDatePicker, datePickerRef } = useOpenDatePicker();
    const {
        isLoading,
        isSuccess,
        isError,
        data: reportingData,
    } = useSelector((state: any) => state?.reporting?.reportList);
    // all states
    const [shopList, setShopList] = useState<TShop[]>([]);
    const [reasonsList, setReasonsList] = useState<ReasonSelectProps[]>([]);
    const [disputeStatusBtnCheck, setDisputeStatusBtnCheck] = useState(false);
    const [disputeClick, setDisputeClick] = useState(false);
    const [disputeStatusButton, setDisputeStatusButton] = useState([
        ...reportingStatusSection,
    ]);
    const [paymentProcessor, setPaymentProcessor] = useState<SelectFilterType[]>(
        []
    );
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [dateFilterCheck, setDateFilterCheck] = useState(false);

    //for-payload (for all types of API call):-
    const [disputesPayload, setDisputesPayload] = useState<any>();
    const disputePageLength = getItem("disputePageLength");
    const SingleAccountCheck = getItem("singleAccountCheck");
    useEffect(() => {
        if (shop) {
            setShopList((prev) => uniqBy([...prev, shop], "value"));
        }
    }, [shop]);
    //for date dispute filter
    useEffect(() => {
        setPage(1);
        setItem("disputePage", 1);

        if (dateFilterCheck && startDate && endDate) {
            const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
            const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
            let datePayload = {
                ...disputesPayload,
                page: 1,
                fromDate: formattedStartDate,
                toDate: formattedEndDate,
            };
            setDisputesPayload(datePayload);
        } else if (dateFilterCheck && !startDate && !endDate) {
            let datePayload = {
                ...disputesPayload,
                page: 1,
                fromDate: null,
                toDate: null,
            };
            setDisputesPayload(datePayload);
        }
    }, [dateFilterCheck, startDate, endDate]);

    // for shop filtering
    useEffect(() => {
        if (shopList.length > 0) {
            const maped: any = shopList.map((item: any) => item.value);
            const payload = {
                ...disputesPayload,
                shopIds: maped.length ? maped : null,
            };
            setDisputesPayload(payload);
        }
    }, [shopList]);

    // for paymentProcessor filtering
    useEffect(() => {
        if (paymentProcessor.length > 0) {
            const maped: any = paymentProcessor.map((item: any) => item.label);
            const payload = {
                ...disputesPayload,
                paymentProcessor: maped.length ? maped : null,
            };
            setDisputesPayload(payload);
        }
    }, [paymentProcessor]);

    // for paymentProcessor filtering
    useEffect(() => {
        if (reasonsList.length > 0) {
            const maped: any = reasonsList.map((item: any) => item.value);
            const payload = {
                ...disputesPayload,
                reason: maped.length ? maped : null,
            };
            setDisputesPayload(payload);
        }
    }, [reasonsList]);

    // handle status filtering handledByChargePay,Won,Lost,Active,Inactive
    const handleDisputeStatus = (disputeStatus: any, index: number) => {
        setPage(1);
        setItem("disputePage", 1);
        const disputeBtn = cloneDeep(disputeStatusButton);
        disputeStatus.status = !disputeStatus.status;
        setDisputeStatusBtnCheck(!disputeStatusBtnCheck);
        setDisputeClick(true);
        disputeBtn[index] = disputeStatus;
        setDisputeStatusButton([...disputeBtn]);
    };
    // for handleStatus filter payload
    const handleStatusFilterPayload = (statusFilter: string[]) => {
        if (!SingleAccountCheck) {
            return {
                ...disputesPayload,
                pageLength: Number(disputePageLength),
                statuses: statusFilter?.length ? statusFilter : null,
            };
        }
    };

    //handle dispatch for reports filter
    const handleDispatch = (payload: any) => {
        dispatch(getReportingListRequest(payload));
    };

    useEffect(() => {
        if (disputeClick) {
            const statusArr = disputeStatusButton.filter(
                (statusData) => statusData.status
            );
            const statusFilterArr = statusArr.map((item) => {
                return item?.title?.toUpperCase();
            });
            const payload = handleStatusFilterPayload(statusFilterArr);
            setDisputesPayload(payload); //set the payload by filtering status button
        }
    }, [disputeStatusBtnCheck, disputeStatusButton]);
    //initial payload when disputes render
    useEffect(() => {
        if (!SingleAccountCheck) {
            setItem("disputePage", page);
            setItem("disputePageLength", tableList);
            const payload = {};
            setDisputesPayload({
                ...payload,
                ...disputesPayload,
                // page: page,
            });
        } else {
            setItem("disputePage", page);
            setItem("disputePageLength", tableList);
            const payload = {};
            setDisputesPayload({
                ...payload,
                ...disputesPayload,
                // page: page,
            });
        }
    }, []);
    const blur = () => {
        selectRef.current?.blur();
    };
    const handleKeyDown: KeyboardEventHandler = (
        event: React.KeyboardEvent<Element>
    ) => {
        switch (event.key) {
            case "Enter":
            case "Tab":
                blur();
                const maped: any = shopList.map((item: any) => item.value);
                const mappedReponse: any = paymentProcessor.map(
                    (item: any) => item.label
                );
                const mappedReasons: any = reasonsList.map((item: any) => item.value);
                const payload = {
                    ...disputesPayload,
                    shopIds: maped.length ? maped : null,
                    paymentProcessor: mappedReponse.length ? mappedReponse : null,
                    reason: mappedReasons.length ? mappedReasons : null,
                };
                setDisputesPayload(payload);
                event.preventDefault();
        }
    };

    //to call all APIs
    useEffect(() => {
        if (disputesPayload) {
            const statuses = ["HANDLED BY CHARGEPAY", "WON"];
            const payload = handleStatusFilterPayload(statuses);
            if (isEmpty(disputesPayload)) {
                handleDispatch(payload);
                setDisputesPayload(payload);
            } else {
                handleDispatch(disputesPayload);
            }
        }
    }, [disputesPayload]);

    const onPageChange = (pageNumber: number) => {
        setItem("disputePage", pageNumber);
        setItem("disputePageLength", tableList);
        setPage(pageNumber);
        setDisputesPayload({
            ...disputesPayload,
            page: pageNumber,
        });
    };
    return {
        paymentProcessor,
        dateFilterCheck,
        dateRange,
        shopList,
        isError,
        isSuccess,
        isLoading,
        storesOptions,
        reportingData,
        datePickerRef,
        reasonsOptions,
        reasonsList,
        tableList,
        startDate,
        disputeStatusButton,
        endDate,
        page,
        selectRef,
        onPageChange,
        handleKeyDown,
        handleDisputeStatus,
        setDateFilterCheck,
        setDateRange,
        setPaymentProcessor,
        setShopList,
        openDatePicker,
        setReasonsList
    }
}
export default useGlobalReporting;