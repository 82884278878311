import { Pagination, Loading } from 'components';
import styles from './style.module.scss';
import { ByMonthlyReportingTable } from 'components/reporting/ReportingTable';
import { useByMonthlyReporting } from 'hooks';
import { Button } from '@mui/material';

const ReportByMonthly = () => {
  const {
    page,
    isLoading,
    isSuccess,
    isError,
    monthlyReportingData,
    onPageChange,
    handleSubmitByChargePay,
    submittedByChargePay,
  } = useByMonthlyReporting();

  return (
    <div className={styles.disputesWrapper}>
      <div className={styles.search}>
        <div className={styles.searchTop}>
          <Button
            className={
              submittedByChargePay
                ? styles.handleSubmitButtonActive
                : styles.handleSubmitButton
            }
            onClick={handleSubmitByChargePay}
            variant='contained'
            size='small'
          >
            <div className={submittedByChargePay ? styles.openActive : styles.open}></div>
            Submitted By Chargepay
          </Button>
        </div>
      </div>
      <div>
        {isLoading ? (
          <div style={{ background: '#fff' }}>
            <Loading />
          </div>
        ) : (
          <ByMonthlyReportingTable
            {...{
              data: monthlyReportingData?.monthlyReports,
              isLoading,
              isSuccess,
              isError,
            }}
          />
        )}
      </div>
      <div>
        {Math.ceil(monthlyReportingData?.monthlyReports?.length / 10) > 1 && (
          <Pagination
            onPageChange={pageNum => onPageChange(pageNum)}
            page={page}
            totalPages={Math.ceil(
              monthlyReportingData?.monthlyReports?.length / 10
            )}
          />
        )}
      </div>
    </div>
  );
};

export default ReportByMonthly;
