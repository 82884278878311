import { Api } from "services";
import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

interface disputeBilling {
  data: {};
  status: number;
}

interface ResProp {
  data: {
      count: number,
      disputeResponseList: [],
  },
  status: number
}


export function* postDisputeBillingRequest(action: any) {
  try {
    const res: disputeBilling = yield Api.PostBillingReports(action.payload);
    if (res.status === 500) {
      yield put(actions.postDisputeBillingError());
    } else {
      yield put(actions.postDisputeBillingSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.postDisputeBillingError());
  }
}

export function* postDisputeApproveRequest(action: any) {
  try {
    const res: disputeBilling = yield Api.PostDisputeApprove(action.payload);
    if (res.status === 500) {
      yield put(actions.postDisputeApproveError());
    } else {
      yield put(actions.postDisputeApproveSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.postDisputeApproveError());
  }
}
export function* postDisputeReadyStatusRequest(action: any) {
  try {
    const res: disputeBilling = yield Api.PostConfirmBilling(action.payload);
    if (res.status === 500) {
      yield put(actions.postDisputeReadyStatusError());
      toast.error("Oops! There was an error approving the dispute", {
        theme: "colored",
      });
    } else {
      yield put(actions.postDisputeReadyStatusSuccess(res.data));
      toast.success("Dispute approved successfully", {
        theme: "colored",
      });
    }
  } catch (e) {
    yield put(actions.postDisputeReadyStatusError());
  }
}

export function* postNewBillingListRequest(action: any) {
  try {
    const res: disputeBilling = yield Api.postNewBillingList(action.payload);
    if (res.status === 500) {
      yield put(actions.postNewBillingListError())
    } else {
      yield put(actions.postNewBillingListSuccess(res.data))
    }
  } catch (e) {
    yield put(actions.postNewBillingListError())
  }
}
export function* getNewBillingReadyDisputeListRequest(action: any) {
  try {
    const res: disputeBilling = yield Api.getNewBillingListReadyDispute(action.payload);
    if (res.status === 500) {
      yield put(actions.getNewBillingReadyDisputeListError())
    } else {
      yield put(actions.getNewBillingReadyDisputeListSuccess(res.data))
    }
  } catch (e) {
    yield put(actions.getNewBillingReadyDisputeListError())
  }
}
export function* getTotalBilledAmountReportListRequest(action: any) {
  try {
      const res: ResProp = yield Api.getTotalBilledAmountBillingReport(action.payload);
      if (res.status === (500 || 401)) {
          yield put(actions.getTotalBilledAmountError());
      } else {
          yield put(actions.getTotalBilledAmountSuccess(res.data));
      }

  } catch (e) {
      yield put(actions.getTotalBilledAmountError());
  }
}

export function* getTotalAmountRequest(action: any) {
  try {
      const res: ResProp = yield Api.getTotalAmountList(action.payload);
      if (res.status === (500 || 401)) {
          yield put(actions.getTotalAmountError());
      } else {
          yield put(actions.getTotalAmountSuccess(res.data));
      }

  } catch (e) {
      yield put(actions.getTotalAmountError());
  }
}
export function* getNewBillingTotalBilledAmountRequest(action: any) {
  try {
      const res: ResProp = yield Api.getNewBillingTotalBilledAmount(action.payload);
      if (res.status === (500 || 401)) {
          yield put(actions.getNewBillingTotalBilledAmountError());
      } else {
          yield put(actions.getNewBillingTotalBilledAmountSuccess(res.data));
      }

  } catch (e) {
      yield put(actions.getNewBillingTotalBilledAmountError());
  }
}
export function* getNewBillingTotalAmountRequest(action: any) {
  try {
      const res: ResProp = yield Api.getNewBillingTotalAmount(action.payload);
      if (res.status === (500 || 401)) {
          yield put(actions.getNewBillingTotalAmountError());
      } else {
          yield put(actions.getNewBillingTotalAmountSuccess(res.data));
      }

  } catch (e) {
      yield put(actions.getNewBillingTotalAmountError());
  }
}
export function* getReadyDisputeTotalBilledAmountRequest(action: any) {
  try {
      const res: ResProp = yield Api.getReadyDisputeTotalBilledAmount(action.payload);
      if (res.status === (500 || 401)) {
          yield put(actions.getReadyDisputeTotalBilledAmountError());
      } else {
          yield put(actions.getReadyDisputeTotalBilledAmountSuccess(res.data));
      }

  } catch (e) {
      yield put(actions.getReadyDisputeTotalBilledAmountError());
  }
}
export function* getReadyDisputeTotalAmountRequest(action: any) {
  try {
      const res: ResProp = yield Api.getReadyDisputeTotalAmount(action.payload);
      if (res.status === (500 || 401)) {
          yield put(actions.getReadyDisputeTotalAmountError());
      } else {
          yield put(actions.getReadyDisputeTotalAmountSuccess(res.data));
      }

  } catch (e) {
      yield put(actions.getReadyDisputeTotalAmountError());
  }
}