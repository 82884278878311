import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { svgIcons } from "elements";
import { FormatCurrency, Loading } from "../../index";
import styles from "./style.module.scss";

const DisputeLost = () => {
  const { isLoading, isSuccess, data } = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );

  return (
    <div className={styles.disputeLostWrapper}>
      {isLoading ? (
        <Loading />
      ) : (
        isSuccess && (
          <div className={styles.disputeLost}>
            <div className={styles.underLost}>
              <img src={svgIcons.Undraw_Lost} alt="Undraw_Lost" />
            </div>
            <div className={styles.statusStatement}>
              <h2>Attention! You lost the dispute</h2>
              <h3>
                {`You lost`}{" "}
                <FormatCurrency
                  value={Number(data.disputeAmountValue)?.toFixed(2)}
                  currencyCode={data?.disputeAmountCurrencyCode}
                />
              </h3>
              {data?.paymentProcessor &&
                data?.paymentProcessor?.toLowerCase() === "paypal" && (
                  <p>
                    {`You can contact ${data?.paymentProcessor} or us to re-open the dispute again. Click below!`}
                  </p>
                )}
            </div>
            {data?.paymentProcessor &&
              data?.paymentProcessor?.toLowerCase() === "paypal" && (
                <div className={styles.appeal}>
                  <Button variant="contained">Appeal</Button>
                </div>
              )}
          </div>
        )
      )}
    </div>
  );
};

export default DisputeLost;
