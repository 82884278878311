import { svgIcons } from 'elements';
import { Loading } from 'components';
import styles from './style.module.scss';
import Image from 'components/image';

type GlobalReportData = {
  shop: string;
  disputeReason: string;
  outcome: string;
  handledByChargePay: boolean;
  disputeCount: number;
  totalDisputeAmount: number;
  paymentProcessor: string;
  billingStatus: null
}

type ReportData = {
  shop: string;
  paymentProcessor: string;
  disputeReason: string;
  totalDisputeAmount: number;
  disputeCount: number;
  totalBillableDisputeCount: number;
  totalBilledDisputeCount: number;
  totalBillableAmount: number;
  totalBilledAmount: number
}
//TODO: change with monthly report [any] 
type MonthlyReportData = {
  shop: string;
  reason: string;
}

type GlobalDataReportType = {
  data: GlobalReportData[];
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}
type ReportingTableType = {
  data: ReportData[];
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}
const usersColumns = [
  'Shop',
  'Psp',
  'Dispute Reason',
  'Outcome',
  'Handled By Chargepay',
  'Dispute Count',
  'Total Dispute Amount',
];
const ReportingTable = ({ data, isLoading, isSuccess, isError }: GlobalDataReportType) => {
  return (
    <div>
      <div className={styles.tableWrapper}>
        {isLoading && (
          <div className={styles.loader}>
            <Loading />
          </div>
        )}
        {!isLoading && isSuccess && data && data?.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                {usersColumns.map(usersColumn => (
                  <th key={usersColumn}>{usersColumn}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((user: GlobalReportData, index: number) => (
                <tr key={index} className={styles.tableDataRow}>
                  <td>{user?.shop}</td>
                  <td>
                    <Image
                      icon={user?.paymentProcessor}
                      altText='payment processor'
                    />
                  </td>
                  <td>{user?.disputeReason.split('_').join(' ')}</td>
                  <td>
                    <p
                      style={{
                        background:
                          user?.outcome === 'RESOLVED_BUYER_FAVOUR'
                            ? 'rgb(221, 223, 226)'
                            : user?.outcome === 'RESOLVED_SELLER_FAVOUR'
                              ? 'rgb(215, 243, 228)'
                              : '',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '4px',
                        padding: '4px',
                      }}
                    >
                      {user?.outcome === 'RESOLVED_BUYER_FAVOUR'
                        ? 'Lost'
                        : user?.outcome === 'RESOLVED_SELLER_FAVOUR'
                          ? 'Won'
                          : null}
                    </p>
                  </td>
                  <td>{String(user?.handledByChargePay)}</td>
                  <td>{user?.disputeCount}</td>
                  <td>{user?.totalDisputeAmount.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ((!isLoading && isError) || (isSuccess && data?.length === 0)) && (
            <div className={styles.NoData}>
              <img src={svgIcons.Coffee_Break} alt='disputes' />
              <h3>No stores</h3>
            </div>
          )
        )}
      </div>
    </div>
  );
};
const usersByStoresColumns = [
  'Shop',
  'Dispute Count',
  'Total Dispute Amount',
  'Total Billable DisputeCount',
  'Total Billed DisputeCount',
  'Total Billable Amount',
  'Total Billed Amount',
];

const ByStoresReportingTable = ({
  data = [],
  isLoading,
  isSuccess,
  isError,
}: ReportingTableType) => {
  return (
    <div>
      <div className={styles.tableWrapper}>
        {isLoading && (
          <div className={styles.loader}>
            <Loading />
          </div>
        )}
        {!isLoading && isSuccess && data && data?.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                {usersByStoresColumns.map(usersColumn => (
                  <th key={usersColumn}>{usersColumn}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((user: ReportData, index: number) => (
                <tr key={index} className={styles.tableDataRow}>
                  <td>{user?.shop}</td>
                  <td>{user?.disputeCount}</td>
                  <td>{user?.totalDisputeAmount.toFixed(2)}</td>

                  <td>{user?.totalBillableDisputeCount.toFixed(2)}</td>
                  <td>{user?.totalBilledDisputeCount.toFixed(2)}</td>
                  <td>{user?.totalBillableAmount.toFixed(2)}</td>
                  <td>{user?.totalBilledAmount.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ((!isLoading && isError) || (isSuccess && data?.length === 0)) && (
            <div className={styles.NoData}>
              <img src={svgIcons.Coffee_Break} alt='disputes' />
              <h3>No stores</h3>
            </div>
          )
        )}
      </div>
    </div>
  );
};
const usersByReasonsColumns = [
  'Dispute Reason',
  'Dispute Count',
  'Total Dispute Amount',
  'Total Billable DisputeCount',
  'Total Billed DisputeCount',
  'Total Billable Amount',
  'Total Billed Amount',
];

const ByReasonsReportingTable = ({
  data = [],
  isLoading,
  isSuccess,
  isError
}: ReportingTableType) => {
  return (
    <div>
      <div className={styles.tableWrapper}>
        {isLoading && (
          <div className={styles.loader}>
            <Loading />
          </div>
        )}
        {!isLoading && isSuccess && data && data?.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                {usersByReasonsColumns.map(usersColumn => (
                  <th key={usersColumn}>{usersColumn}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((user: ReportData, index: number) => (
                <tr key={index} className={styles.tableDataRow}>
                  <td>{user?.disputeReason.split('_').join(' ')}</td>
                  <td>{user?.disputeCount}</td>
                  <td>{user?.totalDisputeAmount.toFixed(2)}</td>

                  <td>{user?.totalBillableDisputeCount.toFixed(2)}</td>
                  <td>{user?.totalBilledDisputeCount.toFixed(2)}</td>
                  <td>{user?.totalBillableAmount.toFixed(2)}</td>
                  <td>{user?.totalBilledAmount.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ((!isLoading && isError) || (isSuccess && data?.length === 0)) && (
            <div className={styles.NoData}>
              <img src={svgIcons.Coffee_Break} alt='disputes' />
              <h3>No stores</h3>
            </div>
          )
        )}
      </div>
    </div>
  );
};
const usersByPspColumns = [
  'Shop',
  'Psp',
  'Dispute Count',
  'Total Dispute Amount',
  'Total Billable DisputeCount',
  'Total Billed DisputeCount',
  'Total Billable Amount',
  'Total Billed Amount',
];

const ByPspReportingTable = ({
  data = [],
  isLoading,
  isSuccess,
  isError,
}: ReportingTableType) => {
  return (
    <div>
      <div className={styles.tableWrapper}>
        {isLoading && (
          <div className={styles.loader}>
            <Loading />
          </div>
        )}
        {!isLoading && isSuccess && data && data?.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                {usersByPspColumns.map(usersColumn => (
                  <th key={usersColumn}>{usersColumn}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((user: ReportData, index: number) => (
                <tr key={index} className={styles.tableDataRow}>
                  <td>{user?.shop}</td>
                  <td>
                    <Image
                      icon={user?.paymentProcessor}
                      altText='payment processor'
                    />
                  </td>
                  {/* <td>{user?.disputeReason}</td> */}
                  <td>{user?.disputeCount.toFixed(2)}</td>
                  <td>{user?.totalDisputeAmount}</td>

                  <td>{user?.totalBillableDisputeCount.toFixed(2)}</td>
                  <td>{user?.totalBilledDisputeCount.toFixed(2)}</td>
                  <td>{user?.totalBillableAmount.toFixed(2)}</td>
                  <td>{user?.totalBilledAmount.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ((!isLoading && isError) || (isSuccess && data?.length === 0)) && (
            <div className={styles.NoData}>
              <img src={svgIcons.Coffee_Break} alt='disputes' />
              <h3>No stores</h3>
            </div>
          )
        )}
      </div>
    </div>
  );
};
const usersByMonthlyColumns = [
  'Month',
  'Shop',
  'Dispute Reason',
  'Total Billable DisputeCount',
  'Total Dispute Amount',
  'Total Billable Amount',
];

const ByMonthlyReportingTable = ({
  data = [],
  isLoading,
  isSuccess,
  isError,
}: ReportingTableType) => {
  return (
    <div>
      <div className={styles.tableWrapper}>
        {isLoading && (
          <div className={styles.loader}>
            <Loading />
          </div>
        )}
        {!isLoading && isSuccess && data && data?.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                {usersByMonthlyColumns.map(usersColumn => (
                  <th key={usersColumn}>{usersColumn}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((user: any, index: number) => (
                <tr key={index} className={styles.tableDataRow}>
                  <td style={{ minWidth: "90px" }}>{user?.month}</td>
                  <td>{user?.shop}</td>
                  <td>{user?.reason}</td>
                  <td>{user?.count}</td>
                  <td>{user?.disputeAmount}</td>
                  <td>{user?.billableAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ((!isLoading && isError) || (isSuccess && data?.length === 0)) && (
            <div className={styles.NoData}>
              <img src={svgIcons.Coffee_Break} alt='disputes' />
              <h3>No stores</h3>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export {
  ByStoresReportingTable,
  ReportingTable,
  ByPspReportingTable,
  ByReasonsReportingTable,
  ByMonthlyReportingTable
};
