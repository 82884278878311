import { useState } from "react";
import cn from "classnames";
import styles from "./style.module.scss";
import { SearchIcon } from "components/disputeFlow/NodeHelpers/flowIcons";

interface SearchProps {
  onSearch: (query: string) => void;
  onType: (query: string) => void;
  type: string;
  placeholder: string;
  className?: string;
}

export default function Search(props: SearchProps) {
  const { onSearch, onType, type, placeholder, className } = props;
  const [query, setQuery] = useState("");

  return (
    <div className={styles.searchWrapper}>
      <input
        className={cn(styles.search, className)}
        type={type}
        placeholder={placeholder}
        value={query}
        onChange={({ target }) => {
          setQuery(target.value);
          onType(target.value);
        }}
        onKeyUp={({ key }) => key === "Enter" && onSearch(query)}
      />
      <span className={styles.searchIcon}>
        <SearchIcon />
      </span>
    </div>
  );
}
