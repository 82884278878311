import { useEffect, useState } from "react";
import { getDisputeTemplateReasonListRequest } from "redux/actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const useDisputeReasonList = () => {
  const dispatch = useDispatch();
  const [reasonList, setReasonList] = useState<any>([]);
  const _data = "21231221";
  const { isLoading, isSuccess, isError, data } = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplateReasonList
  );

  useEffect(() => {
    if (_data) {
      dispatch(getDisputeTemplateReasonListRequest(_data));
    }
  }, []);
  useEffect(() => {
    if (isSuccess && data) {
      let reasonOptions: any = [];
      data?.forEach((reason: any) => {
        let reasonObj = {
          value: reason?.disputeReasonValue,
          label: reason?.disputeReasonValue.split("_").join(" "),
        };
        reasonOptions.push(reasonObj);
      });
      setReasonList(reasonOptions);
    }
  }, [isSuccess, data])



  return {
    isLoading,
    isSuccess,
    isError,
    data,
    reasonList
  };
};
export default useDisputeReasonList;
