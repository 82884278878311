import { FC } from 'react';
import styles from './style.module.scss';
import { svgIcons } from '../../elements';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Success: FC = () => {
  const {user} = useAuth0();
  const navigate = useNavigate();
  
  return (
    <div className={styles.connectWrapper}>
      <div className={styles.connectLeft}>
        <div className={styles.lineConnect}>
          <img src={svgIcons.TPConnect} alt="tp" />
          <img src={svgIcons.TPConnect} alt="tp" />
          <img src={svgIcons.TPConnect} alt="tp" />
          <div className={styles.line} />
        </div>
        <h3>Connection Successful</h3>

        <div className={styles.infoWrapper}>
          <div className={styles.secure}>100% secure connection</div>
        </div>
      </div>
      <div className={styles.connectRight}>
        <p>Please connect an account to get started with us</p>
        <div>
          <button>
            <img src={svgIcons.PayPalFill} alt="tp" />
            {user?.email}
          </button>
          <button className={styles.continue} onClick={()=> navigate('/option')}>Continue</button>
        </div>
      </div>
    </div>
  );
};

export default Success;
