import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getDisputeTemplateListRequest } from "redux/actions";

const useDisputeTemplateList = (type?: string) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { isLoading, isSuccess, isError, data } = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplateList
  );

  const [listPayload, setListPayload] = useState<any>();
  const tableList = 15;
  const { storeId } = useParams();
  let location = useLocation();
  const toBeSelected = location?.pathname.includes("store-details");
  const {
    isLoading: singleIsLoading,
    isSuccess: singleIsSuccess,
    isError: singleError,
    data: singleStoreTemplate,
  } = useSelector(
    (state: any) => state.disputeTemplate.disputeTemplateFindByStore
  );
  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };
  useEffect(() => {
    const payload1 = {
      enabled: true,
      page: 1,
      pageLength: "",
    };
    const payload2 = listPayload
      ? {
          ...listPayload,
          page,
        }
      : {
          enabled: true,
          page,
          pageLength: tableList,
        };
    dispatch(
      getDisputeTemplateListRequest(type === "flow" ? payload1 : payload2)
    );
  }, [page, type]);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    singleIsLoading,
    singleIsSuccess,
    singleError,
    singleStoreTemplate,
    toBeSelected,
    storeId,
    page,
    listPayload,
    tableList,
    onPageChange,
    setListPayload,
  };
};
export default useDisputeTemplateList;

