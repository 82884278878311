import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import {useOrderFulfillmentDetails} from "hooks";
import dayjs from "dayjs";
import Loading from "components/loading/loading";

const OrderFulfillment = () => {
  const { data, isLoading } = useOrderFulfillmentDetails();
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {data && data.length >= 1 ? (
            <div>
              {data.map((item: any, index: any) => (
                <div key={index} className={styles.orderFulfillmentContainer}>
                  <div className={styles.orderFulfillmentWrapper}>
                    <p className={styles.orderFulfillmentTitle}>
                      Tracking Number
                    </p>
                    <Link
                      to={item.trackingUrl}
                      target="_blank"
                      className={styles.orderFulfillmentDetails}
                    >
                      <p style={{ borderBottom: "1px solid #000" }}>
                        #{item?.trackingNumber}
                      </p>
                    </Link>
                  </div>

                  <div className={styles.orderFulfillmentWrapper}>
                    <p className={styles.orderFulfillmentTitle}>Carrier</p>
                    <p className={styles.orderFulfillmentDetails}>
                      {item?.carrier}
                    </p>
                  </div>

                  <div className={styles.orderFulfillmentWrapper}>
                    <p className={styles.orderFulfillmentTitle}>Status</p>
                    <p
                      className={`${
                        item.status === "success"
                          ? styles.orderFulfillmentDetailsSuccess
                          : styles.orderFulfillmentDetailsError
                      }`}
                    >
                      {item?.status}
                    </p>
                  </div>

                  <div className={styles.orderFulfillmentWrapper}>
                    <p className={styles.orderFulfillmentTitle}>Date</p>
                    <p className={styles.orderFulfillmentDetails}>
                      {item?.createdAt &&
                        dayjs(item?.createdAt).format("DD MMMM YYYY")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                fontSize: "12px",
                textAlign: "center",
                color: "#98A2B3",
                display: "flex",
                alignItems: "center",
                minHeight: "100px",
                justifyContent: "center",
              }}
            >
              No order record
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default OrderFulfillment;
