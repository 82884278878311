import cn from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Pagination,
  // Notifications,
  NoDataFound,
} from "components";
import { svgIcons } from "elements";

import styles from "./style.module.scss";

const mockNotificationsData = {
  count: 0,
  data: [],
};

export default function NotificationPage() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const tableList = 7;
  const data = mockNotificationsData;

  // const {isLoading, isSuccess, isError, } = useSelector((state: any)=> state?.notifications?.notificationsList)

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  function handleBreadcrumb(page: string) {
    navigate(page);
  }

  // useEffect(() => {
  //   dispatch(getNotificationsListRequest())
  // }, [page, dispatch])

  return (
    <div className={styles.notificationsWrapper}>
      <div className={styles.notificationsBody}>
        {/* <Notifications.Activate /> */}
        <div className={styles.nav}>
          <h3>Notification</h3>
          <div className={styles.navPath}>
            <p
              className={styles.cursor}
              onClick={() => handleBreadcrumb("/home")}
            >
              Home
            </p>

            <p>Notifications</p>
          </div>
        </div>

        <div>
          <p className={styles.desc}>
            All your latest updates here for you to view
          </p>
        </div>

        <div className={styles.notificationContainer}>
          {data?.count > 0 ? (
            mockNotificationsData?.data?.length > 0 &&
            mockNotificationsData?.data?.map((notification: any) => {
              return (
                <div key={notification?.date}>
                  <div className={cn(styles.notificationTitle)}>
                    <h4 className={styles.title}>{notification?.date}</h4>
                  </div>
                  {notification?.notifications?.map((notifyData: any) => {
                    return (
                      <div className={styles.notificationItem}>
                        <div className={styles.notification}>
                          <div
                            className={styles.notifyIcon}
                            style={{
                              background: `${notifyData?.iconBackgound}`,
                            }}
                          >
                            <img src={notifyData?.icon} alt="placeholder" />
                          </div>
                          <div className={styles.notificationContent}>
                            <h3>{notifyData?.title}</h3>
                            <p>{notifyData?.description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <NoDataFound
              icon={svgIcons?.Coffee_Break}
              // isError={isError}
              errorStatement="Oops! Something went wrong. Please try again."
              notFoundMessage="No notification found"
            />
          )}
        </div>
        {Math.ceil(data?.count / tableList) > 1 && (
          <Pagination
            onPageChange={onPageChange}
            page={page}
            totalPages={Math.ceil(data?.count / tableList)}
          />
        )}
      </div>
    </div>
  );
}
