import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
export class GetDisputeOfferDetails {
    /**
     * Function to invoke to get dispute offer details
     */
    async invoke(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_OFFER_DETAILS(payload));

        return res;
    }
}