import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
// import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../../loading/loading";

import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { getDisputeResponseFindByDisputeIdRequest } from "redux/actions";
import { useDispatch } from "react-redux";

const disputeResponseColumns = [
  "ID",
  "Event Type",
  "Occurred At",
  "Handled by",
  "Status",
  "",
];

const tableData = [
  {
    id: "ID-116745",
    event: "PSP Creation",
    date: "14 November 2023 12:45",
    handler: "Chargepay",
    status: "Warning",
  },

  {
    id: "ID-116745",
    event: "PSP Creation",
    date: "14 November 2023 12:45",
    handler: "Chargepay",
    status: "Success",
  },
  {
    id: "ID-116745",
    event: "PSP Creation",
    date: "14 November 2023 12:45",
    handler: "Chargepay",
    status: "Error",
  },
];

const CreateAudit = () => {
  // const isLoading = false;

  // this below full code for only using loader temporally
  const { disputeId } = useParams();
  const dispatch = useDispatch();
  const disputeResponseFindByDisputeId = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponseFindByDisputeId
  );
  const { isLoading } = disputeResponseFindByDisputeId;
  useEffect(() => {
    if (disputeId) {
      dispatch(getDisputeResponseFindByDisputeIdRequest(disputeId));
    }
  }, [dispatch]);
  return (
    <div className={styles.tableWrapper}>
      <ToastContainer />
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div>
          <table className={styles.tableStyle}>
            <thead>
              <h3>Audit</h3>
              <tr>
                {disputeResponseColumns.map((item) => (
                  <th key={item}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData && tableData.length > 0
                ? tableData.map((item) => (
                    <tr>
                      {" "}
                      <td>{item.id}</td>
                      <td>{item.event}</td>
                      <td>{item.date}</td>
                      <td>
                        <span
                          style={{
                            color: "#026AA2",
                            background: "#F0F9FF",
                            borderRadius: "16px",
                            padding: "4px 8px 4px 8px",
                            display: "inline-block",
                          }}
                        >
                          {item.handler}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{
                            color:
                              item.status === "Warning"
                                ? "#FFC61A"
                                : item.status === "Error"
                                ? "#B42318"
                                : item.status === "Success"
                                ? "#12B76A"
                                : "",
                            background:
                              item.status === "Warning"
                                ? "#FFFDF6"
                                : item.status === "Error"
                                ? "#FEF5F4"
                                : item.status === "Success"
                                ? "#F3FDF7"
                                : "",
                            borderRadius: "16px",
                            padding: "4px 8px 4px 8px",
                            display: "inline-block",
                          }}
                        >
                          {item.status}
                        </span>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CreateAudit;
