import styles from "./styles.module.scss";

interface errorMessage {
  errors: any;
  name: string;
}
const ErrorMessage = ({ errors, name }: errorMessage) => {
  return (
    <>
      {errors[name]?.type === "required" && (
        <p className={styles.errorStyle}>{errors[name].message}</p>
      )}
    </>
  );
};

export default ErrorMessage;
