import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDisputeCurrency } from "utils/disputeUtils";
import { svgIcons } from "elements";

import styles from "./style.module.scss";
import FormatCurrency from "components/formatCurrency";

const OrderSummary = () => {
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [totalOrderAmount, setTotalOrderAmount] = useState<number>(0);

  const disputeOrderSummary = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeOrderSummary
  );

  useEffect(() => {
    if (
      disputeOrderSummary?.isSuccess &&
      disputeOrderSummary?.data?.length > 0
    ) {
      let total = 0;
      disputeOrderSummary?.data?.slice(0, 4)?.forEach((order: any) => {
        total += order?.disputeAmountValue;
        setCurrencySymbol(order?.disputeAmountCurrency);
      });
      setTotalOrderAmount(total);
    }
  }, [disputeOrderSummary?.isSuccess]);

  return (
    <div>
      <div>
        {disputeOrderSummary?.isSuccess &&
          disputeOrderSummary?.data?.slice(0, 4)?.map((order: any) => {
            return (
              <div key={order?.id} className={styles.orderWrapper}>
                <img
                  style={{ maxHeight: "60px", maxWidth: "60px" }}
                  src={order?.productImage ?? svgIcons.Half_Slive_Polo_Tshirt}
                  alt="product_image"
                />
                <p className={styles.orderName}>{order?.itemDescription}</p>
                <p>{`x${order?.itemQuantity}`}</p>
                <p>
                  <FormatCurrency
                    value={Number(order?.disputeAmountValue)?.toFixed(2)}
                    currencyCode={order?.disputeAmountCurrencyCode}
                  />
                  {/* {
                    getDisputeCurrency(order?.disputeAmountCurrency)
                      ?.currencySign
                  }
                  {Number(order.disputeAmountValue)
                    ?.toFixed(2)
                    ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} */}
                </p>
              </div>
            );
          })}
      </div>
      <div className={styles.orderCalcWrapper}>
        <div className={styles.orderCalc}>
          <p className={styles.CalcText}>Discount code</p>
          <p className={styles.discountCode}>Not avaiable</p>
        </div>
        <div className={styles.orderCalc}>
          <p className={styles.CalcText}>Total</p>
          <p className={styles.totalAmount}>
            {/* <FormatCurrency
                     value={Number(totalOrderAmount)?.toFixed(2)}
                     currencyCode={currencySymbol}
                    /> */}
            {getDisputeCurrency(currencySymbol)?.currencySign}
            {`${totalOrderAmount
              ?.toFixed(2)
              ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} ${currencySymbol}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
