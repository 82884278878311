import DisputeAwaitingStage from "../disputeAwaitingStage";
import { svgIcons } from "elements";

import styles from "./style.module.scss";

interface DisputeResultProps {
  disputeState: string;
  disputeOutcome: string;
}

const disputeUnderReview = {
  statusDisputeWon: "You won the dispute",
  statusDisputeLost: "You lost the dispute",
};

const DisputeResult = ({
  disputeState,
  disputeOutcome,
}: DisputeResultProps) => {
  return (
    <>
      {disputeState === "RESOLVED" ? (
        <div className={styles.disputeUnderReviewWrapper}>
          <h4>Result</h4>
          <div className={styles.disputeUnderReviewData}>
            <div>
              <h6>Status</h6>
              <p
                className={
                  disputeState === "RESOLVED" &&
                  disputeOutcome === "RESOLVED_SELLER_FAVOUR"
                    ? styles.disputeStatusWonRes
                    : styles.disputeStatusLostRes
                }
              >
                {disputeState === "RESOLVED" &&
                disputeOutcome === "RESOLVED_SELLER_FAVOUR"
                  ? disputeUnderReview.statusDisputeWon
                  : disputeUnderReview.statusDisputeLost}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <DisputeAwaitingStage
          stageIcon={svgIcons.Result}
          stage={"Stage 3"}
          stageName={"Result of Dispute"}
        />
      )}
    </>
  );
};

export default DisputeResult;
