import { FC, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Tooltip, Button } from "@mui/material";
import { useParams } from "react-router-dom";

import { svgIcons } from "elements";
import {
  StatusSection,
  ResponseDate,
  Reasons,
  paymentProcessor,
  Type,
} from "utils/disputeUtils";
import { getItem, removeItem, setItem } from "utils/localStorage";

import styles from "./style.module.scss";
import Loading from "stores/Loading";
import { useDisputeReasonList } from "hooks";

interface StatusProps {
  title: string;
  color: string;
  status: boolean;
}

interface ReasonsProps {
  title: string;
  status: boolean;
}

interface disputeFilterProps {
  disputesPayload: any;
  setDisputesPayload: React.Dispatch<any>;
  setDateRange: any;
  setDateFilterCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const DisputeFilter: FC<disputeFilterProps> = ({
  disputesPayload,
  setDisputesPayload,
  setDateRange,
  setDateFilterCheck,
  setPage,
}) => {
  const { accountId } = useParams();
  const SingleAccountCheck = getItem("singleAccountCheck");
  const [statusFilter, setStatusFilter] =
    useState<StatusProps[]>(StatusSection);
  const [responseDateFilter, setResponseDateFilter] =
    useState<ReasonsProps[]>(ResponseDate);

  // fetching data from hook
  const { data: ReasonData, isLoading } = useDisputeReasonList();
  // set data to the state
  const [reasonFilterData, setReasonFilterData] = useState<ReasonsProps[]>([]);
  const [paymentProcessorFilter, setPaymentProcessorFilter] = useState(paymentProcessor);
  const [typeFilter, setTypeFilter] = useState<ReasonsProps[]>(Type);
  const [filterCheck, setFilterCheck] = useState(true);

  const handleStatus = (data: any, index: any) => {
    const dataList = cloneDeep(statusFilter);
    data.status = !data.status;
    dataList[index] = data;
    setStatusFilter(dataList);
  };

  const handleDate = (data: any, index: any) => {
    const dataList = cloneDeep(responseDateFilter);
    data.status = !data.status;
    dataList[index] = data;
    setResponseDateFilter(dataList);
  };

  const handleReasonFilter = (data: ReasonsProps, index: any) => {
    const dataList = cloneDeep(reasonFilterData);
    data.status = !data.status;
    dataList[index] = data;
    setReasonFilterData(dataList);
  };

  const handlePayment = (data: any, index: any) => {
    const dataList = cloneDeep(paymentProcessorFilter);
    data.status = !data.status;
    dataList[index] = data;
    setPaymentProcessorFilter(dataList);
  };

  const handleType = (data: any, index: any) => {
    const dataList = cloneDeep(typeFilter);
    data.status = !data.status;
    dataList[index] = data;
    setTypeFilter(dataList);
  };

  const handleFiltering = () => {
    //FOR-STATUS
    const statusArr = statusFilter.filter((statusData) => statusData.status);
    const statusFilterArr = statusArr.map((item) => {
      return item?.title.toUpperCase();
    });

    //FOR-RESPONSE-DATE-PAYLOAD
    const responseDateArr = responseDateFilter.filter(
      (filterData) => filterData.status
    );
    let oneDay = false;
    let oneWeek = false;
    let oneMonth = false;
    responseDateArr.forEach((item) => {
      if (item?.title === "1 day") {
        oneDay = true;
      } else if (item?.title === "1 week") {
        oneWeek = true;
      } else if (item?.title === "1 month") {
        oneMonth = true;
      }
    });

    //FOR-REASONS
    const reasonsArr = reasonFilterData.filter(
      (filterData) => filterData.status
    );
    const reasonFilter = reasonsArr.map((item) => {
      return item?.title.toUpperCase();
    });

    //FOR-PAYMENT-PROCESSOR-PAYLOAD
    const paymentArr = paymentProcessorFilter.filter(
      (filterData) => filterData.status
    );
    const paymentFilter = paymentArr.map((item) => {
      return item?.value;
    });

    //FOR-TYPE
    const typeArr = typeFilter.filter((filterData) => filterData.status);
    const typeFilterArr = typeArr.map((item) => {
      return item?.title.toUpperCase();
    });

    const payload =
      SingleAccountCheck === "true"
        ? {
          ...disputesPayload,
          page: 1,
          statuses: statusFilterArr.length ? statusFilterArr : null,
          reasons: reasonFilter.length ? reasonFilter : null,
          stage: typeFilterArr?.length ? typeFilterArr : null,
          paymentProcessor: paymentFilter?.length ? paymentFilter : null,
          userId: accountId,
          oneDay,
          oneWeek,
          oneMonth,
        }
        : {
          ...disputesPayload,
          page: 1,
          statuses: statusFilterArr.length ? statusFilterArr : null,
          reasons: reasonFilter.length ? reasonFilter : null,
          stage: typeFilterArr?.length ? typeFilterArr : null,
          paymentProcessor: paymentFilter?.length ? paymentFilter : null,
          oneDay,
          oneWeek,
          oneMonth,
        };

    setItem("disputePage", 1);
    setPage(1);
    setDisputesPayload(payload);
  };

  const handleFilterReset = () => {
    setStatusFilter(StatusSection);

    setReasonFilterData(reasonFilterData); // problem is here
    setTypeFilter(Type);

    setResponseDateFilter(ResponseDate);
    setPaymentProcessorFilter(paymentProcessor);

    //clear the date-filter
    setDateFilterCheck(false);
    setDateRange([null, null]);

    //remove all the filter payload
    removeItem("filteringDispute");
    removeItem("filterPayload");

    const payload = !SingleAccountCheck
      ? {
        ...disputesPayload,
        page: 1,
        statuses: null,
        reasons: null,
        stage: null,
        paymentProcessor: null,
        oneDay: false,
        oneWeek: false,
        oneMonth: false,
      }
      : {
        ...disputesPayload,
        page: 1,
        statuses: null,
        reasons: null,
        stage: null,
        paymentProcessor: null,
        oneDay: false,
        oneWeek: false,
        oneMonth: false,
        userId: accountId,
      };

    setItem("disputePage", 1);
    setPage(1);
    setDisputesPayload(payload);
  };

  useEffect(() => {
    let checkFilter;
    checkFilter = statusFilter.filter((status) => status.status);

    checkFilter.length === 0 &&
      (checkFilter = reasonFilterData.filter((reason) => reason.status));

    checkFilter.length === 0 &&
      (checkFilter = paymentProcessorFilter.filter(
        (payment) => payment.status
      ));
    checkFilter.length === 0 &&
      (checkFilter = responseDateFilter.filter((date) => date.status));
    checkFilter.length === 0 &&
      (checkFilter = typeFilter.filter((date) => date.status));
    if (checkFilter.length > 0) {
      setFilterCheck(false);
    } else {
      setFilterCheck(true);
    }
  }, [
    statusFilter,
    reasonFilterData,
    paymentProcessorFilter,
    responseDateFilter,
    typeFilter,
  ]);

  useEffect(() => {
    if (ReasonData && ReasonData.length > 1) {
      const newReasonFilterData = ReasonData.map((reason: any) => ({
        title: reason.disputeReasonValue,
        status: false,
      }));
      setReasonFilterData(newReasonFilterData);
    }
  }, [ReasonData]);

  return (
    <>
      <div className={styles.filtersDrop}>
        {isLoading ? (
          <Loading />
        ) : (
          <div className={styles.filtersDropOptions}>
            <div className={styles.check}>
              <div className={styles.filtersDropTitle}>Status</div>
              {statusFilter.map((item, index) => {
                return (
                  <div className={styles.checkbox} key={item.title}>
                    <input
                      id={item.title}
                      checked={item.status}
                      type="checkbox"
                      name={item.title}
                      onChange={() => handleStatus({ ...item }, index)}
                    />
                    <div style={{ background: item.color }} />
                    <label style={{ cursor: "pointer" }} htmlFor={item.title}>
                      {item.title.split("_").join(" ")}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className={styles.check}>
              <div className={styles.filtersDropTitle}>Response Date</div>
              {responseDateFilter.map((response, index) => {
                return (
                  <div
                    className={styles.checkbox}
                    key={response.title}
                    onClick={() => handleDate({ ...response }, index)}
                  >
                    <input
                      type="checkbox"
                      name={response.title}
                      checked={response.status}
                      readOnly
                    />
                    <label
                      style={{
                        cursor: "pointer",
                      }}
                      htmlFor={response.title}
                    >
                      {response.title}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className={styles.check}>
              <div className={styles.filtersDropTitle}>Reasons</div>
              <div className={styles.reasonFilterOptions}>
                {reasonFilterData.length > 1 &&
                  reasonFilterData.map(
                    (reason: ReasonsProps, index: number) => {
                      return (
                        <div
                          className={styles.checkbox}
                          onClick={() =>
                            handleReasonFilter({ ...reason }, index)
                          }
                        >
                          <input
                            type="checkbox"
                            name={reason.title}
                            checked={reason.status}
                            readOnly
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor={reason.title}
                          >
                            {reason.title.split("_").join(" ")}
                          </label>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
            <div className={styles.check}>
              <div className={styles.filtersDropTitle}>Payment Processor</div>
              {paymentProcessorFilter.map((gateWay, index) => {
                return (
                  <div
                    className={styles.checkbox}
                    key={gateWay.processorName}
                    onClick={() => handlePayment({ ...gateWay }, index)}
                  >
                    <input
                      type="checkbox"
                      name={gateWay.processorName}
                      checked={gateWay.status}
                      readOnly
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      htmlFor={gateWay.processorName}
                    >
                      {gateWay.processorName}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className={styles.check}>
              <div className={styles.filtersDropTitle}>Type</div>
              {typeFilter.map((item, index) => {
                return (
                  <div
                    className={styles.checkbox}
                    key={item.title}
                    onClick={() => handleType({ ...item }, index)}
                  >
                    <input
                      type="checkbox"
                      name={item?.title}
                      checked={item?.status}
                      readOnly
                    />
                    <label style={{ cursor: "pointer" }} htmlFor={item.title}>
                      {item.title.split("_").join(" ")}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className={styles.searchButtons}>
        <Button
          variant="contained"
          className={styles.buttonApply}
          onClick={() => handleFiltering()}
          disabled={filterCheck}
          data-testid="apply-filter"
        >
          <img src={svgIcons.CheckTwo} alt="check" /> Apply Filters
        </Button>
        <button
          className={styles.buttonReset}
          onClick={handleFilterReset}
          data-testid="reset-filter"
        >
          Reset
        </button>
      </div>
    </>
  );
};

export default DisputeFilter;
