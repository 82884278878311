import { Close } from "@mui/icons-material";
// import { Button, PopperPlacementType } from "@mui/material";
import { IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useRef } from "react";
// import Fade from "@mui/material/Fade";
// import Paper from "@mui/material/Paper";
// import Popper from "@mui/material/Popper";
import useOnClickOutside from 'hooks/utilsHooks/useClickOutside';
import PopperItem from "./PopperItem";
import { ConditionItemProps } from "../types";
import ToolTipWrapper from "./ToolTipWrapper";
const ConditionItem = (props: ConditionItemProps) => {
  const ref = useRef(null);
  const { conditions, handleDeleteCondition, id: nodeId, styles, type } = props;
  const initStyle = {
    id: null,
    border: "",
  };
  const [changeFocus, setChangeFocus] = useState(initStyle);
  const handleClickOutside = (e: any) => {
    setChangeFocus(initStyle);
  };
  useOnClickOutside(ref, handleClickOutside);

  const handleClickInside = (item: any) => {
    setChangeFocus({ id: item.id, border: "2px solid #ccc" });
  };

  // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
  //   null
  // );
  // const [open, setOpen] = React.useState(false);
  // const [placement, setPlacement] = React.useState<PopperPlacementType>();

  // const handleClick =
  //   (newPlacement: PopperPlacementType) =>
  //   (event: React.MouseEvent<HTMLButtonElement>) => {
  //     setAnchorEl(event.currentTarget);
  //     setOpen((prev) => placement !== newPlacement || !prev);
  //     setPlacement(newPlacement);
  //   };

  return (
    <div>
      {conditions.map((item: any, index: number) => {
        return (
          <div ref={ref} key={index}>
            {item.source && (
              <span className={styles.sourceStyle} id={item.id}>
                {item.source}
              </span>
            )}
            <Typography
              key={item.id}
              id={item.id}
              style={{
                outline:
                  item.id === changeFocus.id ? `${changeFocus.border}` : "",
              }}
              className={`${styles.selectConditionText}`}
              onClick={() => {
                handleClickInside(item);
              }}
            >
              <PopperItem />
              <span
                className={` ${
                  item.source === ""
                    ? styles.beforeFonditionLebel
                    : styles.conditionLebel
                }`}
                id={item.id}
              >
                {item.label}
              </span>

              {/* <Button onClick={handleClick("right-end")}>right-end</Button> */}

              {item.id !== "condition1" && type === "condition" ? (
                <ToolTipWrapper
                  title="Click to remove"
                  placement={"right"}
                  component={
                    <IconButton size="small" aria-label="delete">
                      <Close
                        fontSize="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteCondition(item.id, nodeId);
                        }}
                      />
                    </IconButton>
                  }
                />
              ) : (
                <span style={{ visibility: "hidden" }}>a</span>
              )}
            </Typography>
            {conditions.length >= 2 && index < conditions.length - 1 && (
              <p className={styles.addStyle}>And</p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ConditionItem;
