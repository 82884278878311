
export const configs = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
}
export const API_CONFIG = {
  timeout: 10000,
  headers: {
    common: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
}

export const API_UPLOAD_CONFIG = {
  timeout: 10000,
  headers: {
    "Content-Type": "multipart/form-data",
    common: {
      Accept: 'application/json',
    },
  },
}