import { DisputeDetail } from "components";

const DisputeDetails = () => {
  return (
    <>
      <DisputeDetail />
    </>
  );
};

export default DisputeDetails;
