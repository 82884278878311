import {
  EdgeProps,
  NodeProps,
  NodeTypes,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import { useMemo, useState } from "react";
import {
  ActionNode,
  ConditionNode,
  StartNode,
} from "components/disputeFlow/flowNodes/Nodes";
import { initialNodes, toJSON } from "components/disputeFlow/NodeHelpers/Utils";

const useFlow = () => {
  const { getNodes, getEdges } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [currentNode, setCurrentNode] = useState<NodeProps | any>({});
  // handling sidebar
  const [openSideBar, setOpenSideBar] = useState<any>({});
  const handleNodeClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    node: NodeProps
  ) => {
    const conditionId = event?.target?.id;
    if (conditionId) {
      Object.assign(node, { conditionId });
      setCurrentNode(node);
    }
  };
  const onPaneClick = () => {
    setCurrentNode({});
    setOpenSideBar(false);
  };
  //Working on OnEdgeClick
  const handleEdgeClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    edge: EdgeProps
  ) => {
    console.log("edge_click_event_to_check", event, edge);
  };

  // Defining Node Types
  const nodeTypes: NodeTypes = useMemo(
    () => ({
      start: StartNode,
      condition: ConditionNode,
      action: ActionNode,
    }),
    []
  );

  const handleDownloadJSON = () =>
    toJSON({ nodes: getNodes(), edges: getEdges() });

  //remove attribution from the canvas
  const proOptions = { hideAttribution: true };

  return {
    nodeTypes,
    nodes,
    currentNode,
    edges,
    proOptions,
    openSideBar,
    setNodes,
    onNodesChange,
    setEdges,
    onEdgesChange,
    handleNodeClick,
    setCurrentNode,
    handleEdgeClick,
    handleDownloadJSON,
    onPaneClick,
    setOpenSideBar,
  };
};

export default useFlow;
