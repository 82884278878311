import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  postDisputeResponseSaveContentRequest,
  getAllMacrosRequest,
  PostSavingMissingMacrosRequest,
  getDisputeResponseFetchContentRequest,
} from "redux/actions";
import { capitalizeDisputeDate } from "utils/disputeUtils/disputeDetails";

const useResponseSaveContent = () => {
  const dispatch = useDispatch();
  const [responseId, setResponseId] = useState("");
  const { data, isSuccess, isLoading, isError } = useSelector(
    (state: any) => state?.disputeResponse?.getMacros
  );
  const {
    data: savingMacros,
    isSuccess: isSavingSuccess,
    isLoading: isSavingLoading,
  } = useSelector((state: any) => state?.disputeResponse?.savingMissingMacros);

  const { isSuccess: isSavedContentSuccess, isLoading: isSavedContentLoading } = useSelector((state: any) => state?.disputeResponse?.savedContent)

  const handleUpdateSaveContent = (data: any) => {
    dispatch(postDisputeResponseSaveContentRequest(data));
  };
  const handleMissingMacros = (responseId: any) => {
    dispatch(getAllMacrosRequest(responseId));
  };
  const handleSavingMissingMacros = (savingMacrosData: any) => {
    setResponseId("");
    setResponseId(savingMacrosData?.disputeResponseId);
    dispatch(PostSavingMissingMacrosRequest(savingMacrosData));
  };

  useEffect(() => {
    if (responseId && isSavingSuccess) {
      handleMissingMacros({
        disputeResponseId: responseId,
        responseSection: "ALL",
      });
      dispatch(getDisputeResponseFetchContentRequest(responseId));
      setResponseId("");
    }
  }, [responseId, isSavingSuccess]);

  // const removeImageProperty =
  //   data &&
  //   data?.missingValueFields &&
  //   data?.missingValueFields.filter((item: any) => !item.includes("img_"));
  // const reStructureMacros =
  //   removeImageProperty &&
  //   removeImageProperty.map((item: any) => {
  //     const newItem: any = {};
  //     newItem.id = item;
  //     newItem.name = item;
  //     newItem.label = capitalizeDisputeDate(item);
  //     newItem.type = "text";
  //     return newItem;
  //   });

  // const removeImageProperty =
  // data &&
  // data?.missingValueFields &&
  // data?.missingValueFields.filter((item: any) => !item.includes("img_"));



  const reStructureMacros =
    data && data?.missingValueFields &&
    data?.missingValueFields.map((item: any) => {
      const newItem: any = {};
      newItem.id = item;
      newItem.name = item;
      newItem.label = capitalizeDisputeDate(item);
      newItem.type = "text";
      return newItem;
    });

  return {
    handleUpdateSaveContent,
    handleMissingMacros,
    handleSavingMissingMacros,
    reStructureMacros,
    data,
    isSuccess,
    isLoading,
    isError,
    isSavedContentSuccess,
    isSavedContentLoading
  };
};

export default useResponseSaveContent;
