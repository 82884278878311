import { svgIcons } from "elements"

//flows-filter-otions
export const flowFilters = [
    {
        title: "Not Received",
        status: false,
    },
    {
        title: "Not As Described",
        status: false,
    },
    {
        title: "Credit Not Processed",
        status: false,
    },
    {
        title: "Dublicate Charge",
        status: false,
    },
    {
        title: "Unauthorized Charged",
        status: false,
    },
    {
        title: "Cancellation Recurring",
        status: false,
    },
    {
        title: "Fraudulent",
        status: false,
    },
    {
        title: "Unrecognized",
        status: false,
    },
    {
        title: "Other",
        status: false,
    }
]

//used in making Templates
export const flowData = [
    {
        id: 1,
        title: "Item Not Received",
        icon: svgIcons.Package,
        link: "item-not-received"
    },
    {
        id: 2,
        title: "Item Not As Described",
        icon: svgIcons.Scissors,
        link: "item-not-as-described"
    },
    {
        id: 3,
        title: "Unauthorized Transaction",
        icon: svgIcons.Shield_Off,
        link: "unauthorized-transaction"
    },
    {
        id: 4,
        title: "Refund Not Recieved",
        icon: svgIcons.Shuffle,
        link: "refund-not-recieved"
    },
    {
        id: 5,
        title: "Duplicate Payment",
        icon: svgIcons.Sidebar,
        link: "duplicate-payment"
    },
    {
        id: 6,
        title: "Incorrect Transaction Amount",
        icon: svgIcons.Trello,
        link: "incorrect-transaction-amount"
    },
    {
        id: 7,
        title: "Paid With Other Method",
        icon: svgIcons.Voicemail,
        link: "paid-with-other-method"
    },
    {
        id: 8,
        title: "Wrong Amount or Date",
        icon: svgIcons.X_Circle,
        link: "wrong-amount-or-date"
    },
    {
        id: 9,
        title: "Cancelled Preapproved Payment",
        icon: svgIcons.Slash,
        link: "cancelled-preapproved-payment"
    }
]

//used for making My Flow Template
export const myFlowData = [
    {
        title: "My Flow For Disputes",
        icon: svgIcons.Package
    },
    {
        title: "Easy Flow For Chargeback",
        icon: svgIcons.Package
    },
]

export const itemNotReceivedStripeData = [
    {
        id: 1,
        name: "Visa",
        codes: "visa codes",
        stripeCategory: [
            {
                stripeCategory: "Credit Not Processed",
                stripeData: [
                    {
                        id: "SD1",
                        data: "13.6 Credit Not Processed"
                    },
                    {
                        id: "SD2",
                        data: "13.7 Cancelled Merchandise/Services"
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        name: "Mastercard",
        codes: "mastercard codes",
        stripeCategory: [
            {
                stripeCategory: "Credit Not Processed",
                stripeData: [
                    {
                        id: "SD1",
                        data: "4860 Credit Not Processed"
                    },
                    {
                        id: "SD2",
                        data: "4860 Time Shares"
                    },
                    {
                        id: "SD3",
                        data: "4860 Credit Posted as a Purchase"
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        name: "American Express",
        codes: "amex codes",
        stripeCategory: [
            {
                stripeCategory: "Credit Not Processed",
                stripeData: [
                    {
                        id: "SD1",
                        data: "A01 Charge Amount Exceeds Authorization Amount"
                    },
                    {
                        id: "SD2",
                        data: "C02 Credit Not Processed"
                    },
                    {
                        id: "SD3",
                        data: "C04 Goods/Services Returned or Processed"
                    },
                    {
                        id: "SD4",
                        data: 'C05 "No Show" or CARD Deposit Cancelled'
                    },
                    {
                        id: "SD5",
                        data: "P03 Credit Processed as a Charge"
                    },
                    {
                        id: "SD6",
                        data: "P05 Incorrect Charged Amount"
                    },
                    {
                        id: "SD7",
                        data: "061 Credit Processed as a Charge"
                    },
                    {
                        id: "SD8",
                        data: "062 Charge Processed as a Credit"
                    },
                    {
                        id: "SD9",
                        data: "154 Goods/Services Cancelled or Refused"
                    },
                    {
                        id: "SD10",
                        data: "158 Goods Returned(Request Credit)"
                    },
                    {
                        id: "SD11",
                        data: "170 Cancelled Lodging Reservation/Card Deposit Not Received"
                    },
                    {
                        id: "SD12",
                        data: "175 Credit Not Processed"
                    },
                    {
                        id: "SD13",
                        data: "680 Incorrect Charged Amount"
                    },
                ]
            }
        ]
    },
    {
        id: 4,
        name: "Discover",
        codes: "discover codes",
        stripeCategory: [
            {
                stripeCategory: "Credit Not Processed",
                stripeData: [
                    {
                        id: "SD1",
                        data: "8002 Credit Not Processed"
                    }
                ]
            },
            {
                stripeCategory: "Duplicate",
                stripeData: [
                    {
                        id: "SD1",
                        data: "4354 Duplicate Processing"
                    },
                    {
                        id: "SD2",
                        data: "4865 Paid By Other Means"
                    }
                ]
            }
        ]
    },
]