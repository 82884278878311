import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  getDisputeTemplateReasonListRequest,
  getShopAllShopsRequest,
  postDisputeBillingRequest,
} from 'redux/actions';
import {
  DisputeFormData,
  selectDropDownOption,
} from 'components/disputeFlow/types';
// TODO- Remove unnecessay api's from this hook by checking network

interface disputeBillingFormProps {
  store: string;
  status: string;
  disputeReason: string;
  decisionDate: string;
}
const useGlobalBilling = () => {
  const { disputeFlowView, globalFlowState }: any = useSelector(
    (state: any) => state?.disputeFlow
  );
  const dispatch = useDispatch();
  const initialDefault: disputeBillingFormProps = {
    store: '',
    status: '',
    disputeReason: '',
    decisionDate: '',
  };
  const [defaultValues, setdefaultValues] = useState(initialDefault);
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    watch,
    formState: { errors },
  }: any = useForm<disputeBillingFormProps>({
    defaultValues,
  });
  const [storesOptions, setStoresOptions] = useState<selectDropDownOption[]>(
    []
  );
  const [reasons, setReasons] = useState<selectDropDownOption[]>();
  const [submitted, setSubmitted] = useState<DisputeFormData | null>();
  const { isLoading, isSuccess, isError } = useSelector(
    (state: any) => state?.disputeFlow?.disputeFlow
  );
  const disputeTemplateReasonList = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplateReasonList
  );
  const shopAllShops = useSelector(
    (state: any) => state.storesShop.shopAllShops
  );

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  //Get the implementation of initial State
  const [page, setPage] = useState(1);
  const pageLength = 15;

  useEffect(() => {
    const payload = {
      pagination: {
        page,
        pageLength,
      },
      submitForm: {},
    };
    dispatch(postDisputeBillingRequest(payload));
  }, [page]);

  useEffect(() => {
    const payload = {
      enabled: true,
    };
    dispatch(getDisputeTemplateReasonListRequest(payload));
    dispatch(getShopAllShopsRequest(payload));
  }, [dispatch]);

  useEffect(() => {
    if (
      disputeTemplateReasonList?.isSuccess &&
      disputeTemplateReasonList?.data?.length > 0
    ) {
      const response = disputeTemplateReasonList?.data?.map((reason: any) => {
        let reasonObj = {
          value: reason?.disputeReasonValue,
          label: reason?.description,
        };
        return reasonObj;
      });
      setReasons(response);
    }
    if (shopAllShops?.isSuccess && shopAllShops?.data?.count > 0) {
      let storeOption = shopAllShops?.data?.shopResponseList?.map(
        (store: any) => {
          let storeObj = {
            value: store?.id,
            label: store?.name,
          };
          return storeObj;
        }
      );
      setStoresOptions(storeOption);
    }
  }, [disputeTemplateReasonList, shopAllShops]);

  const mapedForm = [
    {
      label: 'Store',
      name: 'store',
      rules: {},
      placeholder: 'Select store',
      options: storesOptions,
    },
  ];

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const formFunction = {
    control,
    errors,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    watch,
  };
  return {
    storesOptions,
    isLoading,
    isSuccess,
    isError,
    submitted,
    mapedForm,
    formFunction,
    disputeFlowView,
    reasons,
    globalFlowState,
    page,
    pageLength,
    setSubmitted,
    setPage,
    onPageChange
  };
};
export default useGlobalBilling;
