import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    underReviewDisputesList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    underReviewTotalBilledAmount:{
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    underReviewTotalAmount:{
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    }
}

// under review disputes
const getUnderReviewDisputesListRequest = (state: any, action: any) => {
    return update(state, {
        underReviewDisputesList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getUnderReviewDisputesListSuccess = (state: any, action: any) => {
    return update(state, {
        underReviewDisputesList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getUnderReviewDisputesListError = (state: any, action: any) => {
    return update(state, {
        underReviewDisputesList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// under review disputes total billed amount
const getUnderReviewTotalBilledAmountRequest = (state: any, action: any) => {
    return update(state, {
        underReviewTotalBilledAmount: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getUnderReviewTotalBilledAmountSuccess = (state: any, action: any) => {
    return update(state, {
        underReviewTotalBilledAmount: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getUnderReviewTotalBilledAmountError = (state: any, action: any) => {
    return update(state, {
        underReviewTotalBilledAmount: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


// under review disputes total amount
const getUnderReviewTotalAmountRequest = (state: any, action: any) => {
    return update(state, {
        underReviewTotalAmount: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getUnderReviewTotalAmountSuccess = (state: any, action: any) => {
    return update(state, {
        underReviewTotalAmount: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getUnderReviewTotalAmountError = (state: any, action: any) => {
    return update(state, {
        underReviewTotalAmount: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.GET_FETCH_UNDER_REVIEW_DIPSUTES_REQUEST]: getUnderReviewDisputesListRequest,
        [constants.GET_FETCH_UNDER_REVIEW_DISPUTES_SUCCESS]: getUnderReviewDisputesListSuccess,
        [constants.GET_FETCH_UNDER_REVIEW_DISPUTES_ERROR]: getUnderReviewDisputesListError,

        [constants.GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_REQUEST]: getUnderReviewTotalBilledAmountRequest,
        [constants.GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_SUCCESS]: getUnderReviewTotalBilledAmountSuccess,
        [constants.GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_ERROR]: getUnderReviewTotalBilledAmountError,

        [constants.GET_UNDER_REVIEW_TOTAL_AMOUNT_REQUEST]: getUnderReviewTotalAmountRequest,
        [constants.GET_UNDER_REVIEW_TOTAL_AMOUNT_SUCCESS]: getUnderReviewTotalAmountSuccess,
        [constants.GET_UNDER_REVIEW_TOTAL_AMOUNT_ERROR]: getUnderReviewTotalAmountError,
    },
    initialState
);