import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface AccountObj {
    "userId": string,
    "planDisplayName": string,
    "planName": string,
    "email": string,
    "emailVerified": boolean,
    "username": string,
    "phoneNumber": string,
    "phoneVerified": boolean,
    "createdAt": string,
    "updatedAt": string,
    "name": string,
    "nickname": string,
    "lastLogin": string,
    "loginsCount": number,
    "blocked": boolean,
    "givenName": string,
    "familyName": string,
    "birthDate": string,
    "telephone": string,
    "status": string
}

interface ResProp {
    data: {
        count: number,
        accountResponses: AccountObj[],
    }
}


export function* postAccountListDataRequest(action: any) {
    try {
        const res: ResProp = yield Api.postAccountList(action.payload);
        yield put(actions.postAccountListSuccess(res.data));
    } catch (e) {
        yield put(actions.postAccountListError());
    }
}