import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import styles from './style.module.scss';

const Profile = () => {
  const { user = {}, logout } = useAuth0();
  const [userData, setUserData] = useState<any>();

  useEffect(()=>{
    if(user?.nickname){
      setUserData(user)
    }
  },[user])

  return (
    <div className={styles.profileWrapper}>
      <h3>Basic Details</h3>
      <div className={styles.details}>
        <div className={styles.detail}>
          <div>
            <label htmlFor="Fname">Full Name</label>
            <p>Please use your legal name</p>
          </div>
          <input type="text" name="Fname" placeholder="" value={userData?.nickname || ""} readOnly />
        </div>
        <div className={styles.detail}>
          <div>
            <label htmlFor="Pnumber">Phone Number</label>
            <p>For SMS updates & verification</p>
          </div>
          <input type="text" name="Pnumber" placeholder="" readOnly />
        </div>
        <div className={styles.detail}>
          <div>
            <label htmlFor="dob">Email</label>
            <p>Your email </p>
          </div>
          <input
              type="email"
              name="email"
              placeholder=""
              value={userData?.email || ""}
              readOnly
            />
        </div>
      </div>
    </div>
  );
};

export default Profile;
