import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@mui/material";
import styles from "./style.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useUpdateHandler } from "hooks";
import { useEffect } from "react";
interface UpdateManagerTypes {
  toUsers?: any;
  selectedValue?: any;
  setSelectedValue?: any;
  store?: boolean;
  shopFindShop?: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    data: any;
  };
  storeList?: any;
  getDefaultValue?: any;
  dispute?: boolean | any;
}

export default function SelectUpdateManager({
  toUsers,
  selectedValue,
  setSelectedValue,
  shopFindShop,
  dispute,
  store,
  storeList,
}: UpdateManagerTypes) {
  const { handleUpdate } = useUpdateHandler({
    setSelectedValue,
    dispute,
    shopFindShop,
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 320,
      },
    },
  };
  const defaultValue =
    toUsers?.length > 0 &&
    toUsers?.find((item: any) => item?.userName === storeList?.manager);
  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    } else {
      setSelectedValue(null);
    }
  }, [defaultValue]);
  return (
    <Box className={styles.selectUpdateWrapper}>
      {selectedValue?.id && (
        <FormControl sx={{ my: 1, minWidth: 120 }} size="small">
          {!store && <label>Manager</label>}
          <Select
            labelId="demo-select-small-label"
            id="blur-on-select"
            onChange={(e, f) => handleUpdate(e, f as any)}
            value={selectedValue?.id ?? ""}
            // value=""
            IconComponent={KeyboardArrowDownIcon}
            style={{
              width: dispute ? "220px" : "320px",
              borderRadius: "8px",
              height: "40px",
            }}
          >
            {toUsers &&
              toUsers.length &&
              toUsers.map((item: any, index: any) => (
                <MenuItem value={item?.id} key={index}>
                  {item.userName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}
