import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {getDisputeShippingDetailsRequest } from "redux/actions";

type RooState = {
  disputeDetailsStore: {
    manageDisputeShippingDetails: {
      isSuccess: boolean;
      isLoading: boolean;
      isError: boolean;
      data: {
        estimatedDeliveryDates:string[];
        shippingStateDTOList:[];
        trackingState:string;
        trackingStateLastUpdatedAt:string;
      };
    };
  };
}

const useShippingDetails = () => {
  const dispatch = useDispatch();
  const { disputeId } = useParams();

  const {isSuccess,data, isError, isLoading } = useSelector(
    (state: RooState) => state?.disputeDetailsStore?.manageDisputeShippingDetails
  );
  useEffect(() => {
    if (disputeId) {
      const payload = disputeId;
      dispatch(getDisputeShippingDetailsRequest(payload));
    }
  }, [disputeId]);
  return { data,isSuccess, isLoading, isError };
};
export default useShippingDetails;


