import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Handle, NodeProps, Position } from "reactflow";
import {
  ConditionItem,
  PlayIcon,
  ToolTipWrapper,
} from "components/disputeFlow/NodeHelpers";
import BasicPopover from "components/disputeFlow/NodeHelpers/FlowPopover";
import { useState } from "react";
import styles from "./style.module.scss";
import { useUpdateNode } from "hooks";

const ActionNode = (props: NodeProps<any>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { handleSubmitCheck } = useUpdateNode();
  return (
    <Box
      minWidth={"268px"}
      minHeight={100}
      boxShadow={2}
      border={1}
      borderColor={props.selected ? "black" : "#cccccc"}
      borderRadius={"12px"}
      position={"relative"}
      bgcolor={"white"}
      className={styles.actionNodeWrapper}
    >
      <Handle
        type="target"
        position={Position.Top}
        className={styles.handleTopStyle}
      ></Handle>
      <Box padding={4}>
        <Box
          mb={1}
          display={`flex`}
          justifyContent={`start`}
          alignItems={`center`}
          gap={1}
        >
          <PlayIcon />
          <p className={styles.actionLabel}>{props.data?.label}</p>
        </Box>
        {!props.data.conditions[0].source && (
          <p className={styles.subLabel}>Dispute response</p>
        )}
        <ConditionItem
          styles={styles}
          conditions={props.data.conditions}
          id={props.id}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={
                  props.data.conditions[0].additionalArguments.hasAttachment
                }
                onChange={(e) => handleSubmitCheck(e.target.checked, props)}
              />
            }
            label="With attachment"
          />
        </FormGroup>
      </Box>
      <BasicPopover
        props={props}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      <ToolTipWrapper
        title="Click to add new node"
        component={
          <Handle
            type="source"
            position={Position.Bottom}
            className={styles.handleBottomStyle}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e as any);
            }}
          ></Handle>
        }
      />
    </Box>
  );
};

export default ActionNode;
