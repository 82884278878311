export const colorSelectArray: { [key: string]: string }[] = [
  {
    reason: "Merchandise_or_service_not_received",
    type: "Arbitration",
    stage: "Arbitration",
    status: "Open",
    backgroundColor: "#FFFAEB", //--warning--50
    textColor: "#B54708", //--warning--700
  },
  {
    reason: "Incorrect_amount",
    type: "Chargeback",
    stage: "Chargeback",
    backgroundColor: "#FEF3F2", //--error--50
    textColor: "#B42318", //--error--700
  },
  {
    reason: "Unauthorised",
    type: "Inquiry",
    stage: "Inquiry",
    backgroundColor: "#F4F8FF", //--primary--50
    textColor: "#18408E", //--primary--700
  },
  {
    reason: "Merchandise_or_service_not_as_described",
    type: "Pre_arbitration",
    stage: "Pre_arbitration",
    backgroundColor: "#ECFDF3", //--Success--50
    textColor: "#12B76A", //--Success--500
  },
  {
    reason: "Credit_not_processed",
    status: "Waiting_for_buyer_response",
    backgroundColor: "#F5FBEE", //--Moss--50
    textColor: "#669F2A", //--Moss--500
  },
  {
    reason: "Duplicate_transaction",
    status: "Waiting_for_seller_response",
    backgroundColor: "#FFF4ED", //--Orange--dark--50
    textColor: "#EF6820", //--Orange--500
  },
  {
    reason: "Payment_by_other_means",
    status: "Under_review",
    backgroundColor: "#F5F3FF", //--Violet--50
    textColor: "#875BF7", //--Violet--500
  },
  {
    reason: "Canceled_recurring_billing",
    status: "Resolved",
    backgroundColor: "rgba(249, 250, 251, 1)", //--gray--50
    textColor: "rgba(52, 64, 84, 1)", //--gray--700
  },
  {
    reason: "Problem_with_remittance",
    backgroundColor: "rgba(236, 253, 255, 1)", //--cyan--50
    textColor: " rgba(14, 112, 144, 1)", //--cyan--700
  },
  {
    reason: "Other",
    status: "Other",
    backgroundColor: "rgba(253, 242, 250, 1)", //--pink--50
    textColor: "rgba(193, 21, 116, 1)", //--pink--500
  },

  //updated-color-combo
  {
    status: "Lost",
    backgroundColor: "#DDDFE2",
    textColor: "#1D2939",
  },
  {
    status: "Under Review",
    backgroundColor: "#FFF4BD",
    textColor: "#1D2939",
  },
  {
    status: "won",
    backgroundColor: "#D7F3E4",
    textColor: "#1D2939",
  },
];
export const getColorByReason = (reason: string) => {
  let colorStatus = colorSelectArray.find((item, index) => {
    return item?.reason?.toLowerCase() === reason?.toLowerCase();
  });
  return colorStatus;
};
export const getColorByType = (type: string) => {
  let colorStatus = colorSelectArray.find((item, index) => {
    return item?.type?.toLowerCase() === type?.toLowerCase();
  });
  return colorStatus;
};
export const getColorByStatus = (status: string) => {
  let colorStatus = colorSelectArray.find((item, index) => {
    return item?.status?.toLowerCase() === status?.toLowerCase();
  });
  return colorStatus;
};
export const getColorByStage = (stage: string) => {
  let colorStatus = colorSelectArray.find((item, index) => {
    return item?.stage?.toLowerCase() === stage?.toLowerCase();
  });
  return colorStatus;
};

//dispute dashboard filters
export const StatusSection = [
  {
    title: "Processing",
    color: "#3751FB",
    status: false,
  },
  {
    title: "Open",
    color: "#fec768",
    status: false,
  },
  {
    title: "Awaiting Seller Response",
    color: "#65CFFD",
    status: false,
  },
  {
    title: "Awaiting Buyer Response",
    color: "#65CFFD",
    status: false,
  },
  {
    title: "Under_review",
    color: "#98c655",
    status: false,
  },
  {
    title: "Won",
    color: "#21D191",
    status: false,
  },
  {
    title: "Lost",
    color: "#787F8E",
    status: false,
  },
  {
    title: "Appealable",
    color: "#053385",
    status: false,
  },
  {
    title: "Active",
    color: "#21D191",
    status: false,
  },
  {
    title: "Inactive",
    color: "#787F8E",
    status: false,
  },
];
export const underReviewStatusSection =[
  {
    title: "Paypal",
    color: "#3751FB",
    status: false,
  },
  
  {
    title: "Stripe",
    color: "#fec768",
    status: false,
  },
  
  {
    title: "Shopify Payments",
    color: "#21D191",
    status: false,
  },
  {
    title: "Brain Tree",
    color: "#3751FB",
    status: false,
  },
  {
    title: "Klarna",
    color: "#787F8E",
    status: false,
  },


]
export const reportingStatusSection = [
  {
    title: "Handled by ChargePay",
    color: "#3751FB",
    status: true,
  },
  {
    title: "Won",
    color: "#21D191",
    status: true,
  },
  {
    title: "Lost",
    color: "#787F8E",
    status: false,
  },
  {
    title: "Active",
    color: "#21D191",
    status: false,
  },
  {
    title: "Inactive",
    color: "#787F8E",
    status: false,
  },
];

export const ResponseDate = [
  {
    title: "1 day",
    status: false,
  },
  {
    title: "1 week",
    status: false,
  },
  {
    title: "1 month",
    status: false,
  },
];
export const responseStatus = [
  {
    label: "MISSING_INFO",
    value: 1,
  },
  {
    label: "WAITING_REVIEW",
    value: 2,
  },
  {
    label: "QUEUED",
    value: 5,
  },
  {
    label: "IN_REVIEW",
    value: 3,
  },
  {
    label: "APPROVED",
    value: 4,
  },
  {
    label: "SUBMITTED",
    value: 6,
  },
  {
    label: "REJECTED",
    value: 7,
  },
  {
    label: "CREATED",
    value: 8,
  },
];
export const reportingPSP = [
  {
    label: "PayPal",
    value: 1,
  },
  {
    label: "Stripe",
    value: 2,
  },
  {
    label: "ShopifyPayments",
    value: 3,
  },
  {
    label: "BrainTree",
    value: 4,
  },
  {
    label: "Klarna",
    value: 5,
  },
];
export const DisputeOutcomes = [
  {
    label: "RESOLVED_BUYER_FAVOUR",
    value: 1,
  },
  {
    label: "RESOLVED_SELLER_FAVOUR",
    value: 2,
  },
  {
    label: "RESOLVED_WITH_PAYOUT",
    value: 3,
  },
  {
    label: "CANCELLED_BY_BUYER",
    value: 4,
  },
  {
    label: "ACCEPTED",
    value: 5,
  },
  {
    label: "DENIED",
    value: 6,
  },
  {
    label: "NONE",
    value: 7,
  },
];
export const Reasons = [
  {
    title: "Merchandise_or_service_not_received",
    status: false,
  },
  {
    title: "Merchandise_or_service_not_as_described",
    status: false,
  },
  {
    title: "Unauthorised",
    status: false,
  },
  {
    title: "Credit_not_processed",
    status: false,
  },
  {
    title: "Duplicate_transaction",
    status: false,
  },
  {
    title: "Incorrect_amount",
    status: false,
  },
  {
    title: "Payment_by_other_means",
    status: false,
  },
  {
    title: "Canceled_recurring_billing",
    status: false,
  },
  {
    title: "Problem_with_remittance",
    status: false,
  },
  {
    title: "Fraud",
    status: false,
  },
  {
    title: "General",
    status: false,
  },
  {
    title: "Invalid_account",
    status: false,
  },
  {
    title: "Product_not_received",
    status: false,
  },
  {
    title: "Retrieval",
    status: false,
  },
  {
    title: "Other",
    status: false,
  },
];
export const paymentProcessor = [
  {
    processorName: "PayPal",
    status: false,
    value: "PayPal",
  },
  {
    processorName: "BrainTree",
    status: false,
    value: "BrainTree",
  },
  {
    processorName: "Stripe",
    status: false,
    value: "Stripe",
  },
  {
    processorName: "Shopify Payments",
    status: false,
    value: "ShopifyPayments",
  },
  {
    processorName: "Klarna",
    status: false,
    value: "Klarna",
  },
];
export const Type = [
  {
    title: "Inquiry",
    status: false,
  },
  {
    title: "Chargeback",
    status: false,
  },
  {
    title: "Pre_arbitration",
    status: false,
  },
  {
    title: "Arbitration",
    status: false,
  },
];

export const evidenceType = [
  {
    name: "CANCELLATION_POLICY_FILE",
    id: "CANCELLATION_POLICY_FILE",
  },
  {
    name: "CUSTOMER_COMMUNICATION_FILE",
    id: "CUSTOMER_COMMUNICATION_FILE",
  },
  {
    name: "REFUND_POLICY_FILE",
    id: "REFUND_POLICY_FILE",
  },
  {
    name: "SERVICE_DOCUMENTATION_FILE",
    id: "SERVICE_DOCUMENTATION_FILE",
  },
  {
    name: "SHIPPING_DOCUMENTATION_FILE",
    id: "SHIPPING_DOCUMENTATION_FILE",
  },
  {
    name: "UNCATEGORIZED_FILE",
    id: "UNCATEGORIZED_FILE",
  },
];
export const disputeEvidenceType = [
  {
    name: "CVC_AVS_CHECK",
    id: "CVC_AVS_CHECK",
  },
  {
    name: "ORDER_DELIVERY",
    id: "ORDER_DELIVERY",
  },
  {
    name: "PRODUCT_DETAILS",
    id: "PRODUCT_DETAILS",
  },
  {
    name: "SHIPPING_JOURNEY",
    id: "SHIPPING_JOURNEY",
  },
  {
    name: "SIZE_GUIDE",
    id: "SIZE_GUIDE",
  },
  {
    name: "SUPPLEMENTARY_EVIDENCE",
    id: "SUPPLEMENTARY_EVIDENCE",
  },
  {
    name: "TRACKING_DETAILS",
    id: "TRACKING_DETAILS",
  },
];

//currency-sign
export const disputeCurrency: { [key: string]: string }[] = [
  { currencySign: "£", currency: "GBP" },
  { currencySign: "GEL", currency: "GEL" },
  { currencySign: "$", currency: "USD" },
  { currencySign: "€", currency: "EUR" },
  { currencySign: "AED", currency: "AED" },
  { currencySign: "Af", currency: "AFN" },
  { currencySign: "AMD", currency: "AMD" },
  { currencySign: "AR$", currency: "ARS" },
  { currencySign: "ALL", currency: "Lek" },
  { currencySign: "؋", currency: "AFN" },
  { currencySign: "$", currency: "ARS" },
  { currencySign: "ƒ", currency: "AWG" },
  { currencySign: "$", currency: "AUD" },
  { currencySign: "₼", currency: "AZN" },
  { currencySign: "$", currency: "BSD" },
  { currencySign: "$", currency: "BBD" },
  { currencySign: "$", currency: "HKD" },
  { currencySign: "RM", currency: "MYR" },
  { currencySign: "¥", currency: "CNY" },
  { currencySign: "$", currency: "SGD" },
  { currencySign: "kr", currency: "SEK" },
  { currencySign: "¥", currency: "JPY" },
  { currencySign: "kr", currency: "NOK" },
  { currencySign: "$", currency: "AUD" },
  { currencySign: "Ft", currency: "HUF" },
  { currencySign: "CHF", currency: "CHF" },
  { currencySign: "$", currency: "MXN" },
  { currencySign: "Kč", currency: "CZK" },
  { currencySign: "₽", currency: "RUB" },
  { currencySign: "C$", currency: "CAD" },
  { currencySign: "R$", currency: "BRL" },
  { currencySign: "kr", currency: "DKK" },
  { currencySign: "$", currency: "CLP" },
  { currencySign: "R", currency: "ZAR" },
  { currencySign: "$", currency: "NZD" },
  { currencySign: "₱", currency: "PHP" },
  { currencySign: "zł", currency: "PLN" },
  { currencySign: "₹", currency: "INR" },
  { currencySign: "MAD", currency: "MAD" },
  { currencySign: "₡", currency: "CRC" },
  { currencySign: "CFA", currency: "XOF" },
  { currencySign: "Kz", currency: "AOA" },
  { currencySign: "৳", currency: "BDT" },
  { currencySign: "$", currency: "COP" },
  { currencySign: "₫", currency: "VND" },
  { currencySign: "฿", currency: "THB" },
  { currencySign: "₨", currency: "PKR" },
  { currencySign: "K", currency: "PGK" },
  { currencySign: "ر.ع.", currency: "OMR" },
  { currencySign: "S/", currency: "PEN" },
  { currencySign: "$", currency: "NZD" },
  { currencySign: "₦", currency: "NGN" },
  { currencySign: "MRU", currency: "MRU" },
  { currencySign: "₸", currency: "KZT" },
  { currencySign: "₪", currency: "ILS" },
  { currencySign: "G", currency: "HTG" },
  { currencySign: "РСД", currency: "RSD" },
];

export const getDisputeCurrency = (currency: string) => {
  let currencySign = disputeCurrency.find((item, index) => {
    return item.currency?.toLowerCase() === currency?.toLowerCase();
  });
  return currencySign;
};
