import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getDisputeTemplateViewRequest } from 'redux/actions';
import { Loading } from 'components';
import styles from './style.module.scss';
import './index.scss';
import { BreadcumbItem } from 'components/ui';
import { useBreadCumbs, useDisputeTemplate } from 'hooks';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import TiptapEditor from 'components/disputeTemplate/TiptapEditor';

const TemplateView = () => {
  const dispatch = useDispatch();
  const { templateId } = useParams();
  const { editorData, setEditorContent, setValue } = useDisputeTemplate();
  const { isLoading, isSuccess, data } = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplateView
  );
  const { templateViewBreadCumb: breadCumbs } = useBreadCumbs({
    pageName: 'Template View',
  });
  useEffect(() => {
    if (templateId) {
      dispatch(getDisputeTemplateViewRequest(templateId));
    }
  }, [templateId]);

  return (
    <>
      <div className={styles.disputesWrapper}>
        <div className={styles.disputeWrapper}>
          <div className={styles.nav}>
            <h3>Template View</h3>
            <div className={styles.navPath}>
              {breadCumbs.map((item, key) => (
                <BreadcumbItem {...item} {...{ key }} />
              ))}
            </div>
          </div>

          <div className={styles.template}>
            <div className={styles.box}>
              <div className={styles.heading}>
                <h4 data-testid='Items-Not-Received-heading'>
                  {isLoading ? <Loading /> : isSuccess && data?.name}
                </h4>
              </div>
              <div>
                {!isLoading && editorData && (
                  <Box
                    style={{
                      // height: 'calc(100vh - 120px)',
                      height: 'calc(100vh - 214px)',
                      // background: 'crimson',
                    }}
                    className={styles.templateCreatorWrapper}
                  >
                    <TiptapEditor
                      {...{
                        setEditorContent,
                        editorData,
                        setValue,
                        noToolBox: true,
                        preview: true,
                      }}
                    />
                  </Box>
                )}
                {isLoading && <Loading />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateView;
