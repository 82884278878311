import { svgIcons } from "elements";
import styles from "./style.module.scss";
import { EditIcon } from "../NodeHelpers/flowIcons";
import { Button } from "components/ui";
import moment from "moment";
import Loading from "components/loading/loading";
import { Avatar, Tooltip } from "@mui/material";

interface TableData {
  data: any;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const recentDisputesColumns = [
  "Flow name",
  "Payment Gateway",
  "Created at",
  "Created by",
  "Last updated at",
  "Updated by",
  "Actions",
];

const FlowTable = ({ data, isLoading, isSuccess, isError }: TableData) => {
  return (
    <>
      <div className={styles.tableWrapper}>
        {isLoading && (
          <div className={styles.loader}>
            <Loading />
          </div>
        )}
        {!isLoading && isSuccess && data && data?.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                {recentDisputesColumns.map((recentDisputesColumn) => (
                  <th key={recentDisputesColumn}>{recentDisputesColumn}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((flow: any, index: number) => (
                <tr key={index} className={styles.tableDataRow}>
                  <td>{flow?.name}</td>
                  <td>{flow?.paymentGateway}</td>
                  <td>
                    {flow?.createdOn
                      ? moment(flow?.createdOn).format("MM/DD/YYYY HH:mm")
                      : null}
                  </td>
                  <td>
                    {flow?.createdBy ? (
                      <div>
                        <Tooltip
                          title={flow?.createdBy ?? "manager"}
                          arrow
                          placement="top"
                        >
                          <Avatar className={styles.avatarStyle}>
                            <span className={styles.firstLetter}>
                              {flow?.createdBy?.split("")[0]}
                            </span>
                            <span className={styles.secondLetter}>
                              {flow?.createdBy?.split("")[1]}
                            </span>
                          </Avatar>
                        </Tooltip>
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {flow?.lastUpdatedOn
                      ? moment(flow?.lastUpdatedOn).format("MM/DD/YYYY HH:mm")
                      : null}
                  </td>
                  <td>
                    {flow?.lastUpdatedBy ? (
                      <div>
                        <Tooltip
                          title={flow?.lastUpdatedBy ?? "manager"}
                          arrow
                          placement="top"
                        >
                          <Avatar className={styles.avatarStyle}>
                            <span className={styles.firstLetter}>
                              {flow?.lastUpdatedBy?.split("")[0]}
                            </span>
                            <span className={styles.secondLetter}>
                              {flow?.lastUpdatedBy?.split("")[1]}
                            </span>
                          </Avatar>
                        </Tooltip>
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <div className={styles.flowButtonWrapper}>
                      <Button
                        {...{
                          btnLabel: "",
                          btnTitle: "Click to edit",
                          href: `/flows-edit/${flow.id}`,
                          startIcon: <EditIcon style={{ marginLeft: "5px" }} />,
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ((!isLoading && isError) || (isSuccess && data?.length === 0)) && (
            <div className={styles.NoData}>
              <img src={svgIcons.Coffee_Break} alt="disputes" />
              <h3>No Flow</h3>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default FlowTable;
