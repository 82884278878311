import { Loading } from "components";
import styles from "./style.module.scss";
import dayjs from "dayjs";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const usersColumns = [
  "Dispute ID",
  "Store Name",
  "Response Due",
  "Amount",
  "Missing Macro",
];

const MacrosTable = ({ data = [], isLoading, isSuccess, isError }: any) => {
  return (
    <div>
      <div className={styles.tableWrapper}>
        {isLoading && (
          <div className={styles.loader}>
            <Loading />
          </div>
        )}
        <table className={styles.table}>
          <thead>
            <tr>
              {usersColumns.map((usersColumn) => (
                <th key={usersColumn}>{usersColumn}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
              data?.map((user: any, index: number) => (
                <tr key={index} className={styles.tableDataRow}>
                  <Link to={`/dispute/${user?.disputeId}`}>
                    <td>
                      <p className={styles.disputeId}>{user?.disputeId}</p>
                    </td>
                  </Link>
                  <td>{user?.shopName}</td>
                  <td>
                    {dayjs(user?.sellerResponseDueDate).format("Do MMMM YYYY")}
                  </td>
                  <td>{user?.disputeAmountValue}</td>
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      user?.missingValueFields.length > 0 &&
                      user?.missingValueFields.map((missingValue: string) => (
                        <div>{missingValue}</div>
                      ))
                    }
                  >
                    <td>
                      <div className={styles.missingInfoContainer}>
                        {user?.missingValueFields.length > 0 &&
                          user?.missingValueFields
                            .map((missingValue: string) => missingValue)
                            .join(",")}
                      </div>
                    </td>
                  </Tooltip>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MacrosTable;
