import SelectUI from "../RelatedCom/Select";
import Calendar from "./Calendar";
import ValueInput from "./ValueInput";
import { Box } from "@mui/material";
import styles from "./styles.module.scss";
import { ConditionFormItemProps } from "../types";
const ConditionFormItem = (props: ConditionFormItemProps) => {
  const {
    sidebarFormForCondition,
    register,
    openSideBar,
    errors,
    control,
    resultArray,
  } = props;
  return (
    <Box
      display={"flex"}
      justifyContent={`space-between`}
      alignItems={`flex-start`}
      gap={1}
    >
      {sidebarFormForCondition.map((item: any, index: any) =>
        item.type !== "text" ? (
          <SelectUI
            styles={styles}
            control={control}
            name={item.name}
            errors={errors}
            options={resultArray}
            sideBar
            date={openSideBar.date ? true : false}
            label={openSideBar.date ? "Response Due Date" : item.label}
            key={index}
            placeholder={item.placeholder}
          />
        ) : (
          <>
            {openSideBar.date ? (
              <Calendar
                className={styles.calendarStyle}
                control={control}
                name={item.name}
                errors={errors}
              />
            ) : (
              <ValueInput
                styles={styles}
                register={register}
                name={item.name}
                errors={errors}
              />
            )}
          </>
        )
      )}
    </Box>
  );
};

export default ConditionFormItem;
