import { MarkerType } from "reactflow";

const NodeUsesTypes = {
  StartNode: "start",
  ConditionNode: "condition",
  ActionNode: "action",
};
export function generateUUID() {
  const crypto = window.crypto || window.Crypto;
  if (crypto) {
    const data = new Uint8Array(16);
    crypto.getRandomValues(data);
    data[6] = (data[6] & 0x0f) | 0x40;
    data[8] = (data[8] & 0x3f) | 0x80;
    const hex = Array.from(data)
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");

    return `${hex.substr(0, 8)}-${hex.substr(8, 4)}-${hex.substr(
      12,
      4
    )}-${hex.substr(16, 4)}-${hex.substr(20)}`;
  } else {
    console.warn("Crypto API not available. Unable to generate UUID.");
    return null;
  }
}
export const initialNodes = [
  {
    id: "112",
    position: { x: 400, y: 100 },
    type: "start",
    data: {
      label: "Start When",
      conditions: [
        {
          label: "Select a reason",
          id: "condition1",
          source: "",
          additionalArguments: null,
        },
      ],
    },
    draggable: true,
  },
];
export const initialNewNodes = [
  {
    id: "112",
    position: { x: 400, y: 100 },
    type: "start",
    data: {
      label: "Start When",
      conditions: [
        {
          label: "Select a reason",
          id: "condition1",
          source: "",
          additionalArguments: null,
        },
      ],
    },
    draggable: true,
  },
];
const addActionOrConditionNode = (
  type: string,
  data: any,
  sourceHandleId: string
) => {
  const id = generateUUID();
  const finalPosition = {
    x:
      sourceHandleId === "otherwise"
        ? data?.xPos - 300
        : sourceHandleId === "then"
          ? data.xPos + 300
          : data.xPos,
    y:
      sourceHandleId === "otherwise"
        ? data?.yPos + 300
        : sourceHandleId === "then"
          ? data.yPos + 300
          : data.yPos + 280,
  };
  const newNode = {
    id,
    position: finalPosition,
    type: type,
    data: {
      label:
        type === NodeUsesTypes.ConditionNode
          ? "If met condition"
          : "Do this...",
      condition: "",

      conditions: [
        {
          label: `${type === NodeUsesTypes.ConditionNode
            ? "Select a condition"
            : "Select a dispute"
            }`,
          id: "condition1",
          source: "",
          additionalArguments: type === NodeUsesTypes.ActionNode ? {
            hasAttachment: true,
          } : null,
        },
      ],
    },
    draggable: true,
  };
  return newNode;
};
const addNewEdge = (data: any) => {
  const id = generateUUID();
  const newEdge = {
    id,
    type: "default",
    style: {
      stroke: "#225CCB",
      strokeWidth: "1px",
    },
    markerEnd: {
      type: MarkerType.Arrow,
      width: 40,
      height: 40,
      color: "#335CCB",
    },
    sourceHandle: data.sourceHandleId,
    source: data.source,
    target: data.target,
  };
  return newEdge;
};
function toJSON(elements: any) {
  const downloadLink = document.createElement("a");
  const fileBlob = new Blob([JSON.stringify(elements, null, 2)], {
    type: "application/json",
  });
  downloadLink.href = URL.createObjectURL(fileBlob);
  downloadLink.download = `CHARGEPAY_${Math.ceil(Math.random() * 1000)}.json`;
  downloadLink.click();
}
function arrayToObject(array: any) {
  return array.reduce((acc: any, curr: any) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {});
}
function arrayToObjectWithLabelName(
  array: any,
  keyProperty: any,
  valueProperty: any
) {
  return array.reduce((acc: any, curr: any) => {
    acc[curr[keyProperty]] = curr[valueProperty];
    return acc;
  }, {});
}
const templateTypeOptions = [
  { value: "ACCEPT_CLAIM", label: "Accept Claim" },
  { value: "ACKNOWLEDGEMENT", label: "Acknowledgement" },
  { value: "EVIDENCE", label: "Evidence" },
];
const paymentGatewayOptions = [
  { value: "PayPal", label: "PayPal" },
  { value: "BrainTree", label: "BrainTree" },
  { value: "Stripe", label: "Stripe" },
  { value: "ShopifyPayments", label: "Shopify Payments" },
  { value: "Klarna", label: "Klarna" },
];
const statusOptions = [
  { value: "Approved", label: "Approved" },
  { value: "Billed", label: "Billed" },
  { value: "Failed", label: "Failed" },
  { value: "Capped Amount", label: "Capped Amount" },
];
const disputeStatus = [
  {
    label: "Processing",
    value: "PROCESSING",
    status: false,
  },
  {
    label: "Open",
    value: "OPEN",
    status: false,
  },
  {
    label: "Awaiting Seller Response",
    value: "WAITING_FOR_SELLER_RESPONSE",
    status: false,
  },
  {
    label: "Awaiting Buyer Response",
    status: false,
    value: "WAITING_FOR_BUYER_RESPONSE",
  },
  {
    label: "Under_review",
    value: "UNDER_REVIEW",
    status: false,
  },
  {
    label: "Won",
    value: "WON",
    status: false,
  },
  {
    label: "Lost",
    value: "LOST",
    status: false,
  },
  {
    label: "Appealable",
    value: "APPEALABLE",
    status: false,
  },
]
//create-new-template
const handleTemplateType = (type: string, templateTypeOptions: any) => {
  let typeSelected = templateTypeOptions.find((item: any) => {
    return item?.value?.toLowerCase() === type?.toLowerCase();
  });

  return typeSelected;
};

const handlePaymentGateWay = (payment: string, paymentGatewayOptions: any) => {
  let PSPSelected = paymentGatewayOptions.find((item: any) => {
    return item?.value?.toLowerCase() === payment?.toLowerCase();
  });
  return PSPSelected;
};

const handleTemplateStore = (storeId: string, templateStoreOptions: any) => {
  let storeSelected =
    templateStoreOptions &&
    templateStoreOptions.find((item: any) => {
      return item?.id === storeId;
    });
  const actionObj = {
    value: storeSelected?.id,
    label: storeSelected?.name,
  };
  return actionObj;
};

const handleTemplateReason = (reason: string, reasonOptions: any) => {
  let reasonSelected =
    reasonOptions?.length &&
    reasonOptions.find((item: any) => {
      return item?.disputeReasonValue?.toLowerCase() === reason?.toLowerCase();
    });
  const actionObj = {
    value: reasonSelected?.disputeReasonValue,
    label: reasonSelected?.description,
  };
  return actionObj;
};

const handleTemplateStage = (stage: string, stages: any) => {
  let stageSelected =
    stages?.length &&
    stages.find((item: any) => {
      return (
        item?.disputeLifeCycleValues?.toLowerCase() === stage?.toLowerCase()
      );
    });
  const actionObj = {
    value: stageSelected?.disputeLifeCycleValues,
    label: stageSelected?.disputeLifeCycleValues?.split("_")?.join(" "),
  };
  return actionObj;
};

const handleTemplateAction = (action: string, actions: any = []) => {
  let actionSelected = actions.find((item: any) => {
    return item?.disputeActionValue?.toLowerCase() === action?.toLowerCase();
  });
  const actionObj = {
    value: actionSelected?.disputeActionValue,
    label: actionSelected?.description,
  };
  return actionObj;
};
export {
  NodeUsesTypes,
  templateTypeOptions,
  paymentGatewayOptions,
  statusOptions,
  disputeStatus,
  arrayToObjectWithLabelName,
  addActionOrConditionNode,
  arrayToObject,
  addNewEdge,
  toJSON,
  handleTemplateType,
  handlePaymentGateWay,
  handleTemplateReason,
  handleTemplateStage,
  handleTemplateAction,
  handleTemplateStore,
};
