import axios from "axios";
import { configs } from "configs";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const useManageManually = () => {
  const { data: disputeDetails } = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );
  const onFormSubmit = async (data: any, uploadFile: any) => {
    const formData: any = new FormData();
    formData.append("file", uploadFile);
    formData.append("uncategorizedText", data.uncategorizedText);
    formData.append("shopId", disputeDetails?.shopId);
    formData.append("shipping_date", data.shipping_date ?? null);
    formData.append("tracking_id", data.tracking_id ?? null);
    formData.append("disputeId", disputeDetails?.id);
    formData.append("evidenceType", data?.evidenceType);
    formData.append("carrier", data?.carrier);
    const token = localStorage.getItem("accessToken");
    const API_UPLOAD_CONFIG = {
      timeout: 10000,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await axios.post(
        `${configs.BASE_URL}/dispute/shopify-file-upload`,
        formData,
        API_UPLOAD_CONFIG
      );
      if (response.data) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your document has been saved successfully",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Something is wrong with submission file",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };
  return { onFormSubmit };
};
export default useManageManually;
