import RefundEligibility from 'components/disputeTemplate/editor/customBlock/refundEligibility';
import {
  CompanyBackground,
  CompanySummary,
  ShippingTrackingInfo,
  productQuality,
  DisputeDetails,
  SupplementaryEvidence,
  FullTrackingJourney,
  DisputeItems,
  ShippingTerms,
  ShippingDetails,
} from "../../components/disputeTemplate/editor/customBlock";
import { svgIcons } from "elements";
import TermsAndConditions from 'components/disputeTemplate/editor/customBlock/termsAndConditions';
import ReturnPolicy from 'components/disputeTemplate/editor/customBlock/returnPolicy';
import PageBreak from 'components/disputeTemplate/editor/customBlock/pageBreak';

export const templateComponent = [
  {
    id: 1,
    title: "Text",
    icon: svgIcons.Text,
    // Paragraph
  },
  {
    id: 2,
    title: "Heading",
    icon: svgIcons.Heading,
    // Header
  },
  {
    id: 3,
    title: "List",
    icon: svgIcons.List,
    // Envelope
  },
  {
    id: 4,
    title: "Background",
    icon: svgIcons.Background,
  },
  {
    id: 5,
    title: "Summary",
    icon: svgIcons.Summary,
    // Desktop_Solid
  },
  {
    id: 27,
    title: "Supplementary Evidence",
    icon: svgIcons.File,
  },
  {
    id: 11,
    title: "Shipping and Tracking Info",
    icon: svgIcons.Truck_1,
    // Cart_Shopping_Solid
  },
  {
    id: 12,
    title: "Page break",
    icon: svgIcons.pageBreak,
    // Cart_Shopping_Solid
  },
  {
    id: 16,
    title: "Product Quality",
    icon: svgIcons.product,
    // Cart_Shopping_Solid
  },
  {
    id: 26,
    title: "Dispute Details",
    icon: svgIcons.Disputes,
  },

  {
    id: 28,
    title: "Full tracking Journey",
    icon: svgIcons.Marker_Pin,
  },

  {
    id: 29,
    title: "Disputed Items",
    icon: svgIcons.File,
  },

  // {
  //   id:30,
  //   title:"Address Verification System",
  //   icon: svgIcons.Blaze
  // },

  {
    id: 31,
    title: "Shipping Terms",
    icon: svgIcons.Blaze,
  },
  {
    id: 34,
    title: "Shipping Details",
    icon: svgIcons.Blaze,
  },
  {
    id:35,
    title:"Refund Eligibility",
    icon: svgIcons.Refund
  },

  {
    id:36,
    title:"Terms and Conditions",
    icon: svgIcons.TermsCondition
  },
  {
    id:37,
    title:"Return Policy",
    icon: svgIcons.Return
  },

];
export const templateType = [
  { value: "ACCEPT_CLAIM", label: "Accept Claim", status: false },
  { value: "ACKNOWLEDGEMENT", label: "Acknowledgement", status: false },
  { value: "EVIDENCE", label: "Evidence", status: false },
];

//for tiptap Editor data
//To open the dropped block template
const blockReactTemplates = [
  {
    id: 1,
    blockType: "paragraph",
    componentName: "",
    title: "",
  },
  {
    id: 2,
    blockType: "header",
    componentName: "Header",
    title: "Header",
  },
  {
    id: 3,
    blockType: "list",
    componentName: "List",
    title: "List",
  },
  {
    id: 4,
    blockType: "background",
    title: "Background",
    data: CompanyBackground(),
  },
  {
    id: 5,
    blockType: "summary",
    title: "Summary",
    data: CompanySummary(),
  },
  {
    id: 6,
    blockType: "chargesMade",
    title: "Charges Made",
    data: "",
  },
  {
    id: 7,
    blockType: "productDescription",
    title: "Product Description",
    data: "",
  },
  {
    id: 8,
    blockType: "cardAddressVerification",
    title: "Card and Address Verification",
    data: "",
  },
  {
    id: 9,
    blockType: "customerActivityAccessLogs",
    title: "Customer Activity and Access Logs",
    data:"",
  },
  {
    id: 10,
    blockType: "priorTransactions",
    title: "Prior Transactions with Customer",
    data: "",
  },
  {
    id: 11,
    blockType: "shippingTrackingInfo",
    title: "Shipping and Tracking Info",
    data: ShippingTrackingInfo(),
  },
  {
    id: 12,
    blockType: "pageBreak",
    title: "Page break",
    data: PageBreak(),
  },
  {
    id: 13,
    blockType: "communicationWithCustomer",
    title: "Communication with Customer",
    data: "",
  },
  {
    id: 14,
    blockType: "acceptanceTermsConditions",
    title: "Acceptance of Terms and Conditions",
    data: "",
  },
  {
    id: 15,
    blockType: "contactFurtherCorrespondence",
    title: "Contact for Further Correspondence",
    data: "",
  },
  {
    id: 16,
    blockType: "productQuality",
    title: "Product Quality",
    data: productQuality(),
  },
  {
    id: 26,
    blockType: "disputeDetails",
    title: "Dispute Details",
    data: DisputeDetails(),
  },
  {
    id: 27,
    blockType: " supplementaryEvidence",
    title: " Supplementary Evidence",
    data: SupplementaryEvidence(),
  },
  {
    id: 28,
    blockType: "fullTrackingJourney",
    title: "Full Tracking Journey",
    data: FullTrackingJourney(),
  },
  {
    id: 29,
    blockType: "disputeItems",
    title: "Disputed Items",
    data: DisputeItems(),
  },
  {
    id: 30,
    blockType: "addressVerificationSystem",
    title: "Address Verification System",
    data: "",
  },
  {
    id: 31,
    blockType: "shippingTerms",
    title: "Shipping Terms",
    data: ShippingTerms(),
  },
  {
    id: 34,
    blockType: "shippingDetails",
    title: "Shipping Details",
    data: ShippingDetails(),
  },
  {
    id: 35,
    blockType: "refundEligibility",
    title: "Refund Eligibility",
    data: RefundEligibility(),
  },

  {
    id: 36,
    blockType: "termsAndConditions",
    title: "Terms and Conditions",
    data: TermsAndConditions(),
  },
  {
    id: 37,
    blockType: "returnPolicy",
    title: "Return Policy",
    data: ReturnPolicy(),
  },

];
export const openReactTemplateBlockTiptap = (
  droppedId: number,
  index?: number
) => {
  let blockData;
  if ([51, 52, 53, 54, 55].includes(droppedId)) {
    if (index === 4) {
      blockData = gettingSummaryAndSupply.find((item, index) => {
        return item?.id == droppedId;
      });
    } else {
      const newblockData = gettingSummaryAndSupply.find(
        (item, index) => item?.id == droppedId
      );
      const assembeledData = {
        id: droppedId,
        data: newblockData?.supplymentaryev,
      };
      blockData = assembeledData;
    }
  } else {
    blockData = blockReactTemplates.find((item, index) => {
      return item?.id == droppedId;
    });
  }
  return blockData;
};
//to fetch the data for template edit
export const handleTemplateEditData = (data: string, dataCollection: any) => {
  let dataSelected =
    dataCollection?.length &&
    dataCollection.find((item: any) => {
      return item?.value?.toLowerCase() === data?.toLowerCase();
    });
  return dataSelected;
};

export const handleTemplateEditStore = (storeId: string, stores: any) => {
  let storeSelected =
    stores?.length &&
    stores.find((item: any) => {
      return item?.value === storeId;
    });
  return storeSelected;
};

export const gettingSummaryAndSupply = [
  {
    id: 51,
    title: "Not received in transit",
    data: `<h2>Dispute Summary</h2><section><p>The items in question were successfully shipped and will be delivered to the customer's provided shipping address by the carrier <b>{(Order_Carrier)}</b> with tracking number <b>{(Order_TrackingNumber)}</b> which makes the dispute as invalid.</p> <p>In addition, we are still within the delivery timeframe of <b>{(Company_DeliveryTimeFrame)}</b> when the order was disputed. Please note that the transit time only starts once the parcel has been picked up by the carrier. The carrier picked up the parcel on <b>{(Order_PickupDate)}</b>.<p/><p>Furthermore, the customer failed to cancel within the <b>{(Company_CancelationTimeFrame)}</b> cancelation window once the order had been placed. Hence, making the customer ineligible for a refund.</p><p>Lastly, we never received any communication from the customer regarding this issue. We would love to resolve and find the best possible solution that is beneficial for both parties.</p></section>`,
    supplymentaryev: `<h2>Supplementary Evidence</h2><section><p>The item in question was successfully shipped and delivered to the customer's provided shipping address at : <b>{(Customer_ShippingAddress)}</b> on <b>{(Order_DeliveryDate)}</b> with tracking number <b>{(Order_TrackingNumber)}</b>,which makes the dispute invalid. <br/><b>{(img_private_Order_Delivery_Screenshot)}</b></p>
    <p> In addition, we are still within the delivery timeframe of <b>{(Company_DeliveryTimeFrame)}</b> when the order was disputed. Please note that the transit time only starts once the parcel has been picked up by the carrier. The carrier picked up the parcel on <b>{(Order_PickupDate)}</b>.<br/> <b>{(img_private_Company_ShippingJourney_Screenshot)}</b></p>
    <p>
     Furthermore, the customer failed to cancel within the <b>{(Company_CancelationTimeFrame)}</b> cancelation window once the order had been placed. Hence, making the customer ineligible for a refund. Lastly, we never received any communication from the customer regarding this issue. We would love to resolve and find the best possible solution that is beneficial for both parties. <b>{(img_private_Company_CancelationTimeFrame_Screenshot)}</b></p><b>{{img_private_Supplementary_Evidence_Screenshot}}</b></section>`,
  },
  {
    id: 52,
    title: "Not received being processed/ unfulfilled",
    data: `<h2>Dispute Summary</h2><section><p>The item in question is being processed and working on getting it shipped out which makes the dispute invalid.
    The item in question is currently being processed and working on getting it shipped out, it is within the fulfillment and delivery timeframe that we provided which makes the dispute invalid.</p></section>`,
    supplymentaryev: `<h2>Supplementary Evidence</h2><section><p>The item in question is currently being processed and about to be shipped and will soon be delivered to the customer's provided shipping address at : <b>{(shipping_address)}</b>.</p><p><b>{(img_private_Company_ShippingPolicy_Screenshot)}</b></p>
    <p>In addition, as a proof that we have fulfilled their order an order confirmation email was sent to the customer's email address <b>{(Customer_email )}</b>
    (insert screenshot order confirmation email/ order status page)
    Moreover, we are still within the fulfillment and delivery timeframe of <b>{(img_private_Company_ShippingPolicy_Screenshot)}</b> when the customer disputed the order, which makes the dispute invalid.</p>
    <b>{(img_private_Company_ShippingPolicy_Screenshot)}</b> <p><b>{{img_private_Supplementary_Evidence_Screenshot}}</b></p></section>`,
  },
  {
    id: 53,
    title: "Not received being processed/ unfulfilled",
    data: `<h2>Dispute Summary</h2><section><p>The order delivery was attempted, however, the customer provided an incomplete/ incorrect address which makes the delivery unsuccessful. We cannot be held liable for any inaccuracies made by the customer, which makes the dispute invalid.</p>
    <p>The item in question was delivered by the <b>{(Order_Carrier}}</b> ; however, the customer refused the order upon delivery , resulting in the item being returned to the sender. The customer did not comply to the terms and conditions of return as stipulated on our refund policy which makes the dispute invalid.</p></section>`,
    supplymentaryev: `<h2>Supplementary Evidence</h2><section><p>The item in question was successfully shipped  to the customer's provided shipping address at : <b>{(Customer_ShippingAddress)}</b> </p>
    <p>We have used <b>{(Order_Carrier}}</b> to ship their parcel and on <b>{(shipping_date)}</b> it left our warehouse using the tracking number <b>{(order_trackingnumber)}</b>. <b>{{Order_Carrier}}</b> attempted to deliver the package using the <b>{(order_trackingnumber)}</b> on <b>{(Attempted_Delivery_Date)}</b>
    (Returned) but it could not be delivered as the consignee was absent. The package was returned to the sender.
    (refused) but the customer refused the order upon delivery, resulting in the order being returned to the sender.
    (incorrect address) However, they were unsuccessful due to insufficient addresses. It is the customer's sole responsibility to make sure that the provided shipping address is complete and accurate so that the purchased product will be successfully delivered to them.</p>
    <b>{(img_private_shop_Terms_Screenshot)}</b> <p><b>{{img_private_Supplementary_Evidence_Screenshot}}</b></p></section>`,
  },
  {
    id: 54,
    title: "Not received being processed/ unfulfilled",
    data: `<h2>Dispute Summary</h2><section><p>The items in question are the same product described on our website and/ or not eligible for a return/ refund. The customer accepted the  merchandise and no complaints of tampering or damage were recorded when the merchandise was delivered which makes the dispute invalid.</p>
    <p>The items in question are the same product described on our website and/ or not eligible for a return/ refund which makes the dispute invalid. In addition, the items are still in customer's possession and they were never returned back to us.
    The items  in question are the same product described on our website, are neither defective or damaged and/ or not eligible for a refund, which makes the dispute as invalid. In addition, the items are still in customer's possession and they were never returned back to us.</p></section>`,
    supplymentaryev: `<h2>Supplementary Evidence</h2><section><p>We did not receive any communication from the customer regarding this order to cancel a product or to request a refund. We offer a live chat, phone number, and a customer service email address on the contact page, and bottom of our web pages, as well on our notification emails, but this customer did not use any of these features to contact us.</p>
    <p>We even have our social media accounts <b>{(Social_Media_Accounts)}</b> on the website available for the customer to use but did not receive any message from the customer about this charge.</p>
    <b>{(img_private_shop_contact_Screenshot)}</b> <p><b>{{img_private_Supplementary_Evidence_Screenshot}}</b></p></section>`,
  },
  {
    id: 55,
    title: "Not as described unfulfilled/ not delivered",
    data: `<h2>Dispute Summary</h2><section><p>The items in question are the same product described on our website and/ or not eligible for a refund. In addition, the items were not yet delivered at the time the dispute was made. This means that there is no way for the customer to tell whether the items are faulty, damaged or not as described making the claim invalid.</p>
    <p>In addition, the customer failed to comply with the terms and conditions as stipulated on our refund and return policy, making the claim invalid.</p></section>`,
    supplymentaryev: `<h2>Supplementary Evidence</h2><section><p>We did not receive any communication from the customer regarding this order to cancel a product or to request a refund. We offer a live chat, phone number, and a customer service email address on the contact page, and bottom of our web pages, as well on our notification emails, but this customer did not use any of these features to contact us.</p>
    <p>We even have our social media accounts <b>{(Social_Media_Accounts)}</b> on the website available for the customer to use but did not receive any message from the customer about this charge.</p>
    <b>{(img_private_shop_contact_Screenshot)}</b> <p><b>{{img_private_Supplementary_Evidence_Screenshot}}</b></p></section>`,
  },
];
export function htmlToText(html:any, extensions?:any) {
  var text = html;

  if (extensions && extensions["preprocessing"])
    text = extensions["preprocessing"](text);

  text = text
    // Remove line breaks
    .replace(/(?:\n|\r\n|\r)/gi, " ")
    // Remove content in script tags.
    .replace(/<\s*script[^>]*>[\s\S]*?<\/script>/gim, "")
    // Remove content in style tags.
    .replace(/<\s*style[^>]*>[\s\S]*?<\/style>/gim, "")
    // Remove content in comments.
    .replace(/<!--.*?-->/gim, "")
    // Remove !DOCTYPE
    .replace(/<!DOCTYPE.*?>/gi, "");
  if (extensions && extensions["tagreplacement"])
    text = extensions["tagreplacement"](text);

  var doubleNewlineTags = [
    "p",
    "h[1-6]",
    "dl",
    "dt",
    "dd",
    "ol",
    "ul",
    "dir",
    "address",
    "blockquote",
    "center",
    "div",
    "hr",
    "pre",
    "form",
    "textarea",
    "table",
  ];

  var singleNewlineTags = [
    "li",
    "fieldset",
    "legend",
    "tr",
    "th",
    "caption",
    "thead",
    "tbody",
    "tfoot",
  ];

  for (let i = 0; i < doubleNewlineTags.length; i++) {
    var r = RegExp("</?\\s*" + doubleNewlineTags[i] + "[^>]*>", "ig");
    text = text.replace(r, "\n\n");
  }

  for (let i = 0; i < singleNewlineTags.length; i++) {
    var r = RegExp("<\\s*" + singleNewlineTags[i] + "[^>]*>", "ig");
    text = text.replace(r, "\n");
  }

  // Replace <br> and <br/> with a single newline
  text = text.replace(/<\s*br[^>]*\/?\s*>/gi, "\n");

  text = text
    // Remove all remaining tags.
    .replace(/(<([^>]+)>)/gi, "")
    // Trim rightmost whitespaces for all lines
    .replace(/([^\n\S]+)\n/g, "\n")
    .replace(/([^\n\S]+)$/, "")
    // Make sure there are never more than two
    // consecutive linebreaks.
    .replace(/\n{2,}/g, "\n\n")
    // Remove newlines at the beginning of the text.
    .replace(/^\n+/, "")
    // Remove newlines at the end of the text.
    .replace(/\n+$/, "")
    // Decode HTML entities.
    .replace(/&([^;]+);/g, decodeHtmlEntity);

  if (extensions && extensions["postprocessing"])
    text = extensions["postprocessing"](text);

  return text;
}
function decodeHtmlEntity(m:any, n:any) {
  // Determine the character code of the entity. Range is 0 to 65535
  // (characters in JavaScript are Unicode, and entities can represent
  // Unicode characters).
  let code:any;

  // Try to parse as numeric entity. This is done before named entities for
  // speed because associative array lookup in many JavaScript implementations
  // is a linear search.
  if (n.substr(0, 1) == "#") {
    // Try to parse as numeric entity
    if (n.substr(1, 1) == "x") {
      // Try to parse as hexadecimal
      code = parseInt(n.substr(2), 16);
    } else {
      // Try to parse as decimal
      code = parseInt(n.substr(1), 10);
    }
  } else {
    // Try to parse as named entity
    code = ENTITIES_MAP[n];
  }

  // If still nothing, pass entity through
  return code === undefined ||  Number.isNaN(code)
    ? "&" + n + ";"
    : String.fromCharCode(code);
}

const ENTITIES_MAP:any = {
  nbsp: 160,
  iexcl: 161,
  cent: 162,
  pound: 163,
  curren: 164,
  yen: 165,
  brvbar: 166,
  sect: 167,
  uml: 168,
  copy: 169,
  ordf: 170,
  laquo: 171,
  not: 172,
  shy: 173,
  reg: 174,
  macr: 175,
  deg: 176,
  plusmn: 177,
  sup2: 178,
  sup3: 179,
  acute: 180,
  micro: 181,
  para: 182,
  middot: 183,
  cedil: 184,
  sup1: 185,
  ordm: 186,
  raquo: 187,
  frac14: 188,
  frac12: 189,
  frac34: 190,
  iquest: 191,
  Agrave: 192,
  Aacute: 193,
  Acirc: 194,
  Atilde: 195,
  Auml: 196,
  Aring: 197,
  AElig: 198,
  Ccedil: 199,
  Egrave: 200,
  Eacute: 201,
  Ecirc: 202,
  Euml: 203,
  Igrave: 204,
  Iacute: 205,
  Icirc: 206,
  Iuml: 207,
  ETH: 208,
  Ntilde: 209,
  Ograve: 210,
  Oacute: 211,
  Ocirc: 212,
  Otilde: 213,
  Ouml: 214,
  times: 215,
  Oslash: 216,
  Ugrave: 217,
  Uacute: 218,
  Ucirc: 219,
  Uuml: 220,
  Yacute: 221,
  THORN: 222,
  szlig: 223,
  agrave: 224,
  aacute: 225,
  acirc: 226,
  atilde: 227,
  auml: 228,
  aring: 229,
  aelig: 230,
  ccedil: 231,
  egrave: 232,
  eacute: 233,
  ecirc: 234,
  euml: 235,
  igrave: 236,
  iacute: 237,
  icirc: 238,
  iuml: 239,
  eth: 240,
  ntilde: 241,
  ograve: 242,
  oacute: 243,
  ocirc: 244,
  otilde: 245,
  ouml: 246,
  divide: 247,
  oslash: 248,
  ugrave: 249,
  uacute: 250,
  ucirc: 251,
  uuml: 252,
  yacute: 253,
  thorn: 254,
  yuml: 255,
  quot: 34,
  amp: 38,
  lt: 60,
  gt: 62,
  OElig: 338,
  oelig: 339,
  Scaron: 352,
  scaron: 353,
  Yuml: 376,
  circ: 710,
  tilde: 732,
  ensp: 8194,
  emsp: 8195,
  thinsp: 8201,
  zwnj: 8204,
  zwj: 8205,
  lrm: 8206,
  rlm: 8207,
  ndash: 8211,
  mdash: 8212,
  lsquo: 8216,
  rsquo: 8217,
  sbquo: 8218,
  ldquo: 8220,
  rdquo: 8221,
  bdquo: 8222,
  dagger: 8224,
  Dagger: 8225,
  permil: 8240,
  lsaquo: 8249,
  rsaquo: 8250,
  euro: 8364,
};