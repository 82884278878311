import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";

export class GetFilteredAwaitingDisputes {
    /**
       * Function to invoke to get filtered awaiting  disputes
       */
    async invoke(payload: any) {
        const res = await ApiService.getInstance().get(apiConstants.GET_FILTERED_AWAITING_DISPUTES(payload));
        return res;
    }



}