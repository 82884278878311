import { useEffect, useState } from "react";
import { useReactFlow } from "reactflow";

const useZoomCanvas = () => {
  const { zoomTo, getZoom } = useReactFlow();
  const zoom = getZoom();
  const [currentZoom, setCurrentZoom] = useState(zoom);

  useEffect(() => {
    if (currentZoom) {
      zoomTo(currentZoom, { duration: 500 });
    }
  }, [currentZoom, zoomTo]);

  const handleIncrementAndDecrementZoom = (type: string) => {
    if (type === "Decrement") {
      setCurrentZoom((prev) => (prev > 0.1 ? prev - 0.1 : prev));
    }
    if (type === "Increment") {
      setCurrentZoom((prev) => (prev < 1 ? prev + 0.1 : prev));
    }
  };
  return {handleIncrementAndDecrementZoom, currentZoom}
};

export default useZoomCanvas;

