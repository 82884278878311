import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Button } from "components/ui";
import styles from "./style.module.scss";
import { Textarea } from "components/ui";
import BasicSelect from "components/ui/Select/Index";
import { ErrorMessage } from "components/disputeFlow/NodeHelpers";
import { Box, Tooltip } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { svgIcons } from "elements";
import { isEmpty } from "lodash";
import { disputeEvidenceType } from "utils/disputeUtils";
import { useDisputeEvidence } from "hooks";
import { useSelector, useDispatch } from "react-redux";
import { getDisputeResponseFindByDisputeIdRequest } from "redux/actions";
import Loading from "components/loading/loading";
const fileTypes = ["PNG", "JPG", "JPEG", "PDF"];
type FormDataType = {
  evidenceType: string;
  message: string;
  file: any;
  uncategorizedFile: any;
};
const DisputeEvidence = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormDataType>();
  const { disputeId } = useParams();
  const { handleCreateDisputeResponse } = useDisputeEvidence();
  const [uploadFile, setUploadFile] = useState<any>([]);
  const handleFileUpload = (doc: any) => {
    setUploadFile(doc[0]);
  };
  useEffect(() => {
    if (uploadFile?.name) {
      clearErrors("file");
    }
  }, [uploadFile?.name]);
  const onSubmit = (data: any) => {
    const payload = {
      type: data?.evidenceType,
      description: data?.message,
      disputeId,
    };
    handleCreateDisputeResponse(payload, uploadFile);
    reset();
  };

  // this below full code for only using loader temporally
  const dispatch = useDispatch();
  const disputeResponseFindByDisputeId = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponseFindByDisputeId
  );
  const { isLoading } = disputeResponseFindByDisputeId;
  useEffect(() => {
    if (disputeId) {
      dispatch(getDisputeResponseFindByDisputeIdRequest(disputeId));
    }
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.formWrapper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.dropDownWrapper}>
              <div className={styles.initialSelects}>
                <Box gap={2} display={`flex`} justifyContent={`space-between`}>
                  <Box
                    flex={`1`}
                    className={styles.initialSelectWrapper}
                    sx={{ maxWidth: "400px" }}
                  >
                    <Controller
                      name="evidenceType"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Evidence Type is required",
                        },
                      }}
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <BasicSelect
                              label={"Evidence Type"}
                              options={disputeEvidenceType ?? []}
                              field={field}
                              placeholder={"Evidence Type"}
                            />
                            {fieldState.error && (
                              <p className={styles.errorStyle}>
                                {fieldState.error.message}
                              </p>
                            )}
                          </>
                        );
                      }}
                    />
                  </Box>
                </Box>
                <Tooltip
                  arrow
                  placement="top"
                  title="Upload or drag and drop the file"
                >
                  <div className={styles.uploadFile}>
                    <label>Upload File</label>
                    <div className={""}>
                      <FileUploader
                        multiple={true}
                        handleChange={(doc: any) => handleFileUpload(doc)}
                        name="file"
                        types={fileTypes}
                        children={
                          <div className={styles.documentField}>
                            <img
                              style={{ display: uploadFile.name ? "none" : "" }}
                              src={svgIcons.Upload_In_Blue}
                              alt="upload"
                            />
                            <p
                              style={{ display: uploadFile.name ? "none" : "" }}
                            >
                              Upload File
                            </p>
                            <input
                              data-testid="dispute-document"
                              hidden={
                                uploadFile && uploadFile?.name ? false : true
                              }
                              readOnly
                              value={uploadFile?.name}
                            />
                          </div>
                        }
                      />
                    </div>
                    <ErrorMessage name={"file"} errors={errors} />
                  </div>
                </Tooltip>
                <div className={styles.textAreaWrapper}>
                  <label>Message</label>
                  <Textarea
                    minRows={10}
                    aria-label="maximum height"
                    placeholder="Type here..."
                    {...register("message", {
                      required: {
                        value: true,
                        message: "Message is required",
                      },
                    })}
                  />
                  {errors && <ErrorMessage name="message" errors={errors} />}
                </div>
                <Button
                  {...{
                    btnTitle: "Create",
                    btnLabel: "Create",
                    className: styles.selectButtonStyle,
                    type: "submit",
                    onClick: () => {
                      if (isEmpty(uploadFile?.name)) {
                        setError("file", {
                          type: "required",
                          message: "Please add a file",
                        });
                      } else {
                        clearErrors("uncategorizedFile");
                      }
                    },
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default DisputeEvidence;
