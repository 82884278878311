import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    disputeDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeOrderSummary: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeTransactionDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeEvidenceDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeMessageDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeAdjudicationDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeMoneyMovementDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeSupportingInfoDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeOfferDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeFlowUploadFileDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    disputeSendMessage: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    manageDisputeSendMessage: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    manageDisputeCustomerDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    manageDisputeTrackingDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    manageDisputeResponseJsonDetails:{
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    manageDisputeGetTrackingDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        status:'',
        data: {}
    },
    manageDisputeFraudAnalysis:{
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    manageDisputeShippingDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    manageDisputeSubmittedByChargePay: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    manageDisputeFulfillmentDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    manageDisputeCarrierOptions: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    manageDisputeEvidenceTypeOptions: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    manageDisputeAppealDispute: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    manageDisputeAcknowledgeReturnItem: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    manageDisputeAcceptClaim: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    manageDisputeHateoasLink: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    manageDisputeFetchFlow: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    manageDisputeFetchTemplate: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    manageDisputeFetchSummary: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: "",
    },
}

//dispute-details
const getDisputeDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getDisputeDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-order-summary
const getDisputeOrderSummaryRequest = (state: any, action: any) => {
    return update(state, {
        disputeOrderSummary: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeOrderSummarySuccess = (state: any, action: any) => {
    return update(state, {
        disputeOrderSummary: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getDisputeOrderSummaryError = (state: any, action: any) => {
    return update(state, {
        disputeOrderSummary: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-transaction-details
const getDisputeTransactionDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeTransactionDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeTransactionDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTransactionDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeTransactionDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeTransactionDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// dispute-customer-details
const getDisputeCustomerDetailsRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeCustomerDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getDisputeCustomerDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeCustomerDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeCustomerDetailsError = (state: any, action: any) => {
    return update(state, {
        manageDisputeCustomerDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


// dispute-tracking-details
const getDisputeTrackingDetailsRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeTrackingDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getDisputeTrackingDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeTrackingDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeTrackingDetailsError = (state: any, action: any) => {
    return update(state, {
        manageDisputeTrackingDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// dispute-response-json-by-id
const getDisputeResponseJsonByIdRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeResponseJsonDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getDisputeResponseJsonByIdSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeResponseJsonDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeResponseJsonByIdError = (state: any, action: any) => {
    return update(state, {
        manageDisputeResponseJsonDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};



// dispute-fraud analysis details
const getDisputeFraudAnalysisRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeFraudAnalysis: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getDisputeFraudAnalysisSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeFraudAnalysis: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeFraudAnalysisError = (state: any, action: any) => {
    return update(state, {
        manageDisputeFraudAnalysis: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};



// dispute-getTracking-details
const getDisputeTrackingRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeGetTrackingDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getDisputeTrackingSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeGetTrackingDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeTrackingError = (state: any, action: any) => {
    return update(state, {
        manageDisputeGetTrackingDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// dispute-shipping-details
const getDisputeShippingDetailsRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeShippingDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeShippingDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeShippingDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeShippingDetailsError = (state: any, action: any) => {
    return update(state, {
        manageDisputeShippingDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

const postDisputeSubmittedRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeSubmittedByChargePay: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postDisputeSubmittedSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeSubmittedByChargePay: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const postDisputeSubmittedError = (state: any, action: any) => {
    return update(state, {
        manageDisputeSubmittedByChargePay: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};



// dispute-order-fulfillment details
const getDisputeOrderFulfillmentDetailsRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeFulfillmentDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeOrderFulfillmentDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeFulfillmentDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeOrderFulfillmentDetailsError = (state: any, action: any) => {
    return update(state, {
        manageDisputeFulfillmentDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};




//dispute-evidence-details
const getDisputeEvidenceDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeEvidenceDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeEvidenceDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeEvidenceDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeEvidenceDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeEvidenceDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-message-details
const getDisputeMessageDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeMessageDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeMessageDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeMessageDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeMessageDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeMessageDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-adjudication-details
const getDisputeAdjudicationDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeAdjudicationDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeAdjudicationDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeAdjudicationDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeAdjudicationDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeAdjudicationDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-money-movement-details
const getDisputeMoneyMovementDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeMoneyMovementDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeMoneyMovementDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeMoneyMovementDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeMoneyMovementDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeMoneyMovementDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-supporting-info-details
const getDisputeSupportingInfoDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeSupportingInfoDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeSupportingInfoDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeSupportingInfoDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeSupportingInfoDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeSupportingInfoDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-offer-details
const getDisputeOfferDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeOfferDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeOfferDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeOfferDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeOfferDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeOfferDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-order-in-transit
const postDisputeFlowUploadFileRequest = (state: any, action: any) => {
    return update(state, {
        disputeFlowUploadFileDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postDisputeFlowUploadFileSuccess = (state: any, action: any) => {
    return update(state, {
        disputeFlowUploadFileDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const postDisputeFlowUploadFileError = (state: any, action: any) => {
    return update(state, {
        disputeFlowUploadFileDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-order-in-transit
const postDisputeSendMessageRequest= (state: any, action: any) => {
    return update(state, {
        disputeSendMessage: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postDisputeSendMessageSuccess = (state: any, action: any) => {
    return update(state, {
        disputeSendMessage: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const postDisputeSendMessageError = (state: any, action: any) => {
    return update(state, {
        disputeSendMessage: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};



//manage-dispute-send-message
const postManageDisputeSendMessageRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeSendMessage: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postManageDisputeSendMessageSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeSendMessage: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const postManageDisputeSendMessageError = (state: any, action: any) => {
    return update(state, {
        manageDisputeSendMessage: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};



//manage-dispute-carrier-options
const getManageDisputeCarrierOptionsRequestRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeCarrierOptions: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getManageDisputeCarrierOptionsRequestSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeCarrierOptions: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getManageDisputeCarrierOptionsRequestError = (state: any, action: any) => {
    return update(state, {
        manageDisputeCarrierOptions: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//manage-dispute-evidence-type-options
const getManageDisputeEvidenceTypeOptionsRequestRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeEvidenceTypeOptions: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getManageDisputeEvidenceTypeOptionsRequestSuccess = (state: any, action: any) => {
    let eviOptions: any = [];
    action.payload && action.payload?.map((options: any) => {
        eviOptions.push({
            value: options?.evidenceTypeValue,
            label: options?.evidenceTypeValue?.split('_').join(' '),
            description: options?.description
        })
    })
    return update(state, {
        manageDisputeEvidenceTypeOptions: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...eviOptions] }
        }
    });
};

const getManageDisputeEvidenceTypeOptionsRequestError = (state: any, action: any) => {
    return update(state, {
        manageDisputeEvidenceTypeOptions: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//manage-dispute-appeal-dispute
const postManageDisputeAppealDisputeRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeAppealDispute: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postManageDisputeAppealDisputeSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeAppealDispute: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const postManageDisputeAppealDisputeError = (state: any, action: any) => {
    return update(state, {
        manageDisputeAppealDispute: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//manage-dispute-acknowledge-return-item
const postManageDisputeAcknowledgeReturnItemRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeAcknowledgeReturnItem: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postManageDisputeAcknowledgeReturnItemSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeAcknowledgeReturnItem: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const postManageDisputeAcknowledgeReturnItemError = (state: any, action: any) => {
    return update(state, {
        manageDisputeAcknowledgeReturnItem: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//manage-dispute-accept-claim
const postManageDisputeAcceptClaimRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeAcceptClaim: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postManageDisputeAcceptClaimSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeAcceptClaim: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const postManageDisputeAcceptClaimError = (state: any, action: any) => {
    return update(state, {
        manageDisputeAcceptClaim: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//manage-dispute-hateoas-link
const getManageDisputeHateoasLinkRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeHateoasLink: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getManageDisputeHateoasLinkSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeHateoasLink: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getManageDisputeHateoasLinkError = (state: any, action: any) => {
    return update(state, {
        manageDisputeHateoasLink: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//manage-dispute-fetch-flow
const getManageDisputeFlowRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeFetchFlow: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getManageDisputeFlowSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeFetchFlow: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getManageDisputeFlowError = (state: any, action: any) => {
    return update(state, {
        manageDisputeFetchFlow: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


//manage-dispute-fetch-template
const getFetchDisputeTemplateRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeFetchTemplate: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const  getFetchDisputeTemplateSuccess= (state: any, action: any) => {
    return update(state, {
        manageDisputeFetchTemplate: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getFetchDisputeTemplateError = (state: any, action: any) => {
    return update(state, {
        manageDisputeFetchTemplate: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//manage-dispute-fetch-summary
const getFetchDisputeSummaryRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeFetchSummary: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: "" }
        }
    });
};
const  getFetchDisputeSummarySuccess= (state: any, action: any) => {
    return update(state, {
        manageDisputeFetchSummary: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload}
        }
    });
};

const getFetchDisputeSummaryError = (state: any, action: any) => {
    return update(state, {
        manageDisputeFetchSummary: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
export default handleActions(
    {
        [constants.GET_DISPUTE_DETAILS_REQUEST]: getDisputeDetailsRequest,
        [constants.GET_DISPUTE_DETAILS_SUCCESS]: getDisputeDetailsSuccess,
        [constants.GET_DISPUTE_DETAILS_ERROR]: getDisputeDetailsError,

        [constants.GET_DISPUTE_ORDER_SUMMARY_REQUEST]: getDisputeOrderSummaryRequest,
        [constants.GET_DISPUTE_ORDER_SUMMARY_SUCCESS]: getDisputeOrderSummarySuccess,
        [constants.GET_DISPUTE_ORDER_SUMMARY_ERROR]: getDisputeOrderSummaryError,

        [constants.GET_DISPUTE_TRANSACTION_DETAILS_REQUEST]: getDisputeTransactionDetailsRequest,
        [constants.GET_DISPUTE_TRANSACTION_DETAILS_SUCCESS]: getDisputeTransactionDetailsSuccess,
        [constants.GET_DISPUTE_TRANSACTION_DETAILS_ERROR]: getDisputeTransactionDetailsError,

        [constants.GET_DISPUTE_CUSTOMER_DETAILS_REQUEST]: getDisputeCustomerDetailsRequest,
        [constants.GET_DISPUTE_CUSTOMER_DETAILS_SUCCESS]: getDisputeCustomerDetailsSuccess,
        [constants.GET_DISPUTE_CUSTOMER_DETAILS_ERROR]: getDisputeCustomerDetailsError,

        [constants.GET_DISPUTE_TRACKING_DETAILS_REQUEST]: getDisputeTrackingDetailsRequest,
        [constants.GET_DISPUTE_TRACKING_DETAILS_SUCCESS]: getDisputeTrackingDetailsSuccess,
        [constants.GET_DISPUTE_TRACKING_DETAILS_ERROR]: getDisputeTrackingDetailsError,

        [constants.GET_RESPONSE_JSON_BY_ID_REQUEST]: getDisputeResponseJsonByIdRequest,
        [constants.GET_RESPONSE_JSON_BY_ID_SUCCESS]: getDisputeResponseJsonByIdSuccess,
        [constants.GET_RESPONSE_JSON_BY_ID_ERROR]: getDisputeResponseJsonByIdError,

        [constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_REQUEST]: getDisputeFraudAnalysisRequest,
        [constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_SUCCESS]: getDisputeFraudAnalysisSuccess,
        [constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_ERROR]: getDisputeFraudAnalysisError,

        [constants.GET_DISPUTE_TRACKING_REQUEST]: getDisputeTrackingRequest,
        [constants.GET_DISPUTE_TRACKING_SUCCESS]: getDisputeTrackingSuccess,
        [constants.GET_DISPUTE_TRACKING_ERROR]: getDisputeTrackingError,

        [constants.GET_DISPUTE_SHIPPING_DETAILS_REQUEST]: getDisputeShippingDetailsRequest,
        [constants.GET_DISPUTE_SHIPPING_DETAILS_SUCCESS]: getDisputeShippingDetailsSuccess,
        [constants.GET_DISPUTE_SHIPPING_DETAILS_ERROR]: getDisputeShippingDetailsError,

        [constants.POST_DISPUTE_SUBMITTED_REQUEST]: postDisputeSubmittedRequest,
        [constants.POST_DISPUTE_SUBMITTED_SUCCESS]: postDisputeSubmittedSuccess,
        [constants.POST_DISPUTE_SUBMITTED_ERROR]: postDisputeSubmittedError,

        [constants.GET_DISPUTE_FULFILLMENT_DETAILS_REQUEST]: getDisputeOrderFulfillmentDetailsRequest,
        [constants.GET_DISPUTE_FULFILLMENT_DETAILS_SUCCESS]: getDisputeOrderFulfillmentDetailsSuccess,
        [constants.GET_DISPUTE_FULFILLMENT_DETAILS_ERROR]: getDisputeOrderFulfillmentDetailsError,

        [constants.GET_DISPUTE_EVIDENCE_DETAILS_REQUEST]: getDisputeEvidenceDetailsRequest,
        [constants.GET_DISPUTE_EVIDENCE_DETAILS_SUCCESS]: getDisputeEvidenceDetailsSuccess,
        [constants.GET_DISPUTE_EVIDENCE_DETAILS_ERROR]: getDisputeEvidenceDetailsError,

        [constants.GET_DISPUTE_MESSAGE_DETAILS_REQUEST]: getDisputeMessageDetailsRequest,
        [constants.GET_DISPUTE_MESSAGE_DETAILS_SUCCESS]: getDisputeMessageDetailsSuccess,
        [constants.GET_DISPUTE_MESSAGE_DETAILS_ERROR]: getDisputeMessageDetailsError,

        [constants.GET_DISPUTE_ADJUDICATION_DETAILS_REQUEST]: getDisputeAdjudicationDetailsRequest,
        [constants.GET_DISPUTE_ADJUDICATION_DETAILS_SUCCESS]: getDisputeAdjudicationDetailsSuccess,
        [constants.GET_DISPUTE_ADJUDICATION_DETAILS_ERROR]: getDisputeAdjudicationDetailsError,

        [constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_REQUEST]: getDisputeMoneyMovementDetailsRequest,
        [constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_SUCCESS]: getDisputeMoneyMovementDetailsSuccess,
        [constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_ERROR]: getDisputeMoneyMovementDetailsError,

        [constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_REQUEST]: getDisputeSupportingInfoDetailsRequest,
        [constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_SUCCESS]: getDisputeSupportingInfoDetailsSuccess,
        [constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_ERROR]: getDisputeSupportingInfoDetailsError,

        [constants.GET_DISPUTE_OFFER_DETAILS_REQUEST]: getDisputeOfferDetailsRequest,
        [constants.GET_DISPUTE_OFFER_DETAILS_SUCCESS]: getDisputeOfferDetailsSuccess,
        [constants.GET_DISPUTE_OFFER_DETAILS_ERROR]: getDisputeOfferDetailsError,

        [constants.POST_DISPUTE_FLOW_UPLOAD_FILE_REQUEST]: postDisputeFlowUploadFileRequest,
        [constants.POST_DISPUTE_FLOW_UPLOAD_FILE_SUCCESS]: postDisputeFlowUploadFileSuccess,
        [constants.POST_DISPUTE_FLOW_UPLOAD_FILE_ERROR]: postDisputeFlowUploadFileError,
        
        [constants.POST_DISPUTE_SEND_MESSAGE_REQUEST]: postDisputeSendMessageRequest,
        [constants.POST_DISPUTE_SEND_MESSAGE_SUCCESS]: postDisputeSendMessageSuccess,
        [constants.POST_DISPUTE_SEND_MESSAGE_ERROR]: postDisputeSendMessageError,

        //manage-dispute-apis
        [constants.POST_MANAGE_DISPUTE_SEND_MESSAGE_REQUEST]: postManageDisputeSendMessageRequest,
        [constants.POST_MANAGE_DISPUTE_SEND_MESSAGE_SUCCESS]: postManageDisputeSendMessageSuccess,
        [constants.POST_MANAGE_DISPUTE_SEND_MESSAGE_ERROR]: postManageDisputeSendMessageError,

        [constants.GET_MANAGE_DISPUTE_CARRIER_OPTIONS_REQUEST]: getManageDisputeCarrierOptionsRequestRequest,
        [constants.GET_MANAGE_DISPUTE_CARRIER_OPTIONS_SUCCESS]: getManageDisputeCarrierOptionsRequestSuccess,
        [constants.GET_MANAGE_DISPUTE_CARRIER_OPTIONS_ERROR]: getManageDisputeCarrierOptionsRequestError,

        [constants.GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_REQUEST]: getManageDisputeEvidenceTypeOptionsRequestRequest,
        [constants.GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_SUCCESS]: getManageDisputeEvidenceTypeOptionsRequestSuccess,
        [constants.GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_ERROR]: getManageDisputeEvidenceTypeOptionsRequestError,

        [constants.POST_MANAGE_DISPUTE_APPEAL_DISPUTE_REQUEST]: postManageDisputeAppealDisputeRequest,
        [constants.POST_MANAGE_DISPUTE_APPEAL_DISPUTE_SUCCESS]: postManageDisputeAppealDisputeSuccess,
        [constants.POST_MANAGE_DISPUTE_APPEAL_DISPUTE_ERROR]: postManageDisputeAppealDisputeError,

        [constants.POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_REQUEST]: postManageDisputeAcknowledgeReturnItemRequest,
        [constants.POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_SUCCESS]: postManageDisputeAcknowledgeReturnItemSuccess,
        [constants.POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_ERROR]: postManageDisputeAcknowledgeReturnItemError,

        [constants.POST_MANAGE_ACCEPT_CLAIM_REQUEST]: postManageDisputeAcceptClaimRequest,
        [constants.POST_MANAGE_ACCEPT_CLAIM_SUCCESS]: postManageDisputeAcceptClaimSuccess,
        [constants.POST_MANAGE_ACCEPT_CLAIM_ERROR]: postManageDisputeAcceptClaimError,

        [constants.GET_MANAGE_DISPUTE_HATEOAS_LINK_REQUEST]: getManageDisputeHateoasLinkRequest,
        [constants.GET_MANAGE_DISPUTE_HATEOAS_LINK_SUCCESS]: getManageDisputeHateoasLinkSuccess,
        [constants.GET_MANAGE_DISPUTE_HATEOAS_LINK_ERROR]: getManageDisputeHateoasLinkError,

        [constants.GET_DISPUTE_MANAGE_FLOW_REQUEST]: getManageDisputeFlowRequest,
        [constants.GET_DISPUTE_MANAGE_FLOW_SUCCESS]: getManageDisputeFlowSuccess,
        [constants.GET_DISPUTE_MANAGE_FLOW_ERROR]: getManageDisputeFlowError,

        [constants.GET_FETCH_DISPUTE_TEMPLATE_REQUEST]: getFetchDisputeTemplateRequest,
        [constants.GET_FETCH_DISPUTE_TEMPLATE_SUCCESS]: getFetchDisputeTemplateSuccess,
        [constants.GET_FETCH_DISPUTE_TEMPLATE_ERROR]: getFetchDisputeTemplateError,

        [constants.GET_FETCH_DISPUTE_SUMMARY_REQUEST]: getFetchDisputeSummaryRequest,
        [constants.GET_FETCH_DISPUTE_SUMMARY_SUCCESS]: getFetchDisputeSummarySuccess,
        [constants.GET_FETCH_DISPUTE_SUMMARY_ERROR]: getFetchDisputeSummaryError,
    },
    initialState
)
