import { useState, useEffect } from 'react';
import useUpdateManager from './useUpdateManager';
import { SelectChangeEvent } from '@mui/material';

export interface ManagerTypes {
    disputeCount: number;
    email: string;
    id: string;
    role: string;
    userId: string;
    userName: string;
}
export interface UseUpdateHandlerProps {
  dispute: boolean;
  shopFindShop: any;
  setSelectedValue:any;
}

interface UseUpdateHandlerResult {
  submit: { open: boolean; data: any }; // Replace 'any' with the actual type
  handleUpdate: (e:SelectChangeEvent, f: any) => void;
}

const useUpdateHandler = ({setSelectedValue, dispute, shopFindShop }: any): UseUpdateHandlerResult => {
  const { handleUpdateManager } = useUpdateManager();
  const [submit, setSubmit] = useState<{ open: boolean; data: any }>({ open: false, data: {} });

  const handleUpdate = (e:SelectChangeEvent, f: any) => {
    const {value, children} = f.props;
    const dataSubmitted = {
      id:value,
      userName:children
    }
    setSelectedValue(dataSubmitted);
    const data = {
      shopId: dispute ? shopFindShop.shopId : shopFindShop.id,
      disputeId: dispute ? shopFindShop.id : null,
      managerId: dataSubmitted.id,
    };
    setSubmit((prev) => ({ ...prev, data, open: true }));
  };

  useEffect(() => {
    if (submit.open) {
      handleUpdateManager(submit.data, dispute);
      setSubmit((prev) => ({ ...prev, data: {}, open: false }));
    }
  }, [submit.open, submit.data]);

  return {
    submit,
    handleUpdate,
  };
};

export default useUpdateHandler
