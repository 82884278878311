import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import cn from 'classnames';
import { Menu, Badge } from '@mui/material';
import { Billing, svgIcons } from 'elements';
import { Notification } from '../index';
import { clearItems, removeItem } from 'utils/localStorage';
import styles from './style.module.scss';

const Sidebar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);

  const notifications = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: {
      status: '',
      count: 8,
    },
  };

  const { pathname } = useLocation();
  const path = pathname.split('/')[1];

  const { user = {}, logout } = useAuth0();

  const handleNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isHome = path === 'home';
  // const isAccounts = path === 'accounts';
  const isSettings = path === 'settings';
  const isStores = path === 'stores' || path === 'store-details';
  const isDisputeDashboard = path === 'disputes' || path === 'dispute';
  const isDisputeFlow = path === 'flows' || path === 'flows-edit';
  const isDisputesTemplates =
    path === 'templates' ||
    path === 'template-edit' ||
    path === 'template-view';
  const isUsers = path === 'users';
  const isMacros = path === 'macros';
  const isBilling = path === 'billing';
  const isReporting = path === 'reporting';
  const isWaitingSellerResponse = path === 'waitingSellerResponse';
  const isUnderReviewDisputes = path === 'underReviewDisputes';
  const isStoreReports = path === 'storeReports';
  const handleBreadcrumb = () => {
    removeItem('singleAccountCheck');
  };

  const handleLogout = () => {
    clearItems();
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <div className={styles.sidebarWrapper}>
      <div className={styles.sidebar}>
        {/* <Button onClick={() => navigate("/home")}> */}
        <div className={styles.logo} onClick={() => navigate('/home')}>
          <img
            className={styles.logoIcon}
            src={svgIcons.ChargePayLogo}
            alt='tp'
          />
          <img
            className={styles.logoName}
            src={svgIcons.ChargePayName}
            alt='ChargePay'
          />
        </div>
        {/* </Button> */}
        <div className={styles.intro}>
          <div className={styles.heading}>
            <h3>Hi, {user?.nickname}</h3>
            <p className={styles.selfService}>
              <span>Dispute Manager</span>
            </p>
          </div>
          <div className={styles.headingIcons}>
            {notifications?.isSuccess &&
            notifications?.data?.status === 'New' &&
            notifications?.data?.count ? (
              <Badge
                badgeContent={
                  notifications?.isSuccess &&
                  notifications?.data?.status === 'New' &&
                  notifications?.data?.count
                }
                color='primary'
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <img
                  src={svgIcons.NotificationActive}
                  alt='notification'
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    handleNotification(event)
                  }
                />
              </Badge>
            ) : (
              <img
                src={svgIcons.NotificationInactive}
                alt='notification'
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleNotification(event)
                }
              />
            )}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  borderRadius: '11.808px',
                  background: '#FFF',
                },
              }}
            >
              <Notification handleClose={handleClose} />
            </Menu>
          </div>
        </div>
        <div className={styles.sidebarList}>
          <Link to='/home' onClick={handleBreadcrumb}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.DashBoard} alt='home' />
              </div>
              <p className={cn({ [styles.active]: isHome })}>Dashboard</p>
            </div>
          </Link>
          {/* <Link to="/accounts" onClick={handleBreadcrumb}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Accounts} alt="accounts" />
              </div>
              <p className={cn({ [styles.active]: isAccounts })}>Accounts</p>
            </div>
          </Link> */}
          <Link to='/stores' onClick={handleBreadcrumb}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Stores} alt='stores' />
              </div>
              <p className={cn({ [styles.active]: isStores })}>Stores</p>
            </div>
          </Link>
          <Link to='/disputes' onClick={handleBreadcrumb}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Disputes} alt='Disputes' />
              </div>
              <p className={cn({ [styles.active]: isDisputeDashboard })}>
                Disputes
              </p>
            </div>
          </Link>
          <Link to='/waitingSellerResponse' onClick={handleBreadcrumb}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Disputes} alt='waiting seller respone' />
              </div>
              <p className={cn({ [styles.active]: isWaitingSellerResponse })}>
                Waiting Seller Response
              </p>
            </div>
          </Link>
          <Link to='/underReviewDisputes' onClick={handleBreadcrumb}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Disputes} alt='under review disputes' />
              </div>
              <p className={cn({ [styles.active]: isUnderReviewDisputes })}>
                Under Review
              </p>
            </div>
          </Link>
          <Link to='/storeReports' onClick={handleBreadcrumb}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Disputes} alt='under review disputes' />
              </div>
              <p className={cn({ [styles.active]: isStoreReports })}>
                Store Reports
              </p>
            </div>
          </Link>
          <Link to='/flows' onClick={handleBreadcrumb}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Flows} alt='Flows' />
              </div>
              <p className={cn({ [styles.active]: isDisputeFlow })}>Flows</p>
            </div>
          </Link>
          <Link to='/templates' onClick={handleBreadcrumb}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Disputes} alt='Disputes' />
              </div>
              <p className={cn({ [styles.active]: isDisputesTemplates })}>
                Templates
              </p>
            </div>
          </Link>

          <Link
            className={styles.downSidebar}
            to='/users'
            onClick={handleBreadcrumb}
          >
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Users} alt='Users' />
              </div>
              <p className={cn({ [styles.active]: isUsers })}>Users</p>
            </div>
          </Link>

          <Link to='/macros'>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Disputes} alt='Disputes' />
              </div>
              <p className={cn({ [styles.active]: isMacros })}>Macros</p>
            </div>
          </Link>
          <Link to='/billing' onClick={handleBreadcrumb}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <Billing />
              </div>
              <p className={cn({ [styles.active]: isBilling })}>Billing</p>
            </div>
          </Link>

          <Link to='/reporting' onClick={handleBreadcrumb}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Stores} alt='stores' />
              </div>
              <p className={cn({ [styles.active]: isReporting })}>Reporting</p>
            </div>
          </Link>

          <Link to='/settings' onClick={handleBreadcrumb}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Settings} alt='Settings' />
              </div>
              <p className={cn({ [styles.active]: isSettings })}>Settings</p>
            </div>
          </Link>

          <div className={styles.listItem} onClick={handleLogout}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Logout} alt='Logout' />
              </div>
              <p className={styles.logout}>Logout</p>
            </div>
          </div>
          {/* <div className={styles.sidebarFooter}>
            <p>“Today’s work were yesterday’s impossible”</p>
            <h3>Ribert H. Schuller</h3>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
