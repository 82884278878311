import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

const ToolTipTitle = ({ toolTipDetails }: any) => {
  return (
    <>
      <Box padding={1} display="flex" flexDirection={"column"} gap={1}>
        <Typography color="inherit">
          ResponseId: {toolTipDetails?.responseId}
        </Typography>
        <Typography color="inherit">
          Flow Name: {toolTipDetails?.flowName}
        </Typography>
        <Typography color="inherit">
          Template name: {toolTipDetails?.templateName}
        </Typography>
        <Typography color="inherit">
          Created by: {toolTipDetails?.responseCreatedBy}
        </Typography>
        <Typography color="inherit">
          Updated by: {toolTipDetails?.responseUpdatedBy}
        </Typography>
        <Typography color="inherit">
          Approved by: {toolTipDetails?.responseApprovedBy}
        </Typography>
        <Typography color="inherit">
          Creation Date:{" "}
          {toolTipDetails?.creationDate &&
            dayjs(toolTipDetails?.creationDate).format("MMMM D, YYYY")}
        </Typography>
        <Typography color="inherit">
          Update Date:{" "}
          {toolTipDetails?.updateDate &&
            dayjs(toolTipDetails?.updateDate).format("MMMM D, YYYY")}
        </Typography>
        <Typography color="inherit">
          Approval Date:{" "}
          {toolTipDetails?.approvalDate &&
            dayjs(toolTipDetails?.approvalDate).format("MMMM D, YYYY")}
        </Typography>
        <Typography color="inherit">
          Submission Date:{" "}
          {toolTipDetails?.submissionDate &&
            dayjs(toolTipDetails?.submissionDate).format("MMMM D, YYYY")}
        </Typography>
      </Box>
    </>
  );
};

export default ToolTipTitle;
