import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";


const initialState = {
    totalRevenueProtectedData: {
        data: {},
        isLoading: false,
        isError: false,
        isSuccess: false,
    },

    linkedShopsData: {
        data: [],
        isLoading: false,
        isError: false,
        isSuccess: false,
    },

    deleteLinkedShopsData: {
        data: "",
        isLoading: false,
        isError: false,
        isSuccess: false,
    },

    linkedPayPalData: {
        data: [],
        isLoading: false,
        isError: false,
        isSuccess: false,
    },

    recentDisputesData: {
        data: [],
        isLoading: false,
        isError: false,
        isSuccess: false,
    },

    openDisputesData: {
        data: {},
        isLoading: false,
        isError: false,
        isSuccess: false,
    },
    
    recoveredDisputesData: {
        data: {},
        isLoading: false,
        isError: false,
        isSuccess: false,
    },

    currentWeekDisputesData: {
        data: {},
        isLoading: false,
        isError: false,
        isSuccess: false,
    },
}


//total-revenue-protected
const getTotalRevenueProtectedRequest = (state: any, action: any) => {
    return update(state, {
        totalRevenueProtectedData: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getTotalRevenueProtectedSuccess = (state: any, action: any) => {
    return update(state, {
        totalRevenueProtectedData: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set:  {...action.payload}  }
        }
    });
};

const getTotalRevenueProtectedError = (state: any, action: any) => {
    return update(state, {
        totalRevenueProtectedData: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


//linked-shops
const getLinkedShopsRequest = (state: any, action: any) => {
    return update(state, {
        linkedShopsData: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getLinkedShopsSuccess = (state: any, action: any) => {
    return update(state, {
        linkedShopsData: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getLinkedShopsError = (state: any, action: any) => {
    return update(state, {
        linkedShopsData: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


//delete-linked-shops
const deleteLinkedShopsRequest = (state: any, action: any) => {
    return update(state, {
        deleteLinkedShopsData: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: "" }
        }
    });
};

const deleteLinkedShopsSuccess = (state: any, action: any) => {
    return update(state, {
        deleteLinkedShopsData: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const deleteLinkedShopsError = (state: any, action: any) => {
    return update(state, {
        deleteLinkedShopsData: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


//linked-payPal
const getLinkedPayPalRequest = (state: any, action: any) => {
    return update(state, {
        linkedPayPalData: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getLinkedPayPalSuccess = (state: any, action: any) => {
    return update(state, {
        linkedPayPalData: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getLinkedPayPalError = (state: any, action: any) => {
    return update(state, {
        linkedPayPalData: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


//recent-disputes
const getRecentDisputesRequest = (state: any, action: any) => {
    return update(state, {
        recentDisputesData: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getRecentDisputesSuccess = (state: any, action: any) => {
    return update(state, {
        recentDisputesData: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getRecentDisputesError = (state: any, action: any) => {
    return update(state, {
        recentDisputesData: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


//open-disputes
const getOpenDisputesRequest = (state: any, action: any) => {
    return update(state, {
        openDisputesData: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getOpenDisputesSuccess = (state: any, action: any) => {
    return update(state, {
        openDisputesData: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set:  {...action.payload}  }
        }
    });
};

const getOpenDisputesError = (state: any, action: any) => {
    return update(state, {
        openDisputesData: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


//recovered-disputes
const getRecoveredDisputesRequest = (state: any, action: any) => {
    return update(state, {
        recoveredDisputesData: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getRecoveredDisputesSuccess = (state: any, action: any) => {
    return update(state, {
        recoveredDisputesData: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set:  {...action.payload}  }
        }
    });
};

const getRecoveredDisputesError = (state: any, action: any) => {
    return update(state, {
        recoveredDisputesData: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


//current-week-disputes
const getCurrentWeekDisputesRequest = (state: any, action: any) => {
    return update(state, {
        currentWeekDisputesData: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getCurrentWeekDisputesSuccess = (state: any, action: any) => {
    return update(state, {
        currentWeekDisputesData: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set:  {...action.payload}  }
        }
    });
};

const getCurrentWeekDisputesError = (state: any, action: any) => {
    return update(state, {
        currentWeekDisputesData: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.GET_TOTAL_REVENUE_PROTECTED_REQUEST]: getTotalRevenueProtectedRequest,
        [constants.GET_TOTAL_REVENUE_PROTECTED_SUCCESS]: getTotalRevenueProtectedSuccess,
        [constants.GET_TOTAL_REVENUE_PROTECTED_ERROR]: getTotalRevenueProtectedError,

        [constants.GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_REQUEST]: getTotalRevenueProtectedRequest,
        [constants.GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_SUCCESS]: getTotalRevenueProtectedSuccess,
        [constants.GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_ERROR]: getTotalRevenueProtectedError,

        [constants.GET_LINKED_SHOPS_REQUEST]: getLinkedShopsRequest,
        [constants.GET_LINKED_SHOPS_SUCCESS]: getLinkedShopsSuccess,
        [constants.GET_LINKED_SHOPS_ERROR]: getLinkedShopsError,

        [constants.GET_LINKED_SHOPS_BY_ACCOUNT_REQUEST]: getLinkedShopsRequest,
        [constants.GET_LINKED_SHOPS_BY_ACCOUNT_SUCCESS]: getLinkedShopsSuccess,
        [constants.GET_LINKED_SHOPS_BY_ACCOUNT_ERROR]: getLinkedShopsError,

        [constants.DELETE_LINKED_SHOPS_REQUEST]: deleteLinkedShopsRequest,
        [constants.DELETE_LINKED_SHOPS_SUCCESS]: deleteLinkedShopsSuccess,
        [constants.DELETE_LINKED_SHOPS_ERROR]: deleteLinkedShopsError,

        [constants.GET_LINKED_PAYPAL_REQUEST]: getLinkedPayPalRequest,
        [constants.GET_LINKED_PAYPAL_SUCCESS]: getLinkedPayPalSuccess,
        [constants.GET_LINKED_PAYPAL_ERROR]: getLinkedPayPalError,

        [constants.GET_LINKED_PAYPAL_BY_ACCOUNT_REQUEST]: getLinkedPayPalRequest,
        [constants.GET_LINKED_PAYPAL_BY_ACCOUNT_SUCCESS]: getLinkedPayPalSuccess,
        [constants.GET_LINKED_PAYPAL_BY_ACCOUNT_ERROR]: getLinkedPayPalError,

        [constants.GET_RECENT_DISPUTES_REQUEST]: getRecentDisputesRequest,
        [constants.GET_RECENT_DISPUTES_SUCCESS]: getRecentDisputesSuccess,
        [constants.GET_RECENT_DISPUTES_ERROR]: getRecentDisputesError,

        [constants.GET_RECENT_DISPUTES_BY_ACCOUNT_REQUEST]: getRecentDisputesRequest,
        [constants.GET_RECENT_DISPUTES_BY_ACCOUNT_SUCCESS]: getRecentDisputesSuccess,
        [constants.GET_RECENT_DISPUTES_BY_ACCOUNT_ERROR]: getRecentDisputesError,

        [constants.GET_OPEN_DISPUTES_REQUEST]: getOpenDisputesRequest,
        [constants.GET_OPEN_DISPUTES_SUCCESS]: getOpenDisputesSuccess,
        [constants.GET_OPEN_DISPUTES_ERROR]: getOpenDisputesError,

        [constants.GET_OPEN_DISPUTES_BY_ACCOUNT_REQUEST]: getOpenDisputesRequest,
        [constants.GET_OPEN_DISPUTES_BY_ACCOUNT_SUCCESS]: getOpenDisputesSuccess,
        [constants.GET_OPEN_DISPUTES_BY_ACCOUNT_ERROR]: getOpenDisputesError,

        [constants.GET_RECOVERED_DISPUTES_REQUEST]: getRecoveredDisputesRequest,
        [constants.GET_RECOVERED_DISPUTES_SUCCESS]: getRecoveredDisputesSuccess,
        [constants.GET_RECOVERED_DISPUTES_ERROR]: getRecoveredDisputesError,

        [constants.GET_RECOVERED_DISPUTES_BY_ACCOUNT_REQUEST]: getRecoveredDisputesRequest,
        [constants.GET_RECOVERED_DISPUTES_BY_ACCOUNT_SUCCESS]: getRecoveredDisputesSuccess,
        [constants.GET_RECOVERED_DISPUTES_BY_ACCOUNT_ERROR]: getRecoveredDisputesError,

        [constants.GET_CURRENT_WEEK_DISPUTES_REQUEST]: getCurrentWeekDisputesRequest,
        [constants.GET_CURRENT_WEEK_DISPUTES_SUCCESS]: getCurrentWeekDisputesSuccess,
        [constants.GET_CURRENT_WEEK_DISPUTES_ERROR]: getCurrentWeekDisputesError,

        [constants.GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_REQUEST]: getCurrentWeekDisputesRequest,
        [constants.GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_SUCCESS]: getCurrentWeekDisputesSuccess,
        [constants.GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_ERROR]: getCurrentWeekDisputesError,

    },
    initialState
);