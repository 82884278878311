import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface DisputeObj {
    id: string;
    createTime: string;
    updateTime: string;
    status: string;
    reason: string;
    disputeState: string;
    disputeLifeCycleStage: string;
    order: string;
    storeOrderId: string;
    shopName: string;
    shopType: string;
    sellerMerchantId: string;
    disputeAmountValue: number;
    disputeAmountCurrencyCode: string;
    sellerResponseDueDate: string;
    buyerResponseDueDate: string;
    sellerTransactionId: string;
    paypalId: string;
}

interface ResProp {
    data: {
        count: number,
        disputeResponseList: DisputeObj[],
    },
    status: number
}


// Action handlers for awaiting disputes
export function* getFilteredWaitingSellerRequest(action: any) {
    try {
        const res: ResProp = yield Api.getFilteredAwaitingDisputes(action.payload);
        if (res.status === 500 || res.status === 401) {
            yield put(actions.getFilteredWaitingSellerError());
        } else {
            yield put(actions.getFilteredWaitingSellerSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getFilteredWaitingSellerError());
    }
}
