import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useUpdateCollaborator } from "hooks";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styles from "./style.module.scss";

export default function SelectAccess({
  collaboratorAccess,
}: {
  collaboratorAccess: string;
}) {
  const { handleChange, access, toUsers } =
    useUpdateCollaborator(collaboratorAccess);

  return (
    <Box className={styles.selectUpdateWrapper}>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={access}
          IconComponent={KeyboardArrowDownIcon}
          onChange={handleChange}
          style={{
            width: "260px",
            borderRadius: "8px",
            border: `1px solid #D0D5DD`,
            height: "40px",
          }}
        >
          {toUsers.map((item, index) => (
            <MenuItem value={item?.id} key={index}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
